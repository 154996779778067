import React, { useState, useEffect } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import Navbar1 from "../Navbar/Navbar1";
import LeftMenu1 from "../LeftMenu/LeftMenu1";
import { enqueueSnackbar } from 'notistack'
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const CreateProduct = () => {
    const navigate = useNavigate();
    const locations = useLocation();
    const employee = locations.state;
    const [productFamilyName, setProductFamilyName] = useState('');
    const [productType, setProductType] = useState('');
    const [description, setDescription] = useState('');
    const [productTypes, setProductTypes] = useState([]);
    const [productCode, setProductCode] = useState('');
    const [selectedProductType, setSelectedProductType] = useState();
    const [companyId, setCompanyId] = useState("")
    const [status, setStatus] = useState(1)
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getAllProductTypes();
    }, []);
    const handleChangeProductType = (e) => {
        setSelectedProductType(e.target.value);
    }
    const getAllProductTypes = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getAllProductTypes', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, response.data);
                    setProductTypes(response.data.productTypes);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const createProduct = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: 'error' })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "productFamilyName": productFamilyName,
            "productCode": productCode.toUpperCase(),
            "userId": employee.id,
            "companyId": employee.companyId,
            "productType": parseInt(selectedProductType),
            "description": description,
            "status": status
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/createProduct', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    enqueueSnackbar("Product Created Successfully !", { variant: "success" })
                    navigate(`/${employee.companyName}/companyadmin/Product`);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
                enqueueSnackbar(error.response.data.message, { variant: "error" })
            });
    };
    const isAlphanumeric = (input) => {
        const regex = /^[a-zA-Z0-9]+$/;
        if (!regex.test(input)) {
            setErrors({
                ...errors,
                productCodeError: true
            })
            return
        }
        setProductCode(input);
        setErrors({ ...errors, productCodeError: false })
    }
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!productFamilyName) {
            errorObj = {
                ...errorObj,
                productFamilyNameError: true
            }
            error = true;
        }
        if (!productCode || errors.productCodeError) {
            errorObj = {
                ...errorObj,
                productCodeError: true
            }
            error = true;
        }
        if (!selectedProductType) {
            errorObj = {
                ...errorObj,
                productTypeError: true
            };
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    return (
        <section className="h-screen flex flex-col justify-center items-center mx-5">
            <Navbar1 employee={employee} />
            <LeftMenu1 section="Add Product" />
            <div><i onClick={() => navigate(`/${employee.companyName}/companyadmin/Product`)} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i></div>
            <div className="md:w-1/3 max-w-sm">
                <div className="text-center md:text-left">
                    <label className="mr-1 text-lg text-black-600">Create Product</label>
                </div>
                <input className={`text-sm w-full px-4 py-2 ${errors.productCodeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-4`}
                    type="text"
                    onChange={(e) => { isAlphanumeric(e.target.value) }}
                    name="productCode"
                    pattern="[a-zA-Z0-9]+"
                    maxLength={15}
                    placeholder="Product Code" />
                {errors.productCodeError && <div className="text-red-700 text-xs">
                    <p>Please enter only alphanumeric characters</p>
                </div>}
                <input className={`text-sm w-full px-4 py-2 ${errors.productFamilyNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-4`}
                    type="text"
                    onChange={(e) => { setProductFamilyName(e.target.value); setErrors({ ...errors, productFamilyNameError: false }) }}
                    name="productFamilyName"
                    placeholder="Product Family Name" />
                <select name="productType" value={selectedProductType} className={`text-sm w-full px-4 py-2 ${errors.productTypeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-4`}
                    onChange={(e) => { handleChangeProductType(e); setErrors({ ...errors, productTypeError: false }) }}>
                    <option value="">Select Product Type</option>
                    {productTypes && productTypes.map(pType => {
                        return (
                            <option key={pType.id} value={pType.id}>{pType.productType}</option>
                        )
                    })}
                </select>
                <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-4"
                    type="text"
                    onChange={(e) => setDescription(e.target.value)}
                    name="description"
                    placeholder="Description" />

                <div className="flex justify-end text-center md:text-left">
                    {/* <button className="mt-4 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white uppercase rounded text-xs tracking-wider" onClick={() => navigate(-1)} type="submit">Back</button> */}
                    <button className="mt-4 bg-custom-bg-color hover:custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs" onClick={createProduct} type="submit">Create</button>
                </div>
            </div>
        </section>
    )
}

export default CreateProduct
import React, { useContext, useEffect, useState } from 'react'
import Navbar1 from '../../Navbar/Navbar1'
import LeftMenu1 from '../../LeftMenu/LeftMenu1'
import EmployeeContext from '../../context/employee/EmpContext'
import { useLocation, useNavigate } from 'react-router-dom'
import { OverlayTrigger, Tab, Tabs, Tooltip } from 'react-bootstrap'
import { config } from '../../Config/Config'
import axios from 'axios'
import SimpleCrypto from 'simple-crypto-js'
import { enqueueSnackbar } from 'notistack'
import moment from 'moment-timezone'
import ToDoPopup from './ToDoPopup'
import DealToDoDetails from './DealToDoPopup'

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const DealDetails = () => {
    const navigate = useNavigate();
    const loaction = useLocation();
    const { data, key, dataCount } = loaction.state;
    const { empState: employee } = useContext(EmployeeContext);
    const [currentKey, setCurrentKey] = useState();
    const [conversations, setConversations] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [currentStatus, setCurrentStatus] = useState();
    const [currrentConversation, setCurrentConversation] = useState([]);
    const [error, setErorr] = useState(false);
    const [showToDoPopup, setShowToDoPopup] = useState(false);
    const [popupData, setPopupData] = useState();
    const [proposal, setProposal] = useState(false);
    const [dealToDoPopup, setDealToDoPopup] = useState(false);
    const [dealPopupData, setDealPopupData] = useState();

    useEffect(() => {
        console.log("data", data, key);
        setCurrentStatus(data.dealStatus)
        getDealConversations();
    }, [])
    const getDealConversations = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            id: data.id
        }
        axios.post(config.apiUrl + 'employees/getDealConversation', payload, { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    console.log(response.data.message, response.data.dealConversation)
                    setConversations(response.data.dealConversation || []);
                    let conversationData = response.data.dealConversation && response.data.dealConversation.filter(deal => parseInt(deal.status) === data.dealStatus);
                    let dataArray = conversationData?.length > 0 ? conversationData[0].conversations : [];
                    setCurrentConversation(dataArray)
                    setCurrentKey(key);
                } else {
                    console.log("Error");
                }
            }).catch((error) => {
                console.error('Error loading products data:', error);
                enqueueSnackbar("Something went wrong!!", { variant: "error" })
            })
    };
    useEffect(() => {
        let conversationData = [];
        let statusData;
        if (currentKey === "new") {
            conversationData = conversations && conversations.filter(deal => parseInt(deal.status) === 1)
            statusData = 1;
        }
        if (currentKey === "discussion") {
            conversationData = conversations && conversations.filter(deal => parseInt(deal.status) === 2)
            statusData = 2;
        }
        if (currentKey === "negotiation") {
            conversationData = conversations && conversations.filter(deal => parseInt(deal.status) === 3)
            statusData = 3;
        }
        if (currentKey === "closed") {
            conversationData = conversations && conversations.filter(deal => parseInt(deal.status) === 4 || parseInt(deal.status) === 5)
            statusData = conversationData.length > 0 ? conversationData[0].status : 4;
        }
        let dataArray = conversationData.length > 0 ? conversationData[0].conversations : [];
        setCurrentConversation([...dataArray]);
        setCurrentStatus(statusData)
    }, [currentKey]);
    const addNewComment = async () => {
        if (!newComment) {
            setErorr(true)
            enqueueSnackbar('Please fill all the necessary fields!', { variant: 'error' })
            return
        };
        let headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            id: data.id,
            currentStatus: data.dealStatus,
            comment: newComment,
            name: employee.firstName + " " + employee.lastName,
            empId: employee.employeeId,
            proposal: proposal
        }
        console.log(payload)
        axios.post(config.apiUrl + "employees/addNewEmployeeComment", payload, { headers: headers })
            .then((response) => {
                if (response.data.status === true) {
                    console.log("Success", response.data.message)
                    enqueueSnackbar("Status Updated Successfully", { variant: "success" })
                    setNewComment("");
                    setProposal(false)
                    getDealConversations();
                } else {
                    console.log("Error")
                }
            }).catch((error) => {
                enqueueSnackbar("Something went wrong", { variant: "error" });
            })
    }
    const togglePopup = (data = {}) => {
        setPopupData({ ...data, notes: newComment });
        setShowToDoPopup(!showToDoPopup)
    }
    const getEmployeeRole = () => {
        if (employee.role === 1) return "companyadmin"
        else if (employee.role === 2) return "branchadmin"
        else return "salesperson"
    }
    const updateStatus = async (state, commentId) => {
        let headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            id: data.id,
            dealStatus: data.dealStatus,
            newStatus: state,
            commentId: commentId
        }
        console.log(payload)
        axios.post(config.apiUrl + "manager/updateStausOfDealProposals", payload, { headers: headers })
            .then((response) => {
                if (response.data.status === true) {
                    console.log("Success", response.data.message)
                    enqueueSnackbar("Proposal Status Updated Successfully", { variant: "success" })
                    getDealConversations();
                } else {
                    console.log("Error")
                }
            }).catch((error) => {
                enqueueSnackbar("Something went wrong", { variant: "error" });
            })
    };
    const getDataCount = (text, flag) => {
        return (
            <div className={`flex justify-between items-center w-full ${flag ? "text-orange-300" : "text-custom-text-color"}`}>
                <p>{text}</p>
                {/* <div className='w-4 h-4 text-xs rounded-full bg-custom-bg-color text-center text-custom-text-color'>{count}</div> */}
            </div>
        )
    }
    const toggleDealToDoPopup = (data = {}) => {
        setDealPopupData(data);
        setDealToDoPopup(!dealToDoPopup)
    }
    const handleToDoClick = (todoData) => {
        if (todoData.todo && todoData.todoId) {
            toggleDealToDoPopup({ ...todoData, customer: data.customer })
        }
    }
    return (
        <div className="h-screen flex flex-col justify-center items-center">
            {showToDoPopup && <ToDoPopup show={showToDoPopup} togglePopup={togglePopup} data={popupData} getDealConversations={getDealConversations} />}
            {dealToDoPopup && <DealToDoDetails show={dealToDoPopup} togglePopup={toggleDealToDoPopup} data={dealPopupData} />}
            <Navbar1 />
            <LeftMenu1 section="Deal Details" />
            <div className=' absolute left-60 top-16 font-semibold'>{data.customer}</div>
            <div className='max-w-min absolute left-60 top-24'>
                <i onClick={() => navigate(`/${employee.companyName}/${getEmployeeRole()}/Deals`)}
                    className="fa fa-long-arrow-left fa-2x text-orange-600 cursor-pointer"
                    aria-hidden="true">
                </i>
            </div>
            <div className="w-[70vw] ml-40 mt-16 items-center">
                <ul className="breadcrumb" >
                    <li onClick={() => setCurrentKey("new")}><span>{getDataCount("New", currentKey === "new")}</span></li>
                    <li onClick={() => setCurrentKey("discussion")}><span>{getDataCount("Discussion", currentKey === "discussion")}</span></li>
                    <li onClick={() => setCurrentKey("negotiation")}><span>{getDataCount("Negotiation", currentKey === "negotiation")}</span></li>
                    <li onClick={() => setCurrentKey("closed")}><span>{getDataCount("Closed", currentKey === "closed")}</span></li>
                </ul>
            </div>
            <div className="w-[70vw] min-h-[75vh] ml-40 flex flex-col justify-center items-center">
                <div className='w-full h-[63vh] overflow-y-auto border border-gray-600 rounded-xl'>
                    {currrentConversation && currrentConversation.map((data, i) => {
                        return (
                            <div key={data.id} className={`flex flex-col p-2 border-b-2 rounded-lg text-sm ${data.todo && data.todoId ? "cursor-pointer" : ""}
                            ${data.todo ? "bg-orange-100" : data.proposal && data.status == 1 ? "bg-green-300" : data.proposal && data.status == 2 ? "bg-green-200" : data.proposal && data.status == 3 ? "bg-red-400" : "bg-green-50"}`}
                                onClick={() => handleToDoClick(data)}>
                                <p className="text-xs">[{moment(data.id).format("DD-MM-YY hh:mma")}]</p>
                                <div className={`flex justify-between items-center`}>
                                    <p className='max-w-[85%] break-words'>{data.name}({data.empId}) : {data.comment}</p>
                                    <p>{data.todo && data.actionType === 1 ?
                                        <i className="fa fa-phone color-white" aria-hidden="true">&nbsp;{moment(data.targetDate).format("DD-MM")}</i>
                                        : data.actionType === 2 ? <i className="fa fa-envelope color-white" aria-hidden="true">&nbsp;{moment(data.targetDate).format("DD-MM")}</i>
                                            : data.actionType === 2 ? <i className="fa fa-user color-white" aria-hidden="true">&nbsp;{moment(data.targetDate).format("DD-MM")}</i>
                                                : <></>}
                                    </p>
                                </div>
                                {employee.role === 2 && data.proposal && !data.status &&
                                    < div className='flex gap-2 justify-end'>
                                        <button className='text-xs p-1 rounded-sm bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color' onClick={() => updateStatus(1, data.id)}>Accept</button>
                                        <button className='text-xs p-1 rounded-sm bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color' onClick={() => updateStatus(2, data.id)}>Partially Accept</button>
                                        <button className='text-xs p-1 rounded-sm bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color' onClick={() => updateStatus(3, data.id)}>Reject</button>
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
                <div className='w-full flex justify-center items-center mt-3 relative'>
                    <textarea
                        maxLength={150}
                        rows={3}
                        value={newComment}
                        className={`resize-none w-full rounded-xl bg-gray-200 outline-none p-2 ${error ? "border-[1px] border-red-600" : ""}`}
                        onChange={(e) => { setNewComment(e.target.value); setErorr(false) }}
                        placeholder='Enter New Comment'
                    />
                    <button onClick={() => togglePopup(data)}
                        className={`absolute right-16 bottom-1 w-24 p-1 rounded-lg  ${data.dealStatus !== currentStatus ? "bg-gray-400" : "bg-custom-bg-color"} text-custom-text-color cursor-pointer`}
                        aria-hidden="true"
                        disabled={data.dealStatus !== currentStatus}>
                        Add ToDo
                    </button>
                    <button
                        className={`absolute right-1 bottom-1 text-custom-text-color py-1 px-2 rounded-md uppercase ${data.dealStatus !== currentStatus ? "bg-gray-400" : "bg-custom-bg-color"}`}
                        disabled={data.dealStatus !== currentStatus}
                        onClick={addNewComment}
                    >Save</button>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id="button-tooltip">Is this a proposal from customer?</Tooltip>}
                        className="bg-custom-bg-color text-custom-text-color"
                    >
                        <input
                            type='checkbox'
                            value={proposal}
                            onChange={(e) => setProposal(!proposal)}
                            className={`w-3 h-3 absolute top-2 right-3 cursor-pointer ${employee.role !== 3 ? "hidden" : ""}`}
                        />
                    </OverlayTrigger>
                </div>
            </div>

        </div >
    )
}

export default DealDetails
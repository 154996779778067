import React, { Component, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
import { Bar } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const ProductEnquiryGraph = ({ setTProduct, headOffice }) => {
    const [values, setValues] = useState([]);
    const [salesLabels, setSalesLabels] = useState([]);
    const [salesValues, setSalesValues] = useState([]);
    const [branches, setBranches] = useState([]);
    const [current, setCurrent] = useState()
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    useEffect(() => {
        getProductsCountBySales();
    }, [])
    const getProductsCountBySales = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getTopProductsByMonth', { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log(response.data.message, response.data);
                    setValues(response.data.highestMonthlySales);
                    headOffice ? setTProduct(response.data.topProduct?.name) : console.log("brnahces")
                    let curBranches = response.data.allBranches;
                    if (!headOffice) {
                        curBranches = curBranches && curBranches.filter(b => b.branchName !== "Head Office");
                    } else if (headOffice) {
                        curBranches = curBranches && curBranches.filter(b => b.branchName === "Head Office");
                    }
                    setCurrent(curBranches[0]?.id)
                    setBranches(curBranches);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    useEffect(() => {
        updateGraphData();
    }, [current])
    const updateGraphData = () => {
        let graphData = values && values.filter(val => val.branchId === current).map(item => item.data);
        graphData = graphData && graphData[0]
        let sValue = graphData && graphData.map(item => item.count);
        let sLabel = graphData && graphData.map(item => item.name);
        setSalesLabels(sLabel);
        setSalesValues(sValue);
    }
    const users = {
        labels: months,
        datasets: [
            {
                data: salesValues,
                backgroundColor: [
                    'black',
                ],
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20,
                labels: salesLabels
            }
        ]
    }
    if (headOffice) return <></>
    return (
        <div className="">
            <Card className="flex flex-col justify-between relative" style={{ marginTop: "1rem", height: "250px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                <div className="font-semibold text-lg ml-8">Top Product By Sales</div>
                <select name="branches" value={current} className="absolute -top-1 right-1 text-xs w-32  border border-solid border-gray-300 rounded mt-2"
                    onChange={(e) => setCurrent(e.target.value)} disabled={headOffice}>
                    <option value="">---Choose Branch---</option>
                    {branches && branches.map((item, i) => {
                        return (
                            <option value={item.id} key={item.id}>{item.branchName}</option>
                        )
                    })}
                </select>
                <div style={{ height: "200px" }}>
                    <Bar data={users}
                        options={{
                            maintainAspectRatio: true,
                            responsive: true,
                            redraw: false,
                            elements: {
                                line: {
                                    tension: 1
                                }
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        font: {
                                            size: 10, // Adjust the font size as needed
                                        },
                                    },
                                },
                                y: {
                                    ticks: {
                                        precision: 0
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: false
                                },
                                tooltip: {
                                    enabled: true,
                                    callbacks: {
                                        label: function (context) {
                                            const index = context.dataIndex;
                                            const label = context.dataset.labels.find((label, i) => i === index) || '';
                                            const value = context.parsed.y || 0;
                                            return `${label}: ${value}`;
                                        },
                                    },
                                },
                            }
                        }
                        } />
                </div>
            </Card>
        </div >
    );
};

export default ProductEnquiryGraph
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import axios from 'axios';
import { config } from "../../Config/Config";
import SimpleCrypto from 'simple-crypto-js';
import tick from "../../images/right-tick.svg";
import EmployeeContext from '../../context/employee/EmpContext';
import { useQuery } from 'react-query';

var passwordKey = config.PASSWORD_ENCRYPTION_KEY;
var simpleCrypto = new SimpleCrypto(passwordKey);

export default function AllServices({ setSection, setServiceData }) {
    const { empState: employee } = useContext(EmployeeContext);
    const navigate = useNavigate();
    const [services, setServices] = useState([])

    useEffect(() => {
        getAllServices();
    }, []);

    const getAllServices = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        return axios.get(config.apiUrl + 'companyAdmin/getAllServices', { headers: headers })
    };
    const { data: customersDetails, refetch: refetchCustomers } = useQuery("customers-details", getAllServices, {
        onSuccess: (response) => {
            console.log("success", response.data.message);
            setServices(response.data.allServices)
        },
        onError: (error) => {
            console.error('Error loading services data:', error);
        },
    });
    const navigateToUnregister = (service) => {
        navigate(`/${employee.companyName}/${service.serviceName}/unregister`, { state: { data: service } })
    }
    const navigateToRegister = (service) => {
        navigate(`/${employee.companyName}/${service.serviceName}/register`, { state: { data: service } })
    }
    const registerForService = (data) => {
        if (data.external_service)
            navigateToRegister(data);
        else
            setSection(data.section);
        setServiceData(data);
    }
    return (
        <div className='my-16 grid grid-cols-3 gap-4'>
            {services && services.map((data, i) => (
                <div key={i} className="ml-4 mt-4 w-full md:min-h-[40vh] border-[1px] border-black rounded-xl relative flex flex-col justify-between">
                    <img src={tick} alt='' className={`absolute top-14 right-2 ${data.available ? "" : "hidden"}`} />
                    <div className="flex w-full h-12 items-center justify-center rounded-t-xl text-lg bg-custom-bg-color text-custom-text-color">
                        {data.serviceName}
                    </div>
                    <div className='py-4 px-4 flex-grow'>
                        <p>{data.serviceDescription}</p>
                    </div>
                    <div className='flex justify-between items-center m-1'>
                        {data.available && data.section === "Directory" &&

                            <div className='flex justify-between items-center w-3/5'>
                                <button
                                    className={`max-w-max bg-custom-bg-color text-custom-text-color p-2 font-semibold uppercase rounded-lg text-xs`}
                                    onClick={() => setSection("ViewLeads")}
                                >View Leads</button>
                                <button
                                    className={`max-w-max bg-custom-bg-color text-custom-text-color p-2 font-semibold uppercase rounded-lg text-xs`}
                                    onClick={() => setSection(data.section)}
                                >Configure</button>
                            </div>}
                        <div className={`px-4 font-semibold text-base ${data.available ? "hidden" : ""}`}>Rs.{data.price === null ? 0 : data.price?.toFixed(2)}/Year</div>
                        <button
                            className={`max-w-max bg-custom-bg-color text-custom-text-color p-2 font-semibold uppercase rounded-lg text-xs ml-auto ${data.available ? "hidden" : ""}`}
                            onClick={() => registerForService(data)}
                        >Register</button>
                        <button
                            className={`max-w-max bg-custom-bg-color text-custom-text-color p-2 font-semibold uppercase rounded-lg text-xs ml-auto ${data.available ? "" : "hidden"}`}
                            onClick={() => navigateToUnregister(data)}
                        >Unregister</button>
                    </div>
                </div>
            ))}
        </div>
    )
}

import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment-timezone";
import { config } from "../../Config/Config";
import download from "../../images/download-icon.svg";
import { downloadICSFile, generateICSFile } from "./utils";
import business from '../../images/business-meet.svg';
import personal from '../../images/personal-meet.svg';
import self from '../../images/self-meet.svg';
import inPerson from '../../images/in-person.svg';
import phoneMeet from '../../images/phone-meet.svg';
import virtual from '../../images/virtual-meet.svg';
import { enqueueSnackbar } from "notistack";


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const ViewDetail = ({ data, closePopup, queryEmail, statusArr, getAllMeetings, empDetails }) => {
    const [errors, setErrors] = useState();
    const [deleteFlag, setDeleteFlag] = useState(false);
    const [deletionReason, setDeletionReason] = useState('');

    useEffect(() => {
        console.log("data", data);
        setDeletionReason(data?.deletion_reason)
    }, []);
    const getStaus = (id) => {
        let curStatus = statusArr && statusArr.find(data => data.id === id);
        return curStatus?.status
    }
    const handleDownloadICS = (data) => {
        let startTime = new Date(data.start_time);
        let endTime = new Date(data.end_time);
        const meetingDetails = {
            startYear: startTime.getFullYear(),
            startMonth: startTime.getMonth() + 1,
            startDay: startTime.getDate(),
            startHour: startTime.getHours(),
            startMinute: startTime.getMinutes(),
            endYear: endTime.getFullYear(),
            endMonth: endTime.getMonth() + 1,
            endDay: endTime.getDate(),
            endHour: endTime.getHours(),
            endMinute: endTime.getMinutes(),
            title: data.title,
            description: data.description,
            location: 'Online-Web Meet',
        };
        const icsContent = generateICSFile(meetingDetails);
        if (icsContent) {
            downloadICSFile(icsContent, 'meeting.ics');
        }
    };
    const editMeetingStatus = (event, status) => {
        event.preventDefault();
        if (!deleteFlag) {
            setDeleteFlag(!deleteFlag);
            setErrors(true);
            return;
        }
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "meetingStatus": status,
            "id": data.id,
            deletionReason
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'manager/updateMeetingStatus', payload, { headers: headers })
            .then(function (response) {
                console.log("success", response.data);
                getAllMeetings();
                closePopup();
                enqueueSnackbar("Meeting Deleted Successfully", { variant: "success" })
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    return (
        <div>
            <Modal
                show={true}
                onHide={closePopup}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                contentClassName="border-[2px] border-black rounded-2xl"
            >
                <div className="max-w-lg min-w-[25vw] my-2 mx-8 md:mx-auto mb-4">
                    <div className="flex items-center w-full justify-between">
                        <label className="mr-1 text-lg text-black-600 w-2/3 text-end">Meeting Details</label>
                        <OverlayTrigger placement="right"
                            overlay={<Tooltip>
                                <p>Download ICS file</p>
                            </Tooltip>}>
                            <img src={download} alt="" className="w-6 h-6 cursor-pointer" onClick={() => handleDownloadICS(data)} />
                        </OverlayTrigger>
                    </div>
                    <ul className="flex items-center list-none my-2">
                        <li className="flex-shrink-0 h-3 w-3 bg-black rounded-full text-center relative">
                            <span className="absolute top-4 -right-6 text-xs font-semibold">{moment(data.start_time).format("hh:mma")}</span></li>
                        <li className="flex-grow border-t-2 border-black relative">
                            <span className="absolute top-2 left-[40%] text-xs font-semibold">{moment(data.start_time).format("DD-MM-YYYY")}</span>
                        </li>
                        <li className="flex-shrink-0 h-3 w-3 bg-black rounded-full relative">
                            <span className="absolute top-4 -right-6 text-xs font-semibold">{moment(data.end_time).format("hh:mma")}</span>
                        </li>
                    </ul>
                    <div className="grid grid-cols-3 gap-3 w-full mb-3 mt-10">
                        <div className={`flex flex-col gap-3 rounded-lg items-center justify-center border-[1px] border-gray-400 p-2 ${data.meeting_reason === 1 ? "bg-gray-200" : ""}`}>
                            <img src={business} alt="Official" className="w-10 h-10" />
                            <span className="text-xs font-semibold">Official</span>
                        </div>
                        <div className={`flex flex-col gap-3 rounded-lg items-center justify-center border-[1px] border-gray-400 p-2 ${data.meeting_reason === 2 ? "bg-gray-200" : ""}`}>
                            <img src={personal} alt="Personal" className="w-10 h-10" />
                            <span className="text-xs font-semibold">Personal</span>
                        </div>
                        <div className={`flex flex-col gap-3 rounded-lg items-center justify-center border-[1px] border-gray-400 p-2 ${data.meeting_reason === 3 ? "bg-gray-200" : ""}`}>
                            <img src={self} alt="Self" className="w-10 h-10" />
                            <span className={`text-xs font-semibold`}>Self</span>
                        </div>
                    </div>
                    <div className="grid grid-cols-3 gap-3 w-full mb-2">
                        <div className={`flex flex-col gap-3 rounded-lg items-center justify-center border-[1px] border-gray-400 p-2 ${data.conversation_type === 1 ? "bg-gray-200" : ""}`}>
                            <img src={virtual} alt="Virtual" className="w-10 h-10" />
                            <span className="text-xs font-semibold">Virtual</span>
                        </div>
                        <div className={`flex flex-col gap-3 rounded-lg items-center justify-center border-[1px] border-gray-400 p-2 ${data.conversation_type === 2 ? "bg-gray-200" : ""}`}>
                            <img src={inPerson} alt="In Person" className="w-10 h-10" />
                            <span className="text-xs font-semibold">In Person</span>
                        </div>
                        <div className={`flex flex-col gap-3 rounded-lg items-center justify-center border-[1px] border-gray-400 p-2 ${data.conversation_type === 3 ? "bg-gray-200" : ""}`}>
                            <img src={phoneMeet} alt="Phone" className="w-10 h-10" />
                            <span className={`text-xs font-semibold`}>Phone</span>
                        </div>
                    </div>
                    <label className="mr-1 text-sm text-black-600 -mb-2">Name</label>
                    <p className={`text-sm w-full p-2 h-9 rounded border-[1px] border-gray-300`}
                        placeholder={queryEmail ? "Attendees Name" : "Organisers Name"}>{data.organisers_name === empDetails?.name ? data.attendees_name : data.organisers_name}</p>
                    <label className="mr-1 text-sm text-black-600 mt-2 -mb-2">Email</label>
                    <p className={`text-sm w-full p-2 h-9 rounded border-[1px] border-gray-300`}
                        placeholder={queryEmail ? "Attendees Email" : "Organisers Email"}>{data.organisers_email === empDetails?.name ? data.attendees_email : data.organisers_email}</p>
                    <label className="mr-1 text-sm text-black-600 mt-2 -mb-2">Title</label>
                    <p className={`text-sm w-full p-2 h-9 border-[1px] border-gray-300 rounded resize-none`}
                        placeholder="Enter a title for the meeting">{data.title}</p>
                    <label className="mr-1 text-sm text-black-600 mt-2 -mb-2">Description</label>
                    <p className="text-sm w-full p-2 min-h-[2rem] border border-solid border-gray-300 rounded resize-none"
                        placeholder="Decribe the meeting agenda">{data.description}</p>
                    <div className={`flex gap-4 mt-2 w-full items-center ${deleteFlag || data.meeting_status === 5 || data.meeting_status === 6 ? "hidden" : ""}`}>
                        <div className="flex flex-col w-1/2">
                            <label className="mr-1 text-sm text-black-600">Status</label>
                            <p className="text-sm w-full p-2 min-h-[2rem] border border-solid border-gray-300 rounded resize-none"
                                placeholder="Decribe the meeting agenda">{getStaus(data.meeting_status)}</p>
                        </div>
                    </div>
                    <div className={`flex flex-col ${deleteFlag || data.meeting_status === 5 || data.meeting_status === 6 ? "" : "hidden"}`}>
                        <label className="mr-1 text-sm text-black-600 mt-2">{data.meeting_status === 5 ? "Reason for Rejection" : "Reason For Deletion"}</label>
                        <textarea className={`text-sm w-full p-2 mb-2 -mt-2 ${errors ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded resize-none`}
                            type="text"
                            onChange={(e) => { setDeletionReason(e.target.value); setErrors(false) }}
                            name="title"
                            value={deletionReason}
                            placeholder="Deletion Reason"
                            rows={1}
                            maxLength={40}
                            disabled={data.meeting_status === 5 || data.meeting_status === 6}
                        />
                    </div>
                </div>
                <div className={`flex justify-between text-center mb-3 mx-12`}>
                    <button className={`bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white font-semibold uppercase rounded text-xs ${deleteFlag || data.meeting_status === 6 || data.meeting_status === 5 ? "invisible" : ""}`} type="submit" onClick={(e) => editMeetingStatus(e, 6)}>DELETE</button>
                    <button className={`bg-custom-bg-color px-4 py-2 text-white font-semibold uppercase rounded text-xs ${deleteFlag ? "hidden" : ""}`} type="submit" onClick={closePopup}>Close</button>
                    <button className={`bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white font-semibold uppercase rounded text-xs ${deleteFlag ? "" : "hidden"}`} type="submit" onClick={(e) => editMeetingStatus(e, 6)}>UPDATE</button>
                </div>
            </Modal >
        </div >
    )
}

export default ViewDetail
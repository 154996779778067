import { enqueueSnackbar } from 'notistack';
import React, { useContext, useState, useEffect } from 'react'; import { useLocation, useNavigate } from 'react-router-dom';
import EmployeeContext from '../../context/employee/EmpContext';
import { config } from '../../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import axios from 'axios';
import Navbar1 from '../../Navbar/Navbar1';
import LeftMenu1 from '../../LeftMenu/LeftMenu1';


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const UserDetails = (props) => {
    const navigate = useNavigate();
    const { empState: employee } = useContext(EmployeeContext);
    const location = useLocation();
    const customer = location.state?.customer;
    const [errors, setErrors] = useState({});
    const [availableFields, setAvailableFields] = useState([]);
    const [activityId, setActivityId] = useState('');
    const [ageRanges, setAgeRanges] = useState([]);
    const [incomeRanges, setIncomeRanges] = useState([]);
    const [occupations, setOccupations] = useState([]);
    const [maritalStatusValues, setMaritalStatusValues] = useState([]);
    const [genders, setGenders] = useState([]);
    const [place, setPlace] = useState([]);
    const [leadTypes, setLeadTypes] = useState([]);
    const [userTypes, setUserTypes] = useState([]);
    const [insuranceTypes, setInsuranceTypes] = useState([]);
    const [inputValues, setInputValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        leadType: 1,
        region: 1,
        gender: '',
        age: '',
        income: '',
        occupation: '',
        maritalStatus: '',
        companyName: '',
        userType: '',
        annualIncome: null,
        dependents: null,
        otherIncome: null,
        loanAmount: null,
        tenure: null,
        insuranceType: "",
        insuredValue: null
    });

    useEffect(() => {
        getAllParameters();
        getAvailableFields();
    }, [])
    useEffect(() => {
        console.log("Customer useeffect caleed", customer);
        let currentValues = { ...inputValues };
        currentValues = { ...currentValues, ["firstName"]: customer.firstName || "" };
        currentValues = { ...currentValues, ["lastName"]: customer.lastName || "" };
        if (customer?.email)
            currentValues = { ...currentValues, ["email"]: simpleCrypto.decrypt(customer.email) };
        if (customer?.mobile)
            currentValues = { ...currentValues, ["mobile"]: simpleCrypto.decrypt(customer.mobile) };
        if (customer && customer.leadType) {
            currentValues = { ...currentValues, ["leadType"]: customer.leadType };
        }
        if (customer && customer.region) {
            currentValues = { ...currentValues, ["region"]: customer.region };
        }
        if (customer && customer.gender) {
            currentValues = { ...currentValues, ["gender"]: customer.gender };
        }
        if (customer && customer.ageRange) {
            currentValues = { ...currentValues, ["age"]: customer.ageRange };
        }
        if (customer && customer.incomeRange) {
            currentValues = { ...currentValues, ["income"]: customer.incomeRange };
        }
        if (customer && customer.occupation) {
            currentValues = { ...currentValues, ["occupation"]: customer.occupation };
        }
        if (customer && customer.maritalStatus) {
            currentValues = { ...currentValues, ["maritalStatus"]: customer.maritalStatus };
        }
        if (customer && customer.companyName) {
            currentValues = { ...currentValues, ["companyName"]: customer.companyName };
        }
        if (customer && customer.userType) {
            currentValues = { ...currentValues, ["userType"]: customer.userType };
        }
        if (customer && customer.annualIncome) {
            currentValues = { ...currentValues, ["annualIncome"]: customer.userType };
        }
        if (customer && customer.dependents) {
            currentValues = { ...currentValues, ["dependents"]: customer.userType };
        }
        if (customer && customer.loanAmount) {
            currentValues = { ...currentValues, ["loanAmount"]: customer.userType };
        }
        if (customer && customer.otherIncome) {
            currentValues = { ...currentValues, ["otherIncome"]: customer.userType };
        }
        if (customer && customer.tenure) {
            currentValues = { ...currentValues, ["tenure"]: customer.userType };
        }
        if (customer && customer.insuranceType) {
            currentValues = { ...currentValues, ["insuranceType"]: customer.userType };
        }
        if (customer && customer.insuredValue) {
            currentValues = { ...currentValues, ["insuredValue"]: customer.userType };
        }
        setInputValues({ ...inputValues, ...currentValues })
        if (customer && customer.activityId)
            setActivityId(customer.activityId);
    }, [customer]);

    const getAllParameters = () => {
        axios.get(config.apiUrl + 'employees/getAllParameters')
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message);
                    setLeadTypes(response.data.leadTypes);
                    setPlace(response.data.region)
                    setGenders(response.data.gender);
                    setAgeRanges(response.data.ageRange);
                    setIncomeRanges(response.data.incomeRange);
                    setOccupations(response.data.occupation);
                    setMaritalStatusValues(response.data.maritalStatus);
                    setUserTypes(response.data.userTypes);
                    setInsuranceTypes(response.data.insuranceTypes)
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    }

    const getAvailableFields = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'employees/getAvailableFieldsByCompanyId', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success available fields", response.data);
                    setAvailableFields(response.data.availableFields);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };

    const saveAndContinue = (e) => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: "error" })
            return
        };
        e.preventDefault();
        props.nextStep();
    };
    const handleChange = (event) => {
        setInputValues({ ...inputValues, [event.target.name]: event.target.value });
    }

    const isAvailable = (field) => {
        let value = false;
        availableFields.map(item => {
            for (let key in item) {
                if (key === field)
                    value = item[key];
            }
        })
        return value;
    }
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!inputValues.firstName) {
            errorObj = {
                ...errorObj,
                firstNameError: true
            }
            error = true;
        }
        if (!inputValues.lastName) {
            errorObj = {
                ...errorObj,
                lastNameError: true
            };
            error = true;
        }
        if (!inputValues.leadType) {
            errorObj = {
                ...errorObj,
                leadTypeError: true
            }
            error = true;
        }
        if (!inputValues.region) {
            errorObj = {
                ...errorObj,
                regionError: true
            }
            error = true;
        }
        if (employee.businessType === 1 && !inputValues.companyName) {
            errorObj = {
                ...errorObj,
                cNameError: true
            }
            error = true;
        }
        if (employee.businessType === 1 && !inputValues.userType) {
            errorObj = {
                ...errorObj,
                uTypeError: true
            }
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    };

    const updateCustomerDetails = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: "error" })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "customerId": customer?.customerId ? customer.customerId : customer.id,
            "firstName": inputValues.firstName,
            "lastName": inputValues.lastName,
            "email": inputValues.email ? simpleCrypto.encrypt(inputValues.email) : inputValues.email,
            "mobile": inputValues.mobile ? simpleCrypto.encrypt(inputValues.mobile) : inputValues.mobile,
            "leadType": parseInt(inputValues.leadType),
            "region": parseInt(inputValues.region),
            "gender": parseInt(inputValues.gender),
            "ageRange": inputValues.age ? parseInt(inputValues.age) : null,
            "incomeRange": inputValues.income ? parseInt(inputValues.income) : null,
            "occupation": inputValues.occupation ? parseInt(inputValues.occupation) : null,
            "maritalStatus": inputValues.maritalStatus ? parseInt(inputValues.maritalStatus) : null,
            "activityId": customer.activityId,
            "activityStatus": 2,
            "companyName": inputValues.companyName,
            "userType": inputValues.userType ? inputValues.userType : null,
            "annualIncome": inputValues.annualIncome ? inputValues.annualIncome : null,
            "dependents": inputValues.dependents ? inputValues.dependents : null,
            "otherIncome": inputValues.otherIncome ? inputValues.otherIncome : null,
            "loanAmount": inputValues.loanAmount ? inputValues.loanAmount : null,
            "tenure": inputValues.tenure ? inputValues.tenure : null,
            "insuranceType": inputValues.insuranceType ? inputValues.insuranceType : null,
            "insuredValue": inputValues.insuredValue ? inputValues.insuredValue : null,
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'employees/addCustomerDetails', payload, { headers: headers })
            .then((response) => {
                console.log(response);
                enqueueSnackbar("Customer Details Added Successfully!", { variant: "success" })
                navigate(-1);
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });

    };
    return (
        <div className="h-screen flex flex-col">
            <Navbar1 employee={employee} />
            <LeftMenu1 section="Add Activity" />
            <div><i onClick={() => navigate(-1)} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i></div>
            <div className='flex justify-center items-center mt-20 w-full ml-12'>
                <div className="flex flex-col gap-1 w-96">
                    <p className="text-base font-medium self-center">Customer Details</p>
                    <div className='h-[75vh] overflow-y-auto'>
                        <input className={`text-sm w-full p-2 ${errors.firstNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                            type="text"
                            onChange={(e) => { handleChange(e); setErrors({ ...errors, firstNameError: false }) }}
                            value={inputValues.firstName}
                            name="firstName"
                            placeholder="First Name" />
                        <input className={`text-sm  w-full p-2 ${errors.lastNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                            type="text"
                            onChange={(e) => { handleChange(e); setErrors({ ...errors, lastNameError: false }) }}
                            value={inputValues.lastName}
                            name="lastName"
                            placeholder="Last Name" />
                        {customer && !customer.visible && customer.email ?
                            <p className={`text-sm w-full h-9 bg-white p-2 ${errors.emailError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}>{"*".repeat(10)}</p>
                            : <input className={`text-sm w-full p-2 ${errors.emailError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                                type="text"
                                onChange={(e) => { handleChange(e); setErrors({ ...errors, emailError: false }) }}
                                value={inputValues.email}
                                name="email"
                                placeholder="Customer Email" />}
                        {customer && !customer.visible && customer.mobile ?
                            <p className={`text-sm w-full h-9 bg-white p-2 ${errors.emailError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}>{"*".repeat(10)}</p>
                            : <input className={`text-sm bg-white w-full p-2 ${errors.mobileError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                                type="tel"
                                pattern="[0-9]{10}"
                                maxLength="10"
                                onChange={(e) => { handleChange(e); setErrors({ ...errors, mobileError: false }) }}
                                value={inputValues.mobile}
                                name="mobile"
                                placeholder="Customer Mobile" />}
                        <select name="userType" value={inputValues.userType} className={`text-sm w-full p-2 ${errors.uTypeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                            onChange={(e) => { handleChange(e); setErrors({ ...errors, uTypeError: false }) }} >
                            <option value="">--Type Of Customer--</option>
                            {userTypes && userTypes.map(item => {
                                return (
                                    <option value={item.id} key={item.id}>{item.userType}</option>
                                )
                            })}
                        </select>
                        <input className={`text-sm bg-white w-full p-2 ${errors.cNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2 ${inputValues.userType != 1 ? "hidden" : ""}`}
                            type="text"
                            onChange={(e) => { handleChange(e); setErrors({ ...errors, cNameError: false }) }}
                            value={inputValues.companyName}
                            name="companyName"
                            placeholder="Company Name" />
                        <select name="gender" value={inputValues.gender} className={`text-sm w-full p-2 border-[1px] border-gray-300 ${isAvailable('gender') ? "" : "hidden"} rounded mt-2`}
                            onChange={(e) => { handleChange(e) }} >
                            <option value="">---Select Gender---</option>
                            {genders && genders.map(item => {
                                return (
                                    <option value={item.id} key={item.id}>{item.gender}</option>
                                )
                            })}
                        </select>
                        <select name="age" value={inputValues.age} className={`text-sm w-full p-2 border-[1px] border-gray-300 ${isAvailable('age') ? "" : "hidden"} rounded mt-2`}
                            onChange={(e) => { handleChange(e) }} >
                            <option value="">---Select Age Range---</option>
                            {ageRanges && ageRanges.map(item => {
                                return (
                                    <option value={item.id} key={item.id}>{item.age}</option>
                                )
                            })}
                        </select>
                        <select name="maritalStatus" value={inputValues.maritalStatus} className={`text-sm w-full p-2 border-[1px] border-gray-300 ${isAvailable('maritalStatus') ? "" : "hidden"} rounded mt-2`}
                            onChange={(e) => { handleChange(e) }} >
                            <option value="">---Select Marital Status---</option>
                            {maritalStatusValues && maritalStatusValues.map(item => {
                                return (
                                    <option value={item.id} key={item.id}>{item.maritalStatus}</option>
                                )
                            })}
                        </select>
                        <select name="occupation" value={inputValues.occupation} className={`text-sm w-full p-2 border-[1px] border-gray-300 ${isAvailable('occupation') ? "" : "hidden"} rounded mt-2`}
                            onChange={(e) => { handleChange(e) }} >
                            <option value="">---Select Occupation---</option>
                            {occupations && occupations.map(item => {
                                return (
                                    <option value={item.id} key={item.id}>{item.occupation}</option>
                                )
                            })}
                        </select>
                        <select name="income" value={inputValues.income} className={`text-sm w-full p-2 border-[1px] border-gray-300 ${isAvailable('income') ? "" : "hidden"} rounded mt-2`}
                            onChange={(e) => { handleChange(e) }} >
                            <option value="">---Select Income Range---</option>
                            {incomeRanges && incomeRanges.map(item => {
                                return (
                                    <option value={item.id} key={item.id}>{item.income}</option>
                                )
                            })}
                        </select>
                        <select name="leadType" value={inputValues.leadType} className={`text-sm w-full p-2 ${errors.leadTypeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                            onChange={(e) => { handleChange(e); setErrors({ ...errors, leadTypeError: false }) }} >
                            <option value="">---Lead Type---</option>
                            {leadTypes && leadTypes.map(item => {
                                return (
                                    <option value={item.id} key={item.id}>{item.leadType}</option>
                                )
                            })}
                        </select>
                        <select name="region" value={inputValues.region} className={`text-sm w-full p-2 ${errors.regionError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                            onChange={(e) => { handleChange(e); setErrors({ ...errors, regionError: false }) }} >
                            <option value="">---Select Region---</option>
                            {place && place.map(item => {
                                return (
                                    <option value={item.id} key={item.id}>{item.place}</option>
                                )
                            })}
                        </select>
                        <input className={`text-sm bg-white w-full p-2  ${isAvailable('annualIncome') ? "" : "hidden"} rounded mt-2`}
                            type="text"
                            onChange={(e) => { handleChange(e) }}
                            value={inputValues.annualIncome}
                            name="annualIncome"
                            placeholder="Annual income" />
                        <input className={`text-sm bg-white w-full p-2  ${isAvailable('dependents') ? "" : "hidden"} rounded mt-2`}
                            type="text"
                            onChange={(e) => { handleChange(e) }}
                            value={inputValues.dependents}
                            name="dependents"
                            placeholder="Dependents" />
                        <input className={`text-sm bg-white w-full p-2  ${isAvailable('otherIncome') ? "" : "hidden"} rounded mt-2`}
                            type="text"
                            onChange={(e) => { handleChange(e) }}
                            value={inputValues.otherIncome}
                            name="otherIncome"
                            placeholder="Other income" />
                        <input className={`text-sm bg-white w-full p-2  ${isAvailable('loanAmount') ? "" : "hidden"} rounded mt-2`}
                            type="text"
                            onChange={(e) => { handleChange(e) }}
                            value={inputValues.loanAmount}
                            name="loanAmount"
                            placeholder="Loan Amount" />
                        <input className={`text-sm bg-white w-full p-2  ${isAvailable('tenure') ? "" : "hidden"} rounded mt-2`}
                            type="text"
                            onChange={(e) => { handleChange(e) }}
                            value={inputValues.tenure}
                            name="tenure"
                            placeholder="Loan Tenure" />
                        <select name="insuranceType" value={inputValues.insuranceType} className={`text-sm w-full p-2 border-[1px] border-gray-300 ${isAvailable('insuranceType') ? "" : "hidden"} rounded mt-2`}
                            onChange={(e) => { handleChange(e) }} >
                            <option value="">---Select Insurance Type---</option>
                            {insuranceTypes && insuranceTypes.map(item => {
                                return (
                                    <option value={item.id} key={item.id}>{item.insurance}</option>
                                )
                            })}
                        </select>
                        <input className={`text-sm bg-white w-full p-2  ${isAvailable('insuredValue') ? "" : "hidden"} rounded mt-2`}
                            type="text"
                            onChange={(e) => { handleChange(e) }}
                            value={inputValues.insuredValue}
                            name="insuredValue"
                            placeholder="Insured Value" />
                    </div>
                    <button className='bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color rounded-md py-1 px-4 mt-4 max-w-max self-end uppercase'
                        onClick={updateCustomerDetails}>Submit</button>
                </div>
            </div>
        </div>
    );
}

export default UserDetails;

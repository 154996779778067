import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import leftArrow from "../images/left-arrow.svg"
import Navbar1 from "../Navbar/Navbar1";
import LeftMenu1 from "../LeftMenu/LeftMenu1";
import { enqueueSnackbar } from 'notistack'
import EmployeeContext from "../context/employee/EmpContext";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const ReachOut = () => {
    const navigate = useNavigate();
    const locations = useLocation();
    const { empState: employee } = useContext(EmployeeContext)
    const customer = locations.state?.customer;
    const actionTypes = locations.state?.actionTypes;
    const currentNotes = locations.state?.notes;
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [notes, setNotes] = useState('');
    const [targetDate, setTargetDate] = useState();
    const [selectedActionType, setSelectedActionType] = useState();
    const [errors, setErrors] = useState({});


    useEffect(() => {
    }, [])
    useEffect(() => {
        console.log("Customer useeffect caleed", customer, currentNotes);
        setFirstName(customer?.firstName);
        setLastName(customer?.lastName)
    }, [customer])
    const addCustomerActivity = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: "error" })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "customerId": customer?.customerId ? customer.customerId : customer.id,
            "notes": notes,
            "targetDate": targetDate,
            "employeeId": employee.employeeId,
            "actionType": selectedActionType ? parseInt(selectedActionType) : null,
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'employees/createSalesPersonReachOut', payload, { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    enqueueSnackbar("Reach out Added Successfully!", { variant: "success" })
                    navigate(-1);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const handleChange = (val, arr, func) => {
        func(val);
    }
    const getActionType = (typeId) => {
        let actionType = actionTypes.find(item => item.id === typeId);
        return actionType?.actionType;
    }
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!notes) {
            errorObj = {
                ...errorObj,
                notesError: true
            }
            error = true;
        }
        if (!targetDate) {
            errorObj = {
                ...errorObj,
                targetDateError: true
            };
            error = true;
        }
        if (!selectedActionType) {
            errorObj = {
                ...errorObj,
                actionTypeError: true
            };
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }

    return (
        <section className="h-screen flex flex-col justify-center items-center mx-5">
            <Navbar1 employee={employee} />
            <LeftMenu1 section="Reach Out" />
            <div><i onClick={() => navigate(-1)} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i></div>
            {/* <img src={leftArrow} className="absolute left-[18vw] top-16 w-12 cursor-pointer" onClick={() => navigate(-1)} /> */}
            <div className="my-16 ml-[15rem] flex flex-col justify-center items-center h-[80vh]">
                <div className="text-center md:text-left mb-1">
                    <p className="mr-1 text-lg text-black-600 md:whitespace-nowrap">Reach out to {firstName} {lastName}</p>
                    <p className="text-xs">({employee.branchName})</p>
                </div>
                <div className="flex flex-col gap-1 w-[20vw]">
                    <textarea className={`text-sm w-full p-2 ${errors.notesError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2 resize-none`}
                        type="text"
                        rows={2}
                        maxLength={120}
                        onChange={(e) => { setNotes(e.target.value); setErrors({ ...errors, notesError: false }) }}
                        value={notes}
                        name="salesperson notes"
                        placeholder="Relationship Manager Notes"></textarea>
                    <DatePicker className={`text-sm w-full p-2 ${errors.targetDateError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} cursor-pointer mt-2 rounded`}
                        isClearable
                        showTimeSelect
                        dateFormat="MMMM d, yyyy h:mmaa"
                        placeholderText="Target Date"
                        filterDate={d => {
                            return new Date() < d;
                        }}
                        selected={targetDate}
                        minTime={new Date().setHours(8, 0, 0)}
                        maxTime={new Date().setHours(19, 0, 0)}
                        onChange={date => {
                            if (!date) {
                                setTargetDate(null);
                                return;
                            }
                            let new_date = new Date(date);
                            let hours = new_date.getHours();
                            if (hours < 8)
                                new_date = new_date.setHours(8, 0, 0);
                            setTargetDate(new_date)
                            setErrors({ ...errors, targetDateError: false })
                        }}
                    />
                    <select name="actiontype" value={selectedActionType} className={`text-sm w-full p-2 ${errors.actionTypeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                        onChange={(e) => { handleChange(e.target.value, actionTypes, setSelectedActionType); setErrors({ ...errors, actionTypeError: false }) }} >
                        <option value="">{selectedActionType ? getActionType(selectedActionType) : "---Action Type---"}</option>
                        {actionTypes && actionTypes.map(item => {
                            return (
                                <option value={item.id} key={item.id}>{item.action}</option>
                            )
                        })}
                    </select>
                    <div className="flex justify-center text-center md:text-left">
                        <button className="mt-4 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2  font-semibold uppercase rounded text-xs" onClick={addCustomerActivity} type="submit">Add</button>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default ReachOut
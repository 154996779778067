import React, { Component, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const EmployeeCountGraph = () => {
    const [labels, setLabels] = useState([]);
    const [values, setValues] = useState([]);
    useEffect(() => {
        getEmployeesCountByBranch()
    }, [])
    const getEmployeesCountByBranch = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getEmployeesCountByBranch', { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("employees count by branch", response.data);
                    setLabels(response.data.employeeCount.map(data => data.branchName));
                    setValues(response.data.employeeCount.map(data => data.employeesCount));
                    console.log(labels, values);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };

    const users = {
        labels: labels,
        datasets: [
            {
                data: values,
                backgroundColor: [
                    'black',
                ],
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20
            }
        ]
    }
    return (
        <div>
            <Card className="flex flex-col justify-between relative" style={{ marginTop: "1rem", height: "250px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                <center><div className="font-semibold text-lg">Employees Count</div></center>
                <div style={{ height: "200px" }}>
                    <Bar data={users}
                        options={{
                            elements: {
                                line: {
                                    tension: 1
                                }
                            },
                            tooltips: {
                                custom: function (tooltip) {
                                    if (!tooltip) return;
                                    // disable displaying the color box;
                                    tooltip.displayColors = false;
                                },
                                callbacks: {
                                    // use label callback to return the desired label
                                    label: function (tooltipItem, data) {
                                        return tooltipItem.yLabel + " kWh";
                                    },
                                    // remove title
                                    title: function (tooltipItem, data) {
                                        return;
                                    }
                                }
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        font: {
                                            size: 8
                                        }
                                    }
                                },
                                y: {
                                    ticks: {
                                        precision: 0
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: false
                                },
                            }
                        }
                        } />
                </div>
            </Card>
        </div>
    );
};

export default EmployeeCountGraph
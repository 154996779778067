import React, { useEffect, useState } from "react";
import axios from 'axios';
import { config } from '../../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import { useQuery } from 'react-query';
import UsersAsk from "./UsersAskGraph";
import NewProductsData from "../NewProductsData";
import VisitsVsPurchase from "./VisitsVsPurchaseGraph";
import UsersActivity from "./UserActivityGraph";


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function ProductAnalytics() {
    const [section, setSection] = useState("ProductAnalytics")
    const [usersAsk, setUsersAsk] = useState([]);
    const [usersActivity, setUsersActivity] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [products, setProducts] = useState([]);
    const [returnedProduct, setReturnedProduct] = useState()
    const [refundedProduct, setRefundedProduct] = useState();
    const [productCount, setProductCount] = useState();
    const [newProductCount, setNewProductCount] = useState(0)

    const getProductsData = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        return axios.get(config.apiUrl + 'companyAdmin/getProductsAnalysisByCompany', { headers: headers })
    };

    const { data: productsDetails } = useQuery("newCustomers-details", getProductsData, {
        enabled: section === "ProductAnalytics",
        onSuccess: (response) => {
            console.log("success products data from query ", response.data);
            setUsersAsk(response.data.keyAsk);
            setUsersActivity(response.data.userActivity);
            setProducts(response.data.companyProducts);
            setProductsData(response.data.productsData);
        },
        onError: (error) => {
            console.error('Error loading branches data:', error);
        },
    });
    const getTopReturnedProductsByMonth = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        return axios.get(config.apiUrl + 'companyAdmin/getTopReturnedProductsByMonth', { headers: headers })
    };

    const { data: returnDetails } = useQuery("return-details", getTopReturnedProductsByMonth, {
        enabled: section === "ProductAnalytics",
        onSuccess: (response) => {
            console.log("success returned and refunded product from query ", response.data);
            if (response.data.status == true) {
                setReturnedProduct(response.data?.mostReturned?.name)
                setRefundedProduct(response.data?.mostRefunded?.name)
                setProductCount(response.data.activeProducts);
                setNewProductCount(response.data.newProducts)
            } else {
                console.log("Error");
            }
        },
        onError: (error) => {
            console.error('Error loading branches data:', error);
        },
    });
    return (
        <>
            <div className="flex gap-4 items-center text-center" style={{ marginTop: "18px" }}>
                <div className="flex flex-col justify-around mt-2 rounded-xl bg-custom-bg-color hover:custom-bg-color text-custom-text-color w-24 h-24 px-1 py-2 font-semibold">
                    <p className="text-2xl">{productCount}</p>
                    <p className="text-md">Products</p>
                </div>
                <div className="flex flex-col justify-around mt-2 rounded-xl bg-custom-bg-color hover:custom-bg-color text-custom-text-color w-24 h-24 p-1 font-semibold">
                    <p className="text-2xl">{newProductCount}</p>
                    <p className="text-md">New Products</p>
                </div>
                <div className="mt-2 flex rounded-xl bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color items-center text-center justify-center w-24 h-24 font-semibold">
                    <p className="text-sm">{returnedProduct ? returnedProduct : "-"}<br />Return</p>
                </div>
                <div className="mt-2 flex rounded-xl bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color items-center text-center justify-center w-24 h-24 font-semibold">
                    <p className="text-sm">{refundedProduct ? refundedProduct : "-"}<br />Refund</p>
                </div>
            </div>
            <div className="flex gap-4">
                <UsersAsk usersAsk={usersAsk} productsData={productsData} />
                <UsersActivity productsData={productsData} usersActivity={usersActivity} />
                <NewProductsData />
            </div>
            <div className="mt-1 mb-2 flex gap-4">
                <VisitsVsPurchase productsData={productsData} products={products} />
            </div>
        </>
    );
};

import react, { useState, useEffect, useContext } from "react";
import Papa from "papaparse";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import ErrorPopup from "../PopUps/ErrorPopup";
import Navbar1 from "../Navbar/Navbar1";
import LeftMenu1 from "../LeftMenu/LeftMenu1";
import { useLocation, useNavigate } from 'react-router-dom';
import EmployeeContext from "../context/employee/EmpContext";
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

let branchHeadings = ['branchCode', 'branchType', 'branchName', 'location', 'city']
let productHeadings = ['productCode', 'productType', 'productFamilyName', 'description']
let employeeHeadings = ["EmpFN", "EmpLN", "Gender", "EMPID", "EmpRole", "EmpEmail", "EmpPhone", "branchCode"]
let customerHeadings = ["firstName", "lastName", "email", "mobile", "leadType", "activity", "potential", "productCode"]

export default function UploadCsv({ getAllBranches, getAllProducts, getAllCustomers, getAllEmployees }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { empState: employee } = useContext(EmployeeContext);
    const [parsedData, setParsedData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [values, setValues] = useState([]);
    const [name, setName] = useState('')
    const [file, setFile] = useState()
    const [type, setType] = useState()
    const [apiEndPoint, setApiEndPoint] = useState('')
    const [showError, setShowError] = useState(false);
    const [msg, setMsg] = useState('');
    const [uploaded, setUploaded] = useState(false)

    useEffect(() => {
        setType(location.state.bulkType)
    }, []);
    useEffect(() => {
        if (type)
            uploadData()
    }, [type])

    const changeHandler = (event) => {
        setName(event.target.files[0].filename)
        setFile(event.target.files[0])
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                const rowsArray = [];
                const valuesArray = [];
                results.data.map((d) => {
                    rowsArray.push(Object.keys(d));
                    valuesArray.push(Object.values(d));
                });
                setParsedData(results.data);
                setTableRows(rowsArray[0]);
                setValues(valuesArray);
                console.log("res", results.data, rowsArray[0], valuesArray)
            },
        });
    };
    const removeAll = () => {
        setParsedData([])
        setTableRows([])
        setValues([])
        setName('')
        setFile()
        setUploaded(false)
    }
    const compareBranches = (a, b) => {
        console.log("inside branches", a, b);
        {
            for (var i = 0; i < b.length; i++) {
                if (a[i] !== b[i]) {
                    return false;
                }
            }
            return true;
        }
    };
    const compareProducts = (a, b) => {
        console.log("inside produtcs", a, b);
        if (a.length !== b.length) return false;
        else {
            for (var i = 0; i < a.length; i++) {
                if (a[i] !== b[i]) {
                    return false;
                }
            }
            return true;
        }
    };
    const compareEmployees = (a, b) => {
        console.log("inside employees", a, b);
        if (a.length !== b.length) return false;
        else {
            for (var i = 0; i < a.length; i++) {
                if (a[i] !== b[i]) {
                    return false;
                }
            }
            return true;
        }
    };
    const compareCustomers = (a, b) => {
        let result = true;
        values && values.forEach(val => {
            if (!val[0] || !val[2] || !val[3]) {
                result = false;
            }
        })
        return result;
    };

    const validateHeadings = () => {
        if (type === "Branch") return compareBranches(tableRows, branchHeadings);
        if (type === "Product") return compareProducts(tableRows, productHeadings);
        if (type === "Employee") return compareEmployees(tableRows, employeeHeadings);
        if (type === "Customer") return compareCustomers(tableRows, customerHeadings);
    }
    const addBulkBranches = () => {
        if (validateHeadings()) {
            let headers = {
                'Accept': 'application/json',
                "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem('random')),
                'Content-Type': 'multipart/form-data',
                "branchId": employee.role === 2 ? employee.branchId : null,
                "branchName": employee.role === 2 ? employee.branchName : null
            }
            const formData = new FormData();
            formData.append("file", file);
                        axios.post(config.apiUrl + apiEndPoint, formData, { headers: headers })
                .then(function (response) {
                    if (response.data.status == true) {
                        console.log("success", response.data);
                        let newValues = response.data.results.map((result, i) => {
                        let cur = [...values[i], result.message]
                        return cur;
                        })
                        setValues(newValues);
                        setUploaded(true);
                    } else {
                        console.log("Error");
                    }
                })
                .catch(function (error) {
                    console.log("error", error);
                    setUploaded(false);
                    setShowError(true);
                    setMsg("Something went wrong");
                    setTimeout(() => { setShowError(false); setMsg('') }, 2000)
                });
        } else {
            setMsg("Please add correct file");
            setShowError(true);
            setTimeout(() => { setMsg(''); setShowError(false) }, 2000)
        }
    };
    const uploadData = (e) => {
        if (employee.role === 2) {
            setApiEndPoint('manager/uploadCustomerDataByBranchAdmin')
        }
        else if (type === "Branch") {
            setApiEndPoint('companyAdmin/uploadBranchData')
        } else if (type === "Product") {
            setApiEndPoint('companyAdmin/uploadProductData')
        }
        else if (type === "Employee") {
            setApiEndPoint('companyAdmin/uploadEmployeeData')
        }
        else if (type === "Customer") {
            setApiEndPoint('companyAdmin/uploadCustomerData')
        }
    }
    return (
        <section className="h-screen flex flex-col">
            <ErrorPopup show={showError} setShow={setShowError} msg={msg} />
            <Navbar1 employee={employee} />
            <LeftMenu1 section={`Bulk Upload ${type}`} />
            <div><i onClick={() => navigate(-1)} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i></div>

            <div className="ml-44 mt-32">
                {!type ? <></> :
                    <div>
                        <input
                            type="file"
                            value={name}
                            name="file"
                            onChange={changeHandler}
                            accept=".csv"
                            style={{ display: "block", margin: "auto", marginTop: "-2.3rem" }}
                        />
                        <button className="absolute top-[5.7rem] right-10 bg-custom-bg-color hover:custom-bg-color p-2 px-4 text-custom-text-color font-semibold uppercase rounded text-xs  whitespace-nowrap" onClick={removeAll}>Clear</button>
                    </div>}
                {file ?
                    <div className="mt-3 ml-20 overflow-y-auto h-[66vh] w-[91%]">
                        <table className="table-striped" >
                            <thead className="bg-custom-bg-color hover:custom-bg-color text-custom-text-color font-semibold">
                                <tr className="whitespace-nowrap">
                                    {tableRows.map((rows, index) => {
                                        return <th key={index}>{rows}</th>;
                                    })}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {values.map((value, index) => {
                                    return (
                                        <tr key={index}>
                                            {value.map((val, i) => {
                                                return <td key={i}>{val}</td>;
                                            })}
                                            {!uploaded ? <td></td> : null}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div> : <></>}
                {name === '' ? <div></div> :
                    <div className="flex justify-end items-center absolute bottom-4 right-4">
                        <button
                            className={`p-2 px-4 text-custom-text-color font-semibold uppercase rounded text-xs whitespace-nowrap ${uploaded ? "bg-gray-700" : "bg-custom-bg-color hover:custom-bg-color"}`}
                            onClick={addBulkBranches}
                            disabled={uploaded}
                        >
                            UPLOAD
                        </button>
                    </div>
                }
            </div>
        </section>
    );
}

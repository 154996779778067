import axios from 'axios';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react'
import { config } from '../../Config/Config';
import SimpleCrypto from 'simple-crypto-js';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key);

const ViewLeadsFromDirectory = () => {
    const [leads, setLeads] = useState([]);
    useEffect(() => {
        getAllLeadsFromDirectory();
    }, [])
    const getAllLeadsFromDirectory = () => {
        let headers = {
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem('random')),
            "Content-Type": 'application/json',
            "Accept": 'application/json',
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/leadsFromDirectoryService', { headers: headers })
            .then(function (response) {
                console.log("success new leads from directory", response.data);
                setLeads(response.data.leads);
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    return (
        <div className="ml-8 mt-20">
            <div className="flex  justify-between">
                <div className="text-left font-semibold text-black"><h5>Leads From Directory</h5></div>
                <div className="flex flex-row">
                </div>
            </div>

            <div className="mt-3 tableFixHead">
                <table className="border-spacing-x-0 border-spacing-y-2 border-separate" >
                    <thead className="whitespace-nowrap">
                        <tr>
                            <th scope="col" className="th-heading">S.No</th>
                            <th scope="col" className="th-heading">Name</th>
                            <th scope="col" className="th-heading" >Email</th>
                            <th scope="col" className="th-heading">Contact No</th>
                            <th scope="col" className="th-heading">Viewed On</th>
                        </tr>
                    </thead>
                    <tbody className="bg-custom-text-color" >
                        {leads && leads.map((data, i) => (
                            <tr key={data.id} className="tr whitespace-nowrap">
                                <td className="td-text pl-4">{i + 1}</td>
                                <td className="td-text">{data.name}</td>
                                <td className="td-text">{data.email}</td>
                                <td className="td-text">{data.phone}</td>
                                <td className="td-text">{moment(data.createdAt).format("DD-MM-YYYY")}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ViewLeadsFromDirectory
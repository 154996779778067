import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import axios from 'axios';
import { config } from '../../Config/Config';
import { Bar } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const BranchAreaVsSales = () => {
    const [values, setValues] = useState([]);
    const [branchAreas, setBranchAreas] = useState([]);
    const [currentMonth, setCurrentMonth] = useState();
    const [monthlyValues, setMonthlyValues] = useState();

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        getBranchAreaVsSalesCount();
    }, [])
    const getBranchAreaVsSalesCount = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getBranchAreaVsSalesCount', { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log(response.data.message, response.data);
                    setValues(response.data.salesByMonth);
                    setBranchAreas(response.data.branchAreas)
                    setCurrentMonth(new Date().getMonth())
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    useEffect(() => {
        setMonthlyData()
    }, [currentMonth])
    const setMonthlyData = () => {
        let currentMonthData = values && values.find(val => val.month == currentMonth);
        if (!currentMonthData) return;
        const valuesArray = Object.entries(currentMonthData)
            .filter(([key]) => key !== 'month')
            .map(([_, value]) => value);
        setMonthlyValues(valuesArray);
    }
    const salesData = {
        labels: branchAreas && branchAreas.map(data => data.branchArea),
        datasets: [
            {
                data: monthlyValues && monthlyValues.map(val => val),
                backgroundColor: ['black'],
                borderColor: 'black',
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20,
            }
        ]
    }
    return (
        <div className="">
            <Card className="flex flex-col justify-between relative" style={{ marginTop: "1rem", height: "250px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                <div className="font-semibold text-lg ml-8">Branch Area Vs Sales</div>
                <select name="months" value={currentMonth} className="absolute -top-1 right-1 text-xs max-w-max  border border-solid border-gray-300 rounded mt-2"
                    onChange={(e) => setCurrentMonth(e.target.value)} >
                    {months && months.map((item, i) => {
                        return (
                            <option value={i} key={i}>{item}</option>
                        )
                    })}
                </select>
                <div style={{ height: "200px" }}>
                    <Bar data={salesData}
                        options={{
                            maintainAspectRatio: true,
                            responsive: true,
                            redraw: false,
                            elements: {
                                line: {
                                    tension: 1
                                }
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        font: {
                                            size: 10, // Adjust the font size as needed
                                        },
                                    },
                                },
                                y: {
                                    ticks: {
                                        precision: 0
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: false
                                },
                                tooltip: {
                                    enabled: false
                                },
                            }
                        }
                        } />
                </div>
            </Card>
        </div >
    );
};

export default BranchAreaVsSales
import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { Modal } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import DatePicker from 'react-datepicker';
import moment from "moment-timezone";
import { enqueueSnackbar } from "notistack";
import EmployeeContext from "../context/employee/EmpContext";


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const EditEvent = ({ data, closePopup, getCurrentMonthEvents, role }) => {
    const { empState: employee } = useContext(EmployeeContext)
    const [date, setDate] = useState('');
    const [currentDate, setCurrentDate] = useState('');
    const [event, setEvent] = useState("")
    const [toDos, setToDos] = useState(['', '']);
    const [eventTypes, setEventTypes] = useState([])
    const [eventType, setEventType] = useState('')
    const [errors, setErrors] = useState({});
    const [branches, setBranches] = useState([]);
    const [branch, setBranch] = useState('');
    const [oldBranch, setOldBranch] = useState('');
    const [isActive, setIsActive] = useState(false)


    useEffect(() => {
        console.log("Date", data, role);
        setDate(moment(data?.date, 'DD-MM-YYYY').toDate())
        setCurrentDate(moment(data?.date, 'DD-MM-YYYY').toDate())
        setEvent(data?.event);
        if (data?.toDos) {
            let curToDos = [...toDos]
            data.toDos.forEach((toDo, i) => {
                curToDos[i] = toDo;
            })
            setToDos([...curToDos]);
        }
        setEventType(data?.eventType)
        setBranch(data?.branchId);
        setOldBranch(data?.branchId)
        getEventsTypes();
        getAllBranches();
        const [day, month, year] = data.date.split('-').map(Number);
        const inputDate = new Date(year, month - 1, day);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        setIsActive(inputDate >= today);
    }, []);
    const getEventsTypes = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getEventsTypes', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message);
                    setEventTypes(response.data.eventTypes)
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const getAllBranches = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            companyId: employee?.companyId
        }
        axios.post(config.apiUrl + 'companyAdmin/getAllBranches', payload, { headers: headers })
            .then((response) => {
                console.log("success branch from query ", response.data);
                let currentBranches = response.data.branches && response.data.branches?.length > 0 ? response.data.branches : [];
                let activeBranches = currentBranches.filter(branch => branch.status === 1)
                setBranches(activeBranches);
            }).catch((error) => {
                console.error('Error loading branches data:', error);
            })
    };
    const editEvent = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: "error" })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "id": data?.id,
            "date": date,
            "event": event,
            "currentDate": currentDate,
            "eventType": parseInt(eventType),
            "toDos": JSON.stringify(toDos),
            "branchId": branch,
            "oldBranch": oldBranch
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/editEventByCompanyAdmin', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    console.log("success", response.data.message, payload);
                    closePopup()
                    getCurrentMonthEvents(date)
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const getEventType = (typeId) => {
        let event = eventTypes?.find(item => item.id === typeId);
        return event?.eventType;
    }
    const getBranchName = (branchId) => {
        let curBranch = branches && branches.find(item => item.id === branchId);
        return curBranch?.branchName;
    }
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!event) {
            errorObj = {
                ...errorObj,
                eventError: true
            }
            error = true;
        }
        if (!eventType) {
            errorObj = {
                ...errorObj,
                eventTypeError: true
            };
            error = true;
        }
        if (!date) {
            errorObj = {
                ...errorObj,
                dateError: true
            }
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    return (
        <div>
            <Modal
                show={true}
                onHide={closePopup}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="w-[25vw] m-2 mr-2 mx-auto mb-3">
                    <div className="text-center md:text-left my-3">
                        <label className="mr-1 text-lg text-black-600">{role === 1 ? "Update Event" : "Event Details"}</label>
                    </div>
                    <div className="flex flex-col">
                        <label className="mr-1 text-sm text-black-600">Event Date</label>
                        {role === 1 ?
                            <DatePicker className={`text-sm w-full p-2 ${errors.dateError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded resize-none`}
                                isClearable
                                placeholderText="Select Event Date"
                                filterDate={d => {
                                    return new Date() < d;
                                }}
                                dateFormat="dd-MM-yyyy"
                                selected={date}
                                onChange={date => {
                                    setErrors({ ...errors, dateError: "" })
                                    if (!date) {
                                        setDate(null);
                                        return;
                                    }
                                    let new_date = new Date(date);
                                    setDate(new_date)
                                }}
                            /> :
                            <p className="text-sm w-full p-2 border border-gray-500 cursor-pointer rounded">{moment(date).format('DD-MM-YYYY')}</p>}
                    </div>
                    <div className="flex flex-col">
                        <label className="text-sm text-black-600 mt-3">Event Type</label>
                        {role === 1 ?
                            <select name="employee" value={eventType} className={`text-sm w-full p-2 ${errors.eventTypeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                                onChange={(e) => { setEventType(e.target.value); setErrors({ ...errors, eventTypeError: "" }) }} >
                                <option value="">Select Event Type</option>
                                {eventTypes && eventTypes.map(type => {
                                    return (
                                        <option key={type.id} value={type.id}>{type.eventType}</option>
                                    )
                                })}
                            </select>
                            :
                            <p className={`text-sm w-full p-2 min-h-[4vh] border border-gray-500 cursor-pointer rounded ${eventType ? "text-black" : "text-gray-400"}`}>{eventType ? getEventType(eventType) : "Event Type"}</p>}
                    </div>
                    <div className="flex flex-col">
                        <label className="text-sm text-black-600 mt-3">Branch</label>
                        {role === 1 ?
                            <select name="branch" value={branch} className={`text-sm w-full p-2 border-[1px] border-gray-300 rounded`}
                                onChange={(e) => setBranch(e.target.value)} >
                                <option value="">Select Branch</option>
                                {branches && branches.map(type => {
                                    return (
                                        <option key={type.id} value={type.id}>{type.branchName}</option>
                                    )
                                })}
                            </select>
                            :
                            <p className={`text-sm w-full p-2 min-h-[4vh] border border-gray-500 cursor-pointer rounded ${branch ? "text-black" : "text-gray-400"}`}>{branch ? getBranchName(branch) : "Branch"}</p>}
                    </div>
                    <label className="mr-1 mt-3 text-sm text-black-600">Event Details</label>
                    {role === 1 ?
                        <textarea className={`text-sm w-full p-2 ${errors.eventError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded resize-none`}
                            type="text"
                            onChange={(e) => { setEvent(e.target.value); setErrors({ ...errors, eventError: "" }) }}
                            name="event"
                            value={event}
                            placeholder="Event Details"
                            rows={2}
                            maxLength={120}
                        ></textarea>
                        :
                        <p className="text-sm w-full p-2 min-h-max border border-gray-500 cursor-pointer rounded">{event}</p>}
                    <label className="mr-1 mt-3 text-sm text-black-600">Things To Do</label>
                    {role === 1 ?
                        toDos.map((todo, i) => {
                            return (
                                <textarea key={i} className="text-sm w-full p-2 border border-solid border-gray-300 rounded resize-none"
                                    type="text"
                                    onChange={(e) => { let current = [...toDos]; current[i] = e.target.value; setToDos(current) }}
                                    name={`toDo${i + 1}`}
                                    value={toDos.length > i ? toDos[i] : ''}
                                    placeholder={`${i + 1}`}
                                    rows={1}
                                    maxLength={100}
                                ></textarea>
                            )
                        })
                        :
                        toDos.map((todo, i) => {
                            return (
                                <p className={`text-sm w-full p-2 mb-3 min-h-[4vh] border border-gray-500 cursor-pointer rounded ${todo ? "text-black" : "text-gray-400"}`}>{todo ? todo : i + 1 + "."}</p>
                            )
                        })}
                    <div className={`flex ${role === 1 ? "justify-between" : "justify-end"} text-center md:text-left`}>
                        <button className="mt-3 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white font-semibold uppercase rounded text-xs" type="submit" onClick={closePopup}>Back</button>
                        {(event !== "" && isActive) ?
                            <button className={`mt-3 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs ${role === 1 ? "" : "hidden"}`} onClick={editEvent} type="submit">UPDATE</button>
                            :
                            <button className={`mt-3 bg-gray-400 hover:bg-gray-400 text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs  ${role === 1 ? "" : "hidden"}`} disabled={true} type="submit">UPDATE</button>
                        }
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default EditEvent
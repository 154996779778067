import { Modal } from 'react-bootstrap'
import { useNavigate } from "react-router";

const BackClick = ({ closePopup }) => {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    return (
        <Modal show={true} onHide={closePopup} size="md" centered >
            <Modal.Header closeButton style={{ zIndex: "100" }}>
            </Modal.Header>
            <Modal.Body>
                <div className='flex flex-col gap-4 justify-center items-center'>
                    <p className='-mt-10 text-lg text-orange-400'>Are you sure you want to go back?</p>
                    <p className='-mt-4 text-base text-custom-bg-color'>This action is irreversible</p>
                    <div className="flex justify-between text-center md:text-left">
                        <button className="mt-4 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white font-semibold uppercase rounded text-xs mr-5" onClick={closePopup} type="submit">No</button>
                        <button className="ml-5 mt-4 bg-custom-bg-color hover:custom-bg-color text-custom-text-color px-4 py-2  font-semibold uppercase rounded text-xs" onClick={goBack} type="submit">Yes</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default BackClick


import React from 'react';

function ScoreIndicator({ value, maxValue, innerSize }) {
    const percentage = (value / maxValue) * 100;

    return (
        <div className="relative w-20 h-20 border-8 border-gray-400 rounded-full">
            <div
                className="absolute top-0 left-0 w-full h-full rounded-full transform -rotate-180"
                style={{
                    background: `conic-gradient(#262626 ${percentage}%, white ${percentage}%)`,
                }}
            />
            <div
                className={`absolute rounded-full bg-orange-600 ${innerSize ? `w-${innerSize} h-${innerSize}` : 'w-3/4 h-3/4'
                    }`}
                style={{
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semibold">
                {value}
            </div>
        </div>
    );
}
export default ScoreIndicator;
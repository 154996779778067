import React, { useState, useEffect } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import SuccessPopup from "../PopUps/SuccessPopup";
import ErrorPopup from "../PopUps/ErrorPopup";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../images/lms logo.svg";
import picture from "../images/lsm picture.svg";
import NebutechLogo from "../NebutechLogo/NebutechLogo";
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const Terms = () => {
    const { companyname, id: uid } = useParams();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);
    const [msg, setMsg] = useState('');
    const [companyDetails, setCompanyDetails] = useState();
    const [companyId, setCompanyId] = useState();
    const [terms, setTerms] = useState();
    const [conditions, setConditions] = useState();

    useEffect(() => {
        let compDet = sessionStorage.getItem("cName") || localStorage.getItem("cName");
        if (compDet) {
            setCompanyDetails(simpleCrypto.decrypt(compDet))
        }
    }, [])
    useEffect(() => {
        if (companyDetails) {
            getTermsAndConditions();
        }
    }, [companyDetails])
    const getTermsAndConditions = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "companyId": companyDetails.id
        }
        console.log("payload", payload, companyDetails)
        axios.post(config.apiUrl + 'companyAdmin/getCompanyTermsAndCondition', payload, headers)
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message);
                    if (response.data.terms) {
                        setTerms(response.data.terms.terms);
                        setConditions(response.data.terms.conditions);
                    }
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    return (
        <div className="h-screen flex flex-col">
            <div className="flex w-full items-center justify-left text-sm bg-custom-bg-color text-custom-text-color" style={{ height: "3.7rem" }}>
                <div className='justify-left items-center ml-3 w-12'>         {companyname && uid ?
                    <Link to={`/${companyname}/${uid}/login`}><img src={logo} /></Link>
                    : <Link to={`/`}><img src={logo} /></Link>}</div>
                <div className="text-2xl font-semibold w-[90vw] text-center items-center">Entropi</div>
            </div>
            <SuccessPopup show={show} setShow={setShow} msg={msg} />
            <ErrorPopup show={showError} setShow={setShowError} msg={msg} />
            <div>
                <p className='text-2xl font-semibold px-16 py-2'>{companyname && uid ? companyname : ""}</p>
            </div>
            <p className='text-xl font-semibold px-16 py-2'>Terms and Conditions</p>
            {terms ?
                <div className="px-16 mt-2 text-base">
                    <p>{terms}</p>
                    <p>{conditions}</p>
                </div> :
                <div className="px-16 mt-2 text-base">
                    <p>Thanks for exploring opportunity to be part of Lead Management club. We believe in creating a cohesive
                        and collaborative environment to learn and share knowledge covering
                        both its breadth and depth.</p>
                </div>}
            <NebutechLogo />
        </div>
    )
}

export default Terms
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import moment from 'moment-timezone';
import CustomerTimeline from './CustomerTimeline';
import ActivityDetails from '../SalesPerson/ActivityDetails';
import SearchBox from "../Utils/SearchBox";
import EmployeeContext from '../context/employee/EmpContext';
import { useQuery } from 'react-query';
import TableRow from './TableRow';
import viewactivity from "../images/viewactivity.svg";
import addactivity from "../images/addactivity.svg";
import reachout from "../images/reachout.svg";
import newvisit from "../images/newvisit.svg";
import visit from "../images/newvisit1.svg";
import useDebounce from '../components/Serach/Debounce';
import CustActivity from '../components/Deal/CustActivity';
import { enqueueSnackbar } from 'notistack';


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const Customer = ({ customers, role, employees, branches, refetch }) => {
    const navigate = useNavigate();
    const { empState: employee } = useContext(EmployeeContext)
    const [showCustomerActivity, setShowCustomerActivity] = useState(false);
    const [customer, setCustomer] = useState();
    const [currentActivity, setCurrentActivity] = useState([]);
    const [leadTypes, setLeadTypes] = useState([]);
    const [interactions, setInteractions] = useState([]);
    const [userActivities, setUserActivities] = useState([]);
    const [userDemand, setUserDemand] = useState([]);
    const [showTimeline, setShowTimeline] = useState(false);
    const [occasions, setOccasions] = useState([]);
    const [potentials, setPotentials] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const [activityData, setActivityData] = useState([]);
    const [currentCustomers, setCurrentCustomers] = useState([]);
    const [actionTypes, setActionTypes] = useState([]);
    const [options, setOptions] = useState([]);
    const [filteredData1, setFilteredData1] = useState([]);
    const [filteredData2, setFilteredData2] = useState([]);
    const [filteredData3, setFilteredData3] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [leadTypeOptions, setLeadTypeOptions] = useState([]);
    const [activityOptions, setActivityOptions] = useState([]);
    const [searchText2, setSearchText2] = useState('');
    const [searchText3, setSearchText3] = useState('');
    const [visible, setVisible] = useState([]);
    const [curIndex, setCurIndex] = useState();
    const [search, setSearch] = useState("");
    const delayedValue = useDebounce(search);
    const [listItems, setListItems] = useState([]);
    const [surveyLink, setSurveyLink] = useState('')

    useEffect(() => {
        let url = `https://web.entropi.work/${employee.companyName.replace(/\s/g, "")}/${employee.uniqueCode}/surveyform`;
        setSurveyLink(url);
    }, [])
    useEffect(() => {
        filterCustomers();
    }, [delayedValue]);
    const filterCustomers = () => {
        if (!delayedValue) return;
        let currentList = [...customers];
        let newList = currentList && currentList.filter(item => {
            let mob = item.mobile ? simpleCrypto.decrypt(item.mobile) + "" : "";
            let fullName = (item.firstName + " " + item.lastName).toLowerCase();
            if (mob.includes(delayedValue) || fullName.includes(delayedValue.toLowerCase()))
                return item;
        })
        setListItems(newList)
    }
    const branchOptions = () => {
        let currentOptions = branches?.map(branch => {
            let obj = { value: branch.id, label: branch.branchName };
            return obj;
        })
        setOptions(currentOptions);
    }
    const lastActivityOptions = () => {
        let timeframe = [1, 3, 6, 12];
        let currentOptions = timeframe.map(time => {
            let obj = { value: time, label: time + ' months' };
            return obj;
        })
        setActivityOptions(currentOptions);
    }
    const leadOptions = (leadTypes) => {
        let currentOptions = leadTypes?.map(type => {
            let obj = { value: type.id, label: type.leadType };
            return obj;
        })
        setLeadTypeOptions(currentOptions);
    }
    useEffect(() => {
        var purchasesCount = currentActivity?.filter(function (item) {
            return item.userActivity === 4;
        }).length;
        branchOptions()
        lastActivityOptions();
        const today = new Date();
        const ninetyDaysAgo = new Date(today);
        ninetyDaysAgo.setDate(today.getDate() - 90);
        let customersArray = [];
        customersArray = customers && customers.filter((item) => {
            const itemDate = new Date(item.updatedAt);
            return itemDate >= ninetyDaysAgo && itemDate <= today;
        })
        setCurrentCustomers(customersArray ? [...customersArray] : []);
        setVisible(new Array(customersArray?.length).fill(false))
    }, [customers])
    const getAllParameters = () => {
        return axios.get(config.apiUrl + 'employees/getAllParameters')
    }
    const { data: parametersDetails } = useQuery("parameters-details", getAllParameters, {
        onSuccess: (response) => {
            if (response.data.status == true) {
                console.log("Parameters fetched from query", response);
                setLeadTypes(response.data.leadTypes);
                setInteractions(response.data.interactions);
                setUserActivities(response.data.userActivity);
                setUserDemand(response.data.keyAsk);
                setOccasions(response.data.occasions);
                setPotentials(response.data.potentials);
                leadOptions(response.data.leadTypes);
                setActionTypes(response.data.actionTypes);
            } else {
                console.log("Error");
            }
        },
        onError: (error) => {
            console.error('Error loading products data:', error);
        },
    });
    const getLeadType = (leadTypeId) => {
        if (!leadTypeId) return
        let leadtype = leadTypes.filter(item => item.id === leadTypeId)
        return leadtype[0]?.leadType;
    }
    const getInteraction = (interactionId) => {
        if (!interactionId) return
        let current_interaction = interactions.filter(item => item.id === interactionId)
        return current_interaction[0]?.interaction;
    }
    const getUserDemand = (userDemandId) => {
        if (!userDemandId) return
        let current_userDemand = userDemand.filter(item => item.id === userDemandId)
        return current_userDemand[0]?.keyAsk;
    }
    const getActivity = (activityId) => {
        if (!activityId) return
        let current_activity = userActivities.filter(item => item.id === activityId)
        return current_activity[0]?.activity;
    }
    const getOccasion = (activityId) => {
        if (!activityId) return
        let current_occasion = occasions.filter(item => item.id === activityId)
        return current_occasion[0]?.occasion;
    }
    const getPotential = (potentialId) => {
        if (!potentialId) return
        let potential = potentials.filter(item => item.id === potentialId)
        return potential[0]?.potential;
    }

    const toggleTimeline = () => {
        setShowTimeline(!showTimeline)
    }
    const toggleDetails = (data) => {
        setShowDetails(!showDetails)
        setActivityData(data);
    }

    const filter = (option) => {
        setSearchText(option.label);
        let data = [];
        if (filteredData?.length > 0)
            data = [...data, ...filteredData]
        else if (searchText2 || searchText3) {
            if (searchText2)
                data = [...data, ...filteredData2]
            if (searchText2)
                data = [...data, ...filteredData3]
        }
        else
            data = [...data, ...customers];

        data = data.filter(customer => customer.branchName === option.label);
        data = data.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
        setFilteredData1(data)
        setFilteredData(data);
    }
    const lastActivityFilter = (option) => {
        setSearchText2(option.label);
        let endDate = moment().format("MM/DD/YY");
        let startDate = moment().subtract(parseInt(option.value), 'month').format("MM/DD/YY")
        let data = [];
        if (filteredData?.length > 0)
            data = [...data, ...filteredData]
        else if (searchText || searchText3) {
            if (searchText)
                data = [...data, ...filteredData1]
            if (searchText3)
                data = [...data, ...filteredData3]
        }
        else
            data = [...data, ...customers];
        data = data.filter(emp => {
            let lastLogin = moment(emp.lastLogin).format("MM/DD/YY")
            if (lastLogin && lastLogin >= startDate && lastLogin <= endDate)
                return emp

        });
        data = data.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
        setFilteredData(data);
        setFilteredData2(data);
    }
    const leadTypeFilter = (option) => {
        setSearchText3(option.label);
        let data = [];
        if (filteredData?.length > 0)
            data = [...data, ...filteredData]
        else if (searchText || searchText2) {
            if (searchText)
                data = [...data, ...filteredData1]
            if (searchText2)
                data = [...data, ...filteredData2]
        }
        else
            data = [...data, ...customers];
        data = data.filter(customer => customer.leadType === parseInt(option.value));
        data = data.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
        setFilteredData(data);
        setFilteredData3(data);
    }
    const handleClear = (id) => {
        let data = []
        console.log("clear filter", filteredData, id, filteredData1, filteredData2, filteredData3);
        if (id === 1) {
            setFilteredData1([]);
            if (filteredData.length > 0)
                data = [...filteredData]
            else {
                if (searchText2)
                    data = [...data, ...filteredData2];
                if (searchText3)
                    data = [...data, ...filteredData3];
            }
        }
        if (id === 2) {
            setFilteredData2([]);
            if (filteredData.length > 0)
                data = [...filteredData]
            else {
                if (searchText)
                    data = [...data, ...filteredData1];
                if (searchText3)
                    data = [...data, ...filteredData3];
            }
        }
        if (id === 3) {
            setFilteredData3([]);
            if (filteredData.length > 0)
                data = [...filteredData]
            else {
                if (searchText)
                    data = [...data, ...filteredData1];
                if (searchText2)
                    data = [...data, ...filteredData2];
            }
        }
        data = data.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
        setFilteredData(data)
    }
    const handleNaviagate = () => {
        navigate(`/${employee.companyName}/addcustomer`, { state: { employee, employees } })
    }
    const handleNavigateNewVisit = (data) => {
        if (data.visitId) return;
        navigate(`/${employee.companyName}/assignsalesman`, { state: { employee, employees, customer: data } })
    }
    const handleNavigateAddCustomerActivity = (data) => {
        navigate(`/${employee.companyName}/addcustomeractivity`, { state: { employee, customer: data } })
    }
    const handleNavigateReachout = (data) => {
        navigate(`/${employee.companyName}/reachout`, { state: { actionTypes, customer: data } })
    }
    const handleBulkUpload = (text) => {
        console.log("text", text)
        navigate(`/${employee.companyName}/bulkupload`, { state: { employee, bulkType: text } })
    }
    const toggleActivity = (data = {}) => {
        setShowCustomerActivity(!showCustomerActivity);
    }

    return (
        <div className="ml-8 mt-20">
            {showTimeline && <CustomerTimeline closePopup={toggleTimeline} getActivity={getActivity} getPotential={getPotential} getOccasion={getOccasion} getUserDemand={getUserDemand} getInteraction={getInteraction} getLeadType={getLeadType} currentActivity={currentActivity} />}
            {showDetails && <ActivityDetails closePopup={toggleDetails} data={activityData} employee={employee} />}
            {role === 2 ?
                <div>
                    <div className={`pr-8 flex ${showCustomerActivity ? "justify-between" : "justify-end"} items-center gap-8`} >
                        <div className={`flex items-center w-full font-semibold gap-4 text-lg ${showCustomerActivity ? "" : "hidden"}`}>
                            <i className="fa fa-long-arrow-left fa-2x text-orange-600 cursor-pointer" onClick={() => { setShowCustomerActivity(false); setCurrentActivity(null) }} />
                            <p>{customer && customer?.firstName + "'s Activitiy Details as on "}{moment().format('MMMM Do YYYY')}</p>
                            {/* <button className="ml-auto bg-custom-bg-color hover:custom-bg-color text-custom-text-color h-8 p-2 font-semibold  uppercase rounded text-xs whitespace-nowrap -mr-3" onClick={() => toggleTimeline()}>View Timeline</button> */}
                        </div>
                        <div className={`w-[50vw] flex justify-between ${showCustomerActivity ? "hidden" : ""}`}>
                            <div>
                                <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className='cursor-pointer rounded-2xl p-2 w-[20vw] text-xs outline-none' placeholder='Search For Customers By Name Or Mobile' />
                                <div className={`search-results absolute bg-white top-32 z-50 w-64 h-[25vh] overflow-y-auto py-2 rounded-xl text-sm flex flex-col gap-1 ${!delayedValue || listItems.length === 0 ? "hidden" : ""}`} onBlur={() => setListItems([])}>
                                    {listItems && listItems.map((result, index) => (
                                        <div className='hover:shadow-md hover:bg-gray-400 w-full rounded-xl px-2 p-1 cursor-pointer' key={index} onClick={() => handleNavigateNewVisit(result)}>{result.firstName}&nbsp;{result.lastName}</div>
                                    ))}
                                </div>
                            </div>
                            <div className='flex gap-10'>
                                <button className={`bg-custom-bg-color hover:custom-bg-color text-custom-text-color h-8 p-2 font-semibold  uppercase rounded text-xs whitespace-nowrap -mr-7`} onClick={handleNaviagate}>Add Customer</button>
                                <button onClick={() => handleBulkUpload("Customer")} className={`bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs ${showCustomerActivity ? "hidden" : ""}`}>Bulk Upload</button>
                            </div>
                        </div>
                    </div>
                </div> :
                <>
                    <div className="pr-8 flex justify-between items-center">
                        <div className={`w-full items-center flex font-semibold gap-4 text-lg ${showCustomerActivity ? "" : "hidden"}`}>
                            <i className="fa fa-long-arrow-left fa-2x text-orange-600  cursor-pointer" onClick={() => { setShowCustomerActivity(false); setCurrentActivity(null) }} />
                            <p>{customer && customer?.firstName + "'s Activitiy Details as on "}{moment().format('MMMM Do YYYY')}</p>
                            {/* <button className="ml-auto bg-custom-bg-color hover:custom-bg-color text-custom-text-color p-2 px-2 font-semibold  uppercase rounded text-xs whitespace-nowrap -mr-7" onClick={() => toggleTimeline()}>View Timeline</button> */}
                        </div>
                    </div>
                </>
            }
            <div className="flex justify-between items-center">
                <div className={`text-left font-semibold text-black ${role == 2 ? "-mt-8" : ""} ${showCustomerActivity ? "hidden" : ""}`}><h5>Customers</h5></div>
                <div className="flex flex-row">
                    <div className={`${role === 1 ? "" : "hidden"}`}>
                        <button onClick={() => handleBulkUpload("Customer")} className={`bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs ${showCustomerActivity ? "hidden" : ""}`}>Bulk Upload</button>
                    </div>
                </div>
            </div>
            {showCustomerActivity ?
                <CustActivity customerId={customer?.customerId ? customer.customerId : customer.id} setShowActivity={toggleActivity} visible={visible[curIndex]} /> :
                <div className={`mt-3 tableFixHead `} style={{ height: "78vh" }}>
                    <table className={`border-spacing-x-0 border-spacing-y-2 border-separate`} style={showCustomerActivity ? {} : { marginTop: "12px" }}>
                        <thead>
                            <tr className='whitespace-nowrap'>
                                <th scope="col" className="th-heading" >{role !== 1 ? "Status" : "S.No"}</th>
                                <th scope="col" className={`th-heading ${employee.businessType === 1 ? "" : "hidden"}`} >Company Name</th>
                                <th scope="col" className="th-heading" >Name</th>
                                <th scope="col" className={`th-heading ${role === 2 ? "" : "hidden"}`} >Assigned To</th>
                                <th scope="col" className="th-heading" >Email</th>
                                <th scope="col" className="th-heading">Mobile</th>
                                <th scope="col" className={`th-heading ${role === 1 && employee.businessType !== 1 ? "" : "hidden"}`}>
                                    <Dropdown className="p-0 m-0" align="center">
                                        <Dropdown.Toggle className="bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color focus:ring-0 text-semibold  border-none" variant="secondary" id="dropdown-basic" style={{ padding: "0", border: "none" }}>
                                            <div className="flex justify-between items-center">
                                                <p className="text-sm font-bold">{searchText ? searchText.substring(0, 10) : "Branch"}</p>
                                                <span className="text-sm">{searchText ? <i className='fa fa-remove fs-2xs ml-3' onClick={() => { setSearchText(''); handleClear(1) }} style={{ color: "white" }}></i> : <i className="fa fa-angle-down fs-xl ml-2" style={{ color: "white" }}></i>}</span>
                                            </div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <SearchBox placeholder="Search Branches" options={options} handleChange={filter} />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </th>
                                <th scope="col" className={`th-heading ${role === 1 ? "" : "hidden"}`}>Visits</th>
                                <th scope="col" className={`th-heading ${role === 1 ? "" : "hidden"}`}>Points</th>
                                <th scope="col" className="th-heading">
                                    {role !== 1 ? "Lead Type" :
                                        <Dropdown className="p-0 m-0" align="end">
                                            <Dropdown.Toggle className="bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color focus:ring-0 text-semibold border-none" variant="secondary" id="dropdown-basic" style={{ padding: "0", border: "none" }}>
                                                <div className="flex justify-between items-center">
                                                    <p className="text-sm font-bold">{searchText3 ? searchText3.substring(0, 10) : "LeadType"}</p>
                                                    <span className="text-sm">{searchText3 ? <i className='fa fa-remove fs-2xs ml-3' onClick={() => { setSearchText3(''); handleClear(3) }} style={{ color: "white" }}></i> : <i className="fa fa-angle-down fs-lg ml-2" style={{ color: "white" }}></i>}</span>
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <SearchBox placeholder="Filter by Lead Type" options={leadTypeOptions} handleChange={leadTypeFilter} />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                </th>
                                <th scope="col" className="th-heading" >
                                    {role !== 1 ? "Last Activity" :
                                        <Dropdown className="p-0 m-0" align="end">
                                            <Dropdown.Toggle className="bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color focus:ring-0 text-semibold  border-none" variant="secondary" id="dropdown-basic" style={{ padding: "0", border: "none" }}>
                                                <div className="flex justify-between items-center">
                                                    <p className="text-sm font-bold">{searchText2 ? searchText2.substring(0, 10) : "Last Activity"}</p>
                                                    <span className="text-sm">{searchText2 ? <i className='fa fa-remove fs-2xs ml-3' onClick={() => { setSearchText2(''); handleClear(2) }} style={{ color: "white" }}></i> : <i className="fa fa-angle-down fs-lg ml-2" style={{ color: "white" }}></i>}</span>
                                                </div>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <SearchBox placeholder="Filter by Last Activity" options={activityOptions} handleChange={lastActivityFilter} />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }</th>
                                <th scope="col" className="th-heading" ></th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchText || searchText2 || searchText3 ?
                                filteredData?.length === 0 ? <tr className="whitespace-nowrap"></tr>
                                    : filteredData && filteredData.map((data, i) => (
                                        <tr key={i} className='tr whitespace-nowrap' style={data.visitId ? {} : null}>
                                            <td className="td-text pl-8">{1 + i}</td>
                                            <td className={`td-text ${employee.businessType === 1 ? "" : "hidden"}`}>{data.companyName ? data.companyName : ""}</td>
                                            <td className={`td-text ${!data.firstName && !data.lastName ? "text-center" : ""}`}>{data.firstName ? data.firstName : "-"}&nbsp;{data.lastName ? data.lastName : ""}</td>
                                            <td className={`td-text ${role === 2 ? "" : "hidden"}`}>{data.employeeName ? data.employeeName + " (" + data.employeeId + ")" : ""}</td>
                                            <td className="td-text">{data?.email ? simpleCrypto.decrypt(data.email) : "undefined"}</td>
                                            <td className="td-text">{data.mobile ? simpleCrypto.decrypt(data.mobile) : "undefined"}</td>
                                            <td className={`td-text text-left ${role === 1 && employee.businessType !== 1 ? "" : "hidden"}`}>{data.branchName}</td>
                                            <td className={`td-text text-center ${role === 1 ? "" : "hidden"}`}>{data.visitsCount ? data.visitsCount : 0}</td>
                                            <td className={`td-text text-center ${role === 1 ? "" : "hidden"}`}>{data.totalPoints ? data.totalPoints : 0}</td>
                                            <td className="td-text">{data?.leadType ? getLeadType(data.leadType) : ""}</td>
                                            <td className="td-text whitespace-nowrap">{data.updatedAt?.split("T")[0].split("-").reverse().join("-")}</td>
                                            <td className="flex text-right items-end justify-end">
                                                <div className="px-2 icon-w text-right" onClick={() => { setShowCustomerActivity(true); setCustomer(data) }}>
                                                    <div className="text-center items-end justify-center"><img src={viewactivity} /></div>
                                                </div>
                                                <div className={`${role === 3 && data.visitId ? "ml-2 px-2 icon-w text-right" : "hidden"}`} onClick={() => handleNavigateAddCustomerActivity(data)}>
                                                    <div className={`${role === 3 && data.visitId ? "text-center items-end justify-center" : "hidden"}`}><img src={addactivity} /></div>
                                                </div>
                                                <div className={`${role === 2 ? "ml-2 px-2 icon-w text-right" : "hidden"} ${data.visitId ? "bg-gray-200" : "cursor-pointer"}`} disabled={data.visitId ? true : false} onClick={() => { data.visitId ? console.log("no") : handleNavigateNewVisit(data) }}>
                                                    <div className={`${role === 2 ? "text-center items-end justify-center" : "hidden"} ${data.visitId ? "bg-gray-200" : "cursor-pointer"}`}><img src={data.visitId ? visit : newvisit} /></div>
                                                </div>
                                                <div className={`${role === 3 && !data.visitId ? "ml-2 px-2 icon-w text-right" : "hidden"}`} onClick={() => handleNavigateReachout(data)}>
                                                    <div className={`${role === 3 && !data.visitId ? "text-center items-end justify-center" : "hidden"}`}><img src={reachout} /></div>
                                                </div>

                                            </td>
                                        </tr>
                                    ))
                                :
                                currentCustomers && currentCustomers.map((data, i) => (
                                    role !== 1 ? <TableRow key={i} role={role} data={data} index={i} visible={visible} setCurIndex={setCurIndex}
                                        actionTypes={actionTypes} getLeadType={getLeadType} setShowCustomerActivity={setShowCustomerActivity}
                                        setCustomer={setCustomer} handleNavigateNewVisit={handleNavigateNewVisit} employees={employees} refetch={refetch} /> :
                                        <tr key={i} className='tr whitespace-nowrap' style={data.visitId ? {} : null}>
                                            <td className="td-text pl-8">{1 + i}</td>
                                            <td className={`td-text ${employee.businessType === 1 ? "" : "hidden"}`}>{data.companyName ? data.companyName : ""}</td>
                                            <td className={`td-text ${!data.firstName && !data.lastName ? "text-center" : ""}`}>{data.firstName ? data.firstName : "-"}&nbsp;{data.lastName ? data.lastName : ""}</td>
                                            <td className={`td-text ${role === 2 ? "" : "hidden"}`}>{data.employeeName ? data.employeeName + " (" + data.employeeId + ")" : ""}</td>
                                            <td className="td-text">{data?.email ? simpleCrypto.decrypt(data.email) : "undefined"}</td>
                                            <td className="td-text">{data.mobile ? simpleCrypto.decrypt(data.mobile) : "undefined"}</td>
                                            <td className={`td-text text-left ${role === 1 && employee.businessType !== 1 ? "" : "hidden"}`}>{data.branchName}</td>
                                            <td className={`td-text text-center ${role === 1 ? "" : "hidden"}`}>{data.visitsCount ? data.visitsCount : 0}</td>
                                            <td className={`td-text text-center ${role === 1 ? "" : "hidden"}`}>{data.totalPoints ? data.totalPoints : 0}</td>
                                            <td className="td-text">{data?.leadType ? getLeadType(data.leadType) : ""}</td>
                                            <td className="td-text whitespace-nowrap">{data.updatedAt?.split("T")[0].split("-").reverse().join("-")}</td>
                                            <td className="flex text-right items-end justify-end">
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={<Tooltip id="button-tooltip">{data.firstName} Activity Till Today</Tooltip>}
                                                >
                                                    <div className="px-2 icon-w text-right" onClick={() => { setShowCustomerActivity(true); setCustomer(data) }}>
                                                        <div className="text-center items-end justify-center"><img src={viewactivity} /></div>
                                                    </div></OverlayTrigger>
                                                <div className={`${role === 3 && data.visitId ? "ml-2 px-2 icon-w text-right" : "hidden"}`} onClick={() => handleNavigateAddCustomerActivity(data)}>
                                                    <div className={`${role === 3 && data.visitId ? "text-center items-end justify-center" : "hidden"}`}><img src={addactivity} /></div>
                                                </div>
                                                <div className={`${role === 2 ? "ml-2 px-2 icon-w text-right" : "hidden"} ${data.visitId ? "bg-gray-200" : "cursor-pointer"}`} disabled={data.visitId ? true : false} onClick={() => { data.visitId ? console.log("no") : handleNavigateNewVisit(data) }}>
                                                    <div className={`${role === 2 ? "text-center items-end justify-center" : "hidden"} ${data.visitId ? "bg-gray-200" : "cursor-pointer"}`}><img src={data.visitId ? visit : newvisit} /></div>
                                                </div>
                                                <div className={`${role === 3 && !data.visitId ? "ml-2 px-2 icon-w text-right" : "hidden"}`} onClick={() => handleNavigateReachout(data)}>
                                                    <div className={`${role === 3 && !data.visitId ? "text-center items-end justify-center" : "hidden"}`}><img src={reachout} /></div>
                                                </div>

                                            </td>
                                        </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            }
            <div className={`flex items-center w-full mx-auto gap-4 text-sm mt-1 ${!showCustomerActivity ? "" : "hidden"}`}>
                <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-green-600 rounded-xl'></div><span>Today's Customer</span></div>
                <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-orange-600 rounded-xl'></div><span>Evaluation Pending</span></div>
                <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-red-600 rounded-xl'></div><span>Pending Customer</span></div>
                <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-blue-500 rounded-xl'></div><span>Existing Customer</span></div>
                <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-purple-700 rounded-xl'></div><span>Deal Customer</span></div>
            </div>
        </div>
    )
}

export default Customer
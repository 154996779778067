import React, { useState, useEffect } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import Navbar1 from "../Navbar/Navbar1";
import LeftMenu1 from "../LeftMenu/LeftMenu1";
import ErrorPopup from "../PopUps/ErrorPopup";
import { enqueueSnackbar } from "notistack";
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const AddCustomer = () => {
    const navigate = useNavigate();
    const locations = useLocation();
    const employee = locations.state?.employee;
    const [employees, setEmployees] = useState([]);
    const [empSelected, setEmpSelected] = useState('');
    const [empSelectedError, setEmpSelectedError] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [showError, setShowError] = useState(false);
    const [msg, setMsg] = useState('');
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);

    useEffect(() => {
        console.log("state", locations.state?.employees)
        let currentEmployees = locations.state?.employees;
        currentEmployees = currentEmployees.filter(employee => employee.role === 3 && employee.status === 1)
        console.log(currentEmployees);
        setEmployees(currentEmployees);
        getAllBranchProducts();
    }, [])
    const getAllBranchProducts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "branchId": employee?.branchId
        }
        axios.post(config.apiUrl + 'manager/getAllBranchProducts', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log(response.data.message, response.data);
                    setProducts(response.data.products)
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const handleEmployeeChange = (e) => {
        setEmpSelected(e.target.value);
        setEmpSelectedError('');
    }
    const createCustomer = () => {
        if (empSelected === '') {
            setEmpSelectedError("Please select an employee");
            return;
        }
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "branchName": employee.branchName,
            "branchId": employee.branchId,
            "companyId": employee.companyId,
            "salesmanId": empSelected,
            "products": JSON.stringify(selectedProducts),
            "firstName": firstName,
            "lastName": lastName,
            "email": email ? simpleCrypto.encrypt(email) : null,
            "mobile": mobile ? simpleCrypto.encrypt(mobile) : null,
            "status": 1,
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'manager/addNewCustomer', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    enqueueSnackbar("Customer Added Successfully !", { variant: "success" })
                    navigate(`/${employee.companyName}/branchadmin/Customers`);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
                setMsg(error.response.data.message);
                setShowError(true);
                setTimeout(() => { setMsg(''); setShowError(false) }, 2000)
            });
    };
    const selectProducts = (e) => {
        let value = (e.target.value);
        if (e.target.checked) {
            setSelectedProducts([...selectedProducts, value])
        }
        else {
            setSelectedProducts(selectedProducts.filter(product => product !== value))
        }
    }
    return (
        <section className="h-screen flex flex-col justify-center items-center">
            <ErrorPopup show={showError} setShow={setShowError} msg={msg} />
            <Navbar1 employee={employee} />
            <LeftMenu1 section="Add Customer" />
            <div><i onClick={() => navigate(`/${employee.companyName}/branchadmin/Customers`)} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i></div>
            <div className="md:w-1/3 max-w-sm -mt-16">
                <div className="text-center md:text-left">
                    <label className="mr-1 text-lg text-black-600">Add Customer</label>
                </div>
                <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-3"
                    type="text"
                    disabled={true}
                    value={employee.branchName}
                    name="branchName"
                    placeholder="Branch Name" />
                <div>
                    <select name="employee" value={empSelected} className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-3"
                        onChange={(e) => handleEmployeeChange(e)} >
                        <option value="">Select Employee</option>
                        {employees && employees.map(employee => {
                            return (
                                <option key={employee.id} value={employee.id}>{employee.firstName}&nbsp;{employee.lastName}</option>
                            )
                        })}
                    </select>
                    <div className='error'>{empSelectedError && (
                        <div className="error">{empSelectedError}</div>
                    )}</div>
                </div>
                <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-3"
                    type="text"
                    onChange={(e) => setFirstName(e.target.value)}
                    name="firstName"
                    placeholder="First Name" />
                <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-3"
                    type="text"
                    onChange={(e) => setLastName(e.target.value)}
                    name="lastName"
                    placeholder="Last Name" />
                <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-3"
                    type="text"
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    placeholder="Customer Email" />
                <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-3"
                    type="text"
                    onChange={(e) => setMobile(e.target.value)}
                    name="mobile"
                    placeholder="Customer Mobile" />
                <div className="grid grid-cols-1 justify-between ml-2 items-center mt-2 w-full">
                    {products && products.map(product => {
                        return (
                            <div key={product.id} className="flex items-center">
                                <label htmlFor={JSON.stringify(product.id)} className="cursor-pointer">
                                    <input
                                        type="checkbox"
                                        className="cursor-pointer"
                                        id={JSON.stringify(product.id)}
                                        name={product.productFamilyName}
                                        value={product.id}
                                        onChange={(e) => selectProducts(e)}
                                    />
                                    &nbsp;{product.productFamilyName}</label>
                            </div>
                        )
                    })}
                </div>

                <div className="flex justify-end text-center md:text-left">
                    {/* <button className="mt-4 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white uppercase rounded text-xs tracking-wider" onClick={() => navigate(-1)} type="submit">Back</button> */}
                    <button className="mt-4 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold  uppercase rounded text-xs" onClick={createCustomer} type="submit">Add</button>
                </div>
            </div>
        </section>
    )
}

export default AddCustomer
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment-timezone";
import { enqueueSnackbar } from "notistack";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const ViewDetail = ({ data, closePopup, actionStatus, actionTypes, employees }) => {
    const navigate = useNavigate();
    const locations = useLocation();
    const [notes, setNotes] = useState('');
    const [targetDate, setTargetDate] = useState("")
    const [status, setStatus] = useState("")
    const [selectedStatus, setSelectedStatus] = useState()
    const [currentActionType, setCurrentActionType] = useState()
    const [statusType, setStatusType] = useState()
    const [selectedEmployee, setSelectedEmployee] = useState()
    const [errors, setErrors] = useState({});
    const [activeEmployees, setActiveEmployees] = useState([]);
    useEffect(() => {
        console.log(data, actionStatus, employees);
        setNotes(data.notes)
        setStatus(data.status)
        setTargetDate(new Date(data.targetDate))
        setSelectedStatus(data.statusId)
        data.statusId ? getStatus(data.statusId) : getStatus(data.status)
        getActionType(data.actionType)
        let curActiveEmps = employees?.filter(emp => emp.status === 1);
        setActiveEmployees(curActiveEmps);
    }, []);

    const handleChange = (val, arr, func) => {
        func(val);
    }
    const getStatus = (typeId) => {
        let bname = actionStatus.find(data => data.id === typeId);
        setStatusType(bname.status)
    }
    const getActionType = (typeId) => {
        let action = actionTypes.find(data => data.id === typeId);
        setCurrentActionType(action.action)
    }
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!selectedEmployee) {
            errorObj = {
                ...errorObj,
                employeeError: true
            }
            error = true;
        }

        setErrors({ ...errorObj });
        return error;
    }
    const getCurrentEmployee = (typeId) => {
        let employee = activeEmployees.find(item => item.id === typeId);
        return employee?.employeeId;
    }
    const changeEmployeeId = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: "error" })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload;
        if (data.reachOutId) {
            payload = {
                reachOutId: data.reachOutId,
                employeeId: selectedEmployee
            }
        } else {
            payload = {
                id: data.id,
                employeeId: selectedEmployee
            }
        }
        axios.post(config.apiUrl + 'manager/updateEmployeedIdOnNotes', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success products", response.data);
                    closePopup();
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    }
    function formatDate(dateString) {
        const parsedDate = moment(dateString);
        const formattedDate = parsedDate.format('MMMM DD, YYYY hh:mm A');
        return formattedDate;
    }
    return (
        <div>
            <Modal
                show={true}
                onHide={closePopup}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="flex flex-col w-full justify-center items-center mx-auto mt-6">
                    <div className="text-center">
                        <label className="mr-1 text-lg text-black-600">{data.employeeName} action for {data.firstName} {data.lastName}</label>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mt-3">
                        <div className="flex flex-col">
                            <label className="text-xs text-gray-600">Customer Name</label>
                            <p className="text-sm text-black w-44 p-2 border border-solid border-gray-300 rounded">{data.firstName + " " + data.lastName}</p>
                        </div>
                        <div className="flex flex-col">
                            <label className="text-xs text-gray-600">Employee Id</label>
                            {data.employeeStatus === 1 ?
                                <p className="text-sm w-44 text-black p-2 border border-solid border-gray-300 rounded">{data.employeeId}</p>
                                : <select name="employee" value={selectedEmployee} className={`text-sm w-44 p-2 ${errors.employeeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                                    onChange={(e) => { handleChange(e.target.value, employees, setSelectedEmployee); setErrors({ ...errors, placeError: false }) }} >
                                    <option value="">{selectedEmployee ? getCurrentEmployee(selectedEmployee) : "----EmployeeID----"}</option>
                                    {activeEmployees && activeEmployees.map(item => {
                                        return (
                                            <option value={item.employeeId} key={item.id}>{item.firstName} {item.lastName}({item.employeeId})</option>
                                        )
                                    })}
                                </select>
                            }
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mt-3">
                        <div className="flex flex-col">
                            <label className="text-xs text-gray-600">Action Type</label>
                            <p className="text-sm text-black w-44 p-2 border border-solid border-gray-300 rounded">{currentActionType}</p>
                        </div>
                        <div className="flex flex-col">
                            <label className=" text-xs text-gray-600">Status</label>
                            <p className="text-sm text-black w-44 p-2 border border-solid border-gray-300 rounded">{statusType}</p>
                        </div>
                    </div>
                    <div className="flex flex-col mt-3">
                        <label className="text-xs text-gray-600">Notes</label>
                        <p className="text-sm text-black w-[23rem] min-h-[3rem] p-2 border border-solid border-gray-300 rounded">{notes}</p>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mt-3">
                        <div className="flex flex-col">
                            <label className="text-xs text-gray-600">Target Date</label>
                            <p className="text-xs w-44 min-h-[1rem] p-2 border border-solid border-gray-300 rounded">{data.targetDate ? formatDate(data.targetDate) : ""}</p>
                        </div>
                    </div>
                </div>
                <div className={`flex ${data.employeeStatus === 2 ? "justify-between" : "justify-end"} text-center my-6 mx-12`}>
                    <button className="mt-4 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white font-semibold uppercase rounded text-xs" type="submit" onClick={closePopup}>Close</button>
                    <button className={`mt-4 bg-black hover:bg-black px-4 py-2 text-white font-semibold uppercase rounded text-xs ${data.employeeStatus === 2 ? "" : "hidden"} `} type="submit" onClick={changeEmployeeId}>Update</button>
                </div>
            </Modal >
        </div >
    )
}

export default ViewDetail
import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import { config } from '../../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import moment from 'moment-timezone';
import interaction from "../../images/interaction.svg";
import activity from "../../images/activity.svg";
import interest from "../../images/customerinterest.svg";
import lead from "../../images/leadtype.svg";
import ReactSpeedometer from 'react-d3-speedometer';
import BatteryGauge from 'react-battery-gauge'
import DynamicRating from '../../Utils/DynamicRating';
import ScoreIndicator from '../../Utils/ScoreIndicator';
import ActivityDetails from '../../SalesPerson/ActivityDetails';
import EmployeeContext from '../../context/employee/EmpContext';
import { Spinner } from 'react-bootstrap';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const CustActivity = ({ customerId, setShowActivity, visible }) => {
    const { empState: employee } = useContext(EmployeeContext)
    const [customer, setCustomer] = useState();
    const [customerActivities, setCustomerActivities] = useState([]);
    const [currentActivity, setCurrentActivity] = useState([]);
    const [leadTypes, setLeadTypes] = useState([]);
    const [userActivities, setUserActivities] = useState([]);
    const [occasions, setOccasions] = useState([]);
    const [potentials, setPotentials] = useState([]);
    const [ageRanges, setAgeRanges] = useState([]);
    const [incomeRanges, setIncomeRanges] = useState([]);
    const [maritalStatus, setMaritalStatus] = useState([]);
    const [regions, setRegions] = useState([]);
    const [occupations, setOccupations] = useState([]);
    const [visits, setVisits] = useState()
    const [purchases, setPurchases] = useState()
    const [purchasePower, setPurchasePower] = useState()
    const [satisfactionRating, setSatisfactionRating] = useState()
    const [score, setScore] = useState(0);
    const [showDetails, setShowDetails] = useState(false);
    const [activityData, setActivityData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [availableFields, setAvailableFields] = useState([]);
    const [genders, setGenders] = useState([]);
    const [insuranceTypes, setInsuranceTypes] = useState([]);

    useEffect(() => {
        getCustomerDetails();
        getCustomerActivities();
        getAllParameters();
        getAvailableFields();
    }, [])

    const getCustomerDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            id: customerId,
        }
        console.log("Paylod for getcustomerdetails", payload);
        axios.post(config.apiUrl + 'employees/getCustomerDetails', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("success customer details fetched", response.data.message, response.data);
                    setCustomer(response.data.customer)
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const getCustomerActivities = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        setLoading(true)
        var payload = {
            customerId: customerId,
        }
        console.log("Paylod for getcustomeractivities", payload);
        axios.post(config.apiUrl + 'manager/getCustomerActivities', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("success customer activities fetched", response.data.message, response.data);
                    setCustomerActivities([...customerActivities, response.data.customerActivities]);
                    setCurrentActivity(response.data.customerActivities)
                    setVisits(response.data.customerActivities.length)
                    var purchasesCount = response.data.customerActivities.filter(function (item) {
                        return item.userActivity === 3 || item.userActivity === 4;
                    }).length;
                    setPurchases(purchasesCount)
                    setPurchasePower(response.data.purchasePower || 0);
                    setSatisfactionRating(response.data.overallSatisfaction || 0)
                    setScore(response.data.customerPoints)
                } else {
                    console.log("Error");
                }
                setLoading(false)
            })
            .catch(function (error) {
                console.log("error", error);
                setLoading(false)
            });
    };
    const getAllParameters = () => {
        axios.get(config.apiUrl + 'employees/getAllParameters')
            .then((response) => {
                if (response.data.status == true) {
                    console.log("Parameters fetched from query", response);
                    setLeadTypes(response.data.leadTypes);
                    setUserActivities(response.data.userActivity);
                    setOccasions(response.data.occasions);
                    setPotentials(response.data.potentials);
                    setAgeRanges(response.data.ageRange);
                    setIncomeRanges(response.data.incomeRange);
                    setMaritalStatus(response.data.maritalStatus);
                    setRegions(response.data.region);
                    setOccupations(response.data.occupation);
                    setGenders(response.data.gender);
                    setInsuranceTypes(response.data.insuranceTypes);
                } else {
                    console.log("Error");
                }
            }).catch((error) => {
                console.error('Error loading products data:', error);
            });
    };
    const getAvailableFields = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'employees/getAvailableFieldsByCompanyId', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success available fields", response.data);
                    setAvailableFields(response.data.availableFields);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };

    const isAvailable = (field) => {
        let value = false;
        availableFields.map(item => {
            for (let key in item) {
                if (key === field)
                    value = item[key];
            }
        })
        return value;
    }

    const getLeadType = (leadTypeId) => {
        if (!leadTypeId) return
        let leadtype = leadTypes.filter(item => item.id === leadTypeId)
        return leadtype[0]?.leadType;
    }
    const getActivity = (activityId) => {
        if (!activityId) return
        let current_activity = userActivities.filter(item => item.id === activityId)
        return current_activity[0]?.activity;
    }
    const getOccasion = (activityId) => {
        if (!activityId) return
        let current_occasion = occasions.filter(item => item.id === activityId)
        return current_occasion[0]?.occasion;
    }
    const getPotential = (potentialId) => {
        if (!potentialId) return
        let potential = potentials.filter(item => item.id === potentialId)
        return potential[0]?.potential;
    }
    const getAgeGroup = (ageRangeId) => {
        if (!ageRangeId) return
        let age = ageRanges.filter(item => item.id === ageRangeId)
        return age[0]?.age;
    }
    const getIncomeRange = (incomeRangeId) => {
        if (!incomeRangeId) return
        let income = incomeRanges.filter(item => item.id === incomeRangeId)
        return income[0]?.income;
    }
    const getMaritalStatus = (statusId) => {
        if (!statusId) return
        let marital = maritalStatus.filter(item => item.id === statusId)
        return marital[0]?.maritalStatus;
    }
    const getRegion = (statusId) => {
        if (!statusId) return
        let region = regions.filter(item => item.id === statusId)
        return region[0]?.place;
    }
    const getOccupation = (statusId) => {
        if (!statusId) return
        let occupation = occupations.filter(item => item.id === statusId)
        return occupation[0]?.occupation;
    };
    const getGender = (statusId) => {
        if (!statusId) return
        let current = genders && genders.find(item => item.id === statusId)
        return current?.gender;
    };
    const getInsuranceType = (statusId) => {
        if (!statusId) return
        let current = insuranceTypes && insuranceTypes.find(item => item.id === statusId)
        return current?.insurance;
    };
    const getLastPurchasedate = () => {
        let purchase_data = currentActivity && currentActivity.length > 0 ? currentActivity.filter(item => item.userActivity === 4 || item.userActivity === 3) : [];
        if (purchase_data.length > 0) {
            let date = moment(purchase_data[0].updatedAt).format("DD-MM-YY");
            return date;
        }
        return "";
    }
    const toggleDetails = (data) => {
        setShowDetails(!showDetails)
        setActivityData(data);
    }
    if (loading) {
        return (
            <div className='mt-20 flex justify-center items-center'><Spinner animation="border" /></div>
        )
    }
    return (
        <>
            {showDetails && <ActivityDetails closePopup={toggleDetails} data={activityData} employee={employee} />}
            <div className='grid grid-cols-3 w-full h-[80vh] mt-3'>
                <div className='flex flex-col gap-4 border border-gray-400 rounded-xl p-3 overflow-y-auto'>
                    <div className={`flex flex-col gap-1 text-sm my-2`}>
                        <p className='font-semibold text-base'>Demographics</p>
                        <p>Name : {customer && customer?.firstName}&nbsp;{customer && customer?.lastName}</p>
                        <p>Email : {employee.role === 1 || visible ? customer && customer?.email ? simpleCrypto.decrypt(customer.email) : "" : "*".repeat(10)}</p>
                        <p>Mobile : {employee.role === 1 || visible ? customer && customer?.mobile ? simpleCrypto.decrypt(customer.mobile) : "" : "*".repeat(10)}</p>
                        <p>Lead Type : {customer && customer?.leadType ? getLeadType(customer.leadType) : ""}</p>
                        <p className={`${isAvailable('age') ? "" : "hidden"}`}>Age Range : {customer && customer?.ageRange ? getAgeGroup(customer.ageRange) : ""}</p>
                        <p className={`${isAvailable('income') ? "" : "hidden"}`}>Income Range : {customer && customer?.incomeRange ? getIncomeRange(customer.incomeRange) : ""}</p>
                        <p className={`${isAvailable('maritalStatus') ? "" : "hidden"}`}>Marital Status : {customer && customer?.maritalStatus ? getMaritalStatus(customer.maritalStatus) : ""}</p>
                        <p className={`${isAvailable('region') ? "" : "hidden"}`}>Place : {customer && customer?.region ? getRegion(customer.region) : ""}</p>
                        <p className={`${isAvailable('occupation') ? "" : "hidden"}`}>Occupation : {customer && customer?.occupation ? getOccupation(customer.occupation) : ""}</p>
                        <p className={`${isAvailable('gender') ? "" : "hidden"}`}>Gender : {customer && customer?.gender ? getGender(customer.gender) : ""}</p>
                        <p className={`${isAvailable('annualIncome') ? "" : "hidden"}`}>Annual Income : {customer && customer?.annualIncome ? customer.annualIncome : ""}</p>
                        <p className={`${isAvailable('dependents') ? "" : "hidden"}`}>Dependents : {customer && customer?.dependents ? customer.dependents : ""}</p>
                        <p className={`${isAvailable('otherIncome') ? "" : "hidden"}`}>Other Income : {customer && customer?.otherIncome ? customer.otherIncome : ""}</p>
                        <p className={`${isAvailable('loanAmount') ? "" : "hidden"}`}>Loan Amount : {customer && customer?.loanAmount ? customer.loanAmount : ""}</p>
                        <p className={`${isAvailable('tenure') ? "" : "hidden"}`}>Loan Tenure : {customer && customer?.tenure ? customer.tenure : ""}</p>
                        <p className={`${isAvailable('insuranceType') ? "" : "hidden"}`}>Type of Insurance : {customer && customer?.insuranceType ? getInsuranceType(customer.insuranceType) : ""}</p>
                        <p className={`${isAvailable('insuredValue') ? "" : "hidden"}`}>Value Insured : {customer && customer?.insuredValue ? customer.insuredValue : ""}</p>
                    </div>
                    <div className={`flex flex-col gap-1 text-sm my-2`}>
                        <p className='font-semibold text-base'>Details</p>
                        <p>Purchases Count: {currentActivity?.filter(function (item) {
                            return item.userActivity === 4 || item.userActivity == 3;
                        }).length}</p>
                        <p>Customer Since: {currentActivity && currentActivity.length > 0 ? moment(currentActivity[currentActivity.length - 1].updatedAt).year() : ""}</p>
                        <p>Recent Visit Date: {currentActivity && currentActivity.length > 0 ? moment(currentActivity[0].updatedAt).format("DD-MM-YY") : ""}</p>
                        <p>Last Purchase Date: {getLastPurchasedate()}</p>
                    </div>
                    <div className={`flex flex-col gap-1 text-sm my-2 ${employee.companyType === 2 ? "" : "hidden"}`}>
                        <p className='font-semibold text-base'>Occasions</p>
                        {customer?.importantDates && customer.importantDates.map((item, i) => {
                            if (item.occasion)
                                return (
                                    <div className='flex gap-4' key={i}>
                                        <p>{item.occasion}:</p>
                                        <p>{item.date ? moment(item.date).format("DD/MM") : ""}</p>
                                    </div>
                                )
                        })}
                    </div>
                </div>
                <div className='flex flex-col gap-4 border border-gray-400 rounded-xl h-[80vh]'>
                    <div className="overflow-y-auto bg-white w-full h-full pb-2 rounded-xl">
                        {currentActivity && currentActivity.length === 0 ?
                            <p className='py-10 text-lg font-semibold text-center'>No Activity Found</p>
                            :
                            <div className="t-container position-relative" style={{ margin: "0" }}>
                                {currentActivity && currentActivity.map((data, i) => {
                                    return (
                                        <div key={i}>
                                            <div className="timeline-block timeline-block-right">
                                                <div className={getActivity(data.userActivity) == "Paid Advance" ? "marker-semi" : getActivity(data.userActivity) == "Purchased" ? "marker" : "marker-empty"} style={{ zIndex: "10" }}></div>
                                                <div className="timeline-content">
                                                    <div className='flex justify-between font-semibold'>
                                                        <h5>{moment(data.updatedAt).format('MMMM Do YYYY')}</h5>
                                                        <button className="bg-custom-bg-color hover:custom-bg-color text-custom-text-color p-1 mr-1 font-semibold uppercase rounded text-[8px] whitespace-nowrap" onClick={(e) => toggleDetails(data)}>View Details</button>
                                                    </div>
                                                    <p className='timeline-text flex py-0.5'><img src={activity} className='w-7 px-1' /> {getActivity(data.userActivity) == null ? "No activity" : getActivity(data.userActivity)}</p>
                                                    <p className='timeline-text flex py-0.5'><img src={lead} className='w-7 px-1' /> {getOccasion(data.occasion) == null ? "No Occassion" : getOccasion(data.occasion)}</p>
                                                    <p className='timeline-text flex py-0.5'><img src={interest} className='w-7 px-1' />{getPotential(data.customerPotential) == null ? "No Customer Potential" : getPotential(data.customerPotential)}</p>
                                                    <p className='timeline-text flex py-0.5'><img src={interaction} className='w-7 px-1' />{data.salesperson}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>
                </div>
                <div className='flex flex-col items-center gap-1 border border-gray-400 rounded-xl p-3'>
                    <p className='font-semibold'>Conversion Rates</p>
                    <div>
                        <ReactSpeedometer
                            width={200}
                            height={130}
                            maxValue={100}
                            needleColor="black"
                            value={purchases && visits ? ((purchases) / (visits) * 100) : 0}
                            valueFormat={"d"}
                            segmentColors={["#e4e4e4", "#ccc", "#8c8c8c", "#545454", "#3b3a3a"]}
                            currentValueText={`${purchases}/${visits}`}
                            textColor={"black"}
                            needleHeightRatio={0.6}
                            ringWidth={40}
                            needleTransitionDuration={3333}
                            needleTransition="easeElastic"
                            customSegmentLabels={[
                                {
                                    text: 'Poor',
                                    position: 'OUTSIDE',
                                    color: '#555',
                                    fontSize: '12px',
                                },
                                {
                                    text: 'Fair',
                                    position: 'OUTSIDE',
                                    color: '#555',
                                    fontSize: '12px',
                                },
                                {
                                    text: 'Good',
                                    position: 'OUTSIDE',
                                    color: '#555',
                                    fontSize: '12px',
                                },
                                {
                                    text: 'Great',
                                    position: 'OUTSIDE',
                                    color: '#555',
                                    fontSize: '12px',
                                },
                                {
                                    text: 'Excellent',
                                    position: 'OUTSIDE',
                                    color: '#555',
                                    fontSize: '12px',
                                },
                            ]}
                        />
                    </div>
                    <div className='flex flex-col items-center'>
                        <p className='font-semibold mt-2'>Purchase Power</p>
                        <BatteryGauge
                            value={purchasePower}
                            maxValue={5}
                            size={120}
                            customization={{
                                batteryMeter: {
                                    fill: 'black',
                                    lowBatteryValue: 15,
                                    lowBatteryFill: 'black',
                                    outerGap: 1,
                                    noOfCells: 5,
                                    interCellsGap: 1,
                                },
                                readingText: {
                                    style: { display: "none" },
                                },
                            }}
                        />
                        <p className='font-semibold'>{purchasePower}</p>
                    </div>
                    <div className='flex flex-col items-center'>
                        <p className='font-semibold mt-2'>Customer Satisfaction</p>
                        <DynamicRating rating={satisfactionRating} />
                        <p className='font-semibold'>{satisfactionRating}</p>
                    </div>
                    <p className='font-semibold mt-2'>Customer Score</p>
                    <ScoreIndicator value={score} maxValue={720} />
                </div>
            </div>
        </>
    )
}

export default CustActivity
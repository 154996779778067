import { useEffect, useState } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import ProductEnquiryGraph from "./ProductEnquiryGraph";
import { useQuery } from 'react-query';
import OfflineVsOnlieSales from "./CompanyAnalytics/OfflineVsOnlineSalesGraph";
import BranchAreaVsSales from "./CompanyAnalytics/BranchAreaVsSalesGraph";
import LeadTypesCount from "./CompanyAnalytics/LeadTypesGraph";


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function CustomerAnalytics() {
    const [section, setSection] = useState("CustomerAnalytics")
    const [tProduct, setTProduct] = useState()
    const [topBranch, setTopBranch] = useState()
    const [topEmp, setTopEmp] = useState();
    const [branchCount, setBranchCount] = useState()
    const [empCount, setEmpCount] = useState("")
    const [productCount, setProductCount] = useState("")
    const [newProductCount, setNewProductCount] = useState("")
    const [events, setEvents] = useState("")

    const getTopBranchAndEmployee = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        return axios.get(config.apiUrl + 'companyAdmin/getTopBranchAndEmployee', { headers: headers })
    };
    const { data } = useQuery("topBranch&Emp-details", getTopBranchAndEmployee, {
        enabled: section === "CustomerAnalytics",
        onSuccess: (response) => {
            console.log("success top branch and employee from query ", response.data);
            if (response.data.status == true) {
                setTopBranch(response.data.topBranch)
                setTopEmp(response.data.topEmployee)
                setBranchCount(response.data.activeBranches);
                setEmpCount(response.data.activeEmployees);
                setProductCount(response.data.activeProducts);
                setNewProductCount(response.data.newProducts);
                setEvents(response.data.events)
            } else {
                console.log("Error");
            }
        },
        onError: (error) => {
            console.error('Error loading branches data:', error);
        },
    });

    return (
        <div className="">
            <div className="flex gap-4 items-center text-center">
                <div className="flex flex-col justify-around mt-2 rounded-xl bg-custom-bg-color hover:custom-bg-color text-custom-text-color w-24 h-24 px-1 py-2 font-semibold">
                    <p className="text-sm">{topBranch && topBranch.branchName}</p>
                    <p className="text-sm">Top Branch</p>
                </div>
                <div className="flex flex-col justify-around mt-2 rounded-xl bg-custom-bg-color hover:custom-bg-color text-custom-text-color w-24 h-24 p-1 font-semibold">
                    <p className="text-sm">{topEmp && topEmp.name}</p>
                    <p className="text-sm">Top Employee</p>
                </div>
                <div className="flex flex-col justify-around mt-2 rounded-xl bg-custom-bg-color hover:custom-bg-color text-custom-text-color w-24 h-24 p-1 font-semibold">
                    <p className="text-sm">{tProduct ? tProduct : "-"}</p>
                    <p className="text-sm">Top Product</p>
                </div>
                <div className="flex flex-col justify-around mt-2 rounded-xl bg-custom-bg-color hover:custom-bg-color text-custom-text-color w-24 h-24 p-1 font-semibold">
                    <p className="text-2xl">{events}</p>
                    <p className="text-lg">Events</p>
                </div>
            </div>
            <div className="flex gap-2 max-w-max">
                <ProductEnquiryGraph setTProduct={setTProduct} headOffice={true} />
                {/* <AgeGraph /> */}
            </div>
            <div className="flex gap-2 max-w-max mb-2">
                <OfflineVsOnlieSales />
                <BranchAreaVsSales />
                <LeadTypesCount />
            </div>
        </div>
    );
};

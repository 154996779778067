import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import Navbar1 from "../Navbar/Navbar1";
import LeftMenu1 from "../LeftMenu/LeftMenu1";
import { enqueueSnackbar } from 'notistack'
import uploadImage from "../Utils/UploadImage";
import logo from "../images/lms logo.svg";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const CreateCompany = () => {
    const navigate = useNavigate();
    const locations = useLocation();
    const employee = locations.state.employee;
    const companyTypes = locations.state.companyTypes;
    const [companyName, setCompanyName] = useState('');
    const [category, setCategory] = useState('');
    const [userType, setUserType] = useState();
    const [selectedUserType, setSelectedUserType] = useState();
    const [typeOfBusiness, setTypeOfBusiness] = useState();
    const [selectedBusinessType, setSelectedBusinessType] = useState()
    const [pan, setPan] = useState('');
    const [registrationNo, setRegistrationNo] = useState('');
    const [status, setStatus] = useState(1);
    const [companyCode, setCompanyCode] = useState('HO-01')
    const [selectedDuration, setSelectedDuration] = useState()
    const [city, setCity] = useState()
    const [location, setLocation] = useState()
    const [country, setCountry] = useState()
    const [durations, setDurations] = useState([])
    const [errors, setErrors] = useState({});
    const [sectorTypes, setSectorTypes] = useState([]);
    const [selectedSize, setSelectedSize] = useState();
    const [businessTypes, setBuisnessTypes] = useState([]);
    const [selectedIndustryType, setSelectedIndustryType] = useState();
    const [image, setImage] = useState();
    const [industryTypes, setIndustryTypes] = useState([]);

    useEffect(() => {
        console.log("companyTypes", companyTypes, employee)
        getAllDurationsAndCompanySizes()
        getAllUserTypes();
    }, []);
    const handleChangeCategory = (e) => {
        setCategory(e.target.value);
        setErrors({ ...errors, categoryError: false })
    }
    const handleChangeBusinessType = (e) => {
        setSelectedBusinessType(e.target.value);
    }
    const handleChangeDuration = (e) => {
        setSelectedDuration(e.target.value);
    }
    const createCompany = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: "error" })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload;
        if (category === "Paid") {
            payload = {
                "companyCode": companyCode,
                "name": companyName,
                "category": category,
                "pan": pan,
                "userType": parseInt(selectedUserType),
                "duration": parseInt(selectedDuration),
                "registrationNo": registrationNo,
                "typeOfBusiness": selectedBusinessType === null ? typeOfBusiness : parseInt(selectedBusinessType),
                "city": city,
                "location": location,
                "country": country,
                "status": status,
                "sectorType": selectedSize,
                "industryType": parseInt(selectedIndustryType),
                "logo": image
            }
        } else {
            payload = {
                "companyCode": companyCode,
                "name": companyName,
                "category": category,
                "pan": pan,
                "userType": parseInt(selectedUserType),
                "typeOfBusiness": selectedBusinessType === null ? typeOfBusiness : parseInt(selectedBusinessType),
                "registrationNo": registrationNo,
                "status": status,
                "city": city,
                "location": location,
                "country": country,
                "sectorType": selectedSize,
                "industryType": parseInt(selectedIndustryType),
                "logo": image
            }
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/createCompany', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    enqueueSnackbar("Company Created Successfully", { variant: "success" })
                    // createBranch(response.data.newCompany)
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const createBranch = (newCompany) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "newCompanyId": newCompany.id,
            "branchName": companyName,
            "branchCode": companyCode,
            "branchType": 4,
            "branchArea": 1,
            "city": city,
            "country": country,
            "location": location,
            "status": status
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/createBranch', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    enqueueSnackbar("HO Created Successfully", { variant: "success" })
                    handleNaviagate(newCompany)
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const getAllDurationsAndCompanySizes = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getAllDurations', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message);
                    setDurations(response.data.duration);
                    setSectorTypes(response.data.sectorTypes);
                    setBuisnessTypes(response.data.companyTypes);
                    setIndustryTypes(response.data.industryTypes);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const getAllUserTypes = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getAllUserTypes', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message);
                    setUserType(response.data.userTypes)
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const handleNaviagate = (data) => {
        navigate('/addadmin', { state: { employee, data: data } })
    }
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!companyName) {
            errorObj = {
                ...errorObj,
                companyNameError: true
            }
            error = true;
        }
        if (!pan) {
            errorObj = {
                ...errorObj,
                panError: true
            }
            error = true;
        }
        if (!registrationNo) {
            errorObj = {
                ...errorObj,
                registrationNoError: true
            }
            error = true;
        }
        if (!category) {
            errorObj = {
                ...errorObj,
                categoryError: true
            }
            error = true;
        }
        if (!location) {
            errorObj = {
                ...errorObj,
                locationError: true
            };
            error = true;
        }
        if (!city) {
            errorObj = {
                ...errorObj,
                cityError: true
            };
            error = true;
        }
        if (!country) {
            errorObj = {
                ...errorObj,
                countryError: true
            };
            error = true;
        }
        if (!selectedUserType) {
            errorObj = {
                ...errorObj,
                userTypeError: true
            };
            error = true;
        }
        if (!image) {
            errorObj = {
                ...errorObj,
                imageError: true
            };
            error = true;
        }
        if (!selectedIndustryType) {
            errorObj = {
                ...errorObj,
                industryTypeError: true
            };
            error = true;
        }
        if (!selectedSize) {
            errorObj = {
                ...errorObj,
                sectorTypeError: true
            };
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    const handleImage = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const res = await uploadImage(e.target.files[0]);
            if (res) setImage(res)
        }
    }
    return (
        <section className="h-screen flex flex-col justify-center items-center mx-5">
            <Navbar1 employee={employee} />
            <LeftMenu1 section="Create Company" />
            <div>
                <i onClick={() => navigate('/superadmin/Company')} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i>
            </div>
            <div className="md:w-1/3 max-w-sm mt-2 relative">
                <div className="text-center md:text-left">
                    <label className="mt-14 mr-1 text-lg text-black-600">Create Company & Head Office</label>
                </div>
                <div className="h-[77vh] overflow-y-auto">
                    <Row style={{ width: "100%" }} className="no-gutters">
                        <Col md={9}>
                            <p className={`text-sm w-full px-4 py-2 border-[1px] border-gray-300 rounded mt-2`} >{'Head Office'}&nbsp;({companyCode})</p>
                            <input className={`text-sm w-full px-4 py-2 ${errors.companyNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                                type="text"
                                onChange={(e) => { setCompanyName(e.target.value); setErrors({ ...errors, companyNameError: false }) }}
                                name="companyName"
                                value={companyName}
                                placeholder="Company Name" />

                        </Col>
                        <Col md={3}>
                            <div style={{ marginLeft: "5px", marginRight: "5px", borderRadius: "8px", height: "75px", marginTop: "8px", textAlign: "center", objectFit: "contain" }} className={`${errors.imageError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"}`}>
                                <label htmlFor="upload-button">
                                    {image ? (
                                        <OverlayTrigger placement="right" overlay={<Tooltip>Upload Logo</Tooltip>}>
                                            <div className="cursor-pointer">
                                                <img src={image} style={{ marginTop: "1px", width: "72px", height: "72px" }} />
                                            </div>
                                        </OverlayTrigger>
                                    ) : (
                                        <>
                                            <center><img src={logo} className="mt-1 w-12 cursor-pointer" /></center>
                                            <span className="text-[10px] cursor-pointer">Upload Logo</span>
                                        </>
                                    )}
                                </label>
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    id="upload-button"
                                    name="image"
                                    onChange={(e) => handleImage(e)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <select name="typeOfBusiness" value={selectedBusinessType} className={`text-sm w-full px-4 py-2 border-[1px] border-gray-300 rounded mt-2`}
                        onChange={(e) => handleChangeBusinessType(e)} >
                        <option value={typeOfBusiness ? typeOfBusiness : ""}>{"Select Type of Business"}</option>
                        {companyTypes && companyTypes.map(cType => {
                            return (
                                <option key={cType.id} value={cType.id}>{cType.companyType}</option>
                            )
                        })}
                    </select>

                    <select name="industryType" value={selectedIndustryType} className={`text-sm w-full px-4 py-2 ${errors.industryTypeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                        onChange={(e) => { setSelectedIndustryType(e.target.value); setErrors({ ...errors, industryTypeError: false }) }} >
                        <option value="">Select Industry Type</option>
                        {industryTypes && industryTypes.map(cType => {
                            return (
                                <option key={cType.id} value={cType.id}>{cType.industryType}</option>
                            )
                        })}
                    </select>

                    <select name="type" value={selectedUserType} className={`text-sm w-full px-4 py-2 ${errors.userTypeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                        onChange={(e) => { setSelectedUserType(e.target.value); setErrors({ ...errors, userTypeError: false }) }}>
                        <option value="">Select Type of User</option>
                        {userType && userType.map(cType => {
                            return (
                                <option key={cType.id} value={cType.id}>{cType.userType}</option>
                            )
                        })}
                    </select>

                    <input className={`text-sm w-full px-4 py-2 ${errors.locationError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                        type="text"
                        onChange={(e) => { setLocation(e.target.value); setErrors({ ...errors, locationError: false }) }}
                        name="location"
                        placeholder="Location" />
                    <input className={`text-sm w-full px-4 py-2 ${errors.cityError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                        type="text"
                        onChange={(e) => { setCity(e.target.value); setErrors({ ...errors, cityError: false }) }}
                        name="city"
                        placeholder="City" />
                    <input className={`text-sm w-full px-4 py-2 ${errors.countryError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                        type="text"
                        onChange={(e) => { setCountry(e.target.value); setErrors({ ...errors, countryError: false }) }}
                        name="country"
                        placeholder="Country" />

                    <input className={`text-sm w-full px-4 py-2 ${errors.panError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                        type="text"
                        onChange={(e) => { setPan(e.target.value); setErrors({ ...errors, panError: false }) }}
                        name="pan"
                        placeholder="PAN" />
                    <input className={`text-sm w-full px-4 py-2 ${errors.registrationNoError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                        type="text"
                        onChange={(e) => { setRegistrationNo(e.target.value); setErrors({ ...errors, registrationNoError: false }) }}
                        name="registrationNo"
                        placeholder="Registration No" />
                    <select name="sectorType" value={selectedSize} className={`text-sm w-full px-4 py-2 ${errors.sectorTypeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                        onChange={(e) => { setSelectedSize(e.target.value); setErrors({ ...errors, sectorTypeError: false }) }} >
                        <option value="">Sector Type</option>
                        {sectorTypes && sectorTypes.map(item =>
                            <option key={item.id} value={item.id}>{item.sectorType}</option>
                        )}

                    </select>
                    <select name="category" value={category} className={`text-sm w-full px-4 py-2 ${errors.categoryError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                        onChange={(e) => handleChangeCategory(e)} >
                        <option value={category}>{category == '' ? "Select Category" : category}</option>
                        <option key={1} value={"Free"}>Free</option>
                        <option key={2} value={"Paid"}>Paid</option>
                    </select>
                    {category === "Paid" ?
                        <div>
                            <select name="type" value={selectedDuration} className={`text-sm w-full px-4 py-2 border-[1px] border-gray-300 rounded mt-2`}
                                onChange={(e) => handleChangeDuration(e)}>
                                <option value="">Select Duration</option>
                                {durations.map(d => {
                                    return (
                                        <option key={d.id} value={d.id}>{d.duration}</option>
                                    )
                                })}
                            </select>
                        </div>
                        : ""}
                </div>
                <div className="flex justify-end text-center md:text-left">
                    <button className="mt-2 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs" onClick={createCompany} type="submit">Create</button>
                </div>
            </div>
        </section >
    )
}

export default CreateCompany
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import logo from "../images/lms logo.svg";
import { Col, Row } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { enqueueSnackbar } from 'notistack';
import { config } from '../Config/Config';


const VerifyOtp = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const [email, setEmail] = useState()
    const [otp, setOtp] = useState('');
    const { companyname, id: uid } = useParams();

    useEffect(() => {
        setEmail(queryParams.get('email'))
        console.log("email", queryParams.get('email'));
    }, [uid])

    const myComponentStyle = {
        width: '30px',
        height: '38px',
        color: 'black',
        border: '1px solid black',
        paddingBottom: 2,
        borderRadius: '6px',
        marginLeft: '1px',
        marginRight: '1px',
        fontWeight: 'bold',
        fontSize: '20px',
    }
    const verifyOtp = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "companyName": companyname,
            "otp": otp,
            "email": email,
            "code": uid
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/verifyOtp', payload, { headers: headers })
            .then(function (response) {
                console.log("success", response.data.message, payload);
                setTimeout(otpVerified, [2000])
                enqueueSnackbar('OTP Verified Successfully', { variant: 'success' })
            })
            .catch(function (error) {
                console.log("error", error, payload);
                enqueueSnackbar('Incorrect otp!', { variant: 'error' })
            });
    };
    const sendOtp = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "companyName": companyname,
            "email": email,
            "code": uid
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/sendOtp', payload, { headers: headers })
            .then(function (response) {
                enqueueSnackbar('Otp sent successfully!', { variant: 'success' })
                console.log("success", response.data.message, payload);
                enqueueSnackbar("OTP send successfully", { variant: "success" })
            })
            .catch(function (error) {
                console.log("error", error, payload);
                enqueueSnackbar("Failed to send OTP", { variant: "error" })
            });
    };
    const otpVerified = () => {
        navigate(`/${companyname.replace(/\s/g, "")}/${uid}/login`)
    }
    return (
        <section className="flex flex-col">
            <div className="flex w-full h-16 items-center justify-left text-sm" style={{ color: "#EFF2F5", background: "#142431" }}>
                <div className='justify-left items-center ml-3 w-12 cursor-pointer'><img src={logo} /></div>
                <div className="text-2xl font-semibold w-[90vw] text-center items-center">Entropi</div>
            </div>
            <Row style={{ width: "100%" }}>
                <Col md={4}></Col>
                <Col md={4} className='mt-[25vh] border-2 rounded-xl'>
                    <div className="text-center md:text-left">
                        <label className="mt-4 mr-1 text-lg text-black-600">Verify OTP</label>
                    </div>
                    <div className='mt-6 flex items-center text-center justify-center'>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            inputType='number'
                            inputStyle={myComponentStyle}
                            renderSeparator={<span> &nbsp; </span>}
                            renderInput={(props) => <input {...props} />}
                        />
                    </div>
                    <div id="status" className="errormsg"></div>

                    <div className="flex justify-end text-center md:text-left">
                        <button className="mt-4 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs" type="submit" onClick={verifyOtp}>Verify</button>
                    </div>
                    <center>
                        <p className='p-4'>Did not get the OTP? <span onClick={sendOtp} className='text-cyan-700 cursor-pointer'> Resend OTP</span>
                        </p>
                    </center>

                </Col>
            </Row>
        </section>
    )
}

export default VerifyOtp;
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Modal, Form, OverlayTrigger, Tooltip, FormControl, Card, Button } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import ErrorPopup from "../PopUps/ErrorPopup";
import { enqueueSnackbar } from 'notistack'
import Navbar1 from "../Navbar/Navbar1";
import LeftMenu1 from "../LeftMenu/LeftMenu1";
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const AddAdmin = () => {
    const navigate = useNavigate();
    const locations = useLocation();
    const employee = locations?.state.employee
    const data = locations?.state.data
    const [companyName, setCompanyName] = useState('');
    const [category, setCategory] = useState('');
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [role, setRole] = useState(1)
    const [employeeId, setEmployeeId] = useState('')
    const [show, setShow] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const [popupMsg, setPopupMsg] = useState(false);
    const [status, setStatus] = useState('')
    const [errors, setErrors] = useState({});

    useEffect(() => {
        console.log("d", data);
        if (data.adminId)
            getAdminDetails();
    }, []);
    const getAdminDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "adminId": data.adminId
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/getCompanyAdminDetails', payload, { headers: headers })
            .then(function (response) {
                console.log(response.data.message, response.data.adminDetails);
                if (response.data.adminDetails) {
                    setEmployeeId(response.data.adminDetails.employeeId);
                    setFirstName(response.data.adminDetails.firstName);
                    setLastName(response.data.adminDetails.lastName);
                    response.data.adminDetails.email ? setEmail(simpleCrypto.decrypt(response.data.adminDetails.email)) : setEmail('');
                    setMobile(response.data.adminDetails.mobile);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
                setShow(false);
                setShowPopup(true);
                setPopupMsg(error.response.data.message);
                setTimeout(() => { setShowPopup(false) }, 3000)
            });
    };
    const updateCompany = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: "error" })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "companyId": data.id,
            "role": 1,
            "status": 1,
            "employeeId": employeeId.toUpperCase(),
            "firstName": firstName,
            "lastName": lastName,
            "email": simpleCrypto.encrypt(email),
            "mobile": simpleCrypto.encrypt(mobile),
            "adminId": data.adminId
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/addOrUpdateCompanyAdminDetails', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    enqueueSnackbar('Admin Created Successfully', { variant: "success" })
                    handleNaviagate();
                } else {
                    console.log("Error", payload);

                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
                setShow(false);
                setShowPopup(true);
                setPopupMsg(error.response.data.message);
                setTimeout(() => { setShowPopup(false) }, 3000)
            });
    };
    const handleNaviagate = () => {
        navigate('/superadmin/Company', { state: employee })
    }

    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!firstName) {
            errorObj = {
                ...errorObj,
                firstNameError: true
            };
            error = true;
        }
        if (!lastName) {
            errorObj = {
                ...errorObj,
                lastNameError: true
            };
            error = true;
        }
        if (!email) {
            errorObj = {
                ...errorObj,
                emailError: true
            }
            error = true;
        }
        if (!mobile) {
            errorObj = {
                ...errorObj,
                mobileError: true
            }
            error = true;
        }
        if (!employeeId) {
            errorObj = {
                ...errorObj,
                employeeIdError: true
            }
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    return (
        <div>
            {showPopup ?
                <ErrorPopup show={showPopup} setShow={setShowPopup} msg={popupMsg} />
                :
                <section className="h-screen flex flex-col justify-center items-center mx-5">
                    <Navbar1 employee={employee} />
                    <LeftMenu1 section="Add Admin" />
                    <div><i onClick={() => navigate('/superadmin/Company')} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i></div>
                    <div className="md:w-1/3 max-w-sm mt-10">
                        <div className="text-center md:text-left">
                            <label className="mt-8 mr-1 text-lg text-black-600">Add Admin</label>
                        </div>
                        {/* <label className="mr-1 mt-2 text-sm text-black-600">Role</label>
                        <select name="role" value={role} className={`text-sm w-full px-4 py-2 ${errors.roleError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                            onChange={(e) => { setRole(e.target.value); setErrors({ ...errors, roleError: false }) }} >
                            <option value={1}>Company Admin</option>
                        </select> */}
                        <label className="mt-3 text-sm text-black-600">Employee Id</label>
                        <input className={`text-sm w-full px-2 py-2 ${errors.employeeIdError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                            type="text"
                            value={employeeId}
                            onChange={(e) => { setEmployeeId(e.target.value); setErrors({ ...errors, employeeIdError: false }) }}
                            name="employeeId"
                            placeholder="Employee Id" />
                        {/* <label className="mt-3 text-sm text-black-600">Status</label>
                        <select name="status" value={status} className={`text-sm w-full px-2 py-2 ${errors.statusError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                            onChange={(e) => { setStatus(e.target.value); setErrors({ ...errors, statusError: false }) }}>
                            <option value="">Status</option>
                            <option value={1}>Active</option>
                            <option value={2}>Inactive</option>
                        </select> */}
                        <label className="mr-1 mt-3 text-sm text-black-600">First Name</label>
                        <input className={`text-sm w-full px-4 py-2 ${errors.firstNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                            type="text"
                            value={firstName}
                            onChange={(e) => { setFirstName(e.target.value); setErrors({ ...errors, firstNameError: false }) }}
                            name="firstName"
                            placeholder="First Name" />
                        <label className="mr-1 mt-3 text-sm text-black-600">Last Name</label>
                        <input className={`text-sm w-full px-4 py-2 ${errors.lastNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                            type="text"
                            onChange={(e) => { setLastName(e.target.value); setErrors({ ...errors, lastNameError: false }) }}
                            name="lastName"
                            value={lastName}
                            placeholder="Last Name" />
                        <label className="mr-1 mt-3 text-sm text-black-600">Mobile</label>
                        <input className={`text-sm w-full px-4 py-2 ${errors.mobileError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                            type="text"
                            value={mobile}
                            onChange={(e) => { setMobile(e.target.value); setErrors({ ...errors, mobileError: false }) }}
                            name="mobile"
                            placeholder="Mobile" />
                        <label className="mr-1 mt-3 text-sm text-black-600">Email</label>
                        <input className={`text-sm w-full px-4 py-2 ${errors.emailError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                            type="email"
                            value={email}
                            onChange={(e) => { setEmail(e.target.value); setErrors({ ...errors, emailError: false }) }}
                            name="email"
                            placeholder="Email" />

                        <div className="flex justify-end text-center md:text-left">
                            {/* <button className="mt-4 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white uppercase rounded text-xs tracking-wider" onClick={removeEmployee} type="submit">Delete</button> */}
                            <button className="mt-4 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2  font-semibold uppercase rounded text-xs" onClick={updateCompany} type="submit">Add</button>
                        </div>
                    </div>
                    {/* </Modal >} */}
                </section>}
        </div >
    )
}

export default AddAdmin
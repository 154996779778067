import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

const URLPopup = ({ togglePopup, data }) => {
    const [webURL, setWebURL] = useState('');
    const [mobURL, setMobURL] = useState('');
    useEffect(() => {
        let curWebUrl = `https://web.entropi.work/${data.name.replace(/\s/g, "")}/${data.uniqueCode}/login`
        setWebURL(curWebUrl);
        let curMobUrl = `https://${data.name.replace(/\s/g, "")}-${data.uniqueCode}.mob.entropi.work`;
        setMobURL(curMobUrl)
    }, [data])
    const handleCopyClick = (url) => {
        const input = document.createElement('input');
        input.setAttribute('value', url);
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        enqueueSnackbar("Copied to clipboard!", { variant: 'success' })
    };

    return (
        <Modal show={true} onHide={togglePopup} size="md" centered >
            <Modal.Body className='m-0 p-0'>
                <div className='flex flex-col gap-4 justify-center items-start w-full text-sm'>
                    <div className='flex justify-between items-center w-full'>
                        <p className='font-semibold whitespace-nowrap'>Web URL:</p>
                        <button className='w-10 items-end bg-custom-bg-color text-custom-text-color font-semibold uppercase rounded text-[10px]'
                            onClick={() => handleCopyClick(webURL)}>COPY</button>
                    </div>
                    <a className='text-blue-600 underline underline-offset-1 -mt-4' href={webURL} target='_blank'>{webURL}</a>
                    <div className='flex justify-between items-center w-full mt-3'>
                        <p className='font-semibold whitespace-nowrap'>Mobile URL:</p>
                        <button className='w-10 items-end bg-custom-bg-color text-custom-text-color p-0.5 font-semibold uppercase rounded text-[10px]'
                            onClick={() => handleCopyClick(mobURL)}>COPY</button>
                    </div>
                    <a className='text-blue-600 underline underline-offset-1 -mt-4' href={mobURL} target="_blank">{mobURL}</a>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default URLPopup
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Bar } from "react-chartjs-2";


const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export default function UsersActivity({ productsData, usersActivity }) {
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth())
    const [valuesArray, setValuesArray] = useState([]);

    useEffect(() => {
        setMonthlyDataForActivity();
    }, [productsData]);

    useEffect(() => {
        setMonthlyDataForActivity()
    }, [currentMonth]);

    const setMonthlyDataForActivity = () => {
        let curMonth = currentMonth || new Date().getMonth();
        let currentProductsArr = JSON.parse(JSON.stringify(productsData));
        let currentMonthData = currentProductsArr.filter(product => {
            const date = new Date(product.updatedAt);
            const month = date.getMonth();
            if (month == curMonth) return product;
        });
        let countsArr = [];
        currentMonthData && currentMonthData.forEach(data => {
            let index = data.userActivity - 1;
            if (!countsArr[index]) countsArr[index] = {};
            if (!countsArr[index]["totalCount"]) countsArr[index]["totalCount"] = 0;
            countsArr[index]["totalCount"]++;
        })
        setValuesArray(countsArr)
    }

    const productsDataSet2 = {
        labels: usersActivity && usersActivity.map((data, i) => data.activity),
        datasets: [
            {
                data: valuesArray && valuesArray.map((data, i) => data ? data.totalCount : 0),
                backgroundColor: [
                    'black',
                ],
                borderColor: 'black',
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20,
                // labels: values && values.map(item => item.count)
            }
        ]
    }
    return (
        <div className="flex">
            <Card className="flex flex-col justify-between relative" style={{ marginTop: "1rem", height: "250px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                <div className="font-semibold ml-12">
                    <span className="text-sm">Products Count By Users Activity</span>
                </div>
                <select name="months" value={currentMonth} className="absolute -top-1 right-1 text-xs max-w-max  border border-solid border-gray-300 rounded mt-2"
                    onChange={(e) => setCurrentMonth(e.target.value)} >
                    {months && months.map((item, i) => {
                        return (
                            <option value={i} key={i}>{item}</option>
                        )
                    })}
                </select>
                <div style={{ height: "200px" }}>
                    <Bar data={productsDataSet2}
                        options={{
                            maintainAspectRatio: true,
                            responsive: true,
                            redraw: false,
                            elements: {
                                line: {
                                    tension: 0.6
                                }
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        font: {
                                            size: 8, // Adjust the font size as needed
                                        },
                                    },
                                },
                                y: {
                                    ticks: {
                                        precision: 0
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: false
                                },
                            }
                        }
                        } />
                </div>
            </Card>
        </div>
    );
};

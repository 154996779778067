import React, { useContext, useState } from "react";
import logout from '../images/logout.svg'
import { useNavigate } from "react-router";
import SimpleCrypto from "simple-crypto-js";
import { config } from "../Config/Config";
import axios from "axios";
import LogoutPopup from "../PopUps/LogoutPopup";
import EmployeeContext from "../context/employee/EmpContext";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function Navbar({ role }) {
    const { empState: employee } = useContext(EmployeeContext)
    const [showPopup, setShowPopup] = useState(false)

    const togglePopup = () => {
        setShowPopup(!showPopup)
    }
    return (
        <div>
            {showPopup && <LogoutPopup closePopup={togglePopup} employee={employee} />}
            <div className="flex w-[86vw] z-50 fixed top-0 text-custom-text-color h-14 items-center justify-between text-sm bg-custom-bg-color">
                <div className="text-2xl font-semibold w-[44vw] flex justify-end items-center gap-3">
                    {employee && employee.logo ?<div className="bg-gray-50 p-1 rounded-xl"> <img src={employee.logo} alt='' className='w-9 h-9' /></div> : <></>}
                    <p>{employee.companyName || "Entropi"}</p>
                </div>
                <div className='flex items-center justify-between h-12 gap-3 px-6'>
                    <div className="flex flex-col items-start"><p>{employee?.firstName}&nbsp;{employee?.lastName}</p><p>({role})</p></div>
                    <img src={logout} alt='' className='w-8 h-6 cursor-pointer' onClick={() => togglePopup()} />
                </div>
            </div>
        </div>
    )
}
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LeftMenu from '../LeftMenu/LeftMenu';
import axios from 'axios';
import { config } from '../Config/Config';
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from 'moment-timezone';
import Navbar from "../Navbar/Navbar";
import SimpleCrypto from "simple-crypto-js";
import editicon from "../images/editicon.svg";
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const CompanyEmployees = ({ employees }) => {
    const location = useLocation();
    // const employee = location.state;
    const navigate = useNavigate();
    const { path } = useParams();
    const [allEmployees, setAllEmployees] = useState([]);
    const [section, setSection] = useState("CompanyEmployees")

    useEffect(() => {
        console.log("employee", employees)
        getEmployeesByCompany();
    }, []);

    const handleNavigate = (data) => {
        let employee = { ...employees, "companyId": data.companyId }
        navigate("/editdetails", { state: { employee, data: data } })
    }


    const getEmployeesByCompany = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + `companyAdmin/getEmployeesByCompany`, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, response.data);
                    setAllEmployees(response.data.allEmployees);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const getEmployeeRole = (role) => {
        if (role === 1) return "Company Admin"
        else if (role === 4) return "Super Admin"
        else if (role === 2) return "Branch Admin"
        else return "Relationship Manager"
    }
    return (
        <div>
            <div className="ml-8 mt-20">
                <div className="flex flex-col justify-between">
                    <div className="text-left font-semibold text-black"><h5>Admins</h5></div>
                    {/* <div className="-mt-5 px-8 text-right">
                                    <button onClick={handleNaviagate} className="bg-black hover:bg-black px-4 py-2 font-semibold text-white uppercase rounded text-xs -mr-7">Add Company</button>
                                </div> */}
                </div>

                <div className="mt-3 tableFixHead">
                    <table className="border-spacing-x-0 border-spacing-y-2 border-separate" >
                        <thead>
                            <tr className="whitespace-nowrap">
                                <th scope="col" className="th-heading">S.No</th>
                                <th scope="col" className="th-heading">Company Name</th>
                                <th scope="col" className="th-heading">Admin Name</th>
                                <th scope="col" className="th-heading">Email</th>
                                <th scope="col" className="th-heading">Mobile</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {allEmployees.map((data, i) => (
                                <tr key={data.id} className="tr whitespace-nowrap" >
                                    <td className="td-text">{i + 1}</td>
                                    <td className="td-text">{data.name}</td>
                                    <td className="td-text">{data.firstName} &nbsp;{data.lastName}</td>
                                    <td className="td-text">{data.email ? simpleCrypto.decrypt(data.email) : "-"}</td>
                                    <td className="td-text">{data.mobile}</td>
                                    {/* <td className="h-full bg-gray-200 rounded-r-3xl w-2 p-0 m-0">
                                        <div onClick={() => handleNavigate(data)} className="m-auto text-center font-semibold text-xs cursor-pointer"><i class="fa fa-pencil fa-lg" aria-hidden="true"></i></div>
                                    </td> */}
                                    <td className="flex text-right items-end justify-end">
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Edit Admin</Tooltip>}
                                        >
                                            <div className="px-2 icon-w text-right" onClick={() => handleNavigate(data)} >
                                                <div className="text-center items-end justify-center z-10"><img src={editicon} /></div></div>
                                        </OverlayTrigger>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default CompanyEmployees
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import EmployeeContext from '../context/employee/EmpContext';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import { enqueueSnackbar } from "notistack";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const ServcieOptOut = ({ togglePopup, data, getServicesByCompany }) => {
    const { empState: employee } = useContext(EmployeeContext);

    useEffect(() => {
        console.log(data)
    }, [])

    const updateCompanyServiceStatus = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            section: data.section,
            companyId: data.id
        }
        axios.post(config.apiUrl + `superAdmin/unregisterFromService`, payload, { headers: headers })
            .then(function (response) {
                console.log(response.data);
                enqueueSnackbar(response.data.message, { variant: "success" });
                getServicesByCompany();
                togglePopup();
            })
            .catch(function (error) {
                console.log("error", error);
                enqueueSnackbar(error?.response?.data?.message, { variant: "error" })
            });
    }

    return (
        <Modal show={true} onHide={togglePopup} size="md" centered >
            <Modal.Body>
                <div className='flex flex-col gap-16 justify-center items-center'>
                    <p className="text-lg text-black-600 font-semibold text-center">Do you want to continue with unregistering {data.name} from {data.service}</p>
                    <div className='flex items-center justify-around w-full'>
                        <button className='bg-orange-400 text-white p-2 font-semibold uppercase rounded text-xs'
                            onClick={togglePopup}>Cancel</button>
                        <button className='bg-custom-bg-color text-custom-text-color p-2 font-semibold uppercase rounded text-xs' onClick={updateCompanyServiceStatus}>Continue</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ServcieOptOut
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Bar } from "react-chartjs-2";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export default function VisitsVsPurchase({ productsData, products }) {
    const [valuesArr1, setValuesArr1] = useState([]);
    const [currentProduct, setCurrentProduct] = useState("");
    const [productsDataArr, setProductsDataArr] = useState([]);

    useEffect(() => {
        console.log(productsData)
        setProductsDataArray();
    }, [productsData, products])
    const setProductsDataArray = () => {
        let cur = [];
        productsData && productsData.forEach(data => {
            data.products.forEach(prod => {
                let newData = {
                    branchId: data.branchId, customerPotential: data.customerPotential,
                    customerSatisfaction: data.customerSatisfaction, updatedAt: data.updatedAt,
                    userActivity: data.userActivity, userDemand: data.userDemand
                }
                cur.push({ ...newData, "id": prod })
            })
        });
        console.log("cur", cur)
        setProductsDataArr([...cur])
        setCurrentProduct(products[0]?.id)
    }
    useEffect(() => {
        setMonthlyData();
    }, [currentProduct])
    const setMonthlyData = () => {
        let currentMonthData = [], visits = [1, 2], purchase = [3, 4];
        for (let i = 0; i < 12; i++)
            currentMonthData[i] = { "month": 0, "purchaseCount": 0, "visitsCount": 0 };
        let curProdData = productsDataArr && productsDataArr.filter(prod => prod.id === currentProduct);
        curProdData && curProdData.forEach(data => {
            let index = new Date(data.updatedAt).getMonth();
            if (purchase.includes(data.userActivity))
                currentMonthData[index]["purchaseCount"]++;
            else
                currentMonthData[index]["visitsCount"]++;
        });
        setValuesArr1(currentMonthData)
    }

    const productsDataSet = {
        labels: months && months.map((data, i) => data),
        datasets: [
            {
                data: valuesArr1 && valuesArr1.map((data, i) => data && data.visitsCount ? data.visitsCount : 0),
                backgroundColor: [
                    'black',
                ],
                borderColor: 'black',
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20,
            },
            {
                data: valuesArr1 && valuesArr1.map((data, i) => data && data.purchaseCount ? data.purchaseCount : 0),
                backgroundColor: ['green'],
                borderColor: 'green',
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20,
            }
        ]
    }
    return (

        <div>
            <Card className="flex flex-col justify-between relative" style={{ marginTop: "1rem", height: "250px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                <div className="font-semibold ml-12">
                    <span className="text-sm">Visits vs Purchase Graph</span>
                </div>
                <select name="branches" value={currentProduct} className="absolute -top-1 right-1 text-xs w-32  border border-solid border-gray-300 rounded mt-2"
                    onChange={(e) => setCurrentProduct(e.target.value)} >
                    <option value="">--Choose Product--</option>
                    {products && products.map((item, i) => {
                        return (
                            <option value={item.id} key={i}>{item.productFamilyName}</option>
                        )
                    })}
                </select>
                <div className="flex justify-center items-center gap-4 w-full">
                    <div className="flex justify-center items-center gap-1"><span className="bg-black w-2 h-2"></span><span className="text-xs">Visits</span></div>
                    <div className="flex justify-center items-center gap-1"><span className="bg-green-600 w-2 h-2"></span><span className="text-xs">Sales</span></div>
                </div>
                <div style={{ height: "200px" }}>
                    <Bar data={productsDataSet}
                        options={{
                            maintainAspectRatio: true,
                            responsive: true,
                            redraw: false,
                            elements: {
                                line: {
                                    tension: 0.6
                                }
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        font: {
                                            size: 8, // Adjust the font size as needed
                                        },
                                    },
                                },
                                y: {
                                    ticks: {
                                        precision: 0
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: false
                                },
                            }
                        }
                        } />
                </div>
            </Card>
        </div>
    );
};

import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import Navbar1 from "../Navbar/Navbar1";
import LeftMenu1 from "../LeftMenu/LeftMenu1";
import { enqueueSnackbar } from 'notistack'
import EmployeeContext from "../context/employee/EmpContext";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const RaiseCase = () => {
    const navigate = useNavigate();
    const locations = useLocation();
    const { empState: employee } = useContext(EmployeeContext)
    const caseStatusData = locations.state.caseStatusData;
    const caseTypeData = locations.state.caseTypeData;
    const caseSeverityData = locations.state.severityData;
    const [caseType, setCaseType] = useState('');
    const [status, setStatus] = useState('');
    const [severity, setSeverity] = useState('');
    const [caseDetails, setCaseDetails] = useState('');
    const [selectedCaseType, setSelectedCaseType] = useState();
    const [selectedCaseStatus, setSelectedCaseStatus] = useState();
    const [selectedSeverity, setSelectedSeverity] = useState();
    const [leadTypes, setLeadTypes] = useState([]);
    const [selectedLeadTypes, setSelectedLeadTypes] = useState();
    const [place, setPlace] = useState([]);
    const [selectedPlace, setSelectedPlace] = useState();
    const [errors, setErrors] = useState({});


    useEffect(() => {
        console.log("useEffect called");
    }, [])
    const registerCase = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: 'error' })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "severity": selectedSeverity ? parseInt(selectedSeverity) : severity,
            "status": 1,
            "caseType": selectedCaseType ? parseInt(selectedCaseType) : caseType,
            "caseDetails": caseDetails,
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/registerCase', payload, { headers: headers })
            .then((response) => {
                console.log(response);
                enqueueSnackbar(response.data.message, { variant: "success" })
                navigate(`/${employee.companyName}/companyadmin/Cases`);
            })
            .catch(function (error) {
                console.log("error", error, payload);
                enqueueSnackbar(error.response.data.message, { variant: "error" })
            });

    };
    const handleChange = (val, arr, func) => {
        func(val);
    }
    const getCaseType = (typeId) => {
        let caseType = caseTypeData.find(item => item.id === typeId);
        return caseType?.caseType;
    }
    const getSeverity = (typeId) => {
        let severity = caseSeverityData.find(item => item.id === typeId);
        return severity?.severity;
    }
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!selectedSeverity) {
            errorObj = {
                ...errorObj,
                severityError: true
            }
            error = true;
        }
        if (!selectedCaseType) {
            errorObj = {
                ...errorObj,
                caseTypeError: true
            };
            error = true;
        }
        if (!caseDetails) {
            errorObj = {
                ...errorObj,
                caseDetailsError: true
            };
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    return (
        <section className="h-screen flex flex-col">
            <Navbar1 />
            <LeftMenu1 section="Add Case" />
            <div><i onClick={() => navigate(`/${employee.companyName}/companyadmin/Cases`)} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i></div>
            <div className="flex flex-col justify-center items-center mt-20">
                <div className="flex flex-col gap-3 w-[18vw] h-[80vh] justify-center items-center">
                    <label className="mr-1 text-xl text-black-600 md:whitespace-nowrap font-semibold">Register Case</label>
                    <select name="selectedCaseType" value={selectedCaseType} className={`text-sm w-full p-2 ${errors.selectedCaseTypeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                        onChange={(e) => { handleChange(e.target.value, caseTypeData, setSelectedCaseType); setErrors({ ...errors, selectedCaseTypeError: false }) }} >
                        <option value="">{selectedCaseType ? getCaseType(selectedCaseType) : "---Case Type---"}</option>
                        {caseTypeData && caseTypeData.map(t => {
                            return (
                                <option value={t.id} key={t.id}>{t.caseType}</option>
                            )
                        })}
                    </select>
                    <textarea className="text-sm w-full p-2 border border-solid border-gray-300 rounded mt-2 resize-none"
                        type="text"
                        rows={3}
                        onChange={(e) => setCaseDetails(e.target.value)}
                        value={caseDetails}
                        name="caseDetails"
                        placeholder="Case details" />
                    <select name="selectedSeverity" value={selectedSeverity} className={`text-sm w-full p-2 ${errors.selectedSeverityError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                        onChange={(e) => { handleChange(e.target.value, caseSeverityData, setSelectedSeverity); setErrors({ ...errors, selectedSeverityError: false }) }} >
                        <option value="">{selectedSeverity ? getSeverity(selectedSeverity) : "---Severity---"}</option>
                        {caseSeverityData && caseSeverityData.map(s => {
                            return (
                                <option value={s.id} key={s.id}>{s.severity}</option>
                            )
                        })}
                    </select>
                    <button className="mt-2 self-end w-20 text-center bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color p-2  font-semibold uppercase rounded text-xs" onClick={registerCase} type="submit">Create</button>
                </div>
            </div>
        </section >
    )
}

export default RaiseCase
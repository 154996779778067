import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import axios from 'axios';
import { config } from '../../Config/Config';
import { Bar } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const SalesGraph = () => {
    const [branches, setBranches] = useState([]);
    const [values, setValues] = useState();
    const [monthlyValues, setMonthlyValues] = useState();
    const [allBranches, setAllBranches] = useState([]);
    const [currentMonth, setCurrentMonth] = useState();

    useEffect(() => {
        getBranchesData()
    }, []);
    useEffect(() => {
        setMonthlyData()
    }, [currentMonth])
    const setMonthlyData = () => {
        let currentMonthData = allBranches && allBranches.map(branch => branch.data && branch.data.find(d => d.month == parseInt(currentMonth)));
        setMonthlyValues(currentMonthData);
    }
    const getBranchesData = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getCompanyData', { headers: headers })
            .then(function (response) {
                console.log(response.data.message, response.data);
                let dataArray = response.data.branches;
                dataArray.sort((a, b) => {
                    const countA = a.data.reduce((total, item) => total + parseInt(item.customersCount) + parseInt(item.salesCount), 0);
                    const countB = b.data.reduce((total, item) => total + parseInt(item.customersCount) + parseInt(item.salesCount), 0);
                    return countB - countA;
                });
                setBranches(dataArray);
                setValues(dataArray[0]);
                let currentAllBranches = [dataArray];
                setAllBranches(currentAllBranches);
                setCurrentMonth(new Date().getMonth())
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const monthlyData = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
            {
                label: "Customers",
                data: values && values.data.map(val => parseInt(val.customersCount)),
                backgroundColor: [
                    'black',
                ],
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20
            },
            {
                label: "Sales",
                data: values && values.data.map(val => parseInt(val.salesCount)),
                backgroundColor: [
                    'green',
                ],
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20
            }
        ]
    }
    return (
        <div className="flex gap-2">
            <Card className="flex flex-col justify-between relative" style={{ marginTop: "1rem", height: "250px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                <div className="font-semibold ml-12">
                    <span className="text-sm">Sales</span>
                    <span className="text-xs">({values && values.branchName})</span>
                </div>
                <select name="branches" value={values && values.branchName} className="absolute -top-1 right-1 text-xs w-32  border border-solid border-gray-300 rounded mt-2"
                    onChange={(e) => setValues(JSON.parse(e.target.value))} >
                    <option value="">{values ? values.branchName : "---Choose Branch---"}</option>
                    {branches && branches.map((item, i) => {
                        return (
                            <option value={JSON.stringify(item)} key={i}>{item.branchName}</option>
                        )
                    })}
                </select>
                <div className="flex justify-center items-center gap-4 w-full">
                    <div className="flex justify-center items-center gap-1"><span className="bg-black w-2 h-2"></span><span className="text-xs">Customer</span></div>
                    <div className="flex justify-center items-center gap-1"><span className="bg-green-600 w-2 h-2"></span><span className="text-xs">Sales</span></div>
                </div>
                <div style={{ height: "200px" }}>
                    <Bar data={monthlyData}
                        options={{
                            maintainAspectRatio: true,
                            responsive: true,
                            redraw: false,
                            elements: {
                                line: {
                                    tension: 1
                                }
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                },
                                y: {
                                    ticks: {
                                        precision: 0
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: false
                                },
                            }
                        }
                        } />
                </div>
            </Card>
        </div>
    );
};

export default SalesGraph
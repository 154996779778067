import React, { useState, useEffect } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import Navbar1 from "../Navbar/Navbar1";
import LeftMenu1 from "../LeftMenu/LeftMenu1";
import ReactSpeedometer from "react-d3-speedometer";
import moment from "moment-timezone";
import { enqueueSnackbar } from "notistack";
import { Spinner } from "react-bootstrap";
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const AssignSalesman = () => {
    const navigate = useNavigate();
    const locations = useLocation();
    const employee = locations.state?.employee;
    const customer = locations.state?.customer;
    const [employees, setEmployees] = useState([]);
    const [empSelected, setEmpSelected] = useState('');
    const [empSelectedError, setEmpSelectedError] = useState('');
    const [lastActivity, setLastActivity] = useState()
    const [visits, setVisits] = useState(1)
    const [purchases, setPurchases] = useState(1);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let currentEmployees = locations.state?.employees;
        currentEmployees = currentEmployees.filter(employee => employee.role === 3 && employee.status === 1)
        setEmployees(currentEmployees);
        getCustomerLastActivity();
        getAllBranchProducts();
    }, [])
    const handleEmployeeChange = (e) => {
        setEmpSelected(e.target.value);
        setEmpSelectedError('');
    }
    const assignSalesmanToCustomer = () => {
        if (empSelected === '') {
            setEmpSelectedError("Please select an employee");
            return;
        }
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "branchId": employee.branchId,
            "salesmanId": empSelected,
            "customerId": customer.id,
            "status": 1,
            "products": JSON.stringify(selectedProducts),
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'manager/assignsalesman', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    enqueueSnackbar("Customer Assigned Successfully!", { variant: "success" })
                    navigate(`/${employee.companyName}/branchadmin/Customers`);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const getCustomerLastActivity = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "customerId": customer.id,
        }
        axios.post(config.apiUrl + 'manager/getLastActivityOfCustomer', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    setLastActivity(response.data.lastActivity[0]);
                    setVisits(response.data.visits);
                    setPurchases(response.data.purchases);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const getAllBranchProducts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "branchId": employee?.branchId
        }
        setLoading(true)
        axios.post(config.apiUrl + 'manager/getAllBranchProducts', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log(response.data.message, response.data);
                    setProducts(response.data.products)
                } else {
                    console.log("Error");
                }
                setLoading(false);
            })
            .catch(function (error) {
                console.log("error", error);
                setLoading(false)
            });
    };
    const selectProducts = (e) => {
        let value = (e.target.value);
        if (e.target.checked) {
            setSelectedProducts([...selectedProducts, value])
        }
        else {
            setSelectedProducts(selectedProducts.filter(product => product !== value))
        }
    }

    return (
        <section className="h-screen flex flex-col justify-center items-center mx-5">
            <Navbar1 employee={employee} />
            <LeftMenu1 section="Assign Relationship Manager" />
            {loading ?
                <div className='mt-20 flex justify-center items-center'><Spinner animation="border" /></div>
                :
                <div className="grid grid-cols-3 gap-20 mx-[12vw]">
                    <div className="flex justify-start items-start">
                        <i onClick={() => navigate(`/${employee.companyName}/branchadmin/Customers`)} className="fa fa-long-arrow-left fa-2x text-orange-600 cursor-pointer" aria-hidden="true"></i>
                    </div>
                    <div className="sm:w-[15rem] md:w-[20rem]">
                        <div className="text-center md:text-left">
                            <p className="mr-1 text-lg text-black-600">Assign Relationship Manager</p>
                        </div>
                        <label className="text-sm text-black-600 mt-4">Last Served By</label>
                        <p className="text-sm w-full h-8 p-2 border border-solid border-gray-300 rounded ">{lastActivity && lastActivity.employeeName && lastActivity.employeeId ? lastActivity.employeeName + "(" + lastActivity.employeeId + ")" : "No activity till date"}</p>
                        <div className="flex flex-col">
                            <label className="text-sm text-black-600 mt-4">Relationship Manager</label>
                            <select name="employee" value={empSelected} className="text-sm w-full p-2 border border-solid border-gray-300 rounded"
                                onChange={(e) => handleEmployeeChange(e)} >
                                <option value="">Select Relationship Manager</option>
                                {employees && employees.map(employee => {
                                    return (
                                        <option key={employee.id} value={employee.id}>{employee.firstName}&nbsp;{employee.lastName}&nbsp;({employee.employeeId})</option>
                                    )
                                })}
                            </select>
                            <div className='error'>{empSelectedError && (
                                <div className="error">{empSelectedError}</div>
                            )}</div>
                        </div>
                        <label className="text-sm text-black-600 mt-4">Customer Name</label>
                        <p className="text-sm w-full h-8 px-2 py-1 border border-solid border-gray-300 rounded">{customer.firstName}&nbsp;{customer.lastName}</p>
                        <label className="text-sm text-black-600 mt-4">Last Activity</label>
                        <p className={`text-sm w-full h-8 px-2 py-1 border border-solid border-gray-300 rounded ${lastActivity?.activity ? "" : "text-gray-400"}`}>{lastActivity?.activity}&nbsp;{lastActivity?.updatedAt && lastActivity?.activity ? "(" + moment(lastActivity.updatedAt).format("DD-MM-YY") + ")" : "No activity till date"}</p>
                        <div className="grid grid-cols-1 justify-between ml-2 items-center mt-2 w-full">
                            {products && products.map(product => {
                                return (
                                    <div key={product.id} className="flex items-center">
                                        <label htmlFor={JSON.stringify(product.id)} className="cursor-pointer">
                                            <input
                                                type="checkbox"
                                                className="cursor-pointer"
                                                id={JSON.stringify(product.id)}
                                                name={product.productFamilyName}
                                                value={product.id}
                                                onChange={(e) => selectProducts(e)}
                                            />
                                            &nbsp;{product.productFamilyName}</label>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="flex justify-end text-center md:text-left">
                            <button className="mt-4 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 uppercase rounded text-xs tracking-wider" onClick={assignSalesmanToCustomer} type="submit">Assign</button>
                        </div>
                    </div>
                    <div className="">
                        <ReactSpeedometer
                            width={225}
                            height={150}
                            maxValue={100}
                            needleColor="black"
                            value={(purchases) / (visits) * 100}
                            valueFormat={"d"}
                            segmentColors={["#e4e4e4", "#ccc", "#8c8c8c", "#545454", "#3b3a3a"]}
                            currentValueText={`${purchases}/${visits}`}
                            textColor={"black"}
                            needleHeightRatio={0.6}
                            ringWidth={40}
                            needleTransitionDuration={3333}
                            needleTransition="easeElastic"
                            customSegmentLabels={[
                                {
                                    text: 'Poor',
                                    position: 'OUTSIDE',
                                    color: '#555',
                                    fontSize: '12px',
                                },
                                {
                                    text: 'Fair',
                                    position: 'OUTSIDE',
                                    color: '#555',
                                    fontSize: '12px',
                                },
                                {
                                    text: 'Good',
                                    position: 'OUTSIDE',
                                    color: '#555',
                                    fontSize: '12px',
                                },
                                {
                                    text: 'Great',
                                    position: 'OUTSIDE',
                                    color: '#555',
                                    fontSize: '12px',
                                },
                                {
                                    text: 'Excellent',
                                    position: 'OUTSIDE',
                                    color: '#555',
                                    fontSize: '12px',
                                },
                            ]}
                        />
                    </div>
                </div>}
        </section>
    )
}

export default AssignSalesman
import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import { config } from '../../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import SalesGraph from "./SalesGraph";
import LeadsToSales from "./LeadsToSalesGraph";
import NewCustomersGraph from "./MonthlyNewCustomersGraph";
import CustomersCount from "../CustomersCount";
import AvgDealClosureTime from ".././AvgDealClosureTimeGraph";
import AvgStatusClosureTime from ".././AvgStatusClosureGraph";
import { useQuery } from "react-query";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


export default function BranchAnalytics() {
    const [section, setSection] = useState("BranchData")
    const [topBranch, setTopBranch] = useState()
    const [branchCount, setBranchCount] = useState();

    const getTopBranch = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        return axios.get(config.apiUrl + 'companyAdmin/getLastMonthTopBranch', { headers: headers })
    };
    const { data } = useQuery("topBranchDetails", getTopBranch, {
        enabled: section === "BranchData",
        onSuccess: (response) => {
            console.log("success top branch from query ", response.data);
            setTopBranch(response.data.topBranch)
            setBranchCount(response.data.activeBranches);
        },
        onError: (error) => {
            console.error('Error loading branches data:', error);
        },
    });
    return (
        <div className="mb-3">
            <div className="flex gap-4 items-center text-center" style={{ marginTop: "18px" }}>
                <div className="flex flex-col justify-around mt-2 rounded-xl bg-custom-bg-color hover:custom-bg-color text-custom-text-color w-24 h-24 px-1 py-2 font-semibold">
                    <p className="text-2xl">{branchCount}</p>
                    <p className="text-md">Branch</p>
                </div>
                <div className="flex flex-col justify-center gap-3 mt-2 rounded-xl bg-custom-bg-color hover:custom-bg-color text-custom-text-color w-24 h-24 p-1 font-semibold">
                    <p className="text-sm">{topBranch && topBranch.branchName}</p>
                    <p className="whitespace-nowrap text-sm">Top Branch</p>
                </div>
            </div>
            <div className="flex gap-4">
                <SalesGraph />
                <LeadsToSales />
                <NewCustomersGraph />
            </div>
            <div className="flex gap-4">
                {/* <ProductEnquiryGraph headOffice={false} /> */}
                <CustomersCount />
                <AvgDealClosureTime />
                <AvgStatusClosureTime />
            </div>
        </div>
    );
};

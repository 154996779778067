import React, { useState, useEffect } from 'react';
import { Col, Row, Card, Spinner, Table } from 'react-bootstrap';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { config } from '../../Config/Config';
import SimpleCrypto from 'simple-crypto-js'
import { useLocation, useParams } from 'react-router-dom';
import logo from "../../images/lms logo.svg";
import error from '../../images/error-404.svg';
import emojired from '../../images/emoji red-01.svg';
import emojiorange from '../../images/emoji orange.svg';
import emojiyellow from '../../images/emoji yellow.svg';
import emojilightgreen from '../../images/emoji light green.svg';
import emojigreen from '../../images/emoji green.svg';
import SuccessPopup from '../../PopUps/SuccessPopup';
import SurveySuccessPopup from '../../PopUps/SurveySuccessPopup';
import linkExpired from "../../images/link-expired.svg";
import success from "../../images/success.svg";
import moment from 'moment-timezone';
import PageNotFound from '../invalidRoute/PageNotFound';
import surveyCompleted from "../../images/survey completed.svg";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key);

const SurveyForm = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { uniquecode: code } = useParams()
    const [loading, setLoading] = useState(true)
    const [ratings, setRatings] = useState([]);
    const [createdDate, setCreatedDate] = useState("");
    const [comDetails, setComDetails] = useState({});
    let surveyId = queryParams.get("survey_id");
    const [images, setImages] = useState([{ "img": emojired, value: 1 }, { "img": emojiorange, value: 2 }, { "img": emojiyellow, value: 3 }, { "img": emojilightgreen, value: 4 }, { "img": emojigreen, value: 5 }]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState([{}, {}, {}, {}, {}]);
    const [showError, setShowError] = useState(false);
    const [msg, setMsg] = useState('');
    const [text, setText] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [formDetails, setFormDetails] = useState('');
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        getSurveyQuestions();
        // console.log("moment",moment(formDetails?.createdAt, "DD-MM-YYYY").add(15, 'days').format("DD-MM-YYYY"))

    }, [])

    const getSurveyQuestions = async () => {
        let headers = {
            "Content-Type": "application/json",
            "applicationType": "web"
        }
        var payload = {
            "code": code,
            surveyId
        }
        axios.post(config.apiUrl + "companyAdmin/getsurveyform", payload, { headers: headers })
            .then((response) => {
                if (response.data.status === true) {
                    console.log("Questions Retrieved Successfully", response.data)
                    setRatings(response.data.form?.surveyForm);
                    setComDetails(response.data.companyDetails);
                    setFormDetails(response.data.details);
                    setNotFound(!response.data.details?.hasOwnProperty("name"))
                    response.data.details.hasOwnProperty("createdAt") ? setCreatedDate(response.data.details.createdAt) : setCreatedDate(new Date())
                } else {
                    console.log("Error")
                }
                setLoading(false)
            }).catch(error => {
                console.error(error)
                setLoading(false)
            })
    }

    const submitForm = async () => {
        let headers = {
            "Content-Type": "application/json",
            "applicationType": "web"
        }

        const lastElement = answers[currentQuestionIndex - 1];
        console.log("empty", lastElement?.ans, answers.length)

        if (!lastElement?.ans) {
            enqueueSnackbar("Please answer all the questions", { variant: "error" })
            return
        }
        var payload = {
            "surveyId": surveyId,
            "response": JSON.stringify(answers),
            "status": 2
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + "employees/submitform", payload, { headers: headers })
            .then((response) => {
                if (response.data.status === true) {
                    console.log("Configuresurveyform Added successfully", response.data.form)
                    setSuccessMsg("Thank you for your inputs!");
                    setShowSuccess(true);
                    setTimeout(() => { setMsg(''); setShowSuccess(false) }, 2000);
                    setFormDetails({ ...formDetails, status: 2 });
                } else {
                    console.log("Error")
                }
            }).catch(error => {
                console.error(error)
            })
    }
    const handleNextClick = () => {
        console.log("cui", currentQuestionIndex, ratings.length, answers)
        if (currentQuestionIndex < ratings.length) {
            setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        } else {
            console.log("answers", answers)
            submitForm();
        }
    };

    const handleBackClick = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
        }
    };
    const handleAnswerChange = (value, index) => {
        const updatedAnswers = [...answers];
        updatedAnswers[index] = { ans: value };
        setAnswers(updatedAnswers);
        console.log("answers", answers)
    };

    const renderContent = () => {
        if (currentQuestionIndex === 0) {
            return (
                <div>
                    <div className='bottom_bar rounded-t-2xl mt-3 sm:text-xl md:text-xl text-xl flex justify-around items-center text-center'>
                        <div className=' text-white'>Introduction</div>
                    </div>
                    <div className='min-h-[30vh] border-2 border-gray-500 border-t-0 rounded-b-xl p-4'>
                        <div className='text-base min-h-[32vh]'><span className='font-semibold text-blue-500'>Hello</span> <b>{formDetails?.name}</b>,<br />
                            <div className='mt-[3vh] font-semibold'>Please spare few minutes of valuable time to fill below survey questions.</div>
                            <div className='mt-[3vh] font-semibold'>Your feedback is important for us to grow and serve better</div>
                            <div className='relative sm:-bottom-24 md:-bottom-20 mt-[3vh] font-semibold text-red-500'>Please note that this link is valid till "{moment(createdDate).add(15, 'days').format("DD-MM-YYYY")}"</div>
                        </div>
                        <div className="mt-3 flex justify-between text-center md:text-left">
                            <button className={`${currentQuestionIndex > 0 ? "" : "invisible"} bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white font-semibold uppercase rounded text-xs`} onClick={handleBackClick}>BACK</button>
                            {currentQuestionIndex <= ratings.length && (
                                <button className={`bg-black hover:bg-black text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs`} onClick={handleNextClick} type="submit">
                                    {currentQuestionIndex === ratings.length ? 'Submit' : 'Next'}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            );
        } else if (currentQuestionIndex <= ratings.length) {
            return (
                <>
                    <div className='bottom_bar rounded-t-2xl mt-3 sm:text-xl md:text-xl text-xl flex justify-around items-center text-center'>
                        <div className='text-white'>Question {[currentQuestionIndex]}/5</div>
                    </div>
                    <div className='min-h-[30vh] border-2 border-gray-500 border-t-0 rounded-b-xl p-4'>
                        <div className='min-h-[30vh]'>
                            <p key={[currentQuestionIndex - 1]} className={`${ratings[currentQuestionIndex - 1] === currentQuestionIndex - 1 ? "" : ""} blink-text my-3 text-lg font-semibold`}>{ratings[currentQuestionIndex - 1].ques}</p>
                            <div className='flex gap-6 sm:mt-[6vh] md:mt-[8vh] mt-[6vh] justify-center'>
                                {images.map((data, i) => (
                                    <div key={i} onClick={(e) => handleAnswerChange(data.value, currentQuestionIndex - 1)} >
                                        <div className={`${answers[currentQuestionIndex - 1].ans === data.value ? "border-black" : ""} border-2 rounded-md cursor-pointer`}><img src={data.img} className='w-16' /></div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mt-3 flex justify-between text-center md:text-left">
                            <button className={`${currentQuestionIndex > 0 ? "" : "invisible"} bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white font-semibold uppercase rounded text-xs`} onClick={handleBackClick}>BACK</button>
                            {currentQuestionIndex <= ratings.length && (
                                <button className={`bg-black hover:bg-black text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs`} onClick={handleNextClick} type="submit">
                                    {currentQuestionIndex === ratings.length ? 'Submit' : 'Next'}
                                </button>
                            )}
                        </div>
                    </div >


                </>
            );
        }
    };
    if (notFound) {
        return (<PageNotFound />)
    }
    if (loading) {
        return (<div className='mt-20 flex justify-center items-center'><Spinner animation="border" /></div>)
    }
    if (formDetails && formDetails.status === 3) {
        return (<>
            <div className="flex w-full fixed top-0 text-custom-text-color h-10 md:h-14 items-center justify-between bg-custom-bg-color gap-4 z-50">
                <img src={logo} alt="" className="w-12 h-12 my-1" />
                <div className='flex items-center gap-1'>
                    <img src={comDetails?.logo} alt='' className='w-9 h-9' />
                    <p className='text-2xl'>{comDetails?.name || ""}</p>
                </div>
                <div className='invisible'> <img src={logo} alt='' className='w-8 h-6 cursor-pointer' /></div>
            </div>

            <div className='w-full h-[80vh] flex flex-col items-center justify-center'>
                {/* <p className='text-2xl '>{formDetails ? `${formDetails.name}'s Calendar` : ""}</p> */}
                <img src={linkExpired} alt='' className='w-64 h-64' />
                <p className='text-sm md:text-2xl font-medium'>Sorry. This survey link has already expired.</p>
            </div>
        </>)
    }
    if (formDetails && formDetails.status === 2) {
        return (<>
            <div className="flex w-full fixed top-0 text-custom-text-color h-10 md:h-14 items-center justify-between bg-custom-bg-color gap-4 z-50">
                <img src={logo} alt="" className="w-12 h-12 my-1" />
                <div className='flex items-center gap-1'>
                    <img src={comDetails?.logo} alt='' className='w-9 h-9' />
                    <p className='text-2xl'>{comDetails?.name || ""}</p>
                </div>
                <div className='invisible'> <img src={logo} alt='' className='w-8 h-6 cursor-pointer' /></div>
            </div>

            <div className='w-full h-[80vh] flex flex-col items-center justify-center gap-8'>
                <img src={surveyCompleted} alt='' className='w-52 h-52' />
                <p className='text-sm md:text-2xl font-medium'>Thank You. Your response was recieved successfully.</p>
            </div>
        </>)
    }
    return (
        <div>
            <SurveySuccessPopup show={showError} setShow={setShowError} msg={msg} text={text} />
            <SuccessPopup show={showSuccess} setShow={setShowSuccess} msg={successMsg} />
            <div className="flex w-full fixed top-0 text-custom-text-color h-10 md:h-14 items-center justify-between bg-custom-bg-color gap-4 z-50">
                <img src={logo} alt="" className="w-12 h-12 my-1" />
                <div className='flex items-center gap-1'>
                    <img src={comDetails?.logo} alt='' className='w-9 h-9' />
                    <p className='text-2xl'>{comDetails?.name || ""}</p>
                </div>
                <div className='invisible'> <img src={logo} alt='' className='w-8 h-6 cursor-pointer' /></div>
            </div>
            {(!ratings || ratings?.length === 0) && !loading ?
                <div className='w-full flex flex-col justify-center items-center bg-white min-h-screen'>
                    <img src={error} alt='' className="w-[20%]" />
                    <p className='text-lg font-semibold mt-4'>Sorry The Form Is Not Available Right Now!!!</p>
                </div>
                : <div className='w-full flex flex-col justify-center items-center survey-bg'>
                    <p className='absolute top-16 text-black font-extrabold text-2xl text-center'>Customer Survey Form</p>

                    <div className='w-[100vw] md:w-[75vw] min-h-[50vh]'>
                        <div className='px-3'>
                            <div className='-mt-2 text-center text-xl'>
                                <span className='font-semibold text-blue-500'>Raised by </span>
                                <span className=' font-semibold'>{formDetails?.empName}</span>
                            </div><br />
                            {renderContent()}
                        </div>
                    </div>
                </div>}
        </div >
    );
};

export default SurveyForm;
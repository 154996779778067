import React, { useState, useEffect } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import SimpleCrypto from 'simple-crypto-js';
import Navbar1 from "../Navbar/Navbar1";
import LeftMenu1 from "../LeftMenu/LeftMenu1";
import ErrorPopup from "../PopUps/ErrorPopup";
import { enqueueSnackbar } from 'notistack'
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const CreateEmployee = () => {
    const navigate = useNavigate();
    const locations = useLocation();
    const employee = locations.state?.employee;
    const [branches, setBranches] = useState([])
    const [branchName, setBranchName] = useState("")
    const [branchId, setBranchId] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")
    const [role, setRole] = useState(2)
    const [employeeId, setEmployeeId] = useState("")
    const [showError, setShowError] = useState(false)
    const [msg, setMsg] = useState('');
    const [gender, setGender] = useState('');

    const [errors, setErrors] = useState({});

    useEffect(() => {
        getAllBranches();
    }, []);
    const getAllBranches = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        console.log(employee.companyId);
        var payload = {
            companyId: employee.companyId
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/getAllBranches', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, response.data);
                    let active_branches = response.data.branches.filter(branch => branch.status === 1);
                    setBranches(active_branches)
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const createEmployee = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: "error" })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "branchName": branchName,
            "branchId": branchId,
            "companyId": employee.companyId,
            "role": parseInt(role),
            "employeeId": employeeId.toUpperCase(),
            "firstName": firstName,
            "lastName": lastName,
            "gender": parseInt(gender),
            "email": simpleCrypto.encrypt(email),
            "mobile": simpleCrypto.encrypt(mobile)
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/createEmployee', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    enqueueSnackbar("Employee Created Successfully !", { variant: "success" })
                    navigate(`/${employee.companyName}/companyadmin/Employee`);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error.response.data.message, payload);
                setShowError(true);
                setMsg(error.response.data.message);
                setTimeout(() => { setShowError(false); setMsg('') }, 2000)
            });
    };
    const handleBranchChange = (e) => {
        let bname = (branches.find(branch => branch.id === e.target.value))
        setBranchName(bname.branchName);
        setBranchId(e.target.value);
    }
    const isAlphanumeric = (input) => {
        const regex = /^[a-zA-Z0-9]+$/;
        if (!regex.test(input)) {
            setErrors({
                ...errors,
                employeeIdError: true
            })
            return
        }
        setEmployeeId(input);
        setErrors({ ...errors, employeeIdError: false })
    }
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!branchId) {
            errorObj = {
                ...errorObj,
                branchIdError: true
            }
            error = true;
        }
        if (!firstName) {
            errorObj = {
                ...errorObj,
                firstNameError: true
            };
            error = true;
        }
        if (!lastName) {
            errorObj = {
                ...errorObj,
                lastNameError: true
            };
            error = true;
        }
        if (!gender) {
            errorObj = {
                ...errorObj,
                genderError: true
            };
            error = true;
        }
        if (!email) {
            errorObj = {
                ...errorObj,
                emailError: true
            }
            error = true;
        }
        if (!mobile) {
            errorObj = {
                ...errorObj,
                mobileError: true
            }
            error = true;
        }
        if (!employeeId || errors.employeeIdError) {
            errorObj = {
                ...errorObj,
                employeeIdError: true
            }
            error = true;
        }
        if (!role) {
            errorObj = {
                ...errorObj,
                roleError: true
            }
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    return (
        <section className="h-screen flex flex-col justify-center items-center mx-5">
            <ErrorPopup show={showError} setShow={setShowError} msg={msg} />
            <Navbar1 employee={employee} />
            <LeftMenu1 section="Add Employee" />
            <div><i onClick={() => navigate(`/${employee.companyName}/companyadmin/Employee`)} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i></div>
            <div className="md:w-1/3 max-w-sm">
                <div className="text-center md:text-left">
                    <label className="mr-1 text-lg text-black-600">Create Employee</label>
                </div>
                <select name="role" value={role} className={`text-sm w-full px-4 py-2 ${errors.roleError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                    onChange={(e) => { setRole(e.target.value); setErrors({ ...errors, roleError: false }) }} >
                    <option value="">Select Role</option>
                    <option value={2}>Branch Admin</option>
                    <option value={3}>Relationship Manager</option>
                </select>
                <select name="branchId" value={branchId} className={`text-sm w-full px-4 py-2 ${errors.branchIdError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-4`}
                    onChange={(e) => { handleBranchChange(e); setErrors({ ...errors, branchIdError: false }) }}  >
                    <option value="">Select Branch</option>
                    {branches && branches.map(branch => {
                        return (
                            <option key={branch.id} value={branch.id}>{branch.branchName}</option>
                        )
                    })}
                </select>
                <input className={`text-sm w-full px-4 py-2 ${errors.employeeIdError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-4`}
                    type="text"
                    onChange={(e) => { isAlphanumeric(e.target.value) }}
                    name="employeeId"
                    pattern="[a-zA-Z0-9]+"
                    maxLength={15}
                    placeholder="Employee Id" />
                {errors.employeeIdError && <div className="text-red-700 text-xs">
                    <p>Please enter only alphanumeric characters</p>
                </div>}
                <input className={`text-sm w-full px-4 py-2 ${errors.firstNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-4`}
                    type="text"
                    onChange={(e) => { setFirstName(e.target.value); setErrors({ ...errors, firstNameError: false }) }}
                    name="firstName"
                    placeholder="First Name" />
                <input className={`text-sm w-full px-4 py-2 ${errors.lastNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-4`}
                    type="text"
                    onChange={(e) => { setLastName(e.target.value); setErrors({ ...errors, lastNameError: false }) }}
                    name="lastName"
                    placeholder="Last Name" />
                <select name="gender" value={gender} className={`text-sm w-full px-4 py-2 ${errors.genderError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-4`}
                    onChange={(e) => { setGender(e.target.value); setErrors({ ...errors, genderError: false }) }}  >
                    <option value="">Select Gender</option>
                    <option value={1}>Male</option>
                    <option value={2}>Female</option>
                    <option value={3}>Other</option>
                </select>
                <input className={`text-sm w-full px-4 py-2 ${errors.mobileError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-4`}
                    type="text"
                    onChange={(e) => { setMobile(e.target.value); setErrors({ ...errors, mobileError: false }) }}
                    name="mobile"
                    placeholder="Mobile" />
                <input className={`text-sm w-full px-4 py-2 ${errors.emailError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-4`}
                    type="email"
                    onChange={(e) => { setEmail(e.target.value); setErrors({ ...errors, emailError: false }) }}
                    name="email"
                    placeholder="Email" />

                <div className="flex justify-end text-center md:text-left">
                    {/* <button className="mt-4 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white uppercase rounded text-xs tracking-wider" onClick={() => navigate(-1)} type="submit">Back</button> */}
                    <button className="mt-4 bg-custom-bg-color hover:custom-bg-color text-custom-text-color px-4 py-2 font-semibold  uppercase rounded text-xs" onClick={createEmployee} type="submit">Create</button>
                </div>
            </div>
        </section>
    )
}

export default CreateEmployee;
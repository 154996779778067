import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { config } from '../../Config/Config';
import SimpleCrypto from 'simple-crypto-js'
import EmployeeContext from '../../context/employee/EmpContext';
import { useNavigate } from 'react-router-dom';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const ActionPopup = ({ show, togglePopup, data, getEmployeeDeals }) => {
    const navigate = useNavigate();
    const { empState: employee } = useContext(EmployeeContext)
    const [nextStatus, setNextStatus] = useState();
    const [comment, setComment] = useState("");
    const [errors, setErrors] = useState({});


    const updateDealStatus = async () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: 'error' })
            return
        };
        let headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            id: data.id,
            currentStatus: data.dealStatus,
            comment: comment,
            nextStatus,
            name: employee.firstName + " " + employee.lastName,
            empId: employee.employeeId
        }
        console.log(payload)
        axios.post(config.apiUrl + "employees/updateDealStatus", payload, { headers: headers })
            .then((response) => {
                if (parseInt(nextStatus) !== 4 && parseInt(nextStatus) !== 5) {
                    console.log("Success", response.data.message);
                    getEmployeeDeals();
                    togglePopup()
                    enqueueSnackbar("Status Updated Successfully", { variant: "success" });
                } else {
                    navigate(`/${employee.companyName}/customerEvaluation`, { state: { customer: { ...data, newDealStatus: nextStatus } } })
                }
            }).catch((error) => {
                enqueueSnackbar("Something went wrong", { variant: "error" });
                togglePopup()
            })
    }
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!nextStatus) {
            errorObj = {
                ...errorObj,
                nextStatusError: true
            }
            error = true;
        }
        if (!comment) {
            errorObj = {
                ...errorObj,
                commentError: true
            }
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    return (
        <Modal show={show} size="md" onHide={togglePopup} centered>
            <Modal.Header closeButton style={{ zIndex: "100" }}>
            </Modal.Header>
            <Modal.Body>
                <div className='flex flex-col gap-10 justify-center items-center'>
                    <div className='-mt-10 mb-2 text-orange-400 text-center'>
                        <p className="text-xl">Next Action for {data?.customer || "Customer"}</p>
                    </div>
                    <select name="nextaction" value={nextStatus} className={`text-sm w-full px-4 py-2 ${errors.nextStatusError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded-xl`}
                        onChange={(e) => { setNextStatus(e.target.value); setErrors({ ...errors, nextStatusError: false }) }} >
                        <option value="">Choose Next Action</option>
                        {data.dealStatus === 1 && <option value={2}>Move to Discussion</option>}
                        {data.dealStatus === 2 && <option value={3}>Move to Negotiation</option>}
                        {data.dealStatus === 3 && <option value={5}>Deal Completed Successfully</option>}
                        <option value={4}>Reject: Customer Not Interested</option>
                    </select>
                    <textarea
                        rows={2}
                        maxLength={150}
                        placeholder='Enter your comment here'
                        value={comment}
                        onChange={(e) => { setComment(e.target.value); setErrors({ ...errors, commentError: false }) }}
                        className={`resize-none w-full rounded-xl outline-none p-2 ${errors.commentError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"}`}
                    />
                    <div className="flex justify-end items-center w-full">
                        <button className="mt-4 bg-custom-bg-color hover:custom-bg-color text-custom-text-color px-4 py-2  font-semibold uppercase rounded text-xs" onClick={updateDealStatus} type="submit">Update</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ActionPopup
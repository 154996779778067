import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import EmployeeContext from '../../context/employee/EmpContext';
import right from "../../images/right-tick.svg";
import wrong from "../../images/wrong-tick.svg";
import "./Rewards.css"
import { enqueueSnackbar } from 'notistack';
import SimpleCrypto from 'simple-crypto-js';
import { config } from '../../Config/Config';
import axios from 'axios';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const RewardsConfig = ({ rewardsFreq, nonFinancialRewards, rewardTiers }) => {
    const navigate = useNavigate();
    const { empState: employee } = useContext(EmployeeContext)
    const [key, setKey] = useState('performance');
    const [selectedFreq, setSelectedFreq] = useState();
    const [curRewardsFreq, setCurRewardsFreq] = useState();
    const [nonFinRewards, setNonFinRewards] = useState([]);
    const [freqData, setFreqData] = useState({
        1: { tier: 1, threshold: "0", benefit: '0', tag: '' },
        2: { tier: 2, threshold: "0", benefit: '0', tag: '' },
        3: { tier: 3, threshold: "0", benefit: '0', tag: '' },
        4: { tier: 4, threshold: "0", benefit: '0', tag: '' },
        5: { tier: 5, threshold: "0", benefit: '0', tag: '' },
    });
    useEffect(() => {
        if (rewardsFreq.length === 0) return;
        updateCurRewardsFreqArray(rewardsFreq);
    }, [rewardsFreq])
    useEffect(() => {
        if (rewardTiers.length === 0) return;
        setNonFinRewards(nonFinancialRewards);
        if (rewardsFreq && rewardsFreq.length > 0) {
            setSelectedFreq(rewardsFreq[0].id)
        }
    }, [rewardTiers])

    const updateCurRewardsFreqArray = (freqArr) => {
        let newFreqData = {};
        rewardTiers && rewardTiers.forEach(item => {
            newFreqData[item.id] = { tier: item.id, threshold: "", benefit: "", tag: "" }
        });
        let configData = Object.values(newFreqData);
        let newRewardsFreqArr = JSON.parse(JSON.stringify(freqArr));
        console.log("newfreq", newRewardsFreqArr)
        let updatedArr = newRewardsFreqArr.map(item => {
            let curConfigArr = [...item.config];
            configData.forEach(cd => {
                let aval = curConfigArr.find(ele => ele.tier === cd.tier);
                if (!aval) curConfigArr.push(cd);
            })
            item.config = curConfigArr;
            return item;
        })
        setCurRewardsFreq(updatedArr);
    }
    useEffect(() => {
        if (selectedFreq) {
            updateCurRewardsFreqArray(curRewardsFreq)
            updateFreqData()
        }
    }, [selectedFreq])

    ///update current selected frequency data
    const updateFreqData = (freqArr = curRewardsFreq) => {
        let curData = freqArr && freqArr.find(c => c.id == selectedFreq);
        let curFreqdata = {};
        curData.config && curData.config.forEach(r => {
            curFreqdata[r.tier] = r;
        })
        setFreqData(curFreqdata)
    }
    const updateThreshold = (val, tier, para) => {
        let cur = curRewardsFreq && curRewardsFreq.find(r => r.id == selectedFreq);
        let configArr = cur.config;
        configArr.forEach(cf => {
            if (cf.tier === tier) cf[para] = val;
        })
        let updatedFreq = curRewardsFreq && curRewardsFreq.map(element => {
            if (element.id === selectedFreq) {
                element.config = [...configArr];
            }
            return element;
        });
        setCurRewardsFreq(updatedFreq);
        updateFreqData(updatedFreq)
    }
    const updateNonFinRewardsConfig = (id) => {
        if (employee.role !== 1) return;
        let newNonFinRewardsArray = nonFinRewards.map(nf => {
            if (nf.id === id) nf.config.available = !nf.config.available;
            return nf;
        });
        setNonFinRewards(newNonFinRewardsArray);
    }
    const updateRewardsConfig = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            weekly: curRewardsFreq.find(c => c.id === 1).config,
            monthly: curRewardsFreq.find(c => c.id === 2).config,
            quarterly: curRewardsFreq.find(c => c.id === 3).config,
            halfYearly: curRewardsFreq.find(c => c.id === 4).config,
            yearly: curRewardsFreq.find(c => c.id === 5).config,
            nonFinancial: nonFinRewards.map(nf => nf.config)
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/updateRewardsConfigByCompany', payload, { headers: headers })
            .then((response) => {
                console.log(response.data.message, response.data);
                enqueueSnackbar("Rewards Configuration Updated Successfully!", { variant: "success" })
            }).catch(error => {
                console.log("error", error.response.data.message);
            })
    };
    function camelCaseToTitleCase(inputString) {
        const titleCaseString = inputString.replace(/([A-Z])/g, ' $1');
        return titleCaseString.charAt(0).toUpperCase() + titleCaseString.slice(1);
    }
    const handleNavigate = () => {
        navigate(`/${employee.companyName}/rewards/leaderboard`, { state: employee })
    }
    return (
        <div className='mt-20 ml-8 w-[80vw]'>
            <div className="flex justify-between">
                <div className="text-left font-semibold text-black"><h5>Rewards</h5></div>
                <button onClick={() => handleNavigate()} className="bg-custom-bg-color hover:custom-bg-color text-custom-text-color px-4 py-2 font-semibold  uppercase rounded text-xs mr-2">Leaderboard</button>
            </div>
            <ul className="rewards" >
                <li onClick={() => setKey("performance")}><span className={`${key === "performance" ? "text-orange-300" : "text-custom-text-color"}`}>Performance</span></li>
                <li onClick={() => setKey("peer")}><span className={`${key === "peer" ? "text-orange-300" : "text-custom-text-color"}`}>Peer</span></li>
                <li onClick={() => setKey("customer")}><span className={`${key === "customer" ? "text-orange-300" : "text-custom-text-color"}`}>Customer</span></li>
            </ul>
            {key === "performance" ?
                <>
                    <div className="bg-custom-text-color grid grid-cols-2 gap-10 border-2 border-black w-full min-h-[40vh]">
                        <div className='financial pl-2 pt-1'>
                            <p className='text-lg font-semibold'>Financial</p>
                            <div className='flex items-center gap-6 mt-2'>
                                <p className='text-sm font-semibold'>Calculated On</p>
                                <select name="frequency" value={selectedFreq} className="text-sm max-w-max p-2 border-[1px] border-solid border-black rounded"
                                    onChange={(e) => setSelectedFreq(e.target.value)} >
                                    <option value="">---Rewards Frequency---</option>
                                    {rewardsFreq && rewardsFreq.map(item => {
                                        return (
                                            <option value={item.id} key={item.id}>{camelCaseToTitleCase(item.frequency)}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <table className="striped-table max-w-md my-2">
                                <thead className='bg-custom-bg-color text-custom-text-color'>
                                    <tr>
                                        <th>Tier</th>
                                        <th>Threshold</th>
                                        <th>Benefit</th>
                                        <th>Tag</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rewardTiers && rewardTiers.map((item, index) => (
                                        <tr key={index} className={`h-4 ${index % 2 === 0 ? 'even' : 'odd'}`}>
                                            <td className='w-20'>{item.id}</td>
                                            <td className='w-36'>
                                                {employee.role === 1 ?
                                                    <input
                                                        className="flex p-1 w-full h-7 bg-inherit cursor-pointer"
                                                        type="text"
                                                        value={freqData[item.id]?.threshold}
                                                        onChange={(e) => updateThreshold(e.target.value, item.id, "threshold")} />
                                                    : <p className="flex p-1 w-full h-7 bg-inherit">{freqData[item.id]?.threshold}</p>
                                                }
                                            </td>
                                            <td className='w-36'>
                                                {employee.role === 1 ?
                                                    <input
                                                        className="flex p-1 w-full h-7 bg-inherit cursor-pointer"
                                                        type="text"
                                                        value={freqData[item.id]?.benefit}
                                                        onChange={(e) => updateThreshold(e.target.value, item.id, "benefit")} />
                                                    : <p className="flex p-1 w-full h-7 bg-inherit">{freqData[item.id]?.benefit}</p>
                                                }
                                            </td>
                                            <td className='w-52'>
                                                {employee.role === 1 ?
                                                    <input
                                                        className="flex p-1 w-full h-7 bg-inherit cursor-pointer"
                                                        type="text"
                                                        value={freqData[item.id]?.tag}
                                                        onChange={(e) => updateThreshold(e.target.value, item.id, "tag")} />
                                                    : <p className="flex p-1 w-full h-7 bg-inherit">{freqData[item.id]?.tag}</p>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className='financial pl-2 pt-1'>
                            <p className='text-lg font-semibold'>Non Financial</p>
                            <div className="grid grid-cols-1 gap-2 justify-between items-center mt-2 w-full">
                                {nonFinRewards && nonFinRewards.map(data => {
                                    return (
                                        <div key={data.id} className="mt-1.5 flex items-center gap-3">
                                            <div className={`w-5 h-5 flex items-center justify-center border-[1px] border-gray-500 bg-white rounded-sm ${employee.role === 1 ? "cursor-pointer" : ""}`}
                                                onClick={(e) => updateNonFinRewardsConfig(data.id)}>
                                                <img src={data.config.available ? right : wrong} alt='' />
                                            </div>
                                            <p className=''>{data.parameter}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={`w-full flex justify-center items-center mt-4 ${employee.role !== 1 ? "hidden" : ""}`}>
                        <button className="bg-custom-bg-color hover:bg-custom-bg-color px-4 py-2 font-semibold
                         text-custom-text-color uppercase rounded text-xs"
                            onClick={updateRewardsConfig}
                        >Save</button>
                    </div>

                </> :
                key === "peer" ?
                    <div className="flex justify-center items-center text-lg font-semibold bg-custom-text-color">Upcoming Feature</div>
                    : <div className="flex justify-center items-center text-lg font-semibold bg-custom-text-color">Upcoming Feature</div>
            }

        </div >
    )
}

export default RewardsConfig
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import ErrorPopup from "../PopUps/ErrorPopup";
import { enqueueSnackbar } from 'notistack'
import Navbar1 from "../Navbar/Navbar1";
import LeftMenu1 from "../LeftMenu/LeftMenu1";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const AddPlan = () => {
    const navigate = useNavigate();
    const [planName, setPlanName] = useState('');
    const [planDescription, setPlanDescription] = useState('');
    const [price, setPrice] = useState("")
    const [validity, setValidity] = useState("")
    const [tax, setTax] = useState("")
    const [currency, setCurrency] = useState("")
    const [errors, setErrors] = useState({});
    const [currencyArr, setCurrencyArr] = useState([]);


    useEffect(() => {
        getAllCurrencies();
    }, []);
    const getAllCurrencies = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + `superAdmin/allcurrencies`, { headers: headers })
            .then(function (response) {
                console.log("success", response.data.message, response.data);
                setCurrencyArr(response.data.currency);
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const addPlan = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: "error" })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "planName": planName,
            "planDescription": planDescription,
            "price": price,
            "validity": validity,
            "tax": tax,
            "currency": currency,
            "isActive": true
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'superAdmin/addOrUpdatePlan', payload, { headers: headers })
            .then(function (response) {
                console.log("success", response.data.message, payload);
                enqueueSnackbar('Plan Details Added Successfully!', { variant: "success" })
                navigate(-1);
            })
            .catch(function (error) {
                console.log("error", error);
                enqueueSnackbar(error?.response?.data?.message, { variant: "error" })
            });
    };
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!planName) {
            errorObj = {
                ...errorObj,
                planNameError: true
            }
            error = true;
        }
        if (!planDescription) {
            errorObj = {
                ...errorObj,
                planDescriptionError: true
            };
            error = true;
        }
        if (!validity) {
            errorObj = {
                ...errorObj,
                validityError: true
            };
            error = true;
        }
        if (!tax) {
            errorObj = {
                ...errorObj,
                taxError: true
            };
            error = true;
        }
        if (!price) {
            errorObj = {
                ...errorObj,
                priceError: true
            };
            error = true;
        }
        if (!currency) {
            errorObj = {
                ...errorObj,
                currencyError: true
            };
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    };
    const handleInputChange = (e, setFunc, errorProp) => {
        const inputValue = e.target.value;
        const doubleRegex = /^-?\d+(\.\d*)?$/;
        if (!doubleRegex.test(inputValue)) {
            setErrors({ ...errors, [errorProp]: true });
            setFunc("");
        }
        else {
            setFunc(inputValue);
            setErrors({ ...errors, [errorProp]: false })
        }
    }

    const handleValidityChange = (e, setFunc, errorProp) => {
        let inputValue = e.target.value;
        const integerRegex = /^-?\d+$/;

        if (!integerRegex.test(inputValue)) {
            setErrors({ ...errors, [errorProp]: true });
            setFunc("")
        }
        else {
            setFunc(inputValue);
            setErrors({ ...errors, [errorProp]: false });
        }
    };

    return (
        <div>
            <section className="h-screen flex flex-col justify-center items-center mx-5">
                <Navbar1 />
                <LeftMenu1 section="Add Plan" />
                <div><i onClick={() => navigate('/superadmin/Company')} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i></div>
                <div className="md:w-1/3 max-w-sm mt-10">
                    <div className="text-center md:text-left">
                        <label className="mt-8 mr-1 text-lg text-black-600">Add Plan</label>
                    </div>
                    <label className="mr-1 mt-2 text-sm text-black-600">Plan Name</label>
                    <input className={`text-sm w-full px-4 py-2 ${errors.planNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                        type="text"
                        value={planName}
                        onChange={(e) => { setPlanName(e.target.value); setErrors({ ...errors, planNameError: false }) }}
                        name="planName"
                        placeholder="Plan Name" />
                    <label className="mr-1 mt-1 text-sm text-black-600">Currency</label>
                    <select name="currency" value={currency} className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded -mt-2"
                        onChange={(e) => setCurrency(e.target.value)} >
                        <option value={currency || ""}>{currency === "" ? "Select Currency" : currency}</option>
                        {currencyArr && currencyArr.map(cur => {
                            return (
                                <option key={cur.id} value={cur.currency_name}>{cur.currency_name}</option>
                            )
                        })}
                    </select>
                    <label className="mr-1 mt-1 text-sm text-black-600">Validity</label>
                    <input className={`text-sm w-full px-4 py-2 ${errors.validityError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                        type="text"
                        value={validity}
                        onChange={(e) => { handleValidityChange(e, setValidity, "validityError") }}
                        name="validity"
                        placeholder="Plan Validity in years" />
                    <label className="mr-1 mt-1 text-sm text-black-600">Tax</label>
                    <input className={`text-sm w-full px-4 py-2 ${errors.taxError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                        type="text"
                        value={tax}
                        onChange={(e) => { handleInputChange(e, setTax, "taxError") }}
                        name="tax"
                        placeholder="Tax Percentage eg. 9.99" />
                    <label className="mr-1 mt-1 text-sm text-black-600">Price</label>
                    <input className={`text-sm w-full px-4 py-2 ${errors.priceError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                        type="text"
                        value={price}
                        onChange={(e) => { handleInputChange(e, setPrice, "priceError") }}
                        name="price"
                        placeholder="Plan Price eg. 999.99" />
                    <label className="mr-1 mt-1 text-sm text-black-600">Plan Description</label>
                    <textarea className={`text-sm w-full px-4 py-2 ${errors.planDescriptionError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                        type="text"
                        rows={3}
                        style={{ resize: "none" }}
                        value={planDescription}
                        onChange={(e) => { setPlanDescription(e.target.value); setErrors({ ...errors, planDescriptionError: false }) }}
                        name="planDescription"
                        placeholder="Plan Description" />

                    <div className="flex justify-end text-center md:text-left">
                        <button className="mt-4 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2  font-semibold uppercase rounded text-xs" onClick={addPlan} type="submit">Add</button>
                    </div>
                </div>
            </section>
        </div >
    )
}

export default AddPlan
import React from 'react'
import { Modal } from 'react-bootstrap'
import error from "../images/errro.svg"

const ErrorPopup = ({ show, setShow, msg }) => {
    return (
        <Modal show={show} onHide={() => setShow(false)} size="md" centered >
            <Modal.Body>
                <div className='flex flex-col gap-4 justify-center items-center'>
                    <img src={error} alt='' />
                    <p className='text-lg text-orange-400'>{msg}</p>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ErrorPopup
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import SimpleCrypto from 'simple-crypto-js';
import { config } from '../../Config/Config';
import EmployeeContext from '../../context/employee/EmpContext';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const ToDoPopup = ({ data, show, togglePopup, getDealConversations }) => {
    const { empState: employee } = useContext(EmployeeContext)
    const [actionTypes, setActionTypes] = useState([]);
    const [notes, setNotes] = useState('');
    const [targetDate, setTargetDate] = useState();
    const [selectedActionType, setSelectedActionType] = useState();
    const [errors, setErrors] = useState({});

    useEffect(() => {
        console.log("data", data)
        setNotes(data.notes)
        getAvailableActionTypes();
    }, [])

    const getAvailableActionTypes = async () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        };
        axios.get(config.apiUrl + 'employees/getActionTypes', { headers: headers })
            .then((response) => {
                if (response.data.status === true) {
                    console.log("success", response.data.message);
                    setActionTypes(response.data.actionTypes)
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    }
    const addToDo = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: "error" })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "id": data.id,
            "activityId": data.activityId,
            "currentStatus": data.dealStatus,
            "name": employee.firstName + " " + employee.lastName,
            "customerId": data.customerId,
            "notes": notes,
            "targetDate": targetDate,
            "salesmanId": data.salesmanId,
            "empId": employee.employeeId,
            "actionType": selectedActionType ? parseInt(selectedActionType) : null,
            "source": "Deal"
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'employees/addDealToDo', payload, { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    togglePopup();
                    getDealConversations()
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!notes) {
            errorObj = {
                ...errorObj,
                notesError: true
            }
            error = true;
        }
        if (!targetDate) {
            errorObj = {
                ...errorObj,
                targetDateError: true
            };
            error = true;
        }
        if (!selectedActionType) {
            errorObj = {
                ...errorObj,
                actionTypeError: true
            };
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    return (
        <Modal show={show} onHide={togglePopup} centered contentClassName='ml-20 w-[25vw]'>
            <Modal.Header closeButton style={{ zIndex: "100" }}>
            </Modal.Header>
            <Modal.Body>
                < div className="flex flex-col justify-center items-center -mt-14" >
                    <div className="text-center md:text-left mb-1">
                        <p className="mr-1 text-lg text-black-600 md:whitespace-nowrap">Reach out to {data.customer}</p>
                        <p className="text-xs">({employee.branchName})</p>
                    </div>
                    <div className="flex flex-col gap-1 w-full mt-3">
                        <textarea className={`text-sm w-full p-2 ${errors.notesError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2 resize-none`}
                            type="text"
                            rows={2}
                            maxLength={50}
                            onChange={(e) => { setNotes(e.target.value); setErrors({ ...errors, notesError: false }) }}
                            value={notes}
                            name="salesperson notes"
                            placeholder="Relationship Manager Notes"></textarea>
                        <DatePicker className={`text-sm w-full p-2 ${errors.targetDateError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} cursor-pointer mt-2 rounded`}
                            isClearable
                            showTimeSelect
                            dateFormat="MMMM d, yyyy h:mmaa"
                            placeholderText="Target Date"
                            filterDate={d => {
                                return new Date() < d;
                            }}
                            selected={targetDate}
                            minTime={new Date().setHours(8, 0, 0)}
                            maxTime={new Date().setHours(19, 0, 0)}
                            onChange={date => {
                                if (!date) {
                                    setTargetDate(null);
                                    return;
                                }
                                let new_date = new Date(date);
                                let hours = new_date.getHours();
                                if (hours < 8)
                                    new_date = new_date.setHours(8, 0, 0);
                                setTargetDate(new_date)
                                setErrors({ ...errors, targetDateError: false })
                            }}
                        />
                        <select name="actiontype" value={selectedActionType} className={`text-sm w-full p-2 ${errors.actionTypeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                            onChange={(e) => { setSelectedActionType(e.target.value); setErrors({ ...errors, actionTypeError: false }) }} >
                            <option value="">---Action Type---</option>
                            {actionTypes && actionTypes.map(item => {
                                return (
                                    <option value={item.id} key={item.id}>{item.action}</option>
                                )
                            })}
                        </select>
                        <div className="flex justify-end text-center md:text-left">
                            <button className="mt-4 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2  font-semibold uppercase rounded text-xs" onClick={addToDo} type="submit">Add</button>
                        </div>
                    </div>
                </div >
            </Modal.Body>
        </Modal>
    )
}

export default ToDoPopup

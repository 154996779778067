import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
import { Bar } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const AvgStatusClosureTime = () => {
    const [values, setValues] = useState();
    const [current, setCurrent] = useState();
    const [dealsData, setDealsData] = useState([]);
    const [statusData, setStatusData] = useState([]);

    useEffect(() => {
        getBranchesData()
    }, []);
    const getBranchesData = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getAvgDealStatusCompletionTimeByBranch', { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log(response.data.message, response.data);
                    let dataArray = response.data.activeBranches;
                    dataArray.sort((a, b) => {
                        const countA = a.data.reduce((total, item) => total + item, 0);
                        const countB = b.data.reduce((total, item) => total + item, 0);
                        return countB - countA;
                    });
                    setDealsData(dataArray);
                    setStatusData(response.data.dealStatusArray);
                    dataArray ? setCurrent(dataArray[0]?.id) : setCurrent()
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    useEffect(() => {
        updateValuesData();
    }, [current])
    const updateValuesData = () => {
        let newValuesArr = [];
        console.log("dealsdata", dealsData, current)
        let data = dealsData && dealsData.find(deal => deal.id === current);
        console.log("data", data)
        newValuesArr = data ? data.data : []
        console.log("newValuesArr", newValuesArr)
        setValues(newValuesArr);
    }
    const monthlyData = {
        labels: statusData && statusData.map(d => d.dealStatus),
        datasets: [
            {
                label: "No of Days",
                data: values,
                backgroundColor: [
                    'black',
                ],
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20
            }
        ]
    }
    return (
        <div className="flex gap-2">
            <Card className="flex flex-col justify-between relative" style={{ marginTop: "1rem", height: "250px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                <div className="font-semibold ml-12">
                    <p className="text-sm">Avg Status Closure Time</p>
                </div>
                <select name="branch" value={current} className="absolute -top-1 right-1 text-xs w-32  border border-solid border-gray-300 rounded mt-2"
                    onChange={(e) => setCurrent(e.target.value)} >
                    <option value="">---Choose Branch---</option>
                    {dealsData && dealsData.map((item, i) => {
                        return (
                            <option value={item.id} key={item.id}>{item.branchName}</option>
                        )
                    })}
                </select>
                <div style={{ height: "200px" }}>
                    <Bar data={monthlyData}
                        options={{
                            maintainAspectRatio: true,
                            responsive: true,
                            redraw: false,
                            elements: {
                                line: {
                                    tension: 1
                                }
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                },
                                y: {
                                    ticks: {
                                        precision: 0
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: false
                                },
                            }
                        }
                        } />
                </div>
            </Card>
        </div>
    );
};

export default AvgStatusClosureTime
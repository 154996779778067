import axios from 'axios'
import { enqueueSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { config } from '../../Config/Config'
import SimpleCrypto from 'simple-crypto-js'
import EmployeeContext from '../../context/employee/EmpContext'

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const MeetingCalendarRegistration = ({ data }) => {
    const { empState: employee } = useContext(EmployeeContext);
    const [newStatus, setNewStatus] = useState(false);
    useEffect(() => {
        console.log("first", data)
    }, [])
    const updateMeetingSchedulerStatus = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            newStatus,
            "serviceId": data.id,
            "planId": data.planId
        };
        console.log("payload", payload);
        axios.post(config.apiUrl + 'companyAdmin/updateCompanyMeetingServiceStatus', payload, { headers: headers })
            .then(function (response) {
                console.log(response.data);
                enqueueSnackbar("Status update successfully", { variant: "success" })
            })
            .catch(function (error) {
                console.log("error", error);
            });
    }
    return (
        <div className='flex flex-col gap-3 ml-[4vw] w-[75vw] mt-20'>
            <p className='font-bold text-xl'>
                Terms and conditions on usage of "Meetings Scheduler"
            </p>
            <div className='flex flex-col gap-3 font-semibold text-gray-600 border-2 border-black rounded-xl p-3'>
                <p>
                    This represents an added functionality offered to Entropi users, accessible through the selectable option labeled "Meetings Scheduler" within the "Marketplace" section.
                </p>
                <p>
                    Within the "Meetings Scheduler" feature, pertinent details of participating company employees become accessible during meeting bookings conducted by external individuals seeking specific goods or services.
                </p>
                <p>
                    For clarification, company employees details encompass the following: name, email, website, company name, and contact details etc.
                </p>
                <p>
                    Contact name and details of the external user initiating the booking are collected beforehand and presented as potential leads for the company, should the user desire further information.
                </p>
                <p>
                    Information displayed, whether pertaining to the company or the external user, is strictly intended for business purposes and should be utilised with the appropriate level of professional diligence.
                </p>
                <p>
                    Company administrators retain the option to discontinue this service at any time by utilising the "Report Case" function under the case type "Marketplace" and specifying case details as "Opt Out – Meetings Scheduler." Upon processing this case, the company's details will no longer be able to share their meetings calendar.
                </p>
            </div>
            <div className='flex items-center gap-4 mt-4'>
                <input
                    type='checkbox'
                    onChange={(e) => setNewStatus(e.target.checked)}
                    value={newStatus}
                    name="meetingStatus"
                    className='cursor-pointer'
                    defaultChecked={employee.use_meetings && employee.use_meetings === true}
                />
                <label className='font-semibold mt-1.5'>By accepting above said information and details,
                    you have understood to share your company & employee information while booking meetings.</label>
            </div>
            <button onClick={updateMeetingSchedulerStatus}
                className="max-w-max ml-auto bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs"
            >AGREE</button>
        </div>
    )
}

export default MeetingCalendarRegistration
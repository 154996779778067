import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { config } from '../Config/Config';
import { Spinner } from 'react-bootstrap';
import SimpleCrypto from 'simple-crypto-js';
import moment from 'moment-timezone';
import EmployeeContext from '../context/employee/EmpContext';
import NotAvailable from '../components/Calendar/NotAvailable';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const CustomerSurvey = () => {
    const { empState: employee } = useContext(EmployeeContext);
    const [surveys, setSurveys] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getSurveyUpdates();
    }, [])
    const getSurveyUpdates = async () => {
        let headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + "employees/getAllCutomersSurveyUpdatesByRM", { headers: headers })
            .then((response) => {
                if (response.data.status === true) {
                    console.log("Questions Retrieved Successfully", response.data)
                    setSurveys(response.data.updates);
                } else {
                    console.log("Error")
                }
                setLoading(false)
            }).catch(error => {
                console.error(error)
                setLoading(false)
            })
    }
    if (loading) {
        <div className='mt-20 flex justify-center items-center'><Spinner animation="border" /></div>
    }
    if (!employee.survey_service || employee.survey_service === false) {
        return <NotAvailable survey={true} />
    }
    return (
        <div className="ml-8 mt-20">
            <div className="flex justify-between">
                <div className="text-left font-semibold text-black"><h5>Customer Survey Updates</h5></div>
            </div>
            <div className="mt-3 tableFixHead bg-custom-text-color">
                <table className="border-spacing-x-0 border-spacing-y-2 border-separate" >
                    <thead>
                        <tr className="whitespace-nowrap">
                            <th scope="col" className="th-heading w-10" >Status</th>
                            <th scope="col" className="th-heading" >Name</th>
                            <th scope="col" className={`th-heading ${employee.role === 2 ? "" : "hidden"}`} >Assigned By</th>
                            <th scope="col" className="th-heading" >Request</th>
                            <th scope="col" className="th-heading" >Responded</th>
                            <th scope="col" className="th-heading" >Q1</th>
                            <th scope="col" className="th-heading" >Q2</th>
                            <th scope="col" className="th-heading" >Q3</th>
                            <th scope="col" className="th-heading" >Q4</th>
                            <th scope="col" className="th-heading" >Q5</th>
                            <th scope="col" >Overall</th>
                        </tr>
                    </thead>
                    <tbody className="">
                        {surveys && surveys.map((data, i) => (
                            <tr key={i} className="tr whitespace-nowrap">
                                <td className='w-10'>
                                    <div className={`w-3 h-3 rounded-full ml-3
                                    ${data.status === 2 ? "bg-green-700" : data.status === 1 ? "bg-orange-400" : "bg-red-600"}`}>
                                    </div>
                                </td>
                                <td className="td-text">{data.name}</td>
                                <td className={`td-text ${employee.role === 2 ? "" : "hidden"}`}>{data.empName}{data.empId ? `(${data.empId})` : ""}</td>
                                <td className="td-text">{moment(data.createdAt).format("DD-MM-YYYY")}</td>
                                <td className='td-text'>{data.response ? moment(data.updatedAt).format("DD-MM-YYYY") : ""}</td>
                                <td className="td-text pl-3">{data?.response?.length > 0 ? data.response[0].ans : ""}</td>
                                <td className="td-text pl-3">{data?.response?.length > 1 ? data.response[1].ans : ""}</td>
                                <td className="td-text pl-3">{data?.response?.length > 2 ? data.response[2].ans : ""}</td>
                                <td className="td-text pl-3">{data?.response?.length > 3 ? data.response[3].ans : ""}</td>
                                <td className="td-text pl-3">{data?.response?.length > 4 ? data.response[4].ans : ""}</td>
                                <td className="td-text pl-6">{data?.response?.length > 0 ? data.response[0].overall : ""}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className={`flex items-center max-w-max mx-auto gap-4 text-sm mt-1 absolute left-[18vw] bottom-1`}>
                <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-green-700 rounded-xl'></div><span>Filled</span></div>
                <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-red-600 rounded-xl'></div><span>Expired</span></div>
                <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-orange-400 rounded-xl'></div><span>Waiting for Response</span></div>
            </div>
        </div>
    )
}

export default CustomerSurvey
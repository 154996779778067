import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import { enqueueSnackbar } from 'notistack';
import ErrorPopup from "../PopUps/ErrorPopup";
import EmployeeContext from "../context/employee/EmpContext";
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const ReassignRm = ({ data, activeEmployees, refetch, closePopup }) => {
    const navigate = useNavigate();
    const locations = useLocation();
    const { empState: employee } = useContext(EmployeeContext)
    // const employee = locations.state?.employee;
    const [employees, setEmployees] = useState([]);
    const [empSelected, setEmpSelected] = useState('');
    const [productSelected, setProductSelected] = useState('');
    const [empSelectedError, setEmpSelectedError] = useState('');
    const [productSelectedError, setProductSelectedError] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [leadType, setLeadType] = useState(1);
    const [show, setShow] = useState(true);
    const [showError, setShowError] = useState(false);
    const [msg, setMsg] = useState('');
    const [errors, setErrors] = useState({});


    useEffect(() => {
        console.log("state123", activeEmployees)
        let emp = activeEmployees.filter(employee => employee.role === 3 && employee.status === 1 && employee.employeeId != data.employeeId)
        setEmployees(emp)
        console.log("emp", emp)
        setFirstName(data.firstName)
        setLastName(data.lastName)
    }, [])
    const handleEmployeeChange = (e) => {
        setEmpSelected(e.target.value);
        setEmpSelectedError('');
    }
    const updateRelationshipManager = () => {
        if (validateField()) {
            enqueueSnackbar('Please Select Relationship Manager!', { variant: 'error' })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "visitId": data.visitId,
            "activityId": data.activityId,
            "salesmanId": empSelected

        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'manager/updateRelationshipManager', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    console.log("success", response.data.message, payload);
                    enqueueSnackbar(response.data.message, { variant: "success" })
                    closePopup();
                    refetch();
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
                setMsg(error?.response?.data?.message);
                setShowError(true);
                setTimeout(() => { setMsg(''); setShowError(false) }, 2000)
            });
    };
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!empSelected) {
            errorObj = {
                ...errorObj,
                empSelectedError: true
            }
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    return (
        <Modal show={show} onHide={closePopup} size="sm" centered contentClassName="rounded-2xl">
            <Modal.Body>
                <section className="mt-5 ml-2 mr-2">
                    <ErrorPopup show={showError} setShow={setShowError} msg={msg} />
                    <div className="-mt-10 flex flex-col">
                        <div className="text-center md:text-left">
                            <label className="mt-1 font-semibold mr-1 text-lg text-black-600">Reassign RM</label>
                        </div>
                        <center>{firstName}&nbsp;{lastName}</center>
                        <div className="mt-1">
                            <select name="employee" value={empSelected} className={`text-xs w-full px-4 py-2 ${errors.empSelectedError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-1`}
                                onChange={(e) => handleEmployeeChange(e)} >
                                <option value="" className="text-xs">Select RM</option>
                                {employees && employees.map(employee => {
                                    return (
                                        <option className="text-xs" key={employee.id} value={employee.id}>{employee.firstName}&nbsp;{employee.lastName}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="flex justify-center text-center md:text-left">
                            <button className="mt-3 btn-block bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-base" onClick={updateRelationshipManager} type="submit">Submit</button>
                        </div>
                    </div>
                </section>
            </Modal.Body>
        </Modal>
    )
}

export default ReassignRm
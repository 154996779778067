import React from 'react'

const Terms = ({ val, handleSelect }) => {
    return (
        <div className='flex flex-col gap-3 -ml-[4vw] w-[75vw] mt-20'>
            <p className='font-bold text-xl'>
                Terms and conditions on usage of "Directory Services"
            </p>
            <div className='flex flex-col gap-3 font-semibold text-gray-600 border-2 border-black rounded-xl p-3'>
                <p>
                    This represents an added functionality offered to Entropi users, accessible through the selectable option labeled "Directory Services" within the "Marketplace" section.
                </p>
                <p>
                    Within the "Directory Services" feature, pertinent details of participating companies become accessible during searches conducted by external individuals seeking specific goods or services.
                </p>
                <p>
                    For clarification, company details encompass the following: name, logo, location, website, company description, and contact details of both the company administrator and sales head.
                </p>
                <p>
                    Contact name and details of the external user initiating the search are collected beforehand and presented as potential leads for the company, should the user desire further information.
                </p>
                <p>
                    Information displayed, whether pertaining to the company or the external user, is strictly intended for business purposes and should be utilised with the appropriate level of professional diligence.
                </p>
                <p>
                    Company administrators retain the option to discontinue this service at any time by utilising the "Report Case" function under the case type "Marketplace" and specifying case details as "Opt Out – Directory Services." Upon processing this case, the company's details will no longer be included in search results.
                </p>
            </div>
            <div className='flex items-center gap-4 mt-4'>
                <input
                    type='checkbox'
                    onChange={(e) => handleSelect(e)}
                    value={val}
                    name="register"
                    className='cursor-pointer'
                />
                <label className='font-semibold mt-1.5'>By accepting above said information and details, 
                you have understood and participate to provide your company information in search.</label>
            </div>
        </div>
    )
}

export default Terms
import { useState } from "react";
import EmployeeContext from "./EmpContext";
import SimpleCrypto from "simple-crypto-js";
import { config } from "../../Config/Config";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const EmpStateContext = (props) => {
    const [empState, setEmpState] = useState(localStorage.getItem('junk') ? simpleCrypto.decrypt(localStorage.getItem('junk')) : {});

    const updateEmpState = (newState) => {
        setEmpState(newState);
    }
    return (
        <EmployeeContext.Provider value={{ empState, updateEmpState }}>
            {props.children}
        </EmployeeContext.Provider>
    )
}

export default EmpStateContext;
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Modal, Form, OverlayTrigger, Tooltip, FormControl, Card, Button } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import { enqueueSnackbar } from 'notistack'
import Navbar1 from "../Navbar/Navbar1";
import LeftMenu1 from "../LeftMenu/LeftMenu1";
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const EditProduct = () => {
    const navigate = useNavigate();
    const locations = useLocation();
    const employee = locations?.state.employee
    const productData = locations?.state.productData
    const productTypes = locations?.state.productTypes
    const [productFamilyName, setProductFamilyName] = useState('');
    const [productType, setProductType] = useState('');
    const [description, setDescription] = useState('');
    const [selectedProductType, setSelectedProductType] = useState();
    const [status, setStatus] = useState(1)
    const [branchType, setBranchType] = useState('');
    const [productNameType, setProductNameType] = useState('');
    const [selectedBranchType, setSelectedBranchType] = useState();
    const [selectedStatus, setSelectedStatus] = useState();
    const [productId, setProductId] = useState('')
    const [productCode, setProductCode] = useState('')
    const [errors, setErrors] = useState({});

    useEffect(() => {
        console.log("data", productData)
        setProductFamilyName(productData.productFamilyName)
        setProductType(productData.productType)
        setDescription(productData.description)
        setProductId(productData.id)
        setSelectedProductType(productData.productType);
        getProductType(productData.productType);
        setProductCode(productData.productCode)
        setStatus(productData.status)
    }, []);


    const editProduct = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: 'error' })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "productId": productId,
            "productFamilyName": productFamilyName,
            "userId": employee?.id,
            "productCode": productCode,
            "companyId": employee?.companyId,
            "productType": selectedProductType == null ? productType : parseInt(selectedProductType),
            "description": description,
            "status": selectedStatus == null ? status : parseInt(selectedStatus),
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/editProduct', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    enqueueSnackbar("Product Details Updated Successfully !", { variant: "success" })
                    navigate(`/${employee.companyName}/companyadmin/Product`);
                    // closePopup()
                    // getAllProducts()
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };

    const handleChangeProductType = (e) => {
        setSelectedProductType(e.target.value);
    }
    const handleChangeStatus = (e) => {
        setSelectedStatus(e.target.value);
    }
    const getProductType = (typeId) => {
        let pname = (productTypes.find(pType => pType.id === typeId))
        console.log("bname", pname)
        setProductNameType(pname.productType)
    }
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!productFamilyName) {
            errorObj = {
                ...errorObj,
                productFamilyNameError: true
            }
            error = true;
        }
        if (!productCode) {
            errorObj = {
                ...errorObj,
                productCodeError: true
            }
            error = true;
        }
        if (!selectedProductType) {
            errorObj = {
                ...errorObj,
                productTypeError: true
            };
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    return (
        <div>
            <section className="h-screen flex flex-col justify-center items-center mx-5">
                <Navbar1 employee={employee} />
                <LeftMenu1 section="Edit Product" />
                <div><i onClick={() => navigate(`/${employee.companyName}/companyadmin/Product`)} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i></div>
                <div className="md:w-1/3 max-w-sm -mt-4">
                    <div className="text-center md:text-left">
                        <label className="mr-1 text-lg text-black-600">Edit Product</label>
                    </div>
                    <label className="mr-1 mt-2 text-sm text-black-600">Product Code</label>
                    <input className={`text-sm w-full px-4 py-2 ${errors.productCodeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                        type="text"
                        onChange={(e) => { setProductCode(e.target.value); setErrors({ ...errors, productCodeError: false }) }}
                        name="productCode"
                        disabled={true}
                        value={productCode}
                        placeholder="Product Family Name" />
                    <label className="mr-1 mt-2 text-sm text-black-600">Product Name</label>
                    <input className={`text-sm w-full px-4 py-2 ${errors.productFamilyNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                        type="text"
                        onChange={(e) => { setProductFamilyName(e.target.value); setErrors({ ...errors, productFamilyNameError: false }) }}
                        name="productFamilyName"
                        value={productFamilyName}
                        placeholder="Product Family Name" />
                    <label className="mr-1 mt-2 text-sm text-black-600">Product Type</label>
                    <select name="productType" value={selectedProductType} className={`text-sm w-full px-4 py-2 ${errors.selectedProductTypeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                        onChange={(e) => { handleChangeProductType(e); setErrors({ ...errors, selectedProductTypeError: false }) }} >
                        <option value={productType ? productType : ""}>{productNameType ? productNameType : "Select Product Type"}</option>
                        {productTypes && productTypes.map(pType => {
                            return (
                                <option key={pType.id} value={pType.id}>{pType.productType}</option>
                            )
                        })}
                    </select>
                    <label className="mr-1 mt-2 text-sm text-black-600">Status</label>
                    <select name="status" value={selectedStatus} className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded"
                        onChange={(e) => handleChangeStatus(e)} >
                        <option value={selectedStatus == null ? status : selectedStatus}>{selectedStatus == null ? status == 1 ? "Active" : "Inactive" : selectedStatus == 1 ? "Active" : "Inactive"}</option>

                        <option key={1} value={1}>Active</option>
                        <option key={2} value={2}>Inactive</option>
                    </select>
                    <label className="mr-1 mt-2 text-sm text-black-600">Description</label>
                    <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded"
                        type="text"
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                        name="description"
                        placeholder="Description" />

                    <div className="flex justify-end text-center md:text-left">
                        {/* <button className="mt-4 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white uppercase rounded text-xs tracking-wider" onClick={() => removeProduct()} type="submit">Delete</button> */}
                        <button className="mt-4 bg-custom-bg-color hover:custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs" onClick={editProduct} type="submit">Update</button>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default EditProduct
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import axios from 'axios';
import { config } from '../../Config/Config';
import { Bar } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const LeadTypesCount = () => {
    const [values, setValues] = useState([]);

    useEffect(() => {
        getLeadTypesCount();
    }, [])
    const getLeadTypesCount = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getLeadTypeCount', { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log(response.data.message, response.data);
                    setValues(response.data.countsArr);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const salesData = {
        labels: values && values.map(data => data.leadType),
        datasets: [
            {
                data: values && values.map(val => val.count),
                backgroundColor: ['black'],
                borderColor: 'black',
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20,
            }
        ]
    }
    return (
        <div className="">
            <Card className="flex flex-col justify-between relative" style={{ marginTop: "1rem", height: "250px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                <div className="font-semibold text-lg ml-8">Lead Types</div>
                <div style={{ height: "200px" }}>
                    <Bar data={salesData}
                        options={{
                            maintainAspectRatio: true,
                            responsive: true,
                            redraw: false,
                            elements: {
                                line: {
                                    tension: 1
                                }
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        font: {
                                            size: 10, // Adjust the font size as needed
                                        },
                                    },
                                },
                                y: {
                                    ticks: {
                                        precision: 0
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: false
                                },
                                tooltip: {
                                    enabled: false
                                },
                            }
                        }
                        } />
                </div>
            </Card>
        </div >
    );
};

export default LeadTypesCount
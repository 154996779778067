import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LeftMenu from '../LeftMenu/LeftMenu';
import axios from 'axios';
import { config } from '../Config/Config';
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from 'moment-timezone';
import Navbar from "../Navbar/Navbar";
import SimpleCrypto from "simple-crypto-js";
import editicon from "../images/editicon.svg";
import addadmin from "../images/adminicon1.svg";
import CompanyEmployees from "./CompanyEmployees";
import EmployeeContext from "../context/employee/EmpContext";
import AllCases from "../Cases/AllCases";
import EditFields from "../components/common/EditFields";
import Dashboard from "./Dashboard";
import MenuConfig from "../components/common/MenuConfig";
import AllInvoice from "./Invoices";
import web from "../images/web.svg";
import subs from "../images/subscribe.svg";
import URLPopup from "../PopUps/URLPopup";
import MarketPlaceConfig from "./MarketplaceConfig";
import ServicesByCompany from "./ServicesByCompany";
import PricingConfig from "./PricingConfig";
import AddSubscription from "./AddSubscription";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const SuperAdmin = () => {
    const location = useLocation();
    const { empState: employee } = useContext(EmployeeContext)
    const navigate = useNavigate();
    const { path } = useParams();
    const [companies, setCompanies] = useState([]);
    const [companyTypes, setCompanyTypes] = useState([]);
    const { part } = useParams();
    const [section, setSection] = useState(part || "Company");
    const [showURLPopup, setShowURLPopup] = useState(false);
    const [urlPopupData, setURLPopupData] = useState(null);
    const [subsData, setSubsData] = useState();

    useEffect(() => {
        getAllCompanies();
    }, []);

    const handleNaviagate = () => {
        navigate('/createcompany', { state: { employee, companyTypes: companyTypes } })
    }

    const getAllCompanies = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getAllCompanies', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, response.data);
                    setCompanies(response.data.companies);
                    setCompanyTypes(response.data.companyTypes);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const getEmployeeRole = (role) => {
        if (role === 1) return "Company Admin"
        else if (role === 4) return "Super Admin"
        else if (role === 2) return "Branch Admin"
        else return "Relationship Manager"
    }
    const getCompanyType = (id) => {
        if (!id) return
        let type = companyTypes?.filter(item => item.id === id)
        return type[0]?.companyType;
    }
    const toggleURLPopup = (data = {}) => {
        setShowURLPopup(!showURLPopup);
        setURLPopupData(data)
    }
    const addNewSubs = (data) => {
        setSubsData(data);
        setSection("AddSubscription")
    }
    return (
        <div>
            {showURLPopup && <URLPopup data={urlPopupData} togglePopup={toggleURLPopup} />}
            <div className='flex'>
                <div className='w-[14vw]'><LeftMenu section={section} setSection={setSection} /></div>
                <div className='w-5/6'>
                    <Navbar employee={employee} role={getEmployeeRole(employee?.role)} />
                    {section === "Company" ?
                        <div className="ml-8 mt-20">
                            <div className="flex flex-col justify-between">
                                <div className="text-left font-semibold text-black"><h5>Companies</h5></div>
                                <div className="-mt-5 px-8 text-right">
                                    <button onClick={handleNaviagate} className="bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold  uppercase rounded text-xs -mr-7">Add Company</button>
                                </div>
                            </div>

                            <div className="mt-3 tableFixHead">
                                <table className="border-spacing-x-0 border-spacing-y-1" >
                                    <thead>
                                        <tr className="whitespace-nowrap">
                                            <th scope="col" className="th-heading">S.No</th>
                                            <th scope="col" className="th-heading">Company Name</th>
                                            <th scope="col" className="th-heading">Code</th>
                                            <th scope="col" className="th-heading">Category</th>
                                            <th scope="col" className="th-heading">Type</th>
                                            <th scope="col" className="th-heading">Status</th>
                                            <th scope="col" className="th-heading">Created On</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {companies.map((data, i) => (
                                            <tr key={data.id} className="tr whitespace-nowrap" >
                                                <td className="td-text">{i + 1}</td>
                                                <td className="td-text">{data.name}</td>
                                                <td className="td-text">{data.uniqueCode}</td>
                                                <td className="td-text">{data.category}</td>
                                                <td className="td-text">{data?.typeOfBusiness ? getCompanyType(data.typeOfBusiness) : ""}</td>
                                                <td className="td-text">{data.status === 1 ? "Active" : "Inactive"}</td>
                                                <td className="td-text">{data.createdAt?.split("T")[0].split("-").reverse().join("-")}</td>
                                                <td className="flex text-right items-end justify-end">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={<Tooltip id="button-tooltip">Edit Company</Tooltip>}
                                                    >
                                                        <div className="px-2 icon-w text-right" onClick={() => navigate("/editcompany", { state: { employee, data: data, companyTypes: companyTypes } })}>
                                                            <div className="text-center items-end justify-center"><img src={editicon} /></div>
                                                        </div>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={<Tooltip id="button-tooltip">Add Admin</Tooltip>}
                                                    >
                                                        <div className="ml-2 px-2 icon-w text-right" onClick={() => navigate("/addadmin", { state: { employee, data: data } })}>
                                                            <div className="text-center items-end justify-center"><img src={addadmin} className="w-5" /></div>
                                                        </div>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={<Tooltip id="button-tooltip">View Url</Tooltip>}
                                                    >
                                                        <div className="ml-2 px-2 icon-w text-right" onClick={() => toggleURLPopup(data)}>
                                                            <div className="text-center items-end justify-center"><img src={web} className="w-5" /></div>
                                                        </div>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={<Tooltip id="button-tooltip">Add New Subscription</Tooltip>}
                                                    >
                                                        <div className="ml-2 px-2 icon-w text-right" onClick={() => addNewSubs(data)}>
                                                            <div className="text-center items-end justify-center"><img src={subs} className="w-5" /></div>
                                                        </div>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        : (section === "Cases") ?
                            <div>
                                <AllCases />
                            </div>
                            : (section === "Customizations") ?
                                <div>
                                    <EditFields />
                                </div>
                                : (section === "MenuConfig") ?
                                    <div>
                                        <MenuConfig />
                                    </div>
                                    : (section === "Dashboard") ?
                                        <Dashboard />
                                        : (section === "Invoice") ?
                                            <AllInvoice />
                                            : (section === "MarketPlaceConfig") ?
                                                <MarketPlaceConfig />
                                                : (section === "ServicesByCompany") ?
                                                    <ServicesByCompany />
                                                    : (section === "PricingConfig") ?
                                                        <PricingConfig />
                                                        : (section === "AddSubscription") ?
                                                            <AddSubscription data={subsData} setSection={setSection} />
                                                            : <div>
                                                                <CompanyEmployees employees={employee} />
                                                            </div>
                    }
                </div>

            </div>
        </div>
    )
}

export default SuperAdmin
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const HOC = (props) => {
    return (
        <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="button-tooltip" {...props}>
                {props.tooltip}
            </Tooltip>}
        >
            <div className='icon-w'>
                <img src={props.source} alt=''
                    className={`cursor-pointer ${props.icon === 3 ? "pt-1 w-4 h-5" : props.icon === 2 ? "py-1 w-5 pl-0.5" : "py-1"}`}
                    onClick={() => props.handleClick(props.data)} />

            </div>
        </OverlayTrigger>
    )
}

export default HOC
import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { config } from '../Config/Config';
import moment from 'moment-timezone';
import approve from '../images/approve.svg';
import approvegray from '../images/approvegray.svg';
import rework from '../images/rework.svg';
import reworkgray from '../images/reworkgray.svg';
import EmployeeContext from '../context/employee/EmpContext';
import { Modal, Spinner } from 'react-bootstrap';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)
const ApproveTargets = () => {
    const { empState: employee } = useContext(EmployeeContext)
    const [totals, setTotals] = useState([]);
    const [achieved, setAchieved] = useState([]);
    const [cTargets, setCTargets] = useState([])
    const [eTargets, setETargets] = useState([])
    const [companyTargets, setCompanyTargets] = useState([])
    const [targets, setTargets] = useState([])
    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState("");
    const [show, setShow] = useState(false);
    const [curData, setCurData] = useState();
    const [curStatus, setCurStatus] = useState();
    const [emptyArray, setEmptyArray] = useState(new Array(12).fill(0));

    useEffect(() => {
        console.log("emp", employee)
        if (employee.role === 1) {
            getCompanyTargets()
            getTargetsByCompanyAdmin()
        } else {
            getAllBranchTargets()
            getTargetsByEmployee();
        }
    }, [])
    useEffect(() => {
        let newTargets;
        if (employee.role === 1) {
            newTargets = targets
        } else {
            newTargets = eTargets
        }
        console.log("new", newTargets, "C", cTargets)
        let targetsArray = newTargets.map(t => t.targets)
        const totalCounts = targetsArray.reduce((acc, arr) => {
            arr.forEach((obj) => {
                const existingObj = acc.find((item) => item.id === obj.id);
                if (existingObj) {
                    existingObj.totalCount = parseFloat((existingObj.totalCount + obj.target).toFixed(2));
                } else {
                    acc.push({ id: obj.id, totalCount: obj.target });
                }
            });
            return acc;
        }, []);
        const totalAchieved = targetsArray.reduce((acc, arr) => {
            arr.forEach((obj) => {
                const existingObj = acc.find((item) => item.id === obj.id);
                if (existingObj) {
                    existingObj.totalAchieved = parseFloat((existingObj.totalAchieved + obj.achieved).toFixed(2));
                } else {
                    acc.push({ id: obj.id, totalAchieved: obj.achieved });
                }
            });
            return acc;
        }, []);
        console.log("totals", totalAchieved, "t", totalCounts)
        setTotals(totalCounts);
        setAchieved(totalAchieved);
    }, [targets, cTargets, companyTargets])

    const getAllBranchTargets = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "branchId": employee?.branchId
        }
        setLoading(true)
        axios.post(config.apiUrl + 'manager/getBranchTargets', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    console.log(response.data.message, response.data);
                    setCTargets(response.data.getAllTargets || []);
                } else {
                    console.log("Error");
                }
                setLoading(false)
            })
            .catch(function (error) {
                console.log("error", error);
                setLoading(false)
            });
    }
    const getTargetsByEmployee = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        setLoading(true);
        axios.get(config.apiUrl + 'manager/getTargetsByEmployee', { headers: headers })
            .then(function (response) {
                console.log(response.data.message, response.data);
                if (response.data.status === true) {
                    setETargets(response.data.getAllTargets);
                } else {
                    console.log("Error");
                }
                setLoading(false)
            })
            .catch(function (error) {
                console.log("error", error);
                setLoading(false)
            });
    }
    const getCompanyTargets = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "companyId": employee?.companyId
        }
        setLoading(true)
        axios.post(config.apiUrl + 'companyAdmin/getCompanyTargets', payload, { headers: headers })
            .then(function (response) {
                console.log(response.data.message, response.data);
                if (response.data.status === true) {
                    setCompanyTargets(response.data.getAllTargets || []);
                } else {
                    console.log("Error");
                }
                setLoading(false)
            })
            .catch(function (error) {
                console.log("error", error);
                setLoading(false)
            });
    };
    const getTargetsByCompanyAdmin = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        setLoading(true)
        axios.get(config.apiUrl + 'companyAdmin/getTargetsByCompanyAdmin', { headers: headers })
            .then(function (response) {
                console.log(response.data.message, response.data);
                if (response.data.status === true) {
                    setTargets(response.data.getAllTargets);
                } else {
                    console.log("Error");
                }
                setLoading(false)
            })
            .catch(function (error) {
                setLoading(false)
                console.log("error", error);
            });
    };
    const updateBranchTarget = (data, status) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        data = data || curData;
        status = status || curStatus;
        var payload = {
            "id": data.id,
            "status": status,
            "reason": reason
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/updateBranchTargetStatus', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    if (response.status === 200) {
                        getTargetsByCompanyAdmin()
                    }
                }
            })
            .catch(function (error) {
                console.log("error", error)
            })
    };
    const updateEmployeeTarget = (data, status) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        data = data || curData;
        status = status || curStatus;
        var payload = {
            "id": data.id,
            "branchId": data.branchId,
            "employeeId": data.employeeId,
            "year": data.year,
            "targets": JSON.stringify(data.targets),
            "status": status,
            "reason": reason
        }
        console.log("payload", payload, data, status)
        axios.post(config.apiUrl + 'employees/updateEmployeeTargets', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    getTargetsByEmployee()
                }
            })
            .catch(function (error) {
                console.log("error", error)
            })
    }
    const reasonForRework = (data, status) => {
        setCurData(data);
        setCurStatus(status);
        setShow(true)
    }
    if (loading) {
        return (
            <div className='mt-20 flex justify-center items-center'><Spinner animation="border" /></div>
        )
    }
    return (
        <>
            {companyTargets?.length === 0 && cTargets?.length === 0 ?
                <div className="ml-8 mt-20">
                    <div className="flex justify-center items-center">
                        <div className="text-left font-semibold text-black"><h5>No Targets Data Available</h5></div>
                    </div>
                </div>
                : <div className="ml-8 mt-20">
                    <div className="flex flex-col justify-between">
                        <div className="text-left font-semibold text-black"><h5>Approve Targets</h5></div>
                    </div>
                    <div className="mt-3 h-[80vh] overflow-y-auto">
                        <table className="table-striped" >
                            <thead className='bg-custom-bg-color text-custom-text-color'>
                                <tr className="whitespace-nowrap">
                                    <th scope="col" className="th-heading">{employee.role === 1 ? <>{employee?.companyName}</> : <>{employee?.branchName}</>}</th>
                                    <th scope="col" className="th-heading text-center" style={"Jan" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Jan</th>
                                    <th scope="col" className="th-heading text-center" style={"Feb" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Feb</th>
                                    <th scope="col" className="th-heading text-center" style={"Mar" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Mar</th>
                                    <th scope="col" className="th-heading text-center" style={"Apr" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Apr</th>
                                    <th scope="col" className="th-heading text-center" style={"May" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>May</th>
                                    <th scope="col" className="th-heading text-center" style={"Jun" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Jun</th>
                                    <th scope="col" className="th-heading text-center" style={"Jul" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Jul</th>
                                    <th scope="col" className="th-heading text-center" style={"Aug" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Aug</th>
                                    <th scope="col" className="th-heading text-center" style={"Sep" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Sep</th>
                                    <th scope="col" className="th-heading text-center" style={"Oct" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Oct</th>
                                    <th scope="col" className="th-heading text-center" style={"Nov" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Nov</th>
                                    <th scope="col" className="th-heading text-center" style={"Dec" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Dec</th>
                                    <th scope="col" className="th-heading" >Status</th>
                                    <th scope="col" className="th-heading" >Action to Take</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(employee.role === 2) ?
                                    <>
                                        {cTargets && cTargets.map((data, i) => {
                                            return (
                                                <tr key={`${data.id ? data.id : i} targetData`} className="whitespace-nowrap">
                                                    <td className="td-text">Branch Target</td>
                                                    {data.targets.map((t, i) => (
                                                        <td className="td-text text-center" key={`${t.id} targets`}>{t.target}</td>
                                                    ))}
                                                    <td className="td-text text-center"></td>
                                                    <td className="td-text text-center"></td>
                                                </tr>
                                            )
                                        })}
                                        {eTargets.map((data, i) => {
                                            return (
                                                <tr key={`${data.id ? data.id : i} targetData`} className="whitespace-nowrap">
                                                    <td className="td-text">{data.salesperson}</td>
                                                    {data.targets.map((t, i) => (
                                                        <td className="td-text text-center" key={`${t.id} targets`}>{t.target}</td>
                                                    ))}
                                                    <td className="td-text text-center">
                                                        <div className='flex w-full items-center justify-center'>
                                                            {data.status === 1 ? <div className='w-3 h-3 bg-green-600 rounded-xl'></div>
                                                                : data.status === 2 ? <div className='w-3 h-3 bg-blue-500 rounded-xl'></div>
                                                                    : data.status === 3 ? <div className='w-3 h-3 bg-orange-400 rounded-xl'></div>
                                                                        : <div className='w-3 h-3 bg-red-700 rounded-xl'></div>}
                                                        </div>
                                                    </td>
                                                    <td className="flex text-right items-center justify-center">
                                                        <div className={`ml-2 px-2 text-right ${data.status !== 2 ? "icon-t" : "icon-w"}`} >
                                                            <div className={`text-center items-center justify-center ${data.status !== 2 ? "cursor-auto" : "cursor-pointer"}`} onClick={() => { data.status !== 2 ? console.log('no') : updateEmployeeTarget(data, 1) }}><img src={data.status !== 2 ? approvegray : approve} /></div>
                                                        </div>
                                                        <div className={`ml-2 px-2 text-right ${data.status !== 2 ? "icon-t" : "icon-w"}`}>
                                                            <div className={`text-center items-center justify-center ${data.status !== 2 ? "cursor-auto" : "cursor-pointer"}`} onClick={() => { data.status !== 2 ? setShow(false) : reasonForRework(data, 4) }}><img src={data.status !== 2 ? reworkgray : rework} /></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        <tr className={"whitespace-nowrap"} >
                                            <td className="td-text">Total</td>
                                            {totals && totals.length > 0 ?
                                                totals.map((t, i) => (
                                                    <td className="text-center td-text" key={`${i} totals`}>{t.totalCount}
                                                    </td>
                                                ))
                                                : emptyArray.map((t, i) => (
                                                    <td className="text-center td-text" key={`${i} totals`}>{t}</td>
                                                ))}
                                            <td className="td-text text-center"></td>
                                            <td className="td-text text-center"></td>
                                        </tr>
                                        <tr className={"whitespace-nowrap"} >
                                            <td className="td-text">Difference</td>
                                            {totals.length > 0 && cTargets.length > 0 ?
                                                totals.map((t, i) => (
                                                    <td className="text-center td-text" key={`${i} achieved`}>
                                                        <span>
                                                            {cTargets?.length > 0 ? (cTargets[0].targets[i].target - t.totalCount) === 0 ? 0 : parseFloat((cTargets[0].targets[i].target - t.totalCount)).toFixed(2) : 0}
                                                        </span>
                                                    </td>
                                                ))
                                                : emptyArray.map((t, i) => (
                                                    <td className="text-center td-text" key={`${i} achieved`}>{t}</td>
                                                ))}
                                            <td className="td-text text-center"></td>
                                            <td className="td-text text-center"></td>
                                        </tr>
                                    </>
                                    :
                                    <>
                                        {companyTargets.map((data, i) => {
                                            return (
                                                <tr key={`${data.id ? data.id : i} targetData`} className="whitespace-nowrap">
                                                    <td className="td-text">Target (T)</td>
                                                    {data.targets.map((t, i) => (
                                                        <td className="td-text text-center" key={`${t.id} targets`}>{t.target}</td>
                                                    ))}
                                                    <td className="td-text text-center"></td>
                                                    <td className="td-text text-center"></td>
                                                </tr>
                                            )
                                        })}
                                        {targets.map((data, i) => {
                                            return (
                                                <tr key={`${data.id ? data.id : i} targetData`} className="whitespace-nowrap">
                                                    <td className="td-text">{data.branchName}</td>
                                                    {data.targets.map((t, i) => (
                                                        <td className="td-text text-center" key={`${t.id} targets`}>{t.target}</td>
                                                    ))}
                                                    <td className="td-text text-center">
                                                        <div className='flex w-full items-center justify-center'>
                                                            {data.status === 1 ?
                                                                <div className='w-3 h-3 bg-green-600 rounded-xl'></div>
                                                                : data.status === 2 ? <div className='w-3 h-3 bg-blue-500 rounded-xl'></div>
                                                                    : data.status === 3 ? <div className='w-3 h-3 bg-orange-400 rounded-xl'></div>
                                                                        : <div className='w-3 h-3 bg-red-700 rounded-xl'></div>}
                                                        </div>
                                                    </td>
                                                    {/* <td className="td-text flex">
                                                        <div onClick={() => updateBranchTarget(data, 1)} className={`icon-w ${data.status !== 2 ? "bg-gray-600 hover:bg-gray-600" : "bg-black hover:bg-black"}`} disabled={data.status !== 2 ? true : false}><img src={approve}/></div>
                                                        <button onClick={() => { setCurData(data); setCurStatus(4); setShow(true) }} className={`ml-2 w-20 px-3 py-2 font-semibold text-white uppercase rounded text-xs ${data.status !== 2 ? "bg-gray-600 hover:bg-gray-600" : "bg-black hover:bg-black"}`} disabled={data.status !== 2 ? true : false}><img src={rework}/></button>
                                                    </td> */}
                                                    <td className="flex text-right items-center justify-center">
                                                        <div className={`ml-2 px-2 text-right ${data.status !== 2 ? "icon-t" : "icon-w"}`} >
                                                            <div className={`text-center items-center justify-center ${data.status !== 2 ? "cursor-auto" : "cursor-pointer"}`} onClick={() => { data.status !== 2 ? console.log('no') : updateBranchTarget(data, 1) }}><img src={data.status !== 2 ? approvegray : approve} /></div>
                                                        </div>
                                                        <div className={`ml-2 px-2 text-right ${data.status !== 2 ? "icon-t" : "icon-w"}`}>
                                                            <div className={`text-center items-center justify-center ${data.status !== 2 ? "cursor-auto" : "cursor-pointer"}`} onClick={() => { data.status !== 2 ? setShow(false) : reasonForRework(data, 4) }}><img src={data.status !== 2 ? reworkgray : rework} /></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        <tr className={"whitespace-nowrap"} >
                                            <td className="td-text">Total</td>
                                            {totals.map((t, i) => (
                                                <td className="text-center td-text" key={`${i} achieved`}>{t.totalCount}
                                                </td>
                                            ))}
                                            <td className="td-text text-center"></td>
                                            <td className="td-text text-center"></td>
                                        </tr>
                                        <tr className={"whitespace-nowrap"} >
                                            <td className="td-text">Difference</td>
                                            {totals && totals.map((t, i) => (
                                                <td className="text-center td-text" key={`${i} achieved`}>
                                                    <span>
                                                        {companyTargets?.length > 0 ? parseFloat((companyTargets[0].targets[i].target - t.totalCount).toFixed(2)) : 0}
                                                    </span>
                                                </td>
                                            ))}
                                            <td className="td-text text-center"></td>
                                            <td className="td-text text-center"></td>
                                        </tr>
                                    </>
                                }

                            </tbody>
                        </table>
                    </div>
                    <div className='flex items-center w-full mx-auto gap-4'>
                        <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-green-600 rounded-xl'></div><span>Approved</span></div>
                        <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-blue-500 rounded-xl'></div><span>Submitted</span></div>
                        <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-orange-400 rounded-xl'></div><span>Saved</span></div>
                        <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-red-700 rounded-xl'></div><span>Rejected</span></div>
                    </div>

                </div >
            }
            <Modal show={show} onHide={() => setShow(false)} size="sm" centered >
                <Modal.Body>
                    <div className='flex flex-col gap-3'>
                        <p>Enter Reason for Rework</p>
                        <textarea className="text-sm w-full p-2 border border-solid border-gray-300 rounded mt-2 resize-none"
                            type="text"
                            rows={3}
                            onChange={(e) => setReason(e.target.value)}
                            value={reason}
                            name="reason"
                            placeholder="Reson max 150 characters"
                            maxLength={150} />
                        <div className="flex justify-between text-center md:text-left">
                            <button className="mt-4 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white font-semibold uppercase rounded text-xs" type="submit" onClick={() => setShow(false)}>Close</button>
                            <button className="mt-4 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2  font-semibold uppercase rounded text-xs" onClick={() => { setShow(false); employee.role === 1 ? updateBranchTarget() : updateEmployeeTarget() }} type="submit">Submit</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ApproveTargets;
import React, { useState, useEffect, useContext } from "react";
import SimpleCrypto from "simple-crypto-js";
import axios from 'axios';
import { config } from '../Config/Config';
import { enqueueSnackbar } from 'notistack'
import EmployeeContext from "../context/employee/EmpContext";
import { Spinner } from "react-bootstrap";
import moment from "moment-timezone";


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


export default function AddActuals() {
    const { empState: employee } = useContext(EmployeeContext)
    const [empTarget, setEmpTarget] = useState([]);
    const [newActuals, setNewActuals] = useState([]);
    const [curYear, setCurYear] = useState("");
    const [id, setId] = useState("");
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getEmpTargets()
    }, [])
    const updateEmpActual = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            "id": id,
            "curYear": curYear,
            "targets": JSON.stringify(empTarget),
            "branchId": employee.branchId
        }
        console.log(payload)
        axios.post(config.apiUrl + 'employees/updateActuals', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                enqueueSnackbar(response.data.message, { variant: "success" })
            })
            .catch(function (error) {
                console.log("error", error)
                enqueueSnackbar(error.response.data.message, { variant: "error" })
            })
    };
    const getEmpTargets = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "branchId": employee.branchId,
            "employeeId": employee.employeeId,
            "status": 1
        }
        setLoading(true);
        axios.post(config.apiUrl + 'employees/getEmployeeForecast', payload, { headers: headers })
            .then(function (response) {
                console.log("Current Employee Actuals", response.data.getAllTargets);
                let targetsArr = response.data?.getAllTargets && response.data.getAllTargets.length > 0 ?
                    response.data.getAllTargets[0].targets : [];
                targetsArr.forEach(t => { if (t.achieved > 0) t["newActual"] = t.achieved })
                setEmpTarget(targetsArr);
                setCurYear(response.data?.getAllTargets[0]?.year)
                setId(response.data?.getAllTargets[0]?.id)
                setLoading(false)
            })
            .catch(function (error) {
                console.log("error", error)
                setLoading(false);
            })
    };
    const handleTargetChange = (e, index) => {
        let newValue = e.target.value ? e.target.value : '';
        let regex = /^\d+(\.\d{1,2})?$/;
        if (newValue && !(regex.test(newValue))) {
            return enqueueSnackbar("Please use numbers only upto 2 decimal places", { variant: 'error' })
        }
        let currentTargets = empTarget;
        currentTargets.forEach((target, idx) => {
            if (idx === index) {
                target["newActual"] = newValue ? parseFloat(newValue) : 0;
            }
        })
        setEmpTarget([...currentTargets])
    }
    if (loading) {
        return (
            <div className='mt-20 flex justify-center items-center'><Spinner animation="border" /></div>
        )
    }
    return (
        <div>
            {!empTarget || empTarget.length === 0 ?
                <div className="flex mt-20 w-[80%] h-[83.5%] items-center text-center text-lg font-semibold justify-center">
                    <div className="m-auto font-semibold">Target Not Set</div>
                </div> :
                <div className="ml-8 mt-20">
                    <div className="flex font-semibold text-md text-black">Set Actuals &nbsp;</div>
                    <div className="max-w-md">
                        <p className="font-semibold text-md text-black">Current Year {curYear}</p>
                        <div className={"mt-3 grid grid-cols-4 items-center w-full border-2 bg-gray-200 border-gray-800"}>
                            <div className='py-1 px-1 text-center h-full'>Month</div>
                            <div className='py-1 px-1  text-center h-full border-2 border-gray-800 border-top-0 border-bottom-0'>Target</div>
                            <div className='py-1 px-1 text-center h-full border-2 border-gray-800 border-left-0 border-top-0 border-bottom-0'>Forecast</div>
                            <div className='py-1 px-1 text-center h-full border-2 border-gray-800 border-top-0 border-left-0 border-right-0 border-bottom-0'>Actual</div>
                        </div>
                        {empTarget && empTarget.map((t, index) => {
                            return (
                                <div key={index} className={"grid grid-cols-4 items-center w-full border-2 border-gray-800"}>
                                    <div className={"flex py-1 px-1  bg-gray-100 text-center border-2"}>{t.id}</div>
                                    <div className='border-2 border-top-0 border-r-0 border-bottom-0 border-gray-800'>
                                        <input
                                            className={`flex py-1 w-full bg-gray-100 text-center border-2`}
                                            type="text"
                                            value={t.target}
                                            disabled={true}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className='border-2 border-top-0 border-bottom-0 border-gray-800'>
                                        <input
                                            className={"flex py-1 w-full bg-gray-100 text-center border-2"}
                                            type="text"
                                            disabled={true}
                                            value={t.forecast}
                                            readOnly={true} />
                                    </div>
                                    <div>
                                        <input
                                            className={`flex py-1 w-full bg-gray-100 text-center border-2 ${new Date().getMonth() + 1 < moment().month(t.id).format("M") || t.target === 0 ? "bg-inherit" : "bg-white"}`}
                                            type="text"
                                            disabled={new Date().getMonth() + 1 < moment().month(t.id).format("M") || t.target === 0 ? true : false}
                                            value={t.newActual || 0}
                                            onChange={(e) => handleTargetChange(e, index)} />
                                    </div>
                                </div>
                            )
                        })}
                        <div className={`flex justify-end items-center gap-3 mt-3`}>
                            <button className="bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color p-2 px-4  font-semibold uppercase rounded text-xs whitespace-nowrap" onClick={() => updateEmpActual()}>Submit</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
} {/* <div className={`flex justify-between items-center gap-3`}>
<button className="max-w-max bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color p-2 px-3 font-semibold  uppercase rounded text-xs whitespace-nowrap" onClick={() => updateEmpActual(3)}>Save</button>
<button className="bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color p-2 px-4  font-semibold uppercase rounded text-xs whitespace-nowrap" onClick={() => updateEmpActual(1)}>Submit</button>
</div>

<p className="font-semibold text-md text-black">Current Year {curYear}</p>
                        <div className={"mt-3 grid grid-cols-4 items-center w-full border-2 bg-gray-200 border-gray-800"}>
                            <div className='py-1 px-1'>Month</div>
                            <div className='py-1 px-1 border-2 text-center border-gray-800 border-top-0 border-bottom-0'>Target</div>
                            <div className='py-1 px-1 border-2 text-center border-gray-800 border-top-0 border-left-0 border-bottom-0'>Forecast</div>
                            <div className='py-1 px-1 text-center'>Actuals</div>
                        </div>*/}
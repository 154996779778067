import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LeftMenu from '../LeftMenu/LeftMenu';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import Customer from '../Customer/Customer';
import EmployeeDashBoard from '../EmployeeDashBoard/EmployeeDashBoard';
import Navbar from '../Navbar/Navbar';
import tick from "../images/tick.svg";
import BranchDashboard from './BranchDashboard';
import TargetsTable from '../Utils/TargetsTable';
import EmployeeContext from '../context/employee/EmpContext';
import AddBranchTarget from './AddBranchTargets';
import { useQuery } from 'react-query';
import MonthlyView from '../MonthlyView/MonthlyView';
import ApproveTargets from '../Utils/ApproveTargets';
import viewactivity from "../images/viewactivity.svg";
import { enqueueSnackbar } from 'notistack';
import ForceLogoutPopup from '../PopUps/ForceLogout';
import Deal from '../components/Deal/Deal';
import RewardsConfig from '../components/rewards/RewardsConfig';
import share from "../images/award.svg";
import RewardPopup from './RewardPopup';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CustomerSurvey from '../SalesPerson/CustomerSurvey';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const BranchAdmin = () => {
    const navigate = useNavigate();
    const { part } = useParams();
    const { empState: employee } = useContext(EmployeeContext)
    const [employees, setEmployees] = useState([]);
    const [activeEmployees, setActiveEmployees] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [newCustomers, setNewCustomers] = useState([]);
    const [productEmployees, setProductEmployees] = useState([]);
    const [currentProductEmployees, setCurrentProductEmployees] = useState([]);
    const [products, setProducts] = useState([]);
    const [section, setSection] = useState(part || "Employees");
    const [showForceLogOut, setShowForceLogOut] = useState(false);
    const [deals, setDeals] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rewardsFreq, setRewardsFreq] = useState([]);
    const [nonFinacialRewards, setNonFinancialRewards] = useState([]);
    const [rewardTiers, setRewardTiers] = useState([]);
    const [rewardPopup, setRewardPopup] = useState(false);
    const [rewardData, setRewardData] = useState();
    const [isRewardAvailable, setIsRewardAvailable] = useState(true);

    useEffect(() => {
        if (localStorage.getItem("junk")) {
            navigate(`/${employee.companyName}/branchadmin/Customers`)
        }
    }, []);

    useEffect(() => {
        if (section === "Product Employees")
            getAllProductEmployees();
        if (section === "Meetings")
            navigate(`/${employee.companyName}/meetings`)
    }, [section]);

    const getAllProductEmployees = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'manager/getAllProductEmployees', { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    const b = JSON.parse(JSON.stringify(response.data.employeeProducts))
                    setProductEmployees(b);
                    const employeeProducts = JSON.parse(JSON.stringify(b))
                    return employeeProducts;
                } else {
                    console.log("Error");
                }
            }).then((employeeProducts) => {
                const a = (employeeProducts.map((branch, i) => {
                    let branchProducts = branch.products && branch.products.map((product, idx) => {
                        let obj = { "productId": product, "status": 1 }
                        return obj;
                    });
                    branch["products"] = branchProducts;
                    return branch;
                }));
                setCurrentProductEmployees(a);
            })
            .catch(function (error) {
                console.log("error", error);
                if (error.response.status === 401)
                    setShowForceLogOut(true);
            });
    };
    const getAllCustomers = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        return axios.get(config.apiUrl + 'manager/getAllCutomersIdByBranchId', { headers: headers })
    };
    const { data: customersDetails, refetch: refetchCustomers } = useQuery("customers-details", getAllCustomers, {
        enabled: section === 'Customers',
        onSuccess: (response) => {
            console.log("success customers fetched from query", response);
            setCustomers(response.data.customers)
        },
        onError: (error) => {
            console.error('Error loading customers data:', error);
            if (error.response.status === 401)
                setShowForceLogOut(true);
        },
    });
    const getAllEmployeesByBranch = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        return axios.get(config.apiUrl + 'manager/getAllEmployeesByBranch', { headers: headers })
    };
    const { data: employeesDetails } = useQuery("employees-details", getAllEmployeesByBranch, {
        enabled: section === 'Employees' || section === "Customers",
        onSuccess: (response) => {
            if (response.data.status == true) {
                console.log("success employees fetched form query", response.data);
                setEmployees(response.data.employees);
                let curActiveEmps = response.data.employees.filter(emp => emp.status === 1)
                setActiveEmployees(curActiveEmps);
                setIsRewardAvailable(response.data.isAvailable)
            } else {
                console.log("Error");
            }
        },
        onError: (error) => {
            console.error('Error loading employees data:', error);
            if (error.response.status === 401)
                setShowForceLogOut(true);
        },
    });
    const getEmployeeRole = (role) => {
        if (role === 1) return "Company Admin"
        else if (role === 2) return "Branch Admin"
        else return "Relationship Manager"
    }
    const getAllProducts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            branchId: employee.branchId
        }
        console.log("payload branchProducts", payload)
        return axios.post(config.apiUrl + 'manager/getAllBranchProducts', payload, { headers: headers })
    };
    const { data: productsDetails } = useQuery("products-details", getAllProducts, {
        enabled: section === 'Product Employees',
        onSuccess: (response) => {
            if (response.data.status == true) {
                if (response.data.status == true) {
                    console.log("success all products fetched from query", response.data);
                    setProducts(response.data.products);
                } else {
                    console.log("Error");
                }
            } else {
                console.log("Error");
            }
        },
        onError: (error) => {
            console.error('Error loading products data:', error);
            if (error.response.status === 401)
                setShowForceLogOut(true);
        },
    });
    const getRewardsConfig = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        return axios.get(config.apiUrl + 'companyAdmin/getRewardsConfigByCompany', { headers: headers })
    };
    const { data: rewards_config } = useQuery("rewads-config", getRewardsConfig, {
        enabled: section === 'Rewards',
        onSuccess: (response) => {
            if (response.data.status == true) {
                console.log(response.data.message, response.data)
                setRewardsFreq(response.data.rewardsFreq);
                setNonFinancialRewards(response.data.nonFinancialRewards);
                setRewardTiers(response.data.rewardTiers)
            } else {
                console.log("Error");
            }
        },
        onError: (error) => {
            console.error('Error loading products data:', error);
            if (error.response.status === 401) setShowForceLogOut(true);
            else enqueueSnackbar("Something went wrong!!", { variant: "error" });
        },
    });
    const isAvailable = (productId, employeeId) => {
        let currentProducts = currentProductEmployees.filter(item => item.employeeId === employeeId);
        currentProducts = currentProducts[0].products.filter((p, i) => p.productId === productId && p.status === 1)
        if (currentProducts.length === 0) return false;
        return true;
    }
    const toggleAvailability = (productId, employeeId) => {
        let currentProducts = currentProductEmployees.map(item => {
            if (item.employeeId === employeeId) {
                let currentProducts = item.products.filter(product => product.productId === productId)
                if (currentProducts.length > 0) {
                    currentProducts = item.products.map(product => {
                        if (product.productId === productId) {
                            product.status = product.status === 1 ? 2 : 1;
                        }
                        return product;
                    })
                    item["products"] = currentProducts
                }
                else {
                    item["products"] = [...item.products, { "productId": productId, "status": 1 }];
                }
            }
            return item;
        });
        setCurrentProductEmployees([...currentProducts]);
    }

    const updateProductsEmployeeStatus = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            productsEmployee: JSON.stringify(currentProductEmployees)
        }
        console.log("payload", payload);
        axios.post(config.apiUrl + 'manager/updateProductEmployeeStatus', payload, { headers: headers })
            .then((response) => {
                console.log(response.data.message, response.data);
                enqueueSnackbar("Product Employees Updated Successfully!", { variant: "success" })
            }).catch(error => {
                console.log("error", error.response.data.message);
            })
    };
    const cancelProductEmployees = () => {
        const employeeProducts = JSON.parse(JSON.stringify(productEmployees))
        var a = (employeeProducts.map((b, i) => {
            let employeeProducts = b.products && b.products.map((p, idx) => {
                let obj = { "productId": p, "status": 1 }
                return obj;
            });
            b["products"] = employeeProducts;
            return b;
        }));
        setCurrentProductEmployees(a);
    }
    const togglePopup = (data) => {
        console.log("Data", data);
        const empname = data.firstName + " " + data.lastName;
        navigate(`/branchadmin/activity/${empname}`, { state: { employee: data } })

    }
    const getEmployeeDeals = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        setLoading(true)
        return axios.get(config.apiUrl + 'employees/getDealsByEmployee', { headers: headers })
    };
    const { data: dealsData, refetch } = useQuery("deals-details", getEmployeeDeals, {
        enabled: section === 'Deals',
        onSuccess: (response) => {
            if (response.data.status == true) {
                console.log(response.data.message, response.data)
                setDeals(response.data.allDeals);
            } else {
                console.log("Error");
            }
            setLoading(false)
        },
        onError: (error) => {
            console.error('Error loading products data:', error);
            enqueueSnackbar("Something went wrong!!", { variant: "error" });
            setLoading(false)
        },
    });
    const toggleRewardPopup = (data = {}) => {
        setRewardData(data);
        setRewardPopup(!rewardPopup);
    }
    function formatName(firstName, lastName) {
        let fullName = firstName + " " + lastName;
        if (fullName.length > 20) return fullName.substring(0, 20) + "...";
        return fullName;
    }
    function getCurrentQuarter() {
        let cur_month = new Date().getMonth();
        if (cur_month >= 0 && cur_month <= 2) return "Q1";
        else if (cur_month >= 3 && cur_month <= 5) return "Q2";
        else if (cur_month >= 6 && cur_month <= 8) return "Q3";
        else return "Q4";
    }
    return (
        <div className='flex bg-custom-text-color'>
            {showForceLogOut && <ForceLogoutPopup employee={employee} />}
            {rewardPopup && <RewardPopup show={rewardPopup} togglePopup={toggleRewardPopup} data={rewardData} />}
            <div className='w-[14vw] min-h-screen bg-black text-white'>
                <LeftMenu section={section} setSection={setSection} />
            </div>
            <Navbar employee={employee} role={getEmployeeRole(employee?.role)} />
            {section === "Employees" ?
                <div className='w-5/6'>
                    <div className="ml-8 mt-20">
                        <div className="text-left font-semibold text-black"><h5>Employees</h5></div>
                        <div className="mt-4 tableFixHead">
                            <table className="mt-1 border-spacing-x-0 border-spacing-y-2 border-separate" >
                                <thead className='whitespace-nowrap'>
                                    <tr>
                                        <th scope="col" className="th-heading" >Status</th>
                                        <th scope="col" className="th-heading" >Name</th>
                                        <th scope="col" className="th-heading" >Email</th>
                                        <th scope="col" className="th-heading" >Mobile</th>
                                        <th scope="col" className="th-heading" >EMP ID</th>
                                        <th scope="col" className="th-heading" >Role</th>
                                        {isRewardAvailable && <th scope="col" className="th-heading" >Week</th>}
                                        {isRewardAvailable && <th scope="col" className="th-heading" >Month</th>}
                                        {isRewardAvailable && <th scope="col" className="th-heading" >{getCurrentQuarter()}</th>}
                                        {isRewardAvailable && <th scope="col" className="th-heading text-center" >{new Date().getMonth() < 6 ? "H1" : "H2"}</th>}
                                        {isRewardAvailable && <th scope="col" className="th-heading" >Year</th>}
                                        <th scope="col" className="th-heading" ></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {employees && employees.map((data, i) => (
                                        <tr key={data.id} className="tr whitespace-nowrap">
                                            <td className="text-center w-3">
                                                <div className={`ml-3 w-3 h-3 rounded-full ${data.status === 1 ? "bg-green-600" : "bg-red-600"}`}></div>
                                            </td>
                                            <td className={`td-text ${isRewardAvailable ? "w-5" : ""}`}>{formatName(data.firstName, data.lastName)}</td>
                                            <td className={`td-text ${isRewardAvailable ? "w-56" : ""}`}>{data?.email ? simpleCrypto.decrypt(data.email) : ""}</td>
                                            <td className={`td-text ${isRewardAvailable ? "w-5" : ""}`}>{data?.mobile ? (data.mobile) : ""}</td>
                                            <td className={`td-text ${isRewardAvailable ? "w-5" : ""}`}>{data.employeeId}</td>
                                            <td className={`td-text ${isRewardAvailable ? "w-8" : ""}`}>{getEmployeeRole(data.role)}</td>
                                            {isRewardAvailable && <td className="td-text w-2 text-center">{data["weekly_points"]}</td>}
                                            {isRewardAvailable && <td className="td-text w-2 text-center">{data["monthly_points"]}</td>}
                                            {isRewardAvailable && <td className="td-text w-2 text-center">{data["quarterly_points"]}</td>}
                                            {isRewardAvailable && <td className="td-text w-2 text-center">{data["half_yearly_points"]}</td>}
                                            {isRewardAvailable && <td className="td-text w-2 text-center">{data["yearly_points"]}</td>}
                                            <td className="flex text-right items-end justify-end gap-2">
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={<Tooltip id="button-tooltip">{data.firstName}'s Activity</Tooltip>}
                                                >
                                                    <div className="px-2 icon-w text-right" onClick={() => togglePopup(data)} >
                                                        <div className="text-center items-end justify-center z-10"><img src={viewactivity} /></div></div>
                                                </OverlayTrigger>
                                                {isRewardAvailable && <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={<Tooltip id="button-tooltip">Reward {data.firstName}</Tooltip>}
                                                >
                                                    <div className='icon-w' style={employee.role === 2 && parseInt(data.dealStatus) !== 4 && parseInt(data.dealStatus) !== 5 ? { display: "flex" } : { display: "none" }}>
                                                        <img src={share} alt='' className={`cursor-pointer py-1`} onClick={() => toggleRewardPopup(data)} />
                                                    </div>
                                                </OverlayTrigger>}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> :
                (section == "Product Employees") ?
                    <div className='w-5/6'>
                        <div className="ml-8 mt-20">
                            <div className="text-left font-semibold text-black"><h5>Product Employees</h5></div>
                            <div className="mt-4 tableFixHead">
                                <table className="mt-1 border-spacing-x-0 border-spacing-y-2 border-separate" >
                                    <thead>
                                        <tr>
                                            <th scope="col" className="th-heading text-left">Product Family</th>
                                            {activeEmployees && productEmployees?.length > 0 && activeEmployees.map(emp => {
                                                if (emp.role === 3)
                                                    return (
                                                        <th key={emp.employeeId} scope="col" className="th-heading">
                                                            <div className="flex flex-col items-center">
                                                                <p>{emp.firstName}&nbsp;{emp.lastName}</p>
                                                                <p className="text-xs font-light">{emp.employeeId}</p>
                                                            </div>
                                                        </th>
                                                    )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productEmployees?.length > 0 && products.map((data, i) => (
                                            <tr key={data.id} className="tr whitespace-nowrap">
                                                <td className="td-text text-left">{data.productFamilyName}</td>
                                                {productEmployees?.length > 0 && productEmployees.map(emp => {
                                                    return (
                                                        <td key={emp.employeeId} className="td-text">
                                                            <div className="flex justify-center items-center">
                                                                <span className="w-6 h-6 border-2 border-black cursor-pointer" onClick={() => toggleAvailability(data.id, emp.employeeId)}>
                                                                    <img src={isAvailable(data.id, emp.employeeId) ? tick : ""} alt="" />
                                                                </span>
                                                            </div>
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="pr-8 flex justify-between items-center gap-3">
                                <button className="max-w-max bg-orange-600 hover:bg-orange-700 p-2 px-3 text-white font-semibold uppercase rounded text-xs whitespace-nowrap" onClick={() => cancelProductEmployees()}>Cancel</button>
                                <button className=" bg-black  hover:bg-black p-2 px-4 text-white font-semibold uppercase rounded text-xs whitespace-nowrap" onClick={() => updateProductsEmployeeStatus()}>Save</button>
                            </div>
                        </div>
                    </div> :
                    section === "Customers" ?
                        <div className='w-5/6'>
                            <Customer customers={customers} role={2} employees={activeEmployees} newCustomers={newCustomers} refetch={refetchCustomers} />
                        </div>
                        : section === "ToDoList" ?
                            <div className='w-5/6'>
                                <EmployeeDashBoard employee={employee} employees={employees} role={"Branch Admin"} />
                            </div> :
                            section === "Branch Dashboard" ?
                                <div className='w-5/6'>
                                    <BranchDashboard />
                                </div> :
                                (section === "Targets") ?
                                    <div className='w-5/6'>
                                        <TargetsTable /> </div>
                                    : (section === "SetBranchTargets") ?
                                        <div className='w-5/6'>
                                            <AddBranchTarget role={2} employee={employee} />
                                        </div>
                                        : (section === "ApproveTargets") ?
                                            <div className='w-5/6'>
                                                <ApproveTargets />
                                            </div>
                                            : (section === "Deals") ?
                                                <Deal deals={deals} loading={loading} refetch={refetch} />
                                                : (section === "Rewards") ?
                                                    <RewardsConfig rewardsFreq={rewardsFreq} nonFinancialRewards={nonFinacialRewards} rewardTiers={rewardTiers} />
                                                    : (section === "Survey") ?
                                                        <div className='w-5/6'>
                                                            <CustomerSurvey />
                                                        </div>
                                                        : <></>

            }

        </div >
    )
}

export default BranchAdmin
import React, { useContext, useEffect, useState } from 'react';
import { config } from '../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import { useNavigate } from 'react-router-dom';
import EmployeeContext from '../context/employee/EmpContext';
import axios from 'axios';
import { Modal, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { enqueueSnackbar } from 'notistack';
import viewactivity from "../images/viewactivity.svg";
import addactivity from "../images/addactivity.svg";
import details from "../images/custDetails.svg";
import reachout from "../images/reachout.svg";
import newvisit from "../images/newvisit.svg";
import visit from "../images/newvisit1.svg";
import moment from 'moment-timezone';
import AssignRm from '../BranchAdmin/AssignRMToNewCus';
import deal from "../images/deal.svg";
import reassign from "../images/reassign.svg"
import ReassignRm from '../PopUps/ReassignRM';
import survey from "../images/survey.svg"
import NewSurveyLinkPopuup from '../PopUps/NewSurveyLink';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


function TableRow({ index, role, data, setCurIndex, visible, actionTypes, getLeadType, setShowCustomerActivity, setCustomer, handleNavigateNewVisit, employees, refetch }) {
    const navigate = useNavigate();
    const { empState: employee } = useContext(EmployeeContext)
    const [showDetails, setShowDetails] = useState(false);
    const [secretKey, setSecretKey] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [reason, setReason] = useState('');
    const [errors, setErrors] = useState({});
    const [rowStatus, setRowStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [popupData, setPopupData] = useState();
    const [reAssign, setReAssign] = useState(false);
    const [newSurvey, setNewSurvey] = useState(null);
    const [showSurveyPopup, setShowSurveyPopup] = useState(false);

    useEffect(() => {
        setLoading(true);
        if (visible[index]) {
            setIsConfirmed(true);
            setShowDetails(true);
        }
        setStatus();
    }, [])
    const handleNavigateAddCustomerActivity = (data) => {
        if (data.activityStatus === 1)
            navigate(`/${employee.companyName}/customerDetails`, { state: { employee, customer: { ...data, visible: visible[index] } } })
        else
            navigate(`/${employee.companyName}/customerEvaluation`, { state: { employee, customer: data } })
    }
    const handleNavigateReachout = (data) => {
        navigate(`/${employee.companyName}/reachout`, { state: { actionTypes, customer: data } })
    }
    const verifyUsersKey = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: "error" })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            companyName: employee.companyName,
            employeeName: employee.firstName + " " + employee.lastName,
            customerId: data.customerId,
            customerName: data.firstName + " " + data.lastName,
            secretKey: secretKey,
            reason: reason
        }
        axios.post(config.apiUrl + 'employees/customerDataAccessRequest', payload, { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    setIsConfirmed(true);
                    setShowDetails(true);
                    visible[index] = true
                } else {
                    console.log("Error");
                }
            })
            .catch(error => {
                enqueueSnackbar(error.response.data.message, { variant: "error" })
                setIsConfirmed(false);
                setShowDetails(false);
            })
    };
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!secretKey) {
            errorObj = {
                ...errorObj,
                secretKeyError: true
            }
            error = true;
        }
        if (!reason) {
            errorObj = {
                ...errorObj,
                reasonError: true
            };
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    const setStatus = () => {
        let currentStatus = ""
        if (!data.visitId) currentStatus = "";
        let todaysRecord = moment(data.visitDate).format("YYYY-MM-DD") === moment(new Date(Date.now())).format("YYYY-MM-DD");
        if (data.visitId && !todaysRecord) currentStatus = "red";
        if (data.visitId && todaysRecord && data.activityStatus === 1) currentStatus = "green";
        if (data.visitId && todaysRecord && data.activityStatus === 2) currentStatus = "orange";
        setRowStatus(currentStatus);
        setLoading(false);
    }
    const togglePopup = (data = {}) => {
        if (!data.visitId || !data.activityId || data.employeeId) return;
        setShowPopup(!showPopup)
        setPopupData(data)
    }
    const closePopup = (data = {}) => {
        setShowPopup(false)
    }
    const addNewDeal = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "branchName": employee.branchName,
            "branchId": employee.branchId,
            "companyId": employee.companyId,
            "customerId": data?.customerId || data?.id,
            "interaction": 3,
            "userActivity": 10,
            "salesmanId": employee.id,
            "userDemand": 1,
            "customerSatisfaction": 5,
            "customerPotential": 5,
            "customersInterest": "",
            "employeeId": employee.employeeId,
            "occasion": 1,
            "source": 4,
            "visitId": data?.visitId,
            "activityId": data?.activityId
        }
        console.log("payload", payload);
        axios.post(config.apiUrl + 'employees/addCustomerToNewDeal', payload, { headers: headers })
            .then(function (response) {
                console.log("deal created successfully", response.data);
                enqueueSnackbar("New Deal Added Successfully!", { variant: "success" });
                refetch();
            })
            .catch(function (error) {
                console.log("error", error);
                let errMsg = error?.response?.data?.message || "Unable to add deal now."
                enqueueSnackbar(errMsg.toUpperCase(), { variant: "error" });
            });
    };
    const reAssignRm = () => {
        setReAssign(!reAssign)
    }
    if (loading) {
        return (
            <div className='mt-20 flex justify-center items-center'><Spinner animation="border" /></div>
        )
    }
    const getSurveyFormLink = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let url = `${employee.companyName.replace(/\s/g, "")}/${employee.uniqueCode}/surveyform?survey_id=`
        let payload = {
            "employeeId": employee.id,
            "customerId": data.customerId || data.id,
            "companyId": employee.companyId,
            "status": 1,
            "name": data.firstName,
            "mobile": simpleCrypto.decrypt(data.mobile),
            "link": url,
            "companyName": employee.companyName
        }
        axios.post(config.apiUrl + 'employees/getSurveyFormLink', payload, { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    console.log("new survey link generated", response.data)
                    setNewSurvey(response.data.newResponse);
                    toggleSurveyPopup(response.data.newResponse);
                } else {
                    console.log("Error");
                }
            })
            .catch(error => {
                // console.log(error)
                enqueueSnackbar(error?.response?.data?.message, { variant: "error" })
            })
    }
    const toggleSurveyPopup = (data = {}) => {
        console.log("new survey link generated asasa", data)
        setShowSurveyPopup(!showSurveyPopup);
        setNewSurvey(data)
    }
    return (
        <tr className='tr whitespace-nowrap'>
            {showPopup && <AssignRm activeEmployees={employees} data={popupData} closePopup={closePopup} />}
            {reAssign && <ReassignRm data={data} closePopup={reAssignRm} activeEmployees={employees} refetch={refetch} />}
            {showSurveyPopup && <NewSurveyLinkPopuup togglePopup={toggleSurveyPopup} newSurvey={newSurvey} />}
            <td className='flex items-center ml-3 mt-1'>
                <div className={`w-3 h-3 rounded-full ${rowStatus === "red" ? "bg-red-600" : rowStatus === "green" ? "bg-green-600" : rowStatus === "orange" ? "bg-orange-600" : data.deal ? "bg-purple-700" : "bg-blue-500"}`}
                ></div>
            </td>
            <td className={`td-text ${employee.businessType === 1 ? "" : "hidden"}`}>{data.companyName ? data.companyName : ""}</td>
            <td className={`td-text ${!data.firstName && !data.lastName ? "text-center" : ""}`}>{data.firstName ? data.firstName : "-"}&nbsp;{data.lastName ? data.lastName : ""}</td>
            <td className={`td-text ${data.employeeId ? "" : "cursor-pointer"} ${role === 2 ? "" : "hidden"}`} onClick={() => togglePopup(data)}>{data.employeeName && data.employeeId ? data.employeeName + " (" + data.employeeId + ")" : ""}</td>
            <td>
                {isConfirmed ? (
                    showDetails ? (
                        data?.email ? simpleCrypto.decrypt(data.email) : "undefined"
                    ) : (
                        <button onClick={() => setShowDetails(true)}>View Details</button>
                    )
                ) : (
                    <>{showDetails ?
                        <Modal show={showDetails} onHide={() => setShowDetails(false)} size="sm" centered >
                            <Modal.Header closeButton className='m-0 p-0 text-lg font-semibold'>
                                Enter Reason With Your Key
                            </Modal.Header>
                            <Modal.Body className='flex flex-col justify-center items-center -mt-4 gap-5'>
                                <input
                                    className={`border-2 w-full ${errors.secretKeyError ? "border-red-600" : "border-black"} rounded-md`}
                                    type="password"
                                    placeholder="Enter Secret Key"
                                    value={secretKey}
                                    onChange={(e) => { setSecretKey(e.target.value); setErrors({ ...errors, secretKeyError: false }) }}
                                />
                                <select name="age" value={reason} className={`border-2 w-full ${errors.reasonError ? "border-red-600" : "border-black"} rounded-md`}
                                    onChange={(e) => { setReason(e.target.value); setErrors({ ...errors, reasonError: false }) }} >
                                    <option value="">---Select Reason---</option>
                                    <option value="For Calling the customer">For Calling the customer</option>
                                    <option value="For reaching out to the customer">For reaching out to the customer</option>
                                    <option value="For sending mail to the customer">For sending mail to the customer</option>
                                </select>
                                <button className="mt-8 self-end border-2 rounded-md bg-black text-white p-2 text-xs uppercase" onClick={verifyUsersKey}>Submit</button>
                            </Modal.Body>
                        </Modal>
                        : <button onClick={() => setShowDetails(true)}>View Details</button>
                    }
                    </>
                )}
            </td>
            <td>
                {isConfirmed ? (
                    showDetails ? (
                        data.mobile ? simpleCrypto.decrypt(data.mobile) : "undefined"
                    ) : (
                        <button onClick={() => setShowDetails(true)}>View Details</button>
                    )
                ) : (
                    <>**********</>
                )}
            </td>
            <td className="td-text">{data?.leadType ? getLeadType(data.leadType) : ""}</td>
            <td className="td-text whitespace-nowrap">{data.updatedAt?.split("T")[0].split("-").reverse().join("-")}</td>
            <td className="flex text-right items-end justify-end">
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip">Survey link to {data.firstName}</Tooltip>}
                >
                    <div className={`${role === 3 && employee.survey_service ? "px-2 icon-w text-right" : "hidden"}`} onClick={() => getSurveyFormLink()}>
                        <div className={`${role === 3 ? "text-center items-end justify-center" : "hidden"}`}><img src={survey} className='w-4 h-4' /></div>
                    </div>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip">{data.firstName} Activity Till Today</Tooltip>}
                >
                    <div className="ml-2 px-2 icon-w text-right" onClick={() => { setShowCustomerActivity(true); setCustomer(data); setCurIndex(index) }}>
                        <div className="text-center items-end justify-center"><img src={viewactivity} /></div>
                    </div>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip">{data?.activityStatus === 1 ? "Customer Details" : "Customer Evaluation"}</Tooltip>}
                >
                    <div className={`${role === 3 && data.visitId ? "ml-2 px-2 icon-w text-right" : "hidden"}`} onClick={() => handleNavigateAddCustomerActivity(data)}>
                        <div className={`${role === 3 && data.visitId ? "text-center items-end justify-center" : "hidden"}`}><img src={data?.activityStatus === 1 ? details : addactivity} /></div>
                    </div>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip">Add {data.firstName}'s new Visit</Tooltip>}
                >
                    <div className={`${role === 2 && !data.visitId ? "ml-2 px-2 icon-w text-right" : "hidden"} ${data.visitId ? "bg-gray-200" : "cursor-pointer"}`} disabled={data.visitId ? true : false} onClick={() => { data.visitId ? console.log("no") : handleNavigateNewVisit(data) }}>
                        <div className={`${role === 2 && !data.visitId ? "text-center items-end justify-center" : "hidden"} ${data.visitId ? "bg-gray-200" : "cursor-pointer"}`}><img src={data.visitId ? visit : newvisit} /></div>
                    </div>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip">Assign to different RM</Tooltip>}
                >
                    <div className={`${role === 2 && data.visitId ? "ml-2 px-2 icon-w text-right cursor-pointer" : "hidden"}`} onClick={() => { reAssignRm(data) }}>
                        <div className={`${role === 2 && data.visitId ? "text-center items-end justify-center cursor-pointer" : "hidden"}`}><img src={reassign} /></div>
                    </div>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip">Reachout to {data.firstName}</Tooltip>}
                >
                    <div className={`${role === 3 && !data.visitId ? "ml-2 px-2 icon-w text-right" : "hidden"}`} onClick={() => handleNavigateReachout(data)}>
                        <div className={`${role === 3 && !data.visitId ? "text-center items-end justify-center" : "hidden"}`}><img src={reachout} /></div>
                    </div>
                </OverlayTrigger>

                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip">Move to Deal</Tooltip>}
                >
                    <div className={`${role === 3 ? "ml-2 px-2 icon-w text-right" : "hidden"}`} onClick={() => addNewDeal()}>
                        <div className={`${role === 3 ? "text-center items-end justify-center" : "hidden"}`}><img src={deal} /></div>
                    </div>
                </OverlayTrigger>

            </td>
        </tr >
    );
}

export default TableRow;

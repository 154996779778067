import React, { useEffect, useState } from 'react'
import { Modal, Row, Col, Carousel } from 'react-bootstrap';
import register from '../images/register-step-1.svg';
import login from '../images/login-step2.svg';


export default function RegisterProcess({ showRegisterPopup, closePopup }) {
    const [section, setSection] = useState(0)
    const [totalSections, setTotalSections] = useState(2)
    useEffect(() => {
        let interval = setInterval(() => {
            let newSection = (section + 1) % totalSections
            setSection(newSection)
        }, 10000)
        return () => clearInterval(interval)
    }, [section])
    return (
        <Modal show={showRegisterPopup} size='lg'
            onHide={closePopup}
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Body>
                <div className='mx-2'>
                    <center className='my-3 font-semibold text-xl'>Registration Process</center>
                    <div className="ml-3 mr-3 my-3 sec-display">
                        <div className={`sec-color`} onClick={() => setSection(0)}><div className={`${section === 0 ? "bg-color" : ""}`}></div></div>
                        <div className={`sec-color`} onClick={() => setSection(1)}><div className={`${section === 1 ? "bg-color" : ""}`}></div></div>
                    </div>
                    {section === 0 ?
                        <div class="flex w-full h-[55vh] flex-col md:flex-row gap-4 items-center justify-center">
                            <div class="w-1/2 p-2 flex-1 h-[45vh]">
                                <p className='text-left text-2xl font-semibold'>Step 1</p>
                                <p className='mt-2 text-left text-base'>Upon successful OTP verification, a confirmation email is sent to the user's registered email.</p>
                                <p className='mt-2 text-left text-base'>Click on the link, to navigate to Login screen.</p>
                                <p className='mt-2 text-left text-base'>Click on <b>SIGNUP</b> button to identify yourself for the first time and set user identifier, password of your choice.</p>
                                <p className='mt-2 text-left text-base'>Enter your details given at the time of registeration.</p>
                            </div>
                            <div class="w-1/2 p-3 flex-1 ">
                                <img src={register} className='w-full' />
                            </div>
                        </div>
                        :
                        <div class="flex w-full h-[55vh] flex-col md:flex-row gap-4 items-center justify-center">
                            <div class="w-1/2 p-2 flex-1 h-[45vh]">
                                <p className='text-left text-2xl font-semibold'>Step 2</p>
                                <p className='mt-2 text-left text-base'>Upon successful signup, user will be redirected to Login screen.</p>
                                <p className='mt-2 text-left text-base'>Use credentials (user identifier and password) to login into the system.</p>
                                <p className='mt-2 text-left text-base'>This process of two steps should be followed by all employees of your business.</p>
                            </div>
                            <div class="w-1/2 p-3 flex-1 ">
                                <img src={login} className='w-full' />
                            </div>
                        </div>
                    }
                </div>
            </Modal.Body>
        </Modal >
    )
}

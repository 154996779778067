import React, { useContext, useEffect, useState } from 'react'
import EditTargetsTable from './EditTargetsTable';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { config } from '../Config/Config';
import moment from 'moment-timezone';
import TargetsBox from './TargetsBox';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import EmployeeContext from '../context/employee/EmpContext';
import { Spinner } from 'react-bootstrap';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)
const TargetsTable = () => {
    const { empState: employee } = useContext(EmployeeContext)
    const [totals, setTotals] = useState([]);
    const [achieved, setAchieved] = useState([]);
    const [showEdit, setShowEdit] = useState(false);
    const [cTargets, setCTargets] = useState([])
    const [eTargets, setETargets] = useState([])
    const [companyTargets, setCompanyTargets] = useState([])
    const [targets, setTargets] = useState([])
    const navigate = useNavigate();
    const [parentTarget, setParentTarget] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        console.log("emp", employee);
        setLoading(true)
        if (employee.role === 1) {
            getCompanyTargets()
            getTargetsByCompanyAdmin()
        } else if (employee.role === 2) {
            getAllBranchTargets()
            getTargetsByEmployee();
        } else {
            getSalespersonTargets();
        }
    }, [])
    useEffect(() => {
        let newTargets;
        if (employee.role === 1) {
            newTargets = companyTargets
        } else if (employee.role === 3) {
            newTargets = targets
        } else {
            newTargets = cTargets && cTargets.length > 0 ? [...cTargets] : []
        }
        let targetsArray = newTargets.map(t => t.targets)
        const totalCounts = targetsArray.reduce((acc, arr) => {
            arr.forEach((obj) => {
                const existingObj = acc.find((item) => item.id === obj.id);
                if (existingObj) {
                    existingObj.totalCount = parseFloat((existingObj.totalCount + obj.target).toFixed(2));
                } else {
                    acc.push({ id: obj.id, totalCount: obj.target });
                }
            });
            return acc;
        }, []);
        const totalAchieved = targetsArray.reduce((acc, arr) => {
            arr.forEach((obj) => {
                const existingObj = acc.find((item) => item.id === obj.id);
                if (existingObj) {
                    existingObj.totalAchieved = parseFloat((existingObj.totalAchieved + obj.achieved).toFixed(2));
                } else {
                    acc.push({ id: obj.id, totalAchieved: obj.achieved });
                }
            });
            return acc;
        }, []);
        setTotals(totalCounts);
        setAchieved(totalAchieved)
    }, [targets, cTargets, companyTargets])
    const getTotalTrend = () => {
        let totalTrend;
        if (employee.role === 1) {
            totalTrend = companyTargets
        } else if (employee.role === 3) {
            totalTrend = targets
        } else {
            totalTrend = cTargets
        }
        let cMonth = new Date().getMonth()
        let result = 0;
        totalTrend = totalTrend && totalTrend.length > 0 ? totalTrend[0].targets : []
        totalTrend.forEach((data, i) => {
            if (i < cMonth) {
                result += data.achieved - data.forecast
            }
        })
        return result;
    }
    const getAllBranchTargets = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "branchId": employee?.branchId
        }
        axios.post(config.apiUrl + 'manager/getBranchForecast', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log(response.data.message, response.data);
                    setCTargets(response.data.getAllTargets || []);
                    response.data.getAllTargets && response.data.getAllTargets?.length > 0 ? setParentTarget(response.data.getAllTargets[0].parentTarget) : setParentTarget([]);
                } else {
                    console.log("Error");
                }
                setLoading(false);
            })
            .catch(function (error) {
                console.log("error", error);
                setLoading(false);
            });
    }
    const getTargetsByEmployee = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'manager/getTargetsByEmployee', { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log(response.data.message, response.data);
                    setETargets(response.data.getAllTargets);
                } else {
                    console.log("Error");
                }
                setLoading(false);
            })
            .catch(function (error) {
                console.log("error", error);
                setLoading(false);
            });
    }
    const getCompanyTargets = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "companyId": employee?.companyId
        }
        axios.post(config.apiUrl + 'companyAdmin/getCompanyForecast', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    setCompanyTargets(response.data.getAllTargets);
                } else {
                    console.log("Error");
                }
                setLoading(false)
            })
            .catch(function (error) {
                console.log("error", error);
                setLoading(false)
            });
    };
    const getTargetsByCompanyAdmin = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getTargetsByCompanyAdmin', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    setTargets(response.data.getAllTargets || []);
                } else {
                    console.log("Error");
                }
                setLoading(false)
            })
            .catch(function (error) {
                console.log("error", error);
                setLoading(false)
            });
    };
    const getSalespersonTargets = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "branchId": employee?.branchId,
            "employeeId": employee?.employeeId,
            "status": 1
        }
        axios.post(config.apiUrl + 'employees/getEmployeeForecast', payload, { headers: headers })
            .then(function (response) {
                console.log(response.data.message, response.data);
                if (response.data.status == true) {
                    setTargets(response.data.getAllTargets || []);
                    response.data.getAllTargets && response.data.getAllTargets.length > 0 ? setParentTarget(response.data.getAllTargets[0]?.parentTarget) : setParentTarget([]);
                } else {
                    console.log("Error");
                }
                setLoading(false)
            })
            .catch(function (error) {
                console.log("error", error);
                setLoading(false)
            });
    };
    const togglePopup = () => {
        setShowEdit(!showEdit);
    }
    if (loading) {
        return (
            <div className='mt-20 flex justify-center items-center'><Spinner animation="border" /></div>
        )
    }
    return (
        <>
            {showEdit && <EditTargetsTable targets={employee.role === 1 ? companyTargets : (employee.role === 2) ? cTargets : targets} closePopup={togglePopup} getAllTargets={employee.role === 1 ? getCompanyTargets : employee.role === 2 ? getAllBranchTargets : getSalespersonTargets} />}
            {(companyTargets?.length === 0 && cTargets?.length === 0 && targets?.length === 0) || (companyTargets[0]?.status !== 1 && cTargets[0]?.status !== 1 && targets[0]?.status !== 1) ?
                <div className='flex justify-center items-center mt-20 font-semibold text-lg'>No Targets Set</div>
                :
                <div className="ml-8 mt-20">
                    <div className="flex flex-col justify-between">
                        <div className="text-left font-semibold text-black"><h5>Forecasts</h5></div>
                        <div className="-mt-5 px-8 text-right">
                            <button onClick={togglePopup} className={"bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs -mr-7"}>UPDATE</button>
                        </div>
                    </div>
                    < div className="mt-3">
                        <table className="table-striped" >
                            <thead className='bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color'>
                                <tr className="whitespace-nowrap">
                                    <th scope="col" className="th-heading">{employee.role === 1 ? "Head Office" : (employee.role === 3) ? <>{employee?.branchName} </> : <>{employee?.branchName}</>}</th>
                                    <th scope="col" className="th-heading text-center" style={"Jan" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Jan</th>
                                    <th scope="col" className="th-heading text-center" style={"Feb" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Feb</th>
                                    <th scope="col" className="th-heading text-center" style={"Mar" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Mar</th>
                                    <th scope="col" className="th-heading text-center" style={"Apr" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Apr</th>
                                    <th scope="col" className="th-heading text-center" style={"May" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>May</th>
                                    <th scope="col" className="th-heading text-center" style={"Jun" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Jun</th>
                                    <th scope="col" className="th-heading text-center" style={"Jul" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Jul</th>
                                    <th scope="col" className="th-heading text-center" style={"Aug" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Aug</th>
                                    <th scope="col" className="th-heading text-center" style={"Sep" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Sep</th>
                                    <th scope="col" className="th-heading text-center" style={"Oct" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Oct</th>
                                    <th scope="col" className="th-heading text-center" style={"Nov" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Nov</th>
                                    <th scope="col" className="th-heading text-center" style={"Dec" === moment(new Date(Date.now())).format("MMM") ? { backgroundColor: "#ff942c" } : { backgroundColor: "" }}>Dec</th>
                                    <th scope="col" className="th-heading text-center" >Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {employee.role === 3 ?
                                    cTargets.length === 0 && targets.length === 0 ? "No targets" :
                                        <>
                                            <tr className="whitespace-nowrap">
                                                <td className="td-text">Branch Forecast</td>
                                                {parentTarget && parentTarget.map((t, i) => (
                                                    <td className="td-text text-center" key={`${t.id} targets`}>{t.forecast}</td>
                                                ))}
                                                <td className="td-text text-center">{parentTarget && parentTarget.map((t) => t.forecast).reduce((a, b) => parseFloat((a + b).toFixed(2)), 0)}</td>
                                            </tr>
                                            {targets && targets.map((data, i) => {
                                                return (
                                                    <tr key={`${data.id ? data.id : i} targetData`} className="whitespace-nowrap">
                                                        <td className="td-text">Target (T)</td>
                                                        {data.targets.map((t, i) => (
                                                            <td className="td-text text-center" key={`${t.id} targets`}>{t.target}</td>
                                                        ))}
                                                        <td className="td-text text-center">{data.targets.map((t) => t.target).reduce((a, b) => parseFloat((a + b).toFixed(2)), 0)}</td>
                                                    </tr>
                                                )
                                            })}
                                            {targets?.map((data, i) => {
                                                return (
                                                    <tr key={`${data.id ? data.id : i} targetData`} className="whitespace-nowrap">
                                                        <td className="td-text">Forecast (F)</td>
                                                        {data.targets.map((t, i) => (
                                                            <td className="td-text text-center" key={`${t.id} targets`}>{t.forecast}</td>
                                                        ))}
                                                        <td className="td-text text-center">{data.targets.map((t) => t.forecast).reduce((a, b) => parseFloat((a + b).toFixed(2)), 0)}</td>
                                                    </tr>
                                                )
                                            })}
                                            {targets?.map((data, i) => {
                                                return (
                                                    <tr key={`${data.id ? data.id : i} targetData`} className="whitespace-nowrap">
                                                        <td className="td-text">Actual (A)</td>
                                                        {data.targets.map((t, i) => (
                                                            <td className="td-text text-center" key={`${t.id} targets`}>{t.achieved}</td>
                                                        ))}
                                                        <td className="td-text text-center">{data.targets.map((t) => t.achieved).reduce((a, b) => parseFloat((a + b).toFixed(2)), 0)}</td>
                                                    </tr>
                                                )
                                            })}
                                            <tr className={"whitespace-nowrap"} >
                                                <td className="td-text">Trend (A-F)</td>
                                                {achieved.map((t, i) => (
                                                    <td className="text-center td-text" key={`${i} achieved`}>
                                                        {new Date().getMonth() + 1 <= moment().month(t.id).format("M") ? 0 :
                                                            <span>
                                                                {t.totalAchieved - totals[i].totalCount} {t.totalAchieved - totals[i].totalCount < 0 ? <i className="fa fa-caret-down " aria-hidden="true" style={{ color: "red" }}></i> : <i className="fa fa-caret-up" aria-hidden="true" style={{ color: "green" }}></i>}
                                                            </span>
                                                        }
                                                    </td>
                                                ))}
                                                <td className="td-text text-center"> {getTotalTrend()}</td>
                                            </tr>
                                        </>
                                    : (employee.role === 2) ?
                                        <>
                                            <tr className="whitespace-nowrap">
                                                <td className="td-text">{employee.role === 1 ? "Target" : (employee.role === 3) ? "Target" : "HO Forecast"}</td>
                                                {parentTarget && parentTarget.map((t, i) => (
                                                    <td className="td-text text-center" key={`${t.id} targets`}>{t.forecast}</td>
                                                ))}
                                                <td className="td-text text-center">{parentTarget && parentTarget.map((t) => t.forecast).reduce((a, b) => parseFloat((a + b).toFixed(2)), 0)}</td>
                                            </tr>
                                            {cTargets && cTargets.map((data, i) => {
                                                return (
                                                    <tr key={`${data.id ? data.id : i} targetData`} className="whitespace-nowrap">
                                                        <td className="td-text">Target (T)</td>
                                                        {data.targets.map((t, i) => (
                                                            <td className="td-text text-center" key={`${t.id} targets`}>{t.target}</td>
                                                        ))}
                                                        <td className="td-text text-center">{data.targets.map((t) => t.target).reduce((a, b) => parseFloat((a + b).toFixed(2)), 0)}</td>
                                                    </tr>
                                                )
                                            })}
                                            {cTargets && cTargets.map((data, i) => {
                                                return (
                                                    <tr key={`${data.id ? data.id : i} targetData`} className="whitespace-nowrap">
                                                        <td className="td-text">Forecast (F)</td>
                                                        {data.targets.map((t, i) => (
                                                            <td className="td-text text-center" key={`${t.id} targets`}>{t.forecast}</td>
                                                        ))}
                                                        <td className="td-text text-center">{data.targets.map((t) => t.forecast).reduce((a, b) => parseFloat((a + b).toFixed(2)), 0)}</td>
                                                    </tr>
                                                )
                                            })}
                                            {cTargets && cTargets.map((data, i) => {
                                                return (
                                                    <tr key={`${data.id ? data.id : i} targetData`} className="whitespace-nowrap">
                                                        <td className="td-text">Actual (A)</td>
                                                        {data.targets.map((t, i) => (
                                                            <td className="td-text text-center" key={`${t.id} targets`}>{t.achieved}</td>
                                                        ))}
                                                        <td className="td-text text-center">{data.targets.map((t) => t.achieved).reduce((a, b) => parseFloat((a + b).toFixed(2)), 0)}</td>
                                                    </tr>
                                                )
                                            })}
                                            <tr className={"whitespace-nowrap"} >
                                                <td className="td-text">Trend (A-F)</td>
                                                {achieved.map((t, i) => (
                                                    <td className="text-center td-text" key={`${i} achieved`}>
                                                        {new Date().getMonth() + 1 <= moment().month(t.id).format("M") ? 0 :
                                                            <span>
                                                                {t.totalAchieved - totals[i].totalCount} {t.totalAchieved - totals[i].totalCount < 0 ? <i className="fa fa-caret-down " aria-hidden="true" style={{ color: "red" }}></i> : <i className="fa fa-caret-up" aria-hidden="true" style={{ color: "green" }}></i>}
                                                            </span>
                                                        }
                                                    </td>
                                                ))}
                                                <td className="td-text text-center"> {getTotalTrend()}</td>
                                            </tr>
                                        </>
                                        :
                                        companyTargets.length === 0 ? "Targets not set" :
                                            <>
                                                {companyTargets.map((data, i) => {
                                                    return (
                                                        <tr key={`${data.id ? data.id : i} targetData`} className="whitespace-nowrap">
                                                            <td className="td-text">Target (T)</td>
                                                            {data.targets.map((t, i) => (
                                                                <td className="td-text text-center" key={`${t.id} targets`}>{t.target}</td>
                                                            ))}
                                                            <td className="td-text text-center">{data.targets.map((t) => t.target).reduce((a, b) => parseFloat((a + b).toFixed(2)), 0)}</td>
                                                        </tr>
                                                    )
                                                })}
                                                {companyTargets.map((data, i) => {
                                                    return (
                                                        <tr key={`${data.id ? data.id : i} targetData`} className="whitespace-nowrap">
                                                            <td className="td-text">Forecast (F)</td>
                                                            {data.targets.map((t, i) => (
                                                                <td className="td-text text-center" key={`${t.id} targets`}>{t.forecast}</td>
                                                            ))}
                                                            <td className="td-text text-center">{data.targets.map((t) => t.forecast).reduce((a, b) => parseFloat((a + b).toFixed(2)), 0)}</td>
                                                        </tr>
                                                    )
                                                })}
                                                {companyTargets.map((data, i) => {
                                                    return (
                                                        <tr key={`${data.id ? data.id : i} targetData`} className="whitespace-nowrap">
                                                            <td className="td-text">Actual (A)</td>
                                                            {data.targets.map((t, i) => (
                                                                <td className="td-text text-center" key={`${t.id} targets`}>{t.achieved}</td>
                                                            ))}
                                                            <td className="td-text text-center">{data.targets.map((t) => t.achieved).reduce((a, b) => parseFloat((a + b).toFixed(2)), 0)}</td>
                                                        </tr>
                                                    )
                                                })}
                                                <tr className={"whitespace-nowrap"} >
                                                    <td className="td-text">Trend (A-F)</td>
                                                    {achieved.map((t, i) => (
                                                        <td className="text-center td-text" key={`${i} achieved`}>
                                                            {new Date().getMonth() + 1 <= moment().month(t.id).format("M") ? 0 :
                                                                <span>
                                                                    {t.totalAchieved - totals[i].totalCount} {t.totalAchieved - totals[i].totalCount < 0 ? <i className="fa fa-caret-down " aria-hidden="true" style={{ color: "red" }}></i> : <i className="fa fa-caret-up" aria-hidden="true" style={{ color: "green" }}></i>}
                                                                </span>
                                                            }
                                                        </td>
                                                    ))}
                                                    <td className="td-text text-center"> {getTotalTrend()}</td>
                                                </tr>
                                            </>
                                }

                            </tbody>
                        </table>
                    </div>
                    {employee.role === 1 ?
                        <div className='mt-3 grid grid-cols-3 gap-8'>
                            {targets.map((data, i) => {
                                return (
                                    <TargetsBox key={i} employee={employee} data={data} role={employee.role} getAllTargets={getTargetsByCompanyAdmin} />
                                )
                            })}
                        </div>
                        : employee.role === 2 ?
                            <div className='mt-3 grid grid-cols-3 gap-8'>
                                {eTargets.map((data, i) => {
                                    return (
                                        <TargetsBox key={i} data={data} role={employee.role} />
                                    )
                                })}
                            </div> : <></>}
                </div >
            }
        </>
    )
}
export default TargetsTable;
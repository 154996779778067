import rating1 from "../images/emoji red-01.svg";
import rating2 from "../images/emoji orange.svg";
import rating3 from "../images/emoji yellow.svg";
import rating4 from "../images/emoji light green.svg";
import rating5 from "../images/emoji green.svg";
import React, { useEffect, useState } from 'react'

function DynamicRating({ rating }) {
    // const [percentage, setPercentage] = useState(0);
    // useEffect(() => {
    //     let currentPercenatge = 100;
    //     currentPercenatge = parseFloat((rating / 5) * 100);
    //     console.log("current percentage", currentPercenatge);
    //     setPercentage(currentPercenatge);
    // }, [])

    // useEffect(() => {
    //     const elementsToFill = document.querySelectorAll('.rating-element-to-fill');
    //     elementsToFill.forEach(element => {
    //         element.setAttribute('fill', 'url(#gradient)');
    //     });
    // }, [percentage]);

    return (
        <div className='flex'>
            <img className="w-20 h-20" src={rating >= 0 && rating <= 1 ? rating1 : rating > 1 && rating <= 2 ? rating2 : rating > 2 && rating <= 3 ? rating3 : rating > 3 && rating <= 4 ? rating4 : rating5} alt="" />
            {/* <svg style={{ display: 'hidden', width: "0", height: "0" }}>
                <defs>
                    <linearGradient id="gradient">
                        <stop offset={`${percentage}%`} stopColor={'gray'} />
                        <stop offset={`${percentage}%`} stopColor="black" />
                    </linearGradient>
                </defs>
            </svg> */}
        </div>
    )
}

export default DynamicRating

import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import { enqueueSnackbar } from 'notistack'
import EmployeeContext from "../context/employee/EmpContext";
import RespondCase from "./RespondCase";
import respond from '../images/respond.svg';
import viewicon from '../images/viewicon.svg';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const AllCases = () => {
    const navigate = useNavigate();
    const locations = useLocation();
    const { empState: employee } = useContext(EmployeeContext)
    const [cases, setCases] = useState([]);
    const [severityData, setSeverityData] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const [caseTypeData, setCaseTypeData] = useState([]);
    const [showView, setShowView] = useState(false);
    const [viewData, setViewData] = useState();

    useEffect(() => {
        console.log("useEffect called");
        // getAllCasesReferenceTablesData();
        getCompanyCases();
    }, [])

    const handleView = (data) => {
        // navigate(`/viewcase`, { state: data })
        setShowView(!showView)
        setViewData(data)

    }
    const togglePopup = () => {
        setShowView(!showView)
    }
    const handleRespond = (data) => {
        navigate(`/respondcase`, { state: data })
    }

    const getAllCasesReferenceTablesData = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getAllCasesReferenceTablesData', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, response.data);
                    setSeverityData(response.data.severity);
                    setStatusData(response.data.caseStatus);
                    setCaseTypeData(response.data.caseType);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const getCompanyCases = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getAllCompanyCases', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, response.data);
                    setCases(response.data.cases);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    return (
        <div className="ml-8 mt-20">
            {showView && <RespondCase data={viewData} closePopup={togglePopup} />}
            <div className="flex flex-col justify-between">
                <div className="text-left font-semibold text-black"><h5>Cases</h5></div>

            </div>

            <div className="mt-3 tableFixHead">
                <table className="border-spacing-x-0 border-spacing-y-2 border-separate" >
                    <thead>
                        <tr className="whitespace-nowrap">
                            <th scope="col" className="th-heading" >S.No</th>
                            <th scope="col" className="th-heading" >Client Name</th>
                            <th scope="col" className="th-heading" >Severity</th>
                            <th scope="col" className="th-heading" >Company Name</th>
                            <th scope="col" className="th-heading" >Type</th>
                            <th scope="col" className="th-heading" >Issue Date</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cases.length === 0 ?
                            <tr className="tr whitespace-nowrap">
                                <td className="td-text"></td>
                                <td className="td-text"></td>
                                <td className="td-text"></td>
                                <td className="td-text text-lg font-semibold">No Cases</td>
                                <td className="td-text"></td>
                                <td className="td-text"></td>
                                <td className=""></td>
                            </tr>
                            : <>
                                {cases && cases.map((data, i) => {
                                    return (
                                        <tr key={data.id} className="tr whitespace-nowrap">
                                            <td className="td-text">{i + 1}</td>
                                            <td className="td-text">{data.clientName}</td>
                                            <td className="td-text">{data.severityData}</td>
                                            <td className="td-text">{data.name}</td>
                                            <td className="td-text">{data.userType}</td>
                                            <td className="td-text">{data.createdAt?.split("T")[0].split("-").reverse().join("-")}</td>
                                            {/* <td className="h-full bg-gray-200 rounded-r-3xl w-2 p-0 ml-4">
                                                {data.status === 1 ?
                                                    <div onClick={() => handleView(data)} className="m-auto w-6 text-center font-semibold text-xs cursor-pointer"><img src={respond} className='w-10' /></div>
                                                    : <div onClick={() => handleView(data)} className="m-auto text-center font-semibold text-xs cursor-pointer"><i class="fa fa-eye fa-lg" aria-hidden="true"></i></div>}
                                            </td> */}
                                            <td className="flex text-right items-end justify-end">
                                                {data.status === 1 ?
                                                    <div className="px-2 icon-w text-right" onClick={() => handleView(data)}>
                                                        <div className="text-center items-end justify-center"><img src={respond} /></div>
                                                    </div> :
                                                    <div className="ml-2 px-2 icon-w text-right" onClick={() => handleView(data)}>
                                                        <div className="text-center items-end justify-center"><img src={viewicon} /></div>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AllCases
import React, { Component, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
import { Bar } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const CustomersCount = () => {
    const [values, setValues] = useState([]);
    const [salesLabels, setSalesLabels] = useState([]);
    const [salesValues, setSalesValues] = useState([]);
    const [branches, setBranches] = useState([]);
    const [current, setCurrent] = useState()
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    useEffect(() => {
        getNewCustomersCountByBranch();
    }, [])
    const getNewCustomersCountByBranch = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.post(config.apiUrl + 'companyAdmin/getNewCustomersCountByBranch', {}, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log(response.data.message, response.data);
                    setValues(response.data.branchData);
                    setSalesValues(response.data.branchData)
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const users = {
        labels: values && values.map(item => item.branchName),
        datasets: [
            {
                data: values && values.map(item => item.count),
                backgroundColor: [
                    'black',
                ],
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20,
                labels: values && values.map(item => "New Customers"),
            }
        ]
    }
    const userReviews = {
        labels: values && values.map(item => item.branchName),
        datasets: [
            {
                data: values && values.map(item => item.avgPotential),
                backgroundColor: [
                    'green',
                ],
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20,
                labels: values && values.map(item => "avgPotential")
            },
            {
                data: salesValues && salesValues.map(item => item.avgSatisfaction),
                backgroundColor: [
                    'orange',
                ],
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20,
                labels: values && values.map(item => "avgSatisfaction"),
            }
        ]
    }
    return (
        <div className="flex gap-2">
            {/* <Card className="flex flex-col justify-between relative" style={{ marginTop: "1rem", height: "250px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                <div className="font-semibold text-lg ml-8">New Customers By Branch</div>
                <div style={{ height: "200px" }}>
                    <Bar data={users}
                        options={{
                            maintainAspectRatio: true,
                            responsive: true,
                            redraw: false,
                            elements: {
                                line: {
                                    tension: 1
                                }
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        font: {
                                            size: 8, // Adjust the font size as needed
                                        },
                                    },
                                },
                                y: {
                                    ticks: {
                                        precision: 0
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: false
                                },
                                tooltip: {
                                    enabled: true,
                                    // callbacks: {
                                    //     label: function (context) {
                                    //         const index = context.dataIndex;
                                    //         const label = context.dataset.labels.find((label, i) => i === index) || '';
                                    //         const value = context.parsed.y || 0;
                                    //         return `${label}: ${value}`;
                                    //     },
                                    // },
                                },
                            }
                        }
                        } />
                </div>
            </Card> */}
            <Card className="flex flex-col justify-between relative" style={{ marginTop: "1rem", height: "250px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                <div className="font-semibold text-lg ml-8">Avg. Potential & Satisfaction</div>
                <div className="flex justify-center items-center gap-4 w-full">
                    <div className="flex justify-center items-center gap-1"><span className="bg-green-600 w-2 h-2"></span><span className="text-xs">Potential</span></div>
                    <div className="flex justify-center items-center gap-1"><span className="bg-orange-400 w-2 h-2"></span><span className="text-xs">Satisfaction</span></div>
                </div>
                <div style={{ height: "200px" }}>
                    <Bar data={userReviews}
                        options={{
                            maintainAspectRatio: true,
                            responsive: true,
                            redraw: false,
                            elements: {
                                line: {
                                    tension: 1
                                }
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        font: {
                                            size: 8, // Adjust the font size as needed
                                        },
                                    },
                                },
                                y: {
                                    ticks: {
                                        precision: 0
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: false
                                },
                                tooltip: {
                                    enabled: true,
                                    // callbacks: {
                                    //     label: function (context) {
                                    //         const index = context.dataIndex;
                                    //         const label = context.dataset.labels.find((label, i) => i === index) || '';
                                    //         const value = context.parsed.y || 0;
                                    //         return `${label}: ${value}`;
                                    //     },
                                    // },
                                },
                            }
                        }
                        } />
                </div>
            </Card>
        </div >
    );
};

export default CustomersCount
import React from "react";
import error from '../../images/error-404.svg'
import { useNavigate } from "react-router-dom";
import logo from "../../images/lms logo.svg";

export default function PageNotFound() {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1)
    }
    return (
        <div className="bg-white w-screen h-screen"> 
            <div className="flex w-full h-16 items-center justify-left text-sm" style={{ color: "#EFF2F5", background: "#142431" }}>
                <div className='justify-left items-center ml-3 w-12 cursor-pointer'><img src={logo} /></div>
                <div className="text-2xl font-semibold w-[90vw] text-center items-center">Entropi</div>
            </div>
            <div className="px-6">
                <center className="my-20">
                    <img src={error} className="w-[20%]" />
                    <div className="text-xl font-semibold mt-4">Oops!</div>
                    <p className="text-sm leading-6 mt-1 not-italic">Page not found</p>
                    <p className="text-sm leading-6 mt-1 not-italic">...maybe the page you’re looking for is not found or never existed.</p>
                </center>
            </div>
        </div >
    )
}

import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import exclamation from "../../images/exclamation.svg"
import cancel from "../../images/cancel_black.svg"

const NotAvailable = ({ survey }) => {
    const navigate = useNavigate();
    const [show, setShow] = useState(true);

    return (
        <Modal show={show} onHide={() => { setShow(false); navigate(-1) }} centered dialogClassName="max-h-[20vh] overflow-y-auto rounded-xl">
            <Modal.Body>
                <div className='flex flex-col justify-center items-center gap-4 mt-4'>
                    <img src={cancel} alt='' className='absolute top-2 right-2 cursor-pointer'
                        onClick={() => { setShow(false); survey ? console.log("survey form updates") : navigate(-1) }} />
                    <img src={exclamation} alt='' className='w-24 h-auto' />
                    <p className='font-semibold text-lg text-center'>Please Contact Your Admin for allowing this action from marketplace</p>
                </div>
            </Modal.Body>
        </Modal >
    )
}

export default NotAvailable
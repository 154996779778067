import React, { Component, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
import { Bar } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const NewProductsData = () => {
    const [current, setCurrent] = useState();
    const [productValues, setProductValues] = useState([])
    const [months, setMonths] = useState();
    const [values, setValues] = useState([])

    useEffect(() => {
        getNewProductsAnalysis();
    }, [])
    const getNewProductsAnalysis = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getNewProductsAnalysis', { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log(response.data.message, response.data);
                    setProductValues(response.data.newProductsData);
                    setMonths(response.data.months);
                    setCurrent(response.data?.newProductsData[0]?.id)
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    useEffect(() => {
        updateGraphData()
    }, [current])
    const updateGraphData = () => {
        let newData = productValues && productValues.filter(item => item.id === current);
        let newValuesArray = newData?.length > 0 ? newData[0].data : []
        setValues(newValuesArray);
    }
    const productOptions = {
        labels: months,
        datasets: [
            {
                data: values,
                backgroundColor: [
                    'black',
                ],
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20,
            }
        ]
    }
    return (
        <div className="flex gap-2">
            <Card className="flex flex-col justify-between relative" style={{ marginTop: "1rem", height: "250px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                <div className="font-semibold text-lg ml-2">New Products Performance</div>
                <select name="current" value={current} className="absolute -top-1 right-1 text-xs max-w-max  border border-solid border-gray-300 rounded mt-2"
                    onChange={(e) => setCurrent(e.target.value)} >
                    {productValues && productValues.map((item, i) => {
                        return (
                            <option value={item.id} key={i}>{item.name}</option>
                        )
                    })}
                </select>
                <div style={{ height: "200px" }}>
                    <Bar data={productOptions}
                        options={{
                            maintainAspectRatio: true,
                            responsive: true,
                            redraw: false,
                            elements: {
                                line: {
                                    tension: 1
                                }
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        font: {
                                            size: 8, // Adjust the font size as needed
                                        },
                                    },
                                },
                                y: {
                                    ticks: {
                                        precision: 0
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: false
                                },
                                tooltip: {
                                    enabled: true,
                                    // callbacks: {
                                    //     label: function (context) {
                                    //         const index = context.dataIndex;
                                    //         const label = context.dataset.labels.find((label, i) => i === index) || '';
                                    //         const value = context.parsed.y || 0;
                                    //         return `${label}: ${value}`;
                                    //     },
                                    // },
                                },
                            }
                        }
                        } />
                </div>
            </Card>
        </div >
    );
};

export default NewProductsData
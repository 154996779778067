import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import EmployeeContext from '../../context/employee/EmpContext';
import { Spinner, Tab, Tabs } from 'react-bootstrap';
import ActionPopup from './ActionPopup';
import viewactivity from "../../images/viewactivity.svg"
import comment from "../../images/comment.svg";
import next from "../../images/next.svg";
import share from "../../images/share.svg"
import CustActivity from './CustActivity';
import ReassignDealPopup from './ReassignDealPopup';
import "./Deal.css"
import HOC from './HOC';


const Deal = ({ deals, loading, refetch }) => {
    const navigate = useNavigate();
    const { empState: employee } = useContext(EmployeeContext)
    const [key, setKey] = useState('new');
    const [currentDeals, setCurrentDeals] = useState([]);
    const [showPopup, setShowPopup] = useState(false)
    const [popupData, setPopupData] = useState();
    const [showActivity, setShowActivity] = useState(false);
    const [activityData, setActivityData] = useState(false);
    const [dataCount, setDataCount] = useState([0, 0, 0, 0]);
    const [showReassignPopup, setShowReassignPopup] = useState(false)
    const [reassignPopupData, setReassignPopupData] = useState();
    const [currentConversations, setCurrentConversations] = useState([]);

    useEffect(() => {
        if (deals && deals.length > 0)
            updateDealsData();
    }, [deals]);

    const updateDealsData = () => {
        let dealsData = deals || [];
        setKey("new")
        setCurrentDeals(dealsData.filter(data => data.dealStatus === 1));
        let currentDataCount = [0, 0, 0, 0];
        dealsData.forEach(data => {
            if (data.dealStatus === 1)
                currentDataCount[0]++;
            else if (data.dealStatus === 2)
                currentDataCount[1]++;
            else if (data.dealStatus === 3)
                currentDataCount[2]++;
            else if (data.dealStatus === 4 || data.dealStatus === 5)
                currentDataCount[3]++;
        });
        setDataCount(currentDataCount)
    }

    const handleDealNavigate = (data) => {
        navigate(`/${employee.companyName}/dealdetails`, { state: { data, key, dataCount } })
    }
    useEffect(() => {
        if (!deals || deals.length === 0) return;
        let dealsData = [];
        if (key === "new") {
            dealsData = deals && deals.filter(deal => deal.dealStatus === 1)
        }
        if (key === "discussion") {
            dealsData = deals && deals.filter(deal => deal.dealStatus === 2);
        }
        if (key === "negotiation") {
            dealsData = deals && deals.filter(deal => deal.dealStatus === 3)
        }
        if (key === "closed") {
            dealsData = deals && deals.filter(deal => deal.dealStatus === 4 || deal.dealStatus === 5);
        }
        let cur = [], curFlat = [];
        dealsData && dealsData.forEach(d => {
            d.conversations && d.conversations.sort((a, b) => parseInt(b.status) - parseInt(a.status)) && cur.push(d.conversations)
        });
        cur && cur.forEach(e => curFlat.push({ createdAt: e[0]["createdAt"], updatedAt: e[0]["updatedAt"] }))
        setCurrentConversations(curFlat)
        setCurrentDeals([...dealsData]);
    }, [key]);
    const noData = () => {
        return (
            <tr className="tr">
                <td className="td-text"></td>
                <td className="td-text"></td>
                <td className="td-text"></td>
                <td className="td-text t"></td>
                <td className="td-text font-semibold text-lg text-left">{loading ? <Spinner animation="border" /> : "No Data Present"}</td>
                <td className="td-text"></td>
                <td className="td-text"></td>
            </tr>
        )
    }
    const togglePopup = (data = {}) => {
        if (data.dealStatus === 4 || data.dealStatus === 5) return
        setPopupData(data);
        setShowPopup(!showPopup);
    }
    const toggleActivity = (data = {}) => {
        setShowActivity(!showActivity);
        setActivityData(data)
    }
    const daysDiff = (status, date1, date2, i) => {
        const curStatus = parseInt(status);
        const oneDay = 24 * 60 * 60 * 1000;
        const firstDate = curStatus === 4 || curStatus === 5 ? new Date(date1) :
            currentConversations && currentConversations[i]?.updatedAt ? new Date(currentConversations[i]["updatedAt"]) : new Date();
        const secondDate = currentConversations && currentConversations[i]?.createdAt ? new Date(currentConversations[i]["createdAt"]) : new Date(date2);
        const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
        return diffDays + 1;
    }
    const totalDaysDiff = (date1, date2, status) => {
        const oneDay = 24 * 60 * 60 * 1000;
        const firstDate = status === 4 || status === 5 ? new Date(date1) : new Date();
        const secondDate = new Date(date2);
        const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
        return diffDays + 1;
    }
    const toggleReassignPopup = (data = {}) => {
        if (data.dealStatus === 4 || data.dealStatus === 5) return
        setReassignPopupData(data);
        setShowReassignPopup(!showReassignPopup);
    }
    const getDataCount = (text, count, flag) => {
        return (
            <div className={`flex justify-between items-center w-full ${flag ? "text-orange-300" : "text-custom-text-color"}`}>
                <p>{text}</p>
                <div className='w-5 h-5 text-sm rounded-full bg-custom-text-color text-center text-custom-bg-color'>{count}</div>
            </div>
        )
    };
    return (
        <div className='mt-20 ml-8 w-[80vw]'>
            {showPopup && <ActionPopup show={showPopup} togglePopup={togglePopup} data={popupData} getEmployeeDeals={refetch} />}
            {showReassignPopup && <ReassignDealPopup show={showReassignPopup} togglePopup={toggleReassignPopup} data={reassignPopupData} getEmployeeDeals={refetch} />}
            {showActivity ?
                <div className={`${employee.role === 3 ? "mt-28" : "mt-10"}`}>
                    <div className='max-w-min absolute left-60 top-20'>
                        <i onClick={() => toggleActivity()}
                            className="fa fa-long-arrow-left fa-2x text-orange-600 cursor-pointer"
                            aria-hidden="true">
                        </i>
                    </div>
                    <CustActivity customerId={activityData.customerId} setShowActivity={toggleActivity} />
                </div>
                : <>
                    <div className="flex justify-between">
                        <div className="text-left font-semibold text-black"><h5>Deals</h5></div>
                    </div>
                    <ul className="breadcrumb" >
                        <li onClick={() => setKey("new")}><span>{getDataCount("New", dataCount[0], key === "new")}</span></li>
                        <li onClick={() => setKey("discussion")}><span>{getDataCount("Discussion", dataCount[1], key === "discussion")}</span></li>
                        <li onClick={() => setKey("negotiation")}><span>{getDataCount("Negotiation", dataCount[2], key === "negotiation")}</span></li>
                        <li onClick={() => setKey("closed")}><span>{getDataCount("Closed", dataCount[3], key === "closed")}</span></li>
                    </ul>
                    <div className=" tableFixHead bg-custom-text-color" style={{ height: "71vh" }}>
                        <table className="border-spacing-x-0 border-spacing-y-2 border-separate" >
                            <thead>
                                <tr className="whitespace-nowrap">
                                    <th scope="col ml-4" className="th-heading" >S.No</th>
                                    <th scope="col" className="th-heading" >Customer</th>
                                    <th scope="col" className={`th-heading ${employee.role === 2 ? "" : "hidden"}`} >Assigned To</th>
                                    {/* <th scope="col" className="th-heading" >Status</th> */}
                                    <th scope="col" className="th-heading" >Created On</th>
                                    <th scope="col" className="th-heading" >Last Action</th>
                                    <th scope="col" className="th-heading" >Duration(Days)</th>
                                    <th scope="col" className="th-heading" >Total Duration(Days)</th>
                                    <th scope="col" ></th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {currentDeals?.length === 0 || loading ?
                                    noData()
                                    : currentDeals.map((data, i) => (
                                        <tr key={data.id} className="tr whitespace-nowrap">
                                            <td className="td-text pl-4 w-2">{i + 1}</td>
                                            <td className="td-text">{data.customer}</td>
                                            <td className={`td-text ${employee.role === 2 ? "" : "hidden"}`}>{data.emp}</td>
                                            {/* <td className="td-text">{data.dealStatusText}</td> */}
                                            <td className="td-text">{moment(data.createdAt).format("DD-MM-YYYY")}</td>
                                            <td className="td-text">{moment(data.updatedAt).format("DD-MM-YYYY")}</td>
                                            <td className="td-text pl-16">{daysDiff(data.dealStatus, data.updatedAt, data.createdAt, i)}</td>
                                            <td className="td-text pl-20">{totalDaysDiff(data.updatedAt, data.createdAt, data.dealStatus)}</td>
                                            <td className="td-text flex gap-3 justify-center">
                                                <HOC tooltip={"Deal Conversations"} source={comment} data={data} handleClick={handleDealNavigate} icon={1} />
                                                {/* <div className='icon-w'><img src={comment} alt='' className="cursor-pointer py-1" onClick={() => handleDealNavigate(data)} /></div> */}
                                                {employee.role === 3 && <HOC tooltip={"Move to next status"} source={next} data={data} handleClick={togglePopup} icon={2} />}
                                                {/* <div className='icon-w'><img src={next} alt='' className="cursor-pointer py-1 w-5 pl-0.5" aria-hidden="true" onClick={() => togglePopup(data)} /></div> */}
                                                <HOC tooltip={"Customer Activity"} source={viewactivity} data={data} handleClick={toggleActivity} icon={3} />
                                                {/* <div className='icon-w'><img src={viewactivity} alt='' className='pt-1 w-4 h-5 cursor-pointer' onClick={() => toggleActivity(data)} /></div> */}
                                                {employee.role === 2 && parseInt(data.dealStatus) !== 4 && parseInt(data.dealStatus) !== 5 &&
                                                    <HOC tooltip={"Reassign Deal"} source={share} data={data} handleClick={toggleReassignPopup} icon={3} />}
                                                {/* <div className='icon-w' style={employee.role === 2 && parseInt(data.dealStatus) !== 4 && parseInt(data.dealStatus) !== 5 ? { display: "flex" } : { display: "none" }}>
                                                    <img src={share} alt='' className={`cursor-pointer py-1`} onClick={() => toggleReassignPopup(data)} />
                                                </div> */}
                                                <div className={`w-4 h-4 rounded-full my-auto mx-1 ${parseInt(data.dealStatus) === 5 ? "bg-green-600" : parseInt(data.dealStatus) === 4 ? "bg-red-600" : "hidden"}`}></div>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <div className={`flex items-center w-full mx-auto gap-4 mt-2 text-sm ${key === "closed" ? "" : "hidden"}`}>
                        <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-green-600 rounded-xl'></div><span>Deal Successful</span></div>
                        <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-red-600 rounded-xl'></div><span>Customer Not Interested</span></div>
                    </div>
                </>}
        </div >
    )
}

export default Deal
import React, { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
import { Line } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";
import EmployeeContext from "../context/employee/EmpContext";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const CustomersRegionGraph = () => {
    const { empState: employee } = useContext(EmployeeContext)
    const [values, setValues] = useState();
    const [headOffice, setHeadOffice] = useState();
    const [targets, setTargets] = useState([]);

    useEffect(() => {
        getCompanyTargets();
        getTargetsByCompanyAdmin();
    }, [])
    const getCompanyTargets = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "companyId": employee?.companyId
        }
        axios.post(config.apiUrl + 'companyAdmin/getCompanyForecast', payload, { headers: headers })
            .then(function (response) {
                console.log(response.data.message, response.data);
                if (response.data.status == true) {
                    response.data.getAllTargets?.length > 0 ? setHeadOffice(response.data.getAllTargets[0]) : setHeadOffice();
                    
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const getTargetsByCompanyAdmin = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getTargetsByCompanyAdmin', { headers: headers })
            .then(function (response) {
                console.log(response.data.message, response.data);
                if (response.data.status == true) {
                    setTargets(response.data.getAllTargets || []);
                    response.data.getAllTargets?.length > 0 ? setValues(response.data.getAllTargets[0]) : setValues();
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const targetsByMonth = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
            {
                label: "Target",
                data: values && values.targets.map(val => parseInt(val.target)),
                backgroundColor: [
                    '#cc0000',
                ],
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20,
                borderColor: "#cc0000",
                tension: 0.3,
                pointRadius: 2
            },
            {
                label: "Achieved",
                data: values && values.targets.map(val => parseInt(val.achieved)),
                backgroundColor: [
                    '#00b300',
                ],
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20,
                borderColor: "#00b300",
                tension: 0.3,
                pointRadius: 4
            },
            {
                label: "Forecast",
                data: values && values.targets.map(val => parseInt(val.forecast)),
                backgroundColor: [
                    '#e6ac00',
                ],
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20,
                borderColor: "#e6ac00",
                tension: 0.3,
                pointRadius: 6
            }
        ],
        legend: []
    }
    return (
        <div className="">
            <Card className="flex flex-col justify-between relative" style={{ marginTop: "1rem", height: "250px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                <div className="font-semibold ml-12">
                    <span className="text-sm">Targets</span>
                    <span className="text-xs">({values && values.branchName ? values.branchName : "Head Office"})</span>
                </div>
                <select name="branches" value={values && values.branchName} className="absolute -top-1 right-1 text-xs w-32  border border-solid border-gray-300 rounded mt-2"
                    onChange={(e) => e.target.value ? setValues(JSON.parse(e.target.value)) : setValues()} >
                    <option value="">{values ? values.branchName ? values.branchName : "Head Office" : "---Choose Branch---"}</option>
                    {targets && targets.map((item, i) => {
                        return (
                            <option value={JSON.stringify(item)} key={i}>{item.branchName}</option>
                        )
                    })}
                    {/* <option value={headOffice ? JSON.stringify(headOffice) : ""}>Head Office</option> */}
                </select>
                <div className="flex justify-center items-center gap-4 w-full">
                    <div className="flex justify-center items-center gap-1"><span className="bg-[#cc0000] w-2 h-2"></span><span className="text-xs">Target</span></div>
                    <div className="flex justify-center items-center gap-1"><span className="bg-[#e6ac00] w-2 h-2"></span><span className="text-xs">Forecast</span></div>
                    <div className="flex justify-center items-center gap-1"><span className="bg-[#00b300] w-2 h-2"></span><span className="text-xs">Achieved</span></div>
                </div>
                <div style={{ height: "200px" }}>
                    <Line data={targetsByMonth}
                        options={{
                            elements: {
                                line: {
                                    tension: 0.5
                                }
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                },
                                y: {
                                    ticks: {
                                        precision: 0
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: false
                                },
                            }
                        }
                        } />
                </div>
            </Card>
        </div>
    );
};

export default CustomersRegionGraph
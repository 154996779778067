import React, { useState, useEffect } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Modal, Form, OverlayTrigger, Tooltip, FormControl, Card, Button } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import ErrorPopup from "../PopUps/ErrorPopup";
import { enqueueSnackbar } from 'notistack'
import Navbar1 from "../Navbar/Navbar1";
import LeftMenu1 from "../LeftMenu/LeftMenu1";
import uploadImage from "../Utils/UploadImage";
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const EditService = () => {
    const navigate = useNavigate();
    const locations = useLocation();
    const employee = locations?.state.employee;
    const data = locations?.state.data
    const [serviceName, setServiceName] = useState('');
    const [serviceDescription, setServiceDescription] = useState('');
    const [section, setSection] = useState();
    const [price, setPrice] = useState(0.00);
    const [url, setUrl] = useState();
    const [selectedStatus, setSelectedStatus] = useState();
    const [serviceId, setServiceId] = useState();
    const [errors, setErrors] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    const [popupMsg, setPopupMsg] = useState(false);
    const [terms, setTerms] = useState()
    const [checked, setChecked] = useState(false)
    const [show, setShow] = useState(true);
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('');

    useEffect(() => {
        console.log("data", data)
        setServiceName(data.serviceName)
        setServiceDescription(data.serviceDescription)
        setSection(data.section)
        setUrl(data.url)
        setChecked(data.external_service)
        setTerms(data.terms)
        setPrice(data.price)
        setSelectedStatus(data.is_active ? 1 : 0)
        setServiceId(data.id);
        setSelectedPlan(data.planId);
        getAllPlans();
    }, []);

    const getAllPlans = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + `superAdmin/allplans`, { headers: headers })
            .then(function (response) {
                console.log("success", response.data.message, response.data);
                setPlans(response.data.plans);
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };

    const handleCheck = () => {
        setChecked(!checked)
        if (checked === true) {
            setSection("")
            setUrl("")
        } else {
            setSection("External");
            setSelectedPlan("");
        }
    }
    const updateService = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: "error" })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "serviceId": serviceId,
            "serviceName": serviceName,
            "serviceDescription": serviceDescription,
            "section": section,
            "price": price,
            "url": url,
            "external_service": checked,
            "terms": terms,
            "is_active": selectedStatus == 1 ? true : false,
            "planId": selectedPlan
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'superAdmin/editService', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    enqueueSnackbar('Service Details Added Successfully!', { variant: "success" })
                    navigate(-1);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
                setShow(false);
                setShowPopup(true);
                setPopupMsg(error.response.data.message);
                setTimeout(() => { setShowPopup(false) }, 3000)
            });
    };
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!serviceName) {
            errorObj = {
                ...errorObj,
                serviceNameError: true
            }
            error = true;
        }
        if (!serviceDescription) {
            errorObj = {
                ...errorObj,
                serviceDescriptionError: true
            };
            error = true;
        }
        if (!terms) {
            errorObj = {
                ...errorObj,
                termsError: true
            };
            error = true;
        }
        if (!checked && !selectedPlan) {
            errorObj = {
                ...errorObj,
                selectedPlanError: true
            };
            error = true;
        }
        if (checked && !url) {
            errorObj = {
                ...errorObj,
                urlError: true
            };
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    };
    const handleChangeStatus = (e) => {
        setSelectedStatus(e.target.value);
        console.log("val", typeof (e.target.value))
    }
    return (
        <div>
            {showPopup ?
                <ErrorPopup show={showPopup} setShow={setShowPopup} msg={popupMsg} />
                :
                <section className="h-screen flex flex-col justify-center items-center mx-5">
                    <Navbar1 employee={employee} />
                    <LeftMenu1 section="Edit Service" />
                    <div><i onClick={() => navigate('/superadmin/Company')} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i></div>
                    <center className=" mt-[12vh] mr-1 text-lg text-black-600">Edit Service</center>
                    <div className="ml-[13vw] grid grid-cols-2 gap-6 w-9/12">
                        <div className="w-full">
                            <label className="mr-1 text-sm text-black-600">Service Name</label>
                            <input className={`text-sm w-full px-4 py-2 ${errors.serviceNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                                type="text"
                                value={serviceName}
                                onChange={(e) => { setServiceName(e.target.value); setErrors({ ...errors, serviceNameError: false }) }}
                                name="serviceName"
                                placeholder="Service Name" />
                            <label className="mr-1 mt-1 text-sm text-black-600">Service Description</label>
                            <textarea className={`text-sm w-full px-4 py-2 ${errors.serviceDescriptionError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                                type="text"
                                rows={7}
                                style={{ resize: "none" }}
                                onChange={(e) => { setServiceDescription(e.target.value); setErrors({ ...errors, serviceDescriptionError: false }) }}
                                name="serviceDescription"
                                value={serviceDescription}
                                placeholder="Service Description" />
                            <label className="mr-1 text-sm text-black-600">Status</label>
                            <select name="status" value={selectedStatus} className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded -mt-2"
                                onChange={(e) => setSelectedStatus(e.target.value)} >
                                <option value={selectedStatus || ""}>{selectedStatus ? selectedStatus == 1 ? "Active" : "Inactive" : "Select Status"}</option>
                                <option key={1} value={1}>Active</option>
                                <option key={2} value={0}>Inactive</option>
                            </select>
                            <label className="mr-1 mt-1 text-sm text-black-600">Price Per Annum</label>
                            <input className={`text-sm w-full px-4 py-2 border-[1px] border-gray-300 rounded`}
                                type="text"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                name="price"
                                placeholder="Price" />
                            <div className="mr-1 mt-1 text-sm text-black-600">Section</div>
                            <input className={`mt-1 text-sm w-full px-4 py-2 border-[1px] border-gray-300 rounded`}
                                type="text"
                                value={section}
                                onChange={(e) => setSection(e.target.value)}
                                name="section"
                                disabled={checked}
                                placeholder="" />
                            <div className="flex text-xs">
                                <input type="checkbox" className="mt-2" name="checked" value={checked} checked={checked} onChange={handleCheck} />
                                <span className="ml-1 mt-2 text-xs">Is it external site
                                </span></div>
                            <input className={`mt-1 text-sm w-full px-4 py-2 ${errors.urlError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded ${checked ? "" : "hidden"}`}
                                type="text"
                                value={url}
                                disabled={!checked}
                                onChange={(e) => { setUrl(e.target.value); setErrors({ ...errors, urlError: false }) }}
                                name="url"
                                placeholder="URL" />
                            <select name="selectedPlan" value={selectedPlan} className={`text-sm w-full px-4 py-2 ${errors.selectedPlanError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-1
                            ${checked ? "hidden" : ""}`}
                                onChange={(e) => { setSelectedPlan(e.target.value); setErrors({ ...errors, selectedPlanError: false }) }} >
                                <option value="">--Select Plan--</option>
                                {plans && plans.map(item => {
                                    return (
                                        <option value={item.id} key={item.id}>{item.plan_name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="w-full">
                            <label className="mr-1 text-sm text-black-600">Terms & Conditions</label>
                            <textarea className={`resize-none text-sm w-full px-4 py-2 ${errors.termsError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                                type="text"
                                rows={24}
                                value={terms}
                                onChange={(e) => { setTerms(e.target.value); setErrors({ ...errors, termsError: false }) }}
                                name="terms"
                                placeholder="Terms & Conditions" />


                            <div className="flex justify-end text-center md:text-left">
                                {/* <button className="mt-4 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white uppercase rounded text-xs tracking-wider" onClick={removeEmployee} type="submit">Delete</button> */}
                                <button className="mt-4 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2  font-semibold uppercase rounded text-xs" onClick={updateService} type="submit">Update</button>
                            </div>
                        </div>

                    </div>
                    {/* </Modal >} */}
                </section>}
        </div >
    )
}

export default EditService
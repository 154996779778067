import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import axios from 'axios';
import { config } from '../../Config/Config';
import { Line } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const EmpActivity = () => {
    const [values, setValues] = useState();
    const [activities, setActivities] = useState([]);
    const [branches, setBranches] = useState([]);
    const [selected, setSelected] = useState()

    useEffect(() => {
        getEmployeeActivities();
    }, [])
    const getEmployeeActivities = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getEmployeesActivitiesByActions', { headers: headers })
            .then(function (response) {
                console.log(response.data.message, response.data);
                if (response.data.status == true) {
                    setActivities(response.data.activities);
                    setBranches(response.data.branches);
                    setSelected(response.data.branches[0]?.id)
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    useEffect(() => {
        setActivitiesByMonth();
    }, [selected]);
    const setActivitiesByMonth = () => {
        let branchFilter = activities && activities.filter(activity => activity.branchId === selected);
        let currentData = new Array(12);
        for (let i = 0; i < 12; i++)
            currentData[i] = { "todoCount": 0, "reachOutCount": 0, "meetingCount": 0 }
        branchFilter && branchFilter.forEach(data => {
            let index = new Date(data.targetDate).getMonth();
            if (data.todo) currentData[index]["todoCount"]++;
            if (data.reachOut) currentData[index]["reachOutCount"]++;
            if (data.meeting) currentData[index]["meetingCount"]++;
        })
        setValues(currentData);
    }
    const activitiesByMonth = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
            {
                label: "ToDo",
                data: values && values.map(val => parseInt(val.todoCount)),
                backgroundColor: ['#E6570E'],
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20,
                borderColor: "#E6570E",
                tension: 0.3,
                pointRadius: 2
            },
            {
                label: "Meeting",
                data: values && values.map(val => parseInt(val.meetingCount)),
                backgroundColor: ['#66c2ff'],
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20,
                borderColor: "#66c2ff",
                tension: 0.3,
                pointRadius: 4
            },
            {
                label: "ReachOut",
                data: values && values.map(val => parseInt(val.reachOutCount)),
                backgroundColor: ['#00cc00'],
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20,
                borderColor: "#00cc00",
                tension: 0.3,
                pointRadius: 6
            }
        ],
        legend: []
    }
    return (
        <div className="">
            <Card className="flex flex-col justify-between relative" style={{ marginTop: "1rem", height: "250px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                <div className="font-semibold ml-12">
                    <span className="text-sm">Employee Activities</span>
                </div>
                <select name="branches" value={selected} className="absolute -top-1 right-1 text-xs w-32  border border-solid border-gray-300 rounded mt-2"
                    onChange={(e) => setSelected(e.target.value)} >
                    <option value={""}>---Choose Branch---</option>
                    {branches && branches.map((item, i) => {
                        return (
                            <option value={item.id} key={i}>{item.branchName}</option>
                        )
                    })}
                </select>
                <div className="flex justify-center items-center gap-4 w-full">
                    <div className="flex justify-center items-center gap-1"><span className="bg-[#E6570E] w-2 h-2"></span><span className="text-xs">ToDo</span></div>
                    <div className="flex justify-center items-center gap-1"><span className="bg-[#00cc00] w-2 h-2"></span><span className="text-xs">ReachOut</span></div>
                    <div className="flex justify-center items-center gap-1"><span className="bg-[#66c2ff] w-2 h-2"></span><span className="text-xs">Meeting</span></div>
                </div>
                <div style={{ height: "200px" }}>
                    <Line data={activitiesByMonth}
                        options={{
                            elements: {
                                line: {
                                    tension: 0.5
                                }
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                },
                                y: {
                                    ticks: {
                                        precision: 0
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: false
                                },
                            }
                        }
                        } />
                </div>
            </Card>
        </div>
    );
};

export default EmpActivity
import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import EditDashboard from '../SalesPerson/EditDashboard';
import { config } from '../Config/Config';
import axios from 'axios';
import "./EmployeeDashBoard.css"
import SimpleCrypto from "simple-crypto-js";
import ViewDetail from './ViewDetail';
import moment from 'moment-timezone';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const EmployeeDashBoard = ({ employee, role, employees }) => {
    const navigate = useNavigate();
    const [currentEvents, setCurrentEvents] = useState([]);
    const [data, setData] = useState()
    const [actionStatus, setActionStatus] = useState([]);
    const [actionTypes, setActionTypes] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [editDashboardDetails, setEditDashboardDetails] = useState(false);
    const [initialEvents, setInitialEvents] = useState([]);
    const [viewDashboardDetails, setViewDashboardDetails] = useState(false);
    const [meetsArr, setMeetsArr] = useState([]);


    useEffect(() => {
        const timezoneLabel = 'IST';
        const timeGridAxis = document.querySelector('.fc-timegrid-axis-frame');
        timeGridAxis.classList.add("mr-[11px]")
        timeGridAxis.textContent = timezoneLabel;
    }, [])
    const getEmployeeDashboard = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "employeeId": employee.employeeId
        }
        return axios.post(config.apiUrl + 'employees/getSalesPersonDashboard', payload, { headers: headers })
    }
    const { data: employeeDashboard } = useQuery("employees-dashboard", getEmployeeDashboard, {
        enabled: role === "Employee",
        onSuccess: (response) => {
            if (response.data.status == true) {
                console.log("successfully employees to do list from query", response.data.message, response.data);
                setCustomers(response.data.customers);
                setActionStatus(response.data.actionStatus)
                getInitialEvents(response.data.customers, response.data.reachouts, response.data.meetings);
                setActionTypes(response.data.actionTypes)
            } else {
                console.log("Error");
            }
        },
        onError: (error) => {
            console.error('Error loading to do list:', error);
        },
    });
    const getAdminDashboard = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        return axios.get(config.apiUrl + 'manager/getBranchAdminDashboard', { headers: headers })
    }
    const { data: adminDashboard } = useQuery("admin-dashboard", getAdminDashboard, {
        enabled: role === "Branch Admin",
        onSuccess: (response) => {
            if (response.data.status == true) {
                console.log("successfully branch admin's to do list from query", response.data);
                setCustomers(response.data.customers)
                getInitialEvents(response.data.customers, response.data.reachOuts, response.data.meetings);
                setActionStatus(response.data.actionStatus);
                setActionTypes(response.data.actionTypes)
            } else {
                console.log("Error");
            }
        },
        onError: (error) => {
            console.error('Error loading To DO List:', error);
        },
    });

    const handleDateSelect = (selectInfo) => {
        let title = prompt('Please enter a new title for your event')
        let calendarApi = selectInfo.view.calendar
        calendarApi.unselect();
        if (title) {
            calendarApi.addEvent({
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
            })
        }
    }
    const getInitialEvents = (customers, reachOuts, meets) => {
        let events = customers.map(customer => {
            let endDate = new Date(customer?.targetDate);
            let updatedAtDate = new Date(customer?.updatedAt);
            let completedStatus = customer.statusId === 2 || customer.statusId === 3;
            let dateElapsed
            if (customer?.targetDate)
                dateElapsed = moment(moment(endDate).format("MM/DD/YYYY HH:mm")).isBefore(moment(updatedAtDate).format("MM/DD/YYYY HH:mm")) ||
                    (!completedStatus && moment(moment(endDate).format("MM/DD/YYYY HH:mm")).isBefore(moment(new Date()).format("MM/DD/YYYY HH:mm")));
            else
                dateElapsed = false

            endDate.setMinutes(endDate.getMinutes() + 30);
            return (
                {
                    id: customer?.id,
                    title: customer?.notes,
                    start: customer?.targetDate,
                    end: endDate,
                    data: customer,
                    backgroundColor: customer?.source === "Deal" && !dateElapsed ? "#b300b3" : customer?.source === "Deal" && dateElapsed ? "#800080"
                        : completedStatus && !dateElapsed ? "black" : completedStatus && dateElapsed ? "#E6570E" : !completedStatus && dateElapsed ? '#FFC373' : "gray",
                    borderColor: customer.employeeStatus === 2 ? "red" : "inherit",
                    duration: '00:30'
                }
            )
        })
        let reachOutEvents = reachOuts.map(reachout => {
            let endDate = new Date(reachout?.targetDate);
            let updatedAtDate = new Date(reachout?.updatedAt);
            let dateElapsed = moment(moment(endDate).format("MM/DD/YYYY HH:mm")).isBefore(moment(updatedAtDate).format("MM/DD/YYYY HH:mm")) ||
                moment(moment(endDate).format("MM/DD/YYYY HH:mm")).isBefore(moment(new Date()).format("MM/DD/YYYY HH:mm"));
            let completedStatus = reachout.statusId === 2 || reachout.statusId === 3;
            endDate.setMinutes(endDate.getMinutes() + 30);
            return (
                {
                    reachOutId: reachout?.reachOutId,
                    title: reachout?.notes,
                    start: reachout?.targetDate,
                    end: endDate,
                    data: reachout,
                    backgroundColor: completedStatus && !dateElapsed ? "#008000" : completedStatus && dateElapsed ? "#E6570E" : !completedStatus && dateElapsed ? '#FFC373' : "#00cc00",
                    borderColor: reachout.employeeStatus === 2 ? "red" : "inherit",
                    duration: '00:30'
                }
            )
        });
        let curMeets = meets && meets.map(meet => {
            let startTime = new Date(meet?.start_time);
            let endTime = new Date(meet?.end_time);
            const colorCode = "#66c2ff";
            return (
                {
                    id: meet?.id,
                    title: "Meeting",
                    start: startTime,
                    end: endTime,
                    data: meet,
                    backgroundColor: colorCode,
                    borderColor: "inherit",
                    duration: '00:30',
                    isMeet: true
                }
            )
        })
        setInitialEvents([...events, ...reachOutEvents]);
        setMeetsArr(curMeets || [])
    }
    const handleEventClick = (clickInfo) => {
        let isMeet = clickInfo?.event?._def?.extendedProps?.isMeet;
        if (isMeet) {
            let meetId = clickInfo?.event?._def?.extendedProps?.data?.booking_id;
            window.open(`/newmeeting?meeting_id=${meetId}`, '_blank', 'noopener noreferrer');
            return;
        }
        let completedStatusArray = [2, 3];
        let currentStatus = clickInfo.event._def.extendedProps.data.statusId;
        if (role === "Branch Admin" || completedStatusArray.some(data => data === currentStatus))
            toggleAdminPopup(!viewDashboardDetails);
        else
            togglePopup(!editDashboardDetails);
        setData(clickInfo.event._def.extendedProps.data);
    }
    const handleEvents = (events) => {
        setCurrentEvents(events)
    }
    const renderEventContent = (eventInfo) => {
        let isMeet = eventInfo?.event?._def?.extendedProps?.isMeet;
        if (isMeet) {
            return (
                <div className={`flex items-center justify-center cursor-pointer`}>
                    <i>{eventInfo.event.title}</i>
                </div>
            )
        }
        return (
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id={`tooltip-top`}>
                        {<div>
                            <p>Customer - {eventInfo.event._def.extendedProps.data.firstName}&nbsp;{eventInfo.event._def.extendedProps.data.lastName}</p>
                            <p>Notes - {eventInfo.event._def.extendedProps.data.notes}</p>
                        </div>
                        }
                    </Tooltip>

                }
            >
                {<div className={`flex cursor-pointer ${eventInfo.event._def.extendedProps.data.statusId === 2 || eventInfo.event._def.extendedProps.data.statusId === 3 ? "line-through" : ""}`}>
                    <b>{eventInfo.timeText.substring(0, 5)}- </b>
                    <i>{eventInfo.event.title.substring(0, 20)}</i>
                </div>
                }
            </OverlayTrigger>
        )
    }

    const toggleAdminPopup = (data) => {
        setViewDashboardDetails(!viewDashboardDetails)
        setData(data)
    }
    const togglePopup = (data) => {
        setEditDashboardDetails(!editDashboardDetails)
        setData(data)
    }
    const changeDayHeader = (args) => {
        return moment(args.date).format('ddd DD/MM');
    }
    return (
        <>
            <div className='my-2 demo-app-main'>
                {editDashboardDetails && <EditDashboard closePopup={togglePopup} actionStatus={actionStatus} data={data} actionTypes={actionTypes} getDashboardActivity={getEmployeeDashboard} employee={employee} />}
                {viewDashboardDetails && <ViewDetail closePopup={toggleAdminPopup} actionStatus={actionStatus} data={data} actionTypes={actionTypes} employees={employees} />}
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}

                    headerToolbar={{
                        start: 'title',
                        center: '',
                        end: 'prev,next'
                    }}
                    initialView='timeGridWeek'
                    allDaySlot={false}
                    weekends={true}
                    contentHeight='auto'
                    slotMinTime='08:00:00'
                    slotMaxTime='20:00:00'
                    initialEvents={initialEvents} // alternatively, use the `events` setting to fetch from a feed
                    events={[...initialEvents, ...meetsArr]}
                    select={handleDateSelect}
                    eventContent={renderEventContent} // custom render function
                    eventClick={handleEventClick}
                    eventsSet={handleEvents}
                    dayHeaderFormat={changeDayHeader}
                />
            </div>
            <div className={`flex items-center max-w-max mx-auto gap-4 text-sm mt-1 absolute left-[24vw] bottom-0`}>
                <div className='flex flex-col'>
                    <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-[#b300b3] rounded-xl'></div><span>Deal Attended</span></div>
                    <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-[#800080] rounded-xl'></div><span>Deal Pending</span></div>
                </div>
                <div className='flex flex-col'>
                    <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-black rounded-xl'></div><span>ToDo Completed On-Time</span></div>
                    <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-[#E6570E] rounded-xl'></div><span>ToDo Completed Late</span></div>
                </div>
                <div className='flex flex-col'>
                    <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-[#808080] rounded-xl'></div><span>New ToDo</span></div>
                    <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-[#008000] rounded-xl'></div><span>Reach Out Completed On-Time</span></div>
                </div>
                <div className='flex flex-col'>
                    <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-[#E6570E] rounded-xl'></div><span>Reach Out Completed Late</span></div>
                    <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-[#FFC373] rounded-xl'></div><span>Reach Out Pending</span></div>
                </div>
                <div className='flex flex-col'>
                    <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-[#00cc00] rounded-xl'></div><span>New Reach Out</span></div>
                    <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-[#66c2ff] rounded-xl'></div><span>Meeting Slot</span></div>
                </div>

            </div>
        </>
    )
}

export default EmployeeDashBoard
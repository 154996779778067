import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { Modal } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import moment from "moment-timezone";
import { enqueueSnackbar } from "notistack";
import EmployeeContext from "../context/employee/EmpContext";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const AddEvent = ({ data, closePopup, getCurrentMonthEvents, }) => {
    const { empState: employee } = useContext(EmployeeContext)
    const [date, setDate] = useState('');
    const [event, setEvent] = useState("")
    const [toDos, setToDos] = useState(['', '']);
    const [eventTypes, setEventTypes] = useState([])
    const [eventType, setEventType] = useState('')
    const [errors, setErrors] = useState({});
    const [branches, setBranches] = useState([]);
    const [branch, setBranch] = useState('');

    useEffect(() => {
        console.log("Date", data);
        setDate(data?.date)
        getEventsTypes();
        getAllBranches();
    }, []);

    const addEvent = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: "error" })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "date": date,
            "event": event,
            "toDos": (toDos),
            "eventType": parseInt(eventType),
            "branchId": branch
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/addEventByCompanyAdmin', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    closePopup()
                    getCurrentMonthEvents(date)
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const getEventsTypes = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getEventsTypes', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message);
                    setEventTypes(response.data.eventTypes)
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const getAllBranches = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            companyId: employee?.companyId
        }
        axios.post(config.apiUrl + 'companyAdmin/getAllBranches', payload, { headers: headers })
            .then((response) => {
                console.log("success branch from query ", response.data);
                let currentBranches = response.data.branches && response.data.branches?.length > 0 ? response.data.branches : [];
                let activeBranches = currentBranches.filter(branch => branch.status === 1)
                setBranches(activeBranches);
            }).catch((error) => {
                console.error('Error loading branches data:', error);
            })
    };
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!event) {
            errorObj = {
                ...errorObj,
                eventError: true
            }
            error = true;
        }
        if (!eventType) {
            errorObj = {
                ...errorObj,
                eventTypeError: true
            };
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    return (
        <div>
            <Modal
                show={true}
                onHide={closePopup}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="w-[25rem] max-w-max m-2 mr-2 mx-auto mb-4">
                    <div className="text-center md:text-left my-2">
                        <label className="mr-1 text-lg text-black-600">Add Event</label>
                    </div>
                    <label className="mr-1 text-sm text-black-600">Event Date</label>
                    <p className={`text-sm w-full p-2 h-10 border border-solid border-gray-300 rounded ${date ? "" : "text-gray-400"}`}>{date ? moment(date).format("DD-MM-YYYY") : "Date"}</p>
                    <label className="mr-1 mt-3 text-sm text-black-600">Event Details</label>
                    <textarea className={`text-sm w-full p-2 ${errors.eventError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded resize-none`}
                        type="text"
                        onChange={(e) => setEvent(e.target.value)}
                        name="event"
                        value={event}
                        placeholder="Event Details"
                        rows={2}
                        maxLength={120}
                    ></textarea>
                    <div className="flex flex-col">
                        <label className="text-sm text-black-600 mt-3">Event Type</label>
                        <select name="employee" value={eventType} className={`text-sm w-full p-2 ${errors.eventTypeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                            onChange={(e) => setEventType(e.target.value)} >
                            <option value="">Select Event Type</option>
                            {eventTypes && eventTypes.map(type => {
                                return (
                                    <option key={type.id} value={type.id}>{type.eventType}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="flex flex-col">
                        <label className="text-sm text-black-600 mt-3">Branch</label>
                        <select name="branch" value={branch} className={`text-sm w-full p-2 border-[1px] border-gray-300 rounded`}
                            onChange={(e) => setBranch(e.target.value)} >
                            <option value="">Select Branch</option>
                            {branches && branches.map(type => {
                                return (
                                    <option key={type.id} value={type.id}>{type.branchName}</option>
                                )
                            })}
                        </select>
                    </div>
                    <label className="mr-1 mt-3 text-sm text-black-600">Things To Do</label>
                    {toDos.map((todo, i) => {
                        return (
                            <textarea key={i} className="text-sm w-full p-2 border border-solid border-gray-300 rounded resize-none"
                                type="text"
                                onChange={(e) => { let current = [...toDos]; current[i] = e.target.value; setToDos(current) }}
                                name={`toDo${i + 1}`}
                                value={toDos.length > i ? toDos[i] : ''}
                                placeholder={`${i + 1}`}
                                rows={1}
                                maxLength={100}
                            ></textarea>
                        )
                    })}
                    <div className="flex justify-between text-center md:text-left">
                        <button className="mt-3 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white font-semibold uppercase rounded text-xs" type="submit" onClick={closePopup}>Back</button>
                        {(event !== "") ?
                            <button className="mt-3 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2  font-semibold uppercase rounded text-xs" onClick={addEvent} type="submit">Add</button>
                            :
                            <button className="mt-3 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs" disabled={true} type="submit">Add</button>
                        }
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default AddEvent
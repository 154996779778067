import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
import { Bar, Line } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const LeadTypeGraph = () => {
    const [values, setValues] = useState([]);
    const [branches, setBranches] = useState([]);
    const [currentMonth, setCurrentMonth] = useState()
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    useEffect(() => {
        getTopReachoutsByMonth();
    }, [])
    useEffect(() => {
        setMonthlyData()
    }, [currentMonth])
    const setMonthlyData = () => {
        let currentMonthData = branches && branches.map(branch => branch.data && branch.data.find((d, i) => i == currentMonth));
        console.log("branches", branches, currentMonthData, currentMonth)
        setValues(currentMonthData)
    }
    const getTopReachoutsByMonth = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getTopReachoutsByMonth', { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("results", response.data.response)
                    setBranches(response.data.response)
                    let a = response.data.response.map((item, i) => {
                        return item.data.map((c, idx) => c.count)
                    })
                    setCurrentMonth(new Date().getMonth())
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const users = {
        labels: branches && branches.map((data, i) => data.name),
        datasets: [
            {
                data: values && values.map(item => item.count),
                backgroundColor: [
                    'black',
                ],
                borderColor: 'black',
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20,
                labels: values && values.map(item => item.count)
            }
        ]
    }
    return (
        <div className="">
            <Card className="flex flex-col justify-between relative" style={{ marginTop: "1rem", height: "250px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                <div className="font-semibold ml-12">
                    <span className="text-sm">Reachout</span>
                </div>
                <select name="months" value={currentMonth} className="absolute -top-1 right-1 text-xs max-w-max  border border-solid border-gray-300 rounded mt-2"
                    onChange={(e) => setCurrentMonth(e.target.value)} >
                    {months && months.map((item, i) => {
                        return (
                            <option value={i} key={i}>{item}</option>
                        )
                    })}
                </select>
                <div style={{ height: "200px" }}>
                    <Bar data={users}
                        options={{
                            maintainAspectRatio: true,
                            responsive: true,
                            redraw: false,
                            elements: {
                                line: {
                                    tension: 0.6
                                }
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        font: {
                                            size: 8, // Adjust the font size as needed
                                        },
                                    },
                                },
                                y: {
                                    ticks: {
                                        precision: 0
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: false
                                },
                            }
                        }
                        } />
                </div>
            </Card>
        </div>
    );
};

export default LeadTypeGraph
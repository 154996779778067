import React, { useContext, useState } from 'react'
import logout from '../images/logout.svg'
import logo from "../images/lms logo.svg";
import { useNavigate } from 'react-router-dom';
import LogoutPopup from '../PopUps/LogoutPopup';
import EmployeeContext from '../context/employee/EmpContext';

const Navbar1 = () => {
    const { empState: employee } = useContext(EmployeeContext)
    const [showPopup, setShowPopup] = useState(false)
    const navigate = useNavigate();
    const getEmployeeRole = (role) => {
        role = parseInt(role)
        if (role === 1) return "Company Admin"
        else if (role === 2) return "Branch Admin"
        else if (role === 4) return "Super Admin"
        else return "Relationship Manager"
    }

    const togglePopup = () => {
        setShowPopup(!showPopup)
    }
    return (
        <div className="flex w-full z-50 fixed top-0 h-14 items-center justify-between text-sm bg-custom-bg-color text-custom-text-color">

            {showPopup && <LogoutPopup closePopup={togglePopup} employee={employee} />}
            <img src={logo} alt="" className="w-12 h-12 my-1 ml-3" />
            <div className="text-2xl font-semibold w-[28.5vw] flex justify-end items-center gap-3">
                {employee && employee.logo ? <div className="bg-gray-50 p-1 rounded-xl"><img src={employee.logo} alt='' className='w-9 h-9' /></div> : <></>}
                <p>{employee.companyName || "Entropi"}</p>
            </div>
            <div className='flex items-center justify-between h-12 gap-3 px-6'>
                <div className="flex flex-col items-start"><p>{employee?.firstName}&nbsp;{employee?.lastName}</p><p>({getEmployeeRole(employee?.role)})</p></div>
                <img src={logout} alt='' className='w-8 h-6 cursor-pointer' onClick={() => togglePopup()} />
            </div>
        </div>
    )
}

export default Navbar1
const uploadImage = async (image) => {
    let data = new FormData()
    data.append("file", image)
    data.append("upload_preset", "entropi_")
    data.append("cloud_name", "entropi")
    const resp = await fetch(`https://api.cloudinary.com/v1_1/entropi/image/upload`, {
        method: "post",
        body: data
    })
    let res = await resp.json();
    return res.secure_url
}
export default uploadImage;
import React, { useState, useEffect, useContext, lazy } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import LeftMenu from '../LeftMenu/LeftMenu';
import axios from 'axios';
import { config } from '../Config/Config';
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from 'moment-timezone';
import SimpleCrypto from "simple-crypto-js";
import tick from "../images/tick.svg";
// import EditEmployee from "./EditEmployee";
import Customer from "../Customer/Customer";
import CustomerAnalytics from "../CustomerAnalytics/CustomerAnalytics";
import Navbar from "../Navbar/Navbar";
// import EditProduct from "./EditProduct";
// import EmployeeActivity from "../Employee/ViewEmployeeActivity";
// import SearchBox from "../Utils/SearchBox";
// import DeletePopup from "../PopUps/DeletePopup";
// import UploadCsv from "./UploadCsv";
// import TargetsTable from "../Utils/TargetsTable";
import EmployeeContext from "../context/employee/EmpContext";
// import AddTarget from "./AddTarget";
// import BranchTargetsTable from "../BranchAnalytics/BranchAnalytics";
import Cases from "../Cases/Cases";
// import ApproveTargets from "../Utils/ApproveTargets";
import viewactivity from "../images/viewactivity.svg";
import editicon from "../images/editicon.svg";
import TermsConditions from "./TermsConditions";
import { enqueueSnackbar } from "notistack";
import { useQuery } from "react-query";
// import ForceLogoutPopup from "../PopUps/ForceLogout";
// import RewardsConfig from "../components/rewards/RewardsConfig";
import EmployeeAnalytics from "../CustomerAnalytics/EmployeeAnalytics";
import CompanyInvoice from "./CompanyInvoice";
import ProductAnalytics from "../CustomerAnalytics/Product Analysis/ProductAnalytics";
import BranchAnalytics from "../CustomerAnalytics/Branch Analytics/BranchAnalytics";
import CustomerAnalyticsGraphs from "../CustomerAnalytics/CustomerAnalytics/CustomerAnalyticsGraphs";
import Register from "../components/Directory/Register";
import ViewLeadsFromDirectory from "../components/Directory/ViewLeadsFromDirectory";
import MeetingCalendarRegistration from "../components/Calendar/MeetingCalendarRegistration";
import AllServices from "../components/Directory/AllServices";
import SurveyServiceRegistration from "../components/SurveyForms/SurveyTerms";

///lazy loading components
const EditEmployee = lazy(() => import("./EditEmployee"));
const EditProduct = lazy(() => import("./EditProduct"));
const EmployeeActivity = lazy(() => import("../Employee/ViewEmployeeActivity"));
const SearchBox = lazy(() => import("../Utils/SearchBox"));
const DeletePopup = lazy(() => import("../PopUps/DeletePopup"));
const UploadCsv = lazy(() => import("./UploadCsv"));
const TargetsTable = lazy(() => import("../Utils/TargetsTable"));
const AddTarget = lazy(() => import("./AddTarget"));
const BranchTargetsTable = lazy(() => import("../BranchAnalytics/BranchAnalytics"));
const ApproveTargets = lazy(() => import("../Utils/ApproveTargets"));
const ForceLogoutPopup = lazy(() => import("../PopUps/ForceLogout"));
const RewardsConfig = lazy(() => import("../components/rewards/RewardsConfig"));

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const CompanyAdmin = () => {
    const { empState: employee } = useContext(EmployeeContext)
    const navigate = useNavigate();
    const { part } = useParams();
    const [branches, setBranches] = useState([]);
    const [activeBranches, setActiveBranches] = useState([]);
    const [branchTypes, setBranchTypes] = useState([]);
    const [branchAreas, setBranchAreas] = useState([]);
    const [section, setSection] = useState(part || "Branch")
    const [editPopup, setEditPopup] = useState(false)
    const [data, setData] = useState()
    const [editEmployeePopup, setEditEmployeePopup] = useState(false)
    const [empId, setEmpId] = useState("")
    const [employees, setEmployees] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [empDetails, setEmpDetails] = useState([]);
    const [products, setProducts] = useState([]);
    const [activeProducts, setActiveProducts] = useState([]);
    const [productTypes, setProductTypes] = useState([]);
    const [editProductPopup, setEditProductPopup] = useState(false)
    const [productData, setProductData] = useState()
    const [productBranches, setProductBranches] = useState([]);
    const [showEmpActivity, setShowEmpActivity] = useState(false)
    const [currentProductBranches, setCurrentProductBranches] = useState([]);
    const [options, setOptions] = useState([]);
    const [filteredData1, setFilteredData1] = useState([]);
    const [filteredData2, setFilteredData2] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [deleteText, setDeleteText] = useState();
    const [searchText, setSearchText] = useState('');
    const [activityOptions, setActivityOptions] = useState([]);
    const [searchText2, setSearchText2] = useState('');
    const [branchSearch, setBranchSearch] = useState('');
    const [curEmployees, setCurEmployees] = useState([]);
    const [bulkType, setBulkType] = useState('');
    const [showForceLogOut, setShowForceLogOut] = useState(false);
    const [rewardsFreq, setRewardsFreq] = useState([]);
    const [nonFinacialRewards, setNonFinancialRewards] = useState([]);
    const [rewardTiers, setRewardTiers] = useState([]);
    const [mainSection, setMainSection] = useState("Company")
    const [servcieData, setServiceData] = useState({});

    useEffect(() => {
        if (localStorage.getItem("junk")) {
            navigate(`/${employee.companyName}/companyadmin/Branch`)
        }
    }, []);


    useEffect(() => {
        if (section === 'Product Branches')
            getProductsBranches(0);
        if (section === "Survey Form")
            navigate(`/${employee.companyName}/${employee.uniqueCode}/configureform`)
    }, [section]);
    const getAllCustomers = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        return axios.get(config.apiUrl + 'companyAdmin/getAllCutomersByCompanyId', { headers: headers })
    };
    const { data: customersDetails } = useQuery("customers-details", getAllCustomers, {
        enabled: section === 'Customer',
        onSuccess: (response) => {
            if (response.data.status == true) {
                console.log("success customers fetched from query", response.data.message, response.data);
                setCustomers(response.data.customerDetails);
            } else {
                console.log("Error");
            }
        },
        onError: (error) => {
            console.error('Error loading products data:', error);
            if (error.response.status === 401)
                setShowForceLogOut(true);
        },
    });
    const getAllEmployeesByBranch = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {}
        return axios.post(config.apiUrl + 'companyAdmin/getAllEmployeesByBranch', payload, { headers: headers })
    };
    const { data: employeesDetails } = useQuery("employees-details", getAllEmployeesByBranch, {
        enabled: section === 'Employee' || section === "AuditTrail",
        onSuccess: (response) => {
            if (response.data.status == true) {
                console.log("success employees fetched from query", response.data.message, response.data);
                let newEmps = JSON.parse(JSON.stringify(response.data.employees));
                newEmps.sort((a, b) => {
                    if (a.lastLogin === null && b.lastLogin === null) return a.status - b.status;
                    if (a.lastLogin === null) return 1;
                    if (b.lastLogin === null) return -1;
                    return new Date(b.lastLogin) - new Date(a.lastLogin);
                })
                setEmployees(newEmps);
                setCurEmployees(newEmps);
                lastActivityOptions();
            } else {
                console.log("Error");
            }
        },
        onError: (error) => {
            console.error('Error loading products data:', error);
            if (error.response.status === 401)
                setShowForceLogOut(true);
        },
    });
    const getBranchType = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        return axios.get(config.apiUrl + 'companyAdmin/getAllBranchTypes', { headers: headers })
    };
    const { data: branchTypesDetails } = useQuery("branchTypes-details", getBranchType, {
        enabled: section === "Branch",
        onSuccess: (response) => {
            console.log("success branch types from query ", response.data);
            if (response.data.status == true) {
                setBranchTypes(response.data.branchTypes);
                setBranchAreas(response.data.branchArea);
            } else {
                console.log("Error");
            }
        },
        onError: (error) => {
            console.error('Error loading branches data:', error);
        },
    });
    const getAllBranches = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            companyId: employee?.companyId
        }
        return axios.post(config.apiUrl + 'companyAdmin/getAllBranches', payload, { headers: headers })
    };
    const { data: branchesDetails } = useQuery("branches-details", getAllBranches, {
        enabled: section === "Branch",
        onSuccess: (response) => {
            console.log("success branch from query ", response.data);
            let currentBranches = response.data.branches && response.data.branches?.length > 0 ? response.data.branches : [];
            let activeBranches = currentBranches.filter(branch => branch.status === 1)
            setBranches(currentBranches);
            setActiveBranches(activeBranches);
            branchOptions(currentBranches);
        },
        onError: (error) => {
            console.error('Error loading branches data:', error);
            if (error.response.status === 401)
                // enqueueSnackbar("Unauthorized!!Please Login again", { variant: "error" })
                setShowForceLogOut(true)
        },
    });
    const getAllProducts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            companyId: employee?.companyId
        }
        return axios.post(config.apiUrl + 'companyAdmin/getAllProducts', payload, { headers: headers })
    };
    const { data: productsDetails } = useQuery("products-details", getAllProducts, {
        enabled: section === 'Product' || section === "Product Branches",
        onSuccess: (response) => {
            console.log("success product from query", response.data);
            let currentProducts = response.data.products && response.data.products?.length > 0 ? response.data.products : [];
            setProducts([...currentProducts]);
            let active = currentProducts.filter(product => product.status === 1)
            setActiveProducts(active);
        },
        onError: (error) => {
            console.error('Error loading products data:', error);
            if (error.response.status === 401)
                setShowForceLogOut(true);
        },
    });
    const getProductTypes = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        console.log(employee?.companyId);
        return axios.get(config.apiUrl + 'companyAdmin/getAllProductTypes', { headers: headers })
    };
    const { data: productTypesBranches } = useQuery("productTypes-branches", getProductTypes, {
        enabled: section === 'Product',
        onSuccess: (response) => {
            console.log("success product types from query", response);
            if (response.data.status == true) {
                setProductTypes(response.data.productTypes);
            } else {
                console.log("Error");
            }
        },
        onError: (error) => {
            console.error('Error loading products data:', error);
        },
    });
    const getProductsBranches = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getAllProductBranches', { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("success", response.data.branchProducts);
                    const b = JSON.parse(JSON.stringify(response.data.branchProducts))
                    setProductBranches(b);
                    const branchProducts = JSON.parse(JSON.stringify(b))
                    return branchProducts;
                } else {
                    console.log("Error");
                }
            }).then((branchProducts) => {
                const a = (branchProducts.map((branch, i) => {
                    let branchProducts = branch.products && branch.products.map((product, idx) => {
                        let obj = { "productId": product, "status": 1 }
                        return obj;
                    });
                    branch["products"] = branchProducts;
                    return branch;
                }));
                console.log("Branch Prooduct", a);
                setCurrentProductBranches(a);
                return a;
            })
            .catch(function (error) {
                console.log("error", error);
                if (error.response.status === 401)
                    setShowForceLogOut(true);
            });
    };
    const getRewardsConfig = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        return axios.get(config.apiUrl + 'companyAdmin/getRewardsConfigByCompany', { headers: headers })
    };
    const { data: rewards_config, refetch } = useQuery("rewads-config", getRewardsConfig, {
        enabled: section === 'Rewards',
        onSuccess: (response) => {
            if (response.data.status == true) {
                console.log(response.data.message, response.data)
                setRewardsFreq(response.data.rewardsFreq);
                setNonFinancialRewards(response.data.nonFinancialRewards);
                setRewardTiers(response.data.rewardTiers)
            } else {
                console.log("Error");
            }
        },
        onError: (error) => {
            console.error('Error loading products data:', error);
            if (error.response.status === 401) setShowForceLogOut(true);
            else enqueueSnackbar("Something went wrong!!", { variant: "error" });
        },
    });
    const togglePopup = (data) => {
        setEditPopup(!editPopup)
        setData(data)
    }
    const toggleProductPopup = (data) => {
        setEditProductPopup(!editProductPopup)
        setProductData(data)
    }
    const toggleEmployeePopup = (data) => {
        setEditEmployeePopup(!editEmployeePopup)
        setEmpId(data.id)
        setEmpDetails(data)
    }
    const toggleEmployeeClosePopup = () => {
        setEditEmployeePopup(!editEmployeePopup)
    }


    const getEmployeeRole = (role) => {
        if (role === 1) return "Company Admin"
        else if (role === 4) return "Super Admin"
        else if (role === 2) return "Branch Admin"
        else return "Relationship Manager"
    }
    const updatedDate = branches && branches.map((i, idx) => {
        var a = new Date(i.createdAt)
        var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
        return updated;
    })
    const isAvailable = (productId, branchId) => {
        let currentProducts = currentProductBranches.filter(item => item.id === branchId);
        currentProducts = currentProducts[0].products.filter((p, i) => p.productId === productId && p.status === 1)
        if (currentProducts.length === 0) return false;
        return true;
    }
    const toggleAvailability = (productId, branchId) => {
        let currentProducts = currentProductBranches.map(item => {
            if (item.id === branchId) {
                let currentProducts = item.products.filter(product => product.productId === productId)
                if (currentProducts.length > 0) {
                    currentProducts = item.products.map(product => {
                        if (product.productId === productId)
                            product.status = product.status === 1 ? 2 : 1;
                        return product;
                    })
                    item["products"] = currentProducts
                }
                else {
                    item["products"] = [...item.products, { "productId": productId, "status": 1 }];
                }
            }
            return item;
        });
        setCurrentProductBranches(currentProducts);

    }

    const cancelProductBranches = () => {
        const branchProducts = JSON.parse(JSON.stringify(productBranches))
        const a = (branchProducts.map((branch, i) => {
            let branchProducts = branch.products && branch.products.map((product, idx) => {
                let obj = { "productId": product, "status": 1 }
                return obj;
            });
            branch["products"] = branchProducts;
            return branch;
        }));
        setCurrentProductBranches(a);
    }
    const updateProductsBranchStatus = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            productBranches: JSON.stringify(currentProductBranches)
        }
        axios.post(config.apiUrl + 'companyAdmin/updateProductBranchStatus', payload, { headers: headers })
            .then((response) => {
                console.log(response.data.message, response.data);
                enqueueSnackbar("Product Branches Updated Successfully !", { variant: "success" })
            }).catch(error => {
                console.log("error", error.response.data.message);
            })
    };
    const toggleActivity = (id) => {
        setShowEmpActivity(!showEmpActivity)
        setEmpId(id);
    }
    const branchOptions = (branches) => {
        let currentOptions = branches && branches.map(branch => {
            let obj = { value: branch.id, label: branch.branchName };
            return obj;
        })
        setOptions(currentOptions);
    }
    const lastActivityOptions = () => {
        let timeframe = [1, 3, 6, 12];
        let currentOptions = timeframe.map(time => {
            let obj = { value: time, label: time + ' months' };
            return obj;
        })
        setActivityOptions(currentOptions);
    }
    const filter = (option) => {
        setSearchText(option.label);
        let data = searchText2 ? filteredData2 : filteredData.length > 0 ? filteredData : employees;
        data = data.filter(emp => emp.branchId === option.value);
        setFilteredData1(data)
        setFilteredData(data);
    }
    const lastActivityFilter = (option) => {
        setSearchText2(option.label);
        let endDate = moment().format("MM/DD/YY");
        let startDate = moment().subtract(parseInt(option.value), 'month').format("MM/DD/YY")
        let data = searchText ? filteredData1 : filteredData.length > 0 ? filteredData : employees;
        data = data.filter(emp => {
            let lastLogin = moment(emp.lastLogin).format("MM/DD/YY")
            if (lastLogin && lastLogin >= startDate && lastLogin <= endDate)
                return emp

        });
        setFilteredData(data);
        setFilteredData2(data);
    }
    const handleClear = (id) => {
        if (id === 1) {
            setFilteredData1([]);
            if (searchText2)
                setFilteredData([...filteredData2])
            else
                setFilteredData([]);
        }
        if (id === 2) {
            setFilteredData2([]);
            if (searchText)
                setFilteredData([...filteredData1])
            else
                setFilteredData([]);
        }
    }
    const branchFilter = (option) => {
        setBranchSearch(option.label);
        let data = [...curEmployees];
        data = data.filter(emp => emp.branchId === option.value);
        setCurEmployees(data)
    }
    const handleNaviagate = () => {
        navigate(`/${employee.companyName}/createbranch`, { state: employee })
    }
    const handleBulkUpload = (text) => {
        console.log("text", text)
        setBulkType(text)
        navigate(`/${employee.companyName}/bulkupload`, { state: { employee, bulkType: text } })
    }
    const handleNaviagateToAddProduct = () => {
        navigate(`/${employee.companyName}/createproduct`, { state: employee })
    }
    const updateSection = (data) => {
        setSection(data)
    }
    return (
        <div className="">
            {editPopup && <DeletePopup closePopup={togglePopup} data={data} employee={employee} deleteText={deleteText} updateSection={updateSection} branchTypes={branchTypes} />}
            {editProductPopup && <EditProduct closePopup={toggleProductPopup} productData={productData} employee={employee} getAllProducts={getAllProducts} productTypes={productTypes} />}
            {editEmployeePopup && <EditEmployee closePopup={toggleEmployeeClosePopup} empDetails={empDetails} id={empId} getAllEmployeesByBranch={getAllEmployeesByBranch} branches={branches} />}
            {showEmpActivity && <EmployeeActivity closePopup={toggleActivity} employeeId={empId} />}
            {showForceLogOut && <ForceLogoutPopup employee={employee} />}
            <div className='flex'>
                <div className='w-[14vw]'>
                    <LeftMenu section={section} setSection={setSection} mainSection={mainSection} setMainSection={setMainSection} />
                </div>
                <div className='w-5/6'>
                    <Navbar employee={employee} role={getEmployeeRole(employee?.role)} />
                    {section === "Branch" ?
                        <div className="ml-8 mt-20">
                            <div className="flex justify-between items-center">
                                <div className="text-left font-semibold text-black"><h5>Branches</h5></div>
                                <div className="flex flex-row">
                                    <div className="">
                                        <button onClick={() => handleBulkUpload("Branch")} className="bg-custom-bg-color hover:bg-custom-bg-color px-4 py-2 font-semibold text-custom-text-color uppercase rounded text-xs mr-2">Bulk Upload</button>
                                        <button onClick={handleNaviagate} className="bg-custom-bg-color hover:bg-custom-bg-color px-4 py-2 font-semibold text-custom-text-color uppercase rounded text-xs">Add Branch</button>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3 tableFixHead bg-custom-text-color">
                                <table className="border-spacing-x-0 border-spacing-y-2 border-separate" >
                                    <thead>
                                        <tr className="whitespace-nowrap">
                                            <th style={{ width: "4px" }}></th>
                                            <th scope="col" className="th-heading" >S.No</th>
                                            <th scope="col" className="th-heading" >Branch Name</th>
                                            <th scope="col" className="th-heading" >Branch Code</th>
                                            <th scope="col" className="th-heading" >Type</th>
                                            <th scope="col" className="th-heading" >Area</th>
                                            <th scope="col" className="th-heading" >Location</th>
                                            <th scope="col" className="th-heading" >City</th>
                                            <th scope="col" className="th-heading" >Status</th>
                                            <th scope="col" className="th-heading" >Created On</th>
                                            <th scope="col" ></th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {branches && branches.map((data, i) => (

                                            <tr key={data.id} className="tr whitespace-nowrap">
                                                <td ><div className={`w-3 h-3 rounded-full ${moment(data.createdAt).format("YYYY-MM-DD HH") === moment(new Date(Date.now())).format("YYYY-MM-DD HH") ? "bg-green-700" : ""}`}></div></td>
                                                <td className="td-text pl-4">{i + 1}</td>
                                                <td className="td-text">{data.branchName}</td>
                                                <td className="td-text">{data.branchCode}</td>
                                                <td className="td-text">{branchTypes.map((bType) => bType.id == data.branchType ? bType.branchType : "")}</td>
                                                <td className="td-text">{branchAreas.map((b) => b.id == data.branchArea ? b.branchArea : "")}</td>
                                                <td className="td-text">{data.location}</td>
                                                <td className="td-text">{data.city}</td>
                                                <td className="td-text">{data.status === 1 ? "Active" : data.status === 3 ? "Saved" : "Inactive"}</td>
                                                <td className="td-text">{data.createdAt?.split("T")[0].split("-").reverse().join("-")}</td>
                                                <td className="flex text-right items-end justify-end">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={<Tooltip id="button-tooltip">Edit Branch</Tooltip>}
                                                    >
                                                        <div className="px-2 icon-w text-right" onClick={() => navigate(`/${employee.companyName}/editbranch`, { state: { employee, data: data, branchTypes: branchTypes, branchAreas: branchAreas } })}>
                                                            <div className="text-center items-end justify-center z-10"><img src={editicon} /></div>
                                                        </div>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        : (section === "Product") ?
                            <div className="ml-8 mt-20">
                                <div className="flex  justify-between">
                                    <div className="text-left font-semibold text-black"><h5>Products</h5></div>
                                    <div className="flex flex-row">
                                        <div className="">

                                            <button onClick={() => handleBulkUpload("Product")} className="bg-custom-bg-color hover:custom-bg-color text-custom-text-color px-4 py-2 font-semibold  uppercase rounded text-xs mr-2">Bulk Upload</button>
                                            <button onClick={handleNaviagateToAddProduct} className="bg-custom-bg-color hover:custom-bg-color text-custom-text-color px-4 py-2 font-semibold  uppercase rounded text-xs">Add Product</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-3 tableFixHead">
                                    <table className="border-spacing-x-0 border-spacing-y-2 border-separate" >
                                        <thead className="whitespace-nowrap">
                                            <tr>
                                                <th style={{ width: "4px" }}></th>
                                                <th scope="col" className="th-heading">S.No</th>
                                                <th scope="col" className="th-heading">Product Name</th>
                                                <th scope="col" className="th-heading" >Product Code</th>
                                                <th scope="col" className="th-heading">Type</th>
                                                <th scope="col" className="th-heading">Description</th>
                                                <th scope="col" className="th-heading">Status</th>
                                                <th scope="col" className="th-heading">Created On</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-custom-text-color" >
                                            {products && products.map((data, i) => (
                                                <tr key={data.id} className="tr whitespace-nowrap">
                                                    <td ><div className={`w-3 h-3 rounded-full ${moment(data.createdAt).format("YYYY-MM-DD HH") === moment(new Date(Date.now())).format("YYYY-MM-DD HH") ? "bg-green-700" : ""}`}></div></td>
                                                    <td className="td-text pl-4">{i + 1}</td>
                                                    <td className="td-text">{data.productFamilyName}</td>
                                                    <td className="td-text">{data.productCode}</td>
                                                    <td className="td-text">{productTypes.map((pType) => pType.id == data.productType ? pType.productType : "")}</td>
                                                    <td className="td-text">{data.description.length > 20 ? data.description.substring(0, 20) + "..." : data.description}</td>
                                                    <td className="td-text">{data.status === 1 ? "Active" : data.status === 3 ? "Saved" : "Inactive"}</td>
                                                    <td className="td-text">{data.createdAt?.split("T")[0].split("-").reverse().join("-")}</td>
                                                    <td className="flex text-right items-end justify-end">
                                                        <OverlayTrigger
                                                            placement="top"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={<Tooltip id="button-tooltip">Edit Product</Tooltip>}
                                                        >
                                                            <div className="px-2 icon-w text-right" onClick={() => navigate(`/${employee.companyName}/editproduct`, { state: { employee, productData: data, productTypes: productTypes } })}>
                                                                <div className="text-center items-end justify-center z-10"><img src={editicon} /></div>
                                                            </div>
                                                        </OverlayTrigger>
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            : (section === "Product Branches") ?
                                <div className="ml-8 mt-20">
                                    <div className="text-left font-semibold text-black"><h5>Product Branches</h5></div>
                                    <div className="mt-4 tableFixHead">
                                        <table className="border-spacing-x-0 border-spacing-y-2 border-separate" >
                                            <thead style={{ background: "#eee" }}>
                                                <tr>
                                                    <th scope="col" className="th-heading text-left whitespace-nowrap">Product Family</th>
                                                    {activeBranches && productBranches?.length > 0 && activeBranches.map(branch => {
                                                        return (
                                                            <th key={branch.id} scope="col" className="th-heading">
                                                                <div className="flex flex-col items-center">
                                                                    <p className="whitespace-nowrap">{branch.branchName}</p>
                                                                    <p className="text-xs font-light whitespace-nowrap">({branchTypes.map((bType) => bType.id === branch.branchType ? bType.branchType : "")})</p>
                                                                </div>
                                                            </th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody className="rounded-xl">
                                                {productBranches?.length > 0 && activeProducts.map((data, i) => (
                                                    <tr key={data.id} className="tr whitespace-nowrap">
                                                        <td className="td-text text-left">{data.productFamilyName}</td>
                                                        {productBranches.length > 0 && activeBranches.map(branch => {
                                                            return (
                                                                <td key={branch.id} className="py-2 td-text">
                                                                    <div className="flex justify-center items-center">
                                                                        <span className="w-6 h-6 border-2 border-black cursor-pointer" onClick={() => toggleAvailability(data.id, branch.id)}>
                                                                            <img src={isAvailable(data.id, branch.id) ? tick : ""} alt="" />
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            )
                                                        })}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="pr-8 flex justify-between items-center gap-3">
                                        <button className="max-w-max bg-orange-600 hover:bg-orange-700 p-2 px-3 font-semibold text-white uppercase rounded text-xs whitespace-nowrap" onClick={() => cancelProductBranches()}>Cancel</button>
                                        <button className="bg-custom-bg-color hover:custom-bg-color text-custom-text-color p-2 px-4  font-semibold uppercase rounded text-xs whitespace-nowrap" onClick={() => updateProductsBranchStatus()}>Save</button>

                                    </div>
                                </div>
                                : (section === "Employee") ?
                                    <div>
                                        <div className="ml-8 mt-20">
                                            <div className="flex justify-between">
                                                <div className="text-left font-semibold text-black"><h5>Employees</h5></div>
                                                <div className="flex flex-row">
                                                    <div className="">
                                                        <button onClick={() => handleBulkUpload("Employee")} className="bg-custom-bg-color hover:custom-bg-color text-custom-text-color px-4 py-2 font-semibold  uppercase rounded text-xs mr-2">Bulk Upload</button>
                                                        <button onClick={() => navigate(`/${employee.companyName}/createemployee`, { state: { employee } })} className="bg-custom-bg-color hover:custom-bg-color text-custom-text-color px-4 py-2 font-semibold  uppercase rounded text-xs">Add Employee</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-3 tableFixHead">
                                                <table className="resize-none border-spacing-x-0 border-spacing-y-2 border-separate" >
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: "4px" }}></th>
                                                            <th scope="col" className="th-heading">S.No</th>
                                                            <th scope="col" className="th-heading">Name</th>
                                                            <th scope="col" className="th-heading">Email</th>
                                                            <th scope="col" className="th-heading">Phone</th>
                                                            <th scope="col" className="th-heading">Emp Id</th>
                                                            <th scope="col" className="th-heading">Status</th>
                                                            <th scope="col" className="th-heading">
                                                                <Dropdown className="p-0 m-0 w-10" align="end">
                                                                    <Dropdown.Toggle className="bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color focus:ring-0 text-semibold  text-xs border-none" variant="secondary" id="dropdown-basic" style={{ padding: "0", border: "none" }}>
                                                                        <div className="flex justify-between items-center">
                                                                            <p className={`${branchSearch ? "text-xs" : "text-sm"} font-bold`}>{branchSearch ? branchSearch : "Branch"}</p>
                                                                            <span className={`${branchSearch ? "text-xs" : "text-sm"}`}>{branchSearch ? <i className='fa fa-remove fs-2xs ml-3' onClick={() => { setBranchSearch(''); setCurEmployees([...employees]) }} style={{ color: "white" }}></i> : <i className="fa fa-angle-down fs-lg ml-2" style={{ color: "white" }}></i>}</span>
                                                                        </div>
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu style={{ minWidth: "8rem" }}>
                                                                        <SearchBox placeholder="Search Branches" options={options} handleChange={branchFilter} />
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </th>
                                                            <th scope="col" className="th-heading" style={{ width: "12%" }}>Type</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {curEmployees && curEmployees.map((data, i) => (
                                                            <tr key={data.id} className="tr whitespace-nowrap">
                                                                <td ><div className={`w-3 h-3 rounded-full ${moment(data.createdAt).format("YYYY-MM-DD HH") === moment(new Date(Date.now())).format("YYYY-MM-DD HH") ? "bg-green-700" : ""}`}></div></td>
                                                                <td className="td-text pl-4">{1 + i}</td>
                                                                <td className="td-text">{data.firstName}&nbsp;{data.lastName}</td>
                                                                <td className="cursor-pointer" >{data?.email ? simpleCrypto.decrypt(data.email) : ""}</td>
                                                                <td className="td-text">{data.mobile}</td>
                                                                <td className="td-text">{data.employeeId}</td>
                                                                <td className="td-text">{data.status === 1 ? "Active" : data.status === 3 ? "Saved" : "Inactive"}</td>
                                                                <td className="td-text">{data.branchName == null ? "-" : data.branchName}</td>
                                                                <td className="td-text">{getEmployeeRole(data.role)}</td>
                                                                <td className="flex text-right items-end justify-end">
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        delay={{ show: 250, hide: 400 }}
                                                                        overlay={<Tooltip id="button-tooltip">Edit Employee</Tooltip>}
                                                                    >
                                                                        <div className="px-2 icon-w text-right" onClick={() => navigate(`/${employee.companyName}/editemployee`, { state: { employee, empDetails: data, branches: branches } })}>
                                                                            <div className="text-center items-end justify-center z-10"><img src={editicon} /></div>
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                </td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    : (section === "Customer") ?

                                        <Customer customers={customers} branches={branches} role={1} employees={employees} />

                                        : (section === "CustomerAnalytics") ?
                                            <div className="flex flex-col justify-between ml-8 mt-20">
                                                <div className="text-left font-semibold text-custom-bg-color">
                                                    <h5>Company Analytics</h5>
                                                </div>
                                                <CustomerAnalytics />
                                            </div>
                                            : (section === "Employee Analytics") ?
                                                <div className="flex flex-col justify-between ml-8 mt-20">
                                                    <div className="text-left font-semibold text-custom-bg-color">
                                                        <h5>Employee Analytics</h5>
                                                    </div>
                                                    <EmployeeAnalytics />
                                                </div>
                                                : (section === "BulkUpload") ?
                                                    <div className="ml-8 mt-20">
                                                        <div className="flex flex-col justify-between">
                                                            <div className="text-left font-semibold text-black"><h5>Bulk Upload</h5></div>
                                                        </div>
                                                        <UploadCsv getAllBranches={getAllBranches} getAllProducts={getAllProducts} getAllCustomers={getAllCustomers} getAllEmployees={getAllEmployeesByBranch} />
                                                    </div>
                                                    : (section === "AuditTrail") ?
                                                        <div className="ml-8 mt-20">
                                                            <div className="flex flex-col justify-between">
                                                                <div className="text-left font-semibold text-black"><h5>Audit Trail</h5></div>
                                                            </div>

                                                            <div className="mt-3 tableFixHead">
                                                                <table className="border-spacing-x-0 border-spacing-y-2 border-separate" >
                                                                    <thead>
                                                                        <tr className="whitespace-nowrap">
                                                                            <th scope="col" className="th-heading">S.No</th>
                                                                            <th scope="col" className="th-heading">Name</th>
                                                                            {/* <th scope="col" className="th-heading">Email</th>
                                                                            <th scope="col" className="th-heading">Phone</th> */}
                                                                            <th scope="col" className="th-heading">Emp Id</th>
                                                                            <th scope="col" className="th-heading">
                                                                                <Dropdown className="p-0 m-0" align="center">
                                                                                    <Dropdown.Toggle className="bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color focus:ring-0 text-semibold text-sm border-none" variant="secondary" id="dropdown-basic" style={{ padding: "0", border: "none" }}>
                                                                                        <div className="flex justify-between items-center">
                                                                                            <p className="text-sm font-bold">{searchText ? searchText.substring(0, 10) : "Branch"}</p>
                                                                                            <span className="text-sm">{searchText ? <i className='fa fa-remove fs-2xs ml-3' onClick={() => { setSearchText(''); handleClear(1) }} style={{ color: "white" }}></i> : <i className="fa fa-angle-down fs-xl ml-2" style={{ color: "white" }}></i>}</span>
                                                                                        </div>
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu>
                                                                                        <SearchBox placeholder="Search Branches" options={options} handleChange={filter} />
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </th>
                                                                            <th scope="col" className="th-heading">Status</th>
                                                                            <th scope="col" className="th-heading">
                                                                                <Dropdown className="p-0 m-0" align="end">
                                                                                    <Dropdown.Toggle className="bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color focus:ring-0 text-semibold text-sm border-none" variant="secondary" id="dropdown-basic" style={{ padding: "0", border: "none" }}>
                                                                                        <div className="flex justify-between items-center">
                                                                                            <p className="text-sm font-bold">{searchText2 ? searchText2.substring(0, 10) : "Last Activity"}</p>
                                                                                            <span className="text-sm">{searchText2 ? <i className='fa fa-remove fs-2xs ml-3' onClick={() => { setSearchText2(''); handleClear(2) }} style={{ color: "white" }}></i> : <i className="fa fa-angle-down fs-lg ml-2" style={{ color: "white" }}></i>}</span>
                                                                                        </div>
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu>
                                                                                        <SearchBox placeholder="Filter by Last Activity" options={activityOptions} handleChange={lastActivityFilter} />
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </th>
                                                                            <th scope="col" className="th-heading">Type</th>
                                                                            <th scope="col" ></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {searchText || searchText2 ?
                                                                            filteredData.length === 0 ?
                                                                                <tr className="whitespace-nowrap">

                                                                                </tr>
                                                                                :
                                                                                filteredData && filteredData.map((data, i) => (
                                                                                    <tr key={data.id} className="tr whitespace-nowrap">
                                                                                        <td className="">{i + 1}</td>
                                                                                        <td className="">{data.firstName}&nbsp;{data.lastName}</td>
                                                                                        <td className="">{data.employeeId}</td>
                                                                                        <td className="">{data.branchName}</td>
                                                                                        <td className="">{data.status === 1 ? "Active" : data.status === 3 ? "Saved" : "Inactive"}</td>
                                                                                        <td className="">{data.lastLogin?.split("T")[0].split("-").reverse().join("-")}</td>
                                                                                        <td className="" >{getEmployeeRole(data.role)}</td>
                                                                                        {/* <td className="items-center justify-center h-full bg-gray-200 rounded-r-3xl w-3 p-0 m-0">
                                                                                        <div onClick={() => toggleActivity(data.employeeId)} className="font-semibold text-center text-xs cursor-pointer"><img src={viewactivity} className="w-4" /></div>
                                                                                    </td> */}
                                                                                        <td className="flex text-right items-end justify-end">
                                                                                            <OverlayTrigger
                                                                                                placement="top"
                                                                                                delay={{ show: 250, hide: 400 }}
                                                                                                overlay={<Tooltip id="button-tooltip">{data.firstName}'s Activity</Tooltip>}
                                                                                            >
                                                                                                <div className="px-2 icon-w text-right" onClick={() => toggleActivity(data.employeeId)}>
                                                                                                    <div className="text-center items-end justify-center z-10"><img src={viewactivity} /></div>
                                                                                                </div>
                                                                                            </OverlayTrigger>
                                                                                        </td>
                                                                                    </tr>
                                                                                )) :

                                                                            employees && employees.map((data, i) => (
                                                                                <tr key={data.id} className="tr whitespace-nowrap">
                                                                                    <td className="">{i + 1}</td>
                                                                                    <td className="">{data.firstName}&nbsp;{data.lastName}</td>
                                                                                    <td className="">{data.employeeId}</td>
                                                                                    <td className="">{data.branchName}</td>
                                                                                    <td className="">{data.status === 1 ? "Active" : "Inactive"}</td>
                                                                                    <td className="">{data.lastLogin?.split("T")[0].split("-").reverse().join("-")}</td>
                                                                                    <td className="" >{getEmployeeRole(data.role)}</td>
                                                                                    {/* <td className="items-center justify-center h-full bg-gray-200 rounded-r-3xl w-3 p-0 m-0">
                                                                                    <div onClick={() => toggleActivity(data.employeeId)} className="ml-1 font-semibold text-center text-xs cursor-pointer"><img src={viewactivity} className="w-4" /></div>
                                                                                </td> */}
                                                                                    <td className="flex text-right items-end justify-end">
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            delay={{ show: 250, hide: 400 }}
                                                                                            overlay={<Tooltip id="button-tooltip">{data.firstName}'s Activity</Tooltip>}
                                                                                        >
                                                                                            <div className="px-2 icon-w text-right" onClick={() => toggleActivity(data.employeeId)}>
                                                                                                <div className="text-center items-end justify-center z-10"><img src={viewactivity} /></div>
                                                                                            </div>
                                                                                        </OverlayTrigger>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        : (section === "Targets") ?
                                                            <div>
                                                                <TargetsTable />
                                                            </div>
                                                            : (section === "SetCompanyTargets") ?
                                                                <AddTarget />
                                                                :
                                                                (section === "Branch Analytics") ?
                                                                    <BranchTargetsTable />
                                                                    : (section === "Cases") ?
                                                                        <Cases />
                                                                        : (section === "TC") ?
                                                                            <TermsConditions />
                                                                            :
                                                                            (section === "ApproveTargets") ?
                                                                                <ApproveTargets />
                                                                                : (section === "Rewards") ?
                                                                                    <RewardsConfig rewardsFreq={rewardsFreq} nonFinancialRewards={nonFinacialRewards} rewardTiers={rewardTiers} />
                                                                                    : (section === "Invoice") ?
                                                                                        <CompanyInvoice />
                                                                                        : (section === "ProductAnalytics") ?
                                                                                            <div className="flex flex-col justify-between ml-8 mt-20">
                                                                                                <div className="text-left font-semibold text-custom-bg-color">
                                                                                                    <h5>Product Analytics</h5>
                                                                                                </div>
                                                                                                <ProductAnalytics />
                                                                                            </div>
                                                                                            : (section === "BranchData") ?
                                                                                                <div className="flex flex-col justify-between ml-8 mt-20">
                                                                                                    <div className="text-left font-semibold text-custom-bg-color">
                                                                                                        <h5>Branch Analytics</h5>
                                                                                                    </div>
                                                                                                    <BranchAnalytics />
                                                                                                </div>
                                                                                                : (section === "CustomerData") ?
                                                                                                    <div className="flex flex-col justify-between ml-8 mt-20">
                                                                                                        <div className="text-left font-semibold text-custom-bg-color">
                                                                                                            <h5>Customer Analytics</h5>
                                                                                                        </div>
                                                                                                        <CustomerAnalyticsGraphs />
                                                                                                    </div>
                                                                                                    : section === "MarketPlace" ?
                                                                                                        <AllServices setSection={setSection} setServiceData={setServiceData} />
                                                                                                        : section === "Directory" ?
                                                                                                            <Register data={servcieData} />
                                                                                                            : section === "ViewLeads" ?
                                                                                                                <ViewLeadsFromDirectory />
                                                                                                                : section === "Scheduler" ?
                                                                                                                    <MeetingCalendarRegistration data={servcieData} />
                                                                                                                    : section === "Survey" ?
                                                                                                                        <SurveyServiceRegistration />
                                                                                                                        : <></>
                    }
                </div>

            </div>
        </div >
    )
}

export default CompanyAdmin
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import moment from 'moment-timezone';
import { OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import EmployeeContext from "../context/employee/EmpContext";
import { enqueueSnackbar } from "notistack";
import mail from "../images/mail.svg"
import info from "../images/info.svg"


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const AllInvoice = () => {
    const { empState: employee } = useContext(EmployeeContext)
    const navigate = useNavigate();
    const [invoices, setInvoices] = useState([]);
    const [subs, setSubs] = useState([]);
    const [key, setKey] = useState("Subscriptions");
    const [inactive, setInactive] = useState(false);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        getAllCompaniesInvoices();
        getAllSubscriptions();
    }, []);

    useEffect(() => {
        if (key === "Subscriptions") setTableData([...subs]);
        else if (key === "Invoices") setTableData([...invoices])
    }, [key])
    const getAllSubscriptions = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'superAdmin/allsubscriptions', { headers: headers })
            .then(function (response) {
                console.log("success", response.data.message, response.data);
                setSubs(response.data.subs);
                setTableData(response.data.subs)
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };

    const getAllCompaniesInvoices = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'superAdmin/getAllCompaniesInvoices', { headers: headers })
            .then(function (response) {
                console.log("success", response.data.message, response.data);
                setInvoices(response.data.invoices);
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const triggerMail = (data) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "companyId": data.companyId,
            "email": data.email,
            "firstName": data.firstName
        }
        setInactive(true);
        console.log("payload", payload)
        axios.post(config.apiUrl + 'superAdmin/sendMailForNewInvoice', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    enqueueSnackbar('Invoice Reminder sent to your regisstered mail successfully!', { variant: 'success' });
                    getAllCompaniesInvoices();
                } else {
                    console.log("Error", payload);
                    enqueueSnackbar('Something went wrong', { variant: 'error' })
                }
                setInactive(false);
            })
            .catch(function (error) {
                console.log("error", error, payload);
                enqueueSnackbar('internal sever error', { variant: 'error' });
                setInactive(false);
            });
    };
    const navigateToInvoice = (data) => {
        navigate(`/superadmin/${data.name}/generateinvoice`, { state: { data } })
    }
    return (
        <div className="ml-8 mt-20">
            <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3 text-left font-semibold text-black"
            >
                <Tab eventKey="Subscriptions" title="Subscriptions">
                </Tab>
                <Tab eventKey="Invoices" title="Invoices">
                </Tab>
                <Tab eventKey="Bills" title="Bills">
                </Tab>
            </Tabs>
            <div className="mt-3 tableFixHead">
                <table className="border-spacing-x-0 border-spacing-y-1" >
                    <thead>
                        <tr className="whitespace-nowrap text-center">
                            <th className="th-heading text-center w-5">S.No</th>
                            <th className="th-heading text-center">Company Name</th>
                            <th className="th-heading text-center">Registered Email</th>
                            <th className={`th-heading text-center ${key === "Subscriptions" ? "" : "hidden"}`}>Plan</th>
                            <th className={`th-heading text-center ${key === "Subscriptions" ? "" : "hidden"}`}>Validity in Years</th>
                            <th className="th-heading text-center">Generated On</th>
                            <th className="th-heading text-center">Due Date</th>
                            <th className={`th-heading text-center ${key === "Subscriptions" ? "hidden" : ""}`}>Reminder Count</th>
                            <th className="th-heading text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData && tableData.map((data, i) => (
                            <tr key={i} className="tr whitespace-nowrap text-center" >
                                <td className="td-text">{i + 1}</td>
                                <td className="td-text">{data.name}</td>
                                <td className="td-text">{data.email ? simpleCrypto.decrypt(data.email) : ""}</td>
                                <td className={`td-text ${key === "Subscriptions" ? "" : "hidden"}`}>{data.plan_name}</td>
                                <td className={`td-text ${key === "Subscriptions" ? "" : "hidden"}`}>{data.validity_years}</td>
                                <td className="td-text">{moment(data.createdAt).format("MM-DD-YYYY")}</td>
                                <td className="td-text">{moment(data.expiryDate).format("MM-DD-YYYY")}</td>
                                <td className={`td-text ${key === "Subscriptions" ? "hidden" : ""}`}>{data.reminder_count}</td>
                                <td className="flex text-right items-end justify-end gap-2">
                                    {key === "Invoices" && <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip id="button-tooltip">Send Mail</Tooltip>}
                                    >
                                        <div className={`px-2 icon-w text-right`}
                                            onClick={() => triggerMail(data)}>
                                            <div className="text-center items-end justify-center z-10">
                                                <img src={mail} />
                                            </div>
                                        </div>
                                    </OverlayTrigger>}
                                    {key === "Subscriptions" && <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip id="button-tooltip">Generate Invoice</Tooltip>}
                                    >
                                        <div className={`px-2 icon-w text-right`} onClick={() => navigateToInvoice(data)}>
                                            <div className="text-center items-end justify-center z-10 ">
                                                <img src={info} />
                                            </div>
                                        </div>
                                    </OverlayTrigger>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AllInvoice
import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import Navbar1 from "../Navbar/Navbar1";
import LeftMenu1 from "../LeftMenu/LeftMenu1";
import { enqueueSnackbar } from 'notistack'
import ErrorPopup from "../PopUps/ErrorPopup";
import EmployeeContext from "../context/employee/EmpContext";
import { Tab, Tabs } from "react-bootstrap";
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const TermsConditions = () => {
    const navigate = useNavigate();
    const { empState: employee } = useContext(EmployeeContext)
    const [showError, setShowError] = useState(false);
    const [msg, setMsg] = useState('');
    const [terms, setTerms] = useState("")
    const [conditions, setConditions] = useState("")
    const [errors, setErrors] = useState({});
    const [id, setId] = useState();
    const [key, setKey] = useState("term");

    useEffect(() => {
        getTermsAndConditions()
    }, []);


    const createTermsAndConditions = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: "error" })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "id": id,
            "terms": terms,
            "conditions": conditions
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/updateCompanyTermsAndCondition', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    enqueueSnackbar('Terms & Conditions Submitted Successfully!', { variant: "success" })
                    navigate(-1);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error.response.data.message);
                setMsg(error.response.data.message);
                setShowError(true);
                setTimeout(() => { setMsg(''); setShowError(false) }, 2000)
            });
    };
    const getTermsAndConditions = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "companyId": employee.companyId
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/getCompanyTermsAndCondition', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.terms) {
                    setTerms(response.data.terms.terms);
                    setConditions(response.data.terms.conditions);
                    setId(response.data.terms.id);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error.response.data.message);
                setMsg(error.response.data.message);
                setShowError(true);
                setTimeout(() => { setMsg(''); setShowError(false) }, 2000)
            });
    };
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!terms) {
            errorObj = {
                ...errorObj,
                termsError: true
            }
            error = true;
        }
        if (!conditions) {
            errorObj = {
                ...errorObj,
                conditionsError: true
            };
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    return (
        <div className="ml-8 mt-20">
            {/* <div className="flex justify-between">
                <div className="text-left font-semibold text-black"><h5>Terms & Conditions</h5></div>
            </div> */}
            {/* <label className="mt-2 text-sm text-black-600">Terms</label>
            <textarea className={`text-sm w-full px-4 py-2 resize-none ${errors.termsError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                type="text"
                rows={4}
                value={terms}
                onChange={(e) => { setTerms(e.target.value); setErrors({ ...errors, termsError: false }) }}
                name="terms"
                placeholder="Terms" />
            <label className="mt-3 text-sm text-black-600">Conditions</label>
            <textarea className={`text-sm w-full px-4 py-2 resize-none ${errors.conditionsError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                type="text"
                rows={4}
                value={conditions}
                onChange={(e) => { setConditions(e.target.value); setErrors({ ...errors, conditionsError: false }) }}
                name="conditions"
                placeholder="Conditions" /> */}
            <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3 text-left font-semibold text-black"
            >
                <Tab eventKey="term" title="Terms">
                        <label className="mt-2 text-sm text-black-600">Terms</label>
                        <textarea className={`text-sm w-full px-4 py-2 resize-none ${errors.termsError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                            type="text"
                            rows={12}
                            value={terms}
                            onChange={(e) => { setTerms(e.target.value); setErrors({ ...errors, termsError: false }) }}
                            name="terms"
                            placeholder="Terms" />
                </Tab>
                <Tab eventKey="condition" title="Conditions">
                        <label className="mt-2 text-sm text-black-600">Conditions</label>
                        <textarea className={`text-sm w-full px-4 py-2 resize-none ${errors.conditionsError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                            type="text"
                            rows={12}
                            value={conditions}
                            onChange={(e) => { setConditions(e.target.value); setErrors({ ...errors, conditionsError: false }) }}
                            name="conditions"
                            placeholder="Conditions" />
                </Tab>
            </Tabs>
            <div className="absolute right-10 bottom-8 text-center md:text-left">
                <button className="mt-4 bg-custom-bg-color hover:custom-bg-color text-custom-text-color px-4 py-2 font-semibold  uppercase rounded text-xs" onClick={createTermsAndConditions} type="submit">{id ? "Update" : "Submit"}</button>
            </div>
        </div>
    )
}

export default TermsConditions
import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { Modal } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import { enqueueSnackbar } from 'notistack';
import { config } from "../Config/Config";


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const RewardPopup = ({ show, togglePopup, data }) => {
    const [avalrewards, setAvalRewards] = useState([]);
    const [rewardSelected, setRewardSelected] = useState('');
    const [rewardName, setRewardName] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        console.log(data)
        getAvailableNonnFinRewards();
    }, []);
    const getAvailableNonnFinRewards = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'manager/getAvailableNonFinancialRewards', { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    setAvalRewards(response.data.availableNonFinancialRewards)
                } else {
                    console.log("Error");
                }
            }).catch((error) => {
                console.error('Error loading employees data:', error);
            })
    }
    const updateRM = () => {
        if (!rewardSelected) {
            enqueueSnackbar("Please fill the required fields", { variant: "error" });
            setErrors({ ...errors, rewardSelectedError: true });
            return;
        }
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            branchId: data.branchId,
            employeeId: data.employeeId,
            reward: rewardName
        }
        console.log(payload)
        axios.post(config.apiUrl + 'manager/addNonFinancialReward', payload, { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    enqueueSnackbar("Reward added successfully!!", { variant: "success" });
                    togglePopup();
                } else {
                    console.log("Error");
                }
            }).catch((error) => {
                enqueueSnackbar('Error adding reward', { variant: "error" });
                togglePopup();
            })
    }
    const handleEmpChange = (e) => {
        let val = JSON.parse(e.target.value);
        setRewardSelected(val);
        setRewardName(val.parameter)
        setErrors({ ...errors, rewardSelectedError: false });
    }

    return (
        <Modal show={show} onHide={togglePopup} centered contentClassName="ml-20 rounded-2xl h-[35vh] w-[25vw]">
            <Modal.Body>
                <div className="mt-4 max-w-md flex flex-col justify-center items-center">
                    <div className='-mt-4 mb-8 text-orange-400 text-center'>
                        <p className="text-xl">Give a Non Financial Reward</p>
                    </div>
                    <div className="my-2">
                        <select name="reward" value={rewardSelected} className={`text-md w-full px-4 py-2 ${errors.rewardSelectedError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-1`}
                            onChange={(e) => handleEmpChange(e)}>
                            <option value="" className="text-md">{rewardName ? rewardName : "--Select Reward--"}</option>
                            {avalrewards && avalrewards.map(reward => {
                                return (
                                    <option className="text-md" key={reward.id} value={JSON.stringify(reward)}>{reward.parameter}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="flex justify-center text-center md:text-left">
                        <button className="mt-3 btn-block bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-base" onClick={updateRM}>Update</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default RewardPopup
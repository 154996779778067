import { useContext, useEffect, useRef, useState } from 'react'
import EmployeeContext from '../../context/employee/EmpContext';
import { config } from '../../Config/Config';
import axios from 'axios';
import SimpleCrypto from 'simple-crypto-js';
import { enqueueSnackbar } from 'notistack';
import cancelBlack from "../../images/cancel_black.svg"
import Terms from './Terms';
import { Spinner } from 'react-bootstrap';
import PreviewPage from './PreviewDetailsPage';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const suggestionArray = ["Analysis", "Design", "Development", "Build", "Testing", "Project Management",
    "PMO", "Installation", "Sales & Support", "Sales & Services", "Training", "Loans", "Insurance",
    "Wealth", "Tours", "Travels", "Audit", "Consulting", "Governance", "Construction"];

const Register = ({ data }) => {
    const { empState: employee } = useContext(EmployeeContext);
    const [formData, setFormData] = useState({});
    const [industryTypes, setIndustryTypes] = useState([]);
    const [sectorTypes, setSectorTypes] = useState([]);
    const [tags, setTags] = useState([]);
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [adminDetails, setAdminDetails] = useState({});
    const [salesHeadDetails, setSalesHeadDetails] = useState({ name: "", email: "", contact: "" });
    const [loading, setLoading] = useState(true);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [detailsData, setDetailsData] = useState();
    const [showDetailsPopup, setShowDetailsPopup] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        console.log("emp", data)
        getSectors();
        getCompanyDetailsForDirectoryService();
        setAdminDetails({
            show: true,
            name: employee.firstName + " " + employee.lastName,
            email: simpleCrypto.decrypt(employee.email),
            mobile: employee.mobile
        })
        setSuggestions([...suggestionArray]);
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [])
    const getSectors = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Accept": 'application/json',
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/allAvailableIndustryTypes', { headers: headers })
            .then(function (response) {
                console.log("success available industryTypes", response.data);
                setIndustryTypes(response.data.industryTypes);
                setSectorTypes(response.data.sectorTypes);
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const getCompanyDetailsForDirectoryService = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getCompanyDirectoryServiceDetails', { headers: headers })
            .then(function (response) {
                console.log("success available details", response.data);
                setCity(response.data.city);
                setCountry(response.data.country);
                let newForm = {};
                if (response.data.details) {
                    newForm["register"] = true;
                    newForm["industryType"] = response.data.details.industryType;
                    newForm["sectorType"] = response.data.details.sectorType;
                    newForm["website"] = response.data.details.website;
                    newForm["description"] = response.data.details.description;
                    setSalesHeadDetails(response.data.details.sales_head_details);
                    setTags(response.data.details.tags);
                }
                setFormData({ ...newForm });
                setLoading(false);
            })
            .catch(function (error) {
                console.log("error", error);
                setLoading(false)
            });
    };
    const updateCompanyDetailsForDirectoryService = (e) => {
        e.preventDefault();
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            "companyId": employee.companyId,
            "name": employee.companyName,
            "city": city,
            "country": country,
            logo_url: employee.logo,
            admin_details: JSON.stringify(adminDetails),
            sales_head_details: JSON.stringify(salesHeadDetails),
            "industryType": employee.industryType && parseInt(employee.industryType),
            "sectorType": employee.sectorType && parseInt(employee.sectorType),
            "website": formData && formData.website,
            "description": formData && formData.description,
            "tags": JSON.stringify(tags),
            "is_active": true,
            "serviceId": data.id,
            "planId": data.planId
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/updateCompanyDirectoryServiceDetails', payload, { headers: headers })
            .then(function (response) {
                enqueueSnackbar("Details Added Successfully", { variant: "success" })
            })
            .catch(function (error) {
                console.log("error", error);
                enqueueSnackbar("Failed to Add Details.Something went wrong", { variant: "error" })
            });
    };
    let handleRemoveTag = (tagToRemove) => {
        let filteredTags = tags && tags.filter((_, i) => i !== tagToRemove)
        setTags(filteredTags);
    };
    let handleKeyDown = (event) => {
        if (event.key === "Enter") {
            const newTag = event.target.value.trim();
            if (!newTag) {
                enqueueSnackbar("Seacrh tag should not be empty", { variant: "error" });
                event.preventDefault();
                return;
            }
            if (tags.length === 5) {
                enqueueSnackbar("Cannot enter more than 5 search tags", { variant: "error" })
                event.preventDefault();
                return;
            }
            setTags([...tags, newTag])
            event.preventDefault();
            event.target.value = "";
            toggleShowSuggestion();
        }
        else if (event.key === 'Backspace') {
            setSuggestions([...suggestionArray])
        }
    }
    const handleInputchange = (event) => {
        const newTag = event.target.value.trim().toLowerCase();
        console.log("new tag", newTag)
        if (!newTag) {
            setSuggestions([...suggestionArray]);
            return;
        }
        let newSuggestions = suggestions && suggestions.filter(s => s.toLowerCase().includes(newTag));
        setSuggestions([...newSuggestions]);
    }
    const handleSuggestionClick = (suggestion) => {
        console.log("suggestion", suggestion)
        setTags([...tags, suggestion]);
        toggleShowSuggestion();
    };
    const handleClickOutside = (e) => {
        if (inputRef.current && !inputRef.current.contains(e.target)) {
            setShowSuggestions(false);
        }
    };
    const handleSelect = (e) => {
        setFormData({ ...formData, register: e.target.checked });
    }
    const handleAdminSelect = (e) => {
        if (e.target.checked) {
            setAdminDetails({
                show: true,
                name: employee.firstName + " " + employee.lastName,
                email: simpleCrypto.decrypt(employee.email),
                mobile: employee.mobile
            })
        } else {
            setAdminDetails({ show: e.target.checked })
        }
    }
    const toggleShowSuggestion = () => {
        setShowSuggestions(!showSuggestions);
        setSuggestions([...suggestionArray]);
    }
    const toggleDetailsPopup = (data = {}) => {
        setShowDetailsPopup(!showDetailsPopup);
    }
    const showDetailsPage = (e) => {
        e.preventDefault();
        let curData = {
            "name": employee.companyName,
            "address": city + "," + country,
            logo_url: employee.logo,
            admin_details: adminDetails,
            sales_head_details: salesHeadDetails,
            "industryType": industryTypes.find(it => it.id == employee.industryType),
            "sectorType": sectorTypes.find(st => st.id == employee.sectorType),
            "website": formData && formData.website,
            "description": formData && formData.description,
            "tags": tags
        }
        setDetailsData(curData)
        setShowDetailsPopup(true);
    }
    if (loading) {
        return (<div className='mt-20 flex justify-center items-center'><Spinner animation="border" /></div>)
    }
    return (
        <div className='ml-8 mt-16 mb-6 w-[80%] mx-auto'>
            {showDetailsPopup && <PreviewPage data={detailsData} togglePopup={toggleDetailsPopup} />}
            {!formData || !formData.register ?
                <Terms val={formData?.register || false} handleSelect={handleSelect} />
                : <form className='flex flex-col gap-4 text-sm'>
                    <p className='font-semibold text-lg my-1'>Enter Details To Be Shown To Users</p>
                    <div className='flex gap-3 w-2/3'>
                        <select name="industry" value={employee.industryType} className={`text-sm w-full p-2 border-[1px] border-gray-300 rounded-xl`}
                            onChange={(e) => { setFormData({ ...formData, industryType: e.target.value }) }} disabled>
                            <option value="">Select Industry Type</option>
                            {industryTypes && industryTypes.map(item => {
                                return (
                                    <option key={item.id} value={item.id}>{item.industryType}</option>
                                )
                            })}
                        </select>
                        <select name="sectorType" value={employee.sectorType} className={`text-sm w-full p-2 border-[1px] border-gray-300 rounded-xl`}
                            onChange={(e) => { setFormData({ ...formData, sectorType: e.target.value }) }} disabled>
                            <option value="">Select Sector Type</option>
                            {sectorTypes && sectorTypes.map(item => {
                                return (
                                    <option key={item.id} value={item.id}>{item.sectorType}</option>
                                )
                            })}
                        </select>
                    </div>
                    <input className='w-2/3 rounded-lg h-10 p-2 bg-white' disabled={formData && !formData.register}
                        value={formData && formData.website} placeholder='Website URL(optional)' onChange={(e) => setFormData({ ...formData, website: e.target.value })} />
                    <textarea
                        value={formData && formData.description}
                        onChange={e => setFormData({ ...formData, description: e.target.value })}
                        placeholder='Describe your services'
                        className='resize-none w-full p-2 bg-white'
                        rows={11}
                        disabled={formData && !formData.register}
                    />
                    <div className="flex flex-wrap items-start gap-2 p-2 rounded border border-black min-h-[50px] bg-white">
                        {tags && tags.map((tag, i) => {
                            return (
                                <div key={i} className="flex justify-between items-center gap-2 mr-2 rounded-xl min-w-[80px] text-center px-2 bg-custom-bg-color text-custom-text-color">{tag}<img className="float-right cursor-pointer w-5" src={cancelBlack} alt="" onClick={() => handleRemoveTag(i)} /></div>
                            )
                        })
                        }
                        <div>
                            <input type="text" className={`focus:outline-none ${tags.length > 4 ? "hidden" : ""}`} onFocus={toggleShowSuggestion} ref={inputRef}
                                onKeyDown={handleKeyDown} onChange={handleInputchange} placeholder="Enter Search Tag" maxLength="20" disabled={formData && !formData.register} />
                            {showSuggestions &&
                                <ul className='absolute bg-white border border-gray-300 mt-3.5 p-2 z-50 max-h-40 overflow-y-auto rounded-lg min-w-[12vw]'>
                                    <li className="font-semibold p-1 border-b-2 border-black">Suggested Tags</li>
                                    {suggestions && suggestions.length === 0 ?
                                        <li className="my-2 p-1">No Tags Found</li>
                                        : suggestions.map((suggestion, index) =>
                                            <li className="cursor-pointer hover:bg-gray-200 p-1" key={index}
                                                onClick={() => handleSuggestionClick(suggestion)}>
                                                {suggestion}
                                            </li>
                                        )}
                                </ul>}
                        </div>
                    </div>
                    <div className='flex justify-between items-center'>
                        <div className='flex gap-8'>
                            <div className='flex items-center gap-2'>
                                <input
                                    type='checkbox'
                                    onChange={(e) => handleAdminSelect(e)}
                                    value={adminDetails && adminDetails.show}
                                    name="showadmin"
                                    className='cursor-pointer'
                                    defaultChecked
                                />
                                <label className='font-semibold mt-1.5'>Show Company Admin Details in Search</label>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-between items-center'>
                        <input
                            type='text'
                            onChange={(e) => setSalesHeadDetails({ ...salesHeadDetails, name: e.target.value })}
                            value={salesHeadDetails && salesHeadDetails.name}
                            name="Sales Head Name"
                            className='cursor-pointer rounded-xl p-2 w-[25%]'
                            placeholder='Sales Head Name'
                        />
                        <input
                            type='text'
                            onChange={(e) => setSalesHeadDetails({ ...salesHeadDetails, email: e.target.value })}
                            value={salesHeadDetails && salesHeadDetails.email}
                            name="Sales Head Email"
                            className='cursor-pointer rounded-xl p-2 w-[25%]'
                            placeholder='Sales Head Email'
                        />
                        <input
                            type='text'
                            onChange={(e) => setSalesHeadDetails({ ...salesHeadDetails, contact: e.target.value })}
                            value={salesHeadDetails && salesHeadDetails.contact}
                            name="Sales Head Contact No"
                            className='cursor-pointer rounded-xl p-2 w-[25%]'
                            placeholder='Sales Head Contact No'
                        />
                    </div>
                    <div className='flex items-center justify-end gap-3 mt-3'>
                        <button className="bg-custom-bg-color hover:custom-bg-color text-custom-text-color p-2 px-4 font-semibold uppercase rounded text-xs whitespace-nowrap max-w-max"
                            onClick={(e) => showDetailsPage(e)}>Preview</button>
                        <button className="bg-custom-bg-color hover:custom-bg-color text-custom-text-color p-2 px-4 font-semibold uppercase rounded text-xs whitespace-nowrap max-w-max"
                            onClick={(e) => updateCompanyDetailsForDirectoryService(e)}>Save</button>
                    </div>
                </form>
            }
        </div >
    )
}

export default Register
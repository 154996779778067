import { useState, useEffect } from 'react';

function useDebounce(value) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            setDebouncedValue(value);
        }, 500);
        return () => {
            clearTimeout(debounceTimer);
        };
    }, [value]);

    return debouncedValue;
}

export default useDebounce;
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import Navbar1 from "../Navbar/Navbar1";
import LeftMenu1 from "../LeftMenu/LeftMenu1";
import { enqueueSnackbar } from 'notistack'
import ErrorPopup from "../PopUps/ErrorPopup";
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const CreateBranch = () => {
    const navigate = useNavigate();
    const locations = useLocation();
    const employee = locations.state;
    const [branchName, setBranchName] = useState('');
    const [branchCode, setBranchCode] = useState('');
    const [branchType, setBranchType] = useState('');
    const [branchTypes, setBranchTypes] = useState([]);
    const [selectedBranchType, setSelectedBranchType] = useState();
    const [branchArea, setBranchArea] = useState('');
    const [branchAreas, setBranchAreas] = useState([]);
    const [selectedBranchArea, setSelectedBranchArea] = useState();
    const [showError, setShowError] = useState(false);
    const [msg, setMsg] = useState('');
    const [location, setLocation] = useState("")
    const [city, setCity] = useState("")
    const [country, setCountry] = useState("")
    const [status, setStatus] = useState(1)
    const [errors, setErrors] = useState({});
    const [companyDetails, setCompanyDetails] = useState();

    useEffect(() => {
        getBranchType();
        let compDet = sessionStorage.getItem("cName") || localStorage.getItem("cName");
        if (compDet) {
            setCompanyDetails(simpleCrypto.decrypt(compDet))
        }

    }, []);
    useEffect(() => {
        if (companyDetails) {
            console.log("comp", companyDetails?.id, companyDetails?.country)
            setCountry(companyDetails?.country)
        }
    }, [companyDetails]);
    const handleChangeBranchType = (e) => {
        setSelectedBranchType(e.target.value);
    }
    const handleChangeBranchArea = (e) => {
        setSelectedBranchArea(e.target.value);
    }
    const getBranchType = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getAllBranchTypes', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, response.data);
                    setBranchTypes(response.data.branchTypes);
                    setBranchAreas(response.data.branchArea);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const createBranch = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: 'error' })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "branchName": branchName,
            "adminId": employee.id,
            "companyId": employee.companyId,
            "location": location,
            "branchCode": branchCode.toUpperCase(),
            "branchArea": selectedBranchArea == null ? branchArea : parseInt(selectedBranchArea),
            "branchType": selectedBranchType == null ? branchType : parseInt(selectedBranchType),
            "city": city,
            "country": country,
            "status": status
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/createBranch', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    enqueueSnackbar("Branch Details Created Successfully !", { variant: "success" })
                    navigate(`/${employee.companyName}/companyadmin/Branch`)
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error.response.data.message);
                setMsg(error.response.data.message);
                setShowError(true);
                setTimeout(() => { setMsg(''); setShowError(false) }, 2000)
            });
    };
    const isAlphanumeric = (input) => {
        const regex = /^[a-zA-Z0-9]+$/;
        if (!regex.test(input)) {
            setErrors({
                ...errors,
                branchCodeError: true
            })
            return
        }
        setBranchCode(input);
        setErrors({ ...errors, branchCodeError: false })
    }
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!branchName) {
            errorObj = {
                ...errorObj,
                branchNameError: true
            }
            error = true;
        }
        if (!location) {
            errorObj = {
                ...errorObj,
                locationError: true
            };
            error = true;
        }
        if (!city) {
            errorObj = {
                ...errorObj,
                cityError: true
            };
            error = true;
        }
        if (!country) {
            errorObj = {
                ...errorObj,
                countryError: true
            };
            error = true;
        }
        if (!branchCode || errors.branchCodeError) {
            errorObj = {
                ...errorObj,
                branchCodeError: true
            }
            error = true;
        }
        if (!selectedBranchType) {
            errorObj = {
                ...errorObj,
                branchTypeError: true
            }
            error = true;
        }
        if (!selectedBranchArea) {
            errorObj = {
                ...errorObj,
                branchAreaError: true
            }
            error = true;
        }

        setErrors({ ...errorObj });
        return error;
    }
    return (
        <section className="h-screen flex flex-col justify-center items-center mx-5">
            <ErrorPopup show={showError} setShow={setShowError} msg={msg} />
            <Navbar1 employee={employee} />
            <LeftMenu1 section="Create Branch" />
            <div><i onClick={() => navigate(`/${employee.companyName}/companyadmin/Branch`)} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i></div>

            <div className="md:w-1/3 max-w-sm -mt-10">
                <div className="text-center md:text-left">
                    <label className="mr-1 text-lg text-black-600">Create Branch</label>
                </div>
                <input className={`text-sm w-full px-4 py-2 ${errors.branchCodeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-4`}
                    type="text"
                    onChange={(e) => { isAlphanumeric(e.target.value) }}
                    name="branchCode"
                    pattern="[a-zA-Z0-9]+"
                    maxLength={15}
                    placeholder="Branch Code" />
                {errors.branchCodeError && <div className="text-red-700 text-xs">
                    <p>Please enter only alphanumeric characters</p>
                </div>}
                <input className={`text-sm w-full px-4 py-2 ${errors.branchNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-4`}
                    type="text"
                    onChange={(e) => { setBranchName(e.target.value); setErrors({ ...errors, branchNameError: false }) }}
                    name="branchName"
                    placeholder="Branch Name" />
                <select name="branchType" value={selectedBranchType} className={`text-sm w-full px-4 py-2 ${errors.branchTypeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-4`}
                    onChange={(e) => { handleChangeBranchType(e); setErrors({ ...errors, branchTypeError: false }) }}>
                    <option value="">Select Branch Type</option>
                    {branchTypes && branchTypes.map(bType => {
                        return (
                            <option key={bType.id} value={bType.id}>{bType.branchType}</option>
                        )
                    })}
                </select>
                <select name="branchArea" value={selectedBranchArea} className={`text-sm w-full px-4 py-2 ${errors.branchAreaError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-4`}
                    onChange={(e) => { handleChangeBranchArea(e); setErrors({ ...errors, branchAreaError: false }) }}>
                    <option value="">Select Branch Area</option>
                    {branchAreas && branchAreas.map(bArea => {
                        return (
                            <option key={bArea.id} value={bArea.id}>{bArea.branchArea}</option>
                        )
                    })}
                </select>
                {/* <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-4"
                    type="text"
                    onChange={(e) => setAdminId(e.target.value)}
                    name="adminId"
                    placeholder="Admin Id" />
                <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-4"
                    type="text"
                    onChange={(e) => setCompanyId(e.target.value)}
                    name="companyId"
                    placeholder="Company Id" /> */}
                <input className={`text-sm w-full px-4 py-2 ${errors.locationError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-4`}
                    type="text"
                    onChange={(e) => { setLocation(e.target.value); setErrors({ ...errors, locationError: false }) }}
                    name="location"
                    value={location}
                    placeholder="Location" />
                <input className={`text-sm w-full px-4 py-2 ${errors.cityError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-4`}
                    type="text"
                    onChange={(e) => { setCity(e.target.value); setErrors({ ...errors, cityError: false }) }}
                    name="city"
                    value={city}
                    placeholder="City" />
                <input className={`text-sm w-full px-4 py-2 ${errors.countryError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-4`}
                    type="text"
                    value={country}
                    onChange={(e) => { setCountry(e.target.value); setErrors({ ...errors, countryError: false }) }}
                    name="country"
                    placeholder="Country" />

                <div className="flex justify-end text-center md:text-left">


                    <button className="mt-4 bg-custom-bg-color hover:custom-bg-color text-custom-text-color px-4 py-2 font-semibold  uppercase rounded text-xs" onClick={createBranch} type="submit">Create</button>
                </div>
            </div>
        </section>
    )
}

export default CreateBranch
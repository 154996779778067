import React, { useState, useEffect } from "react";
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import tick from '../images/tick.svg';
import 'react-datepicker/dist/react-datepicker.css';
import "./Salesperson.css";
import axios from 'axios';
import moment from "moment-timezone";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const ActivityDetails = ({ data, closePopup, employee }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [interactions, setInteractions] = useState([]);
    const [activity, setActivity] = useState([]);
    const [dealsData, setDealsData] = useState('');
    const [keyAsk, setKeyAsk] = useState([]);
    const [occasions, setOccasions] = useState([]);
    const [satisfactions, setSatisfactions] = useState([]);
    const [potentials, setPotentials] = useState([]);
    const [notes, setNotes] = useState('');
    const [actionType, setActionType] = useState([]);
    const [actionStatus, setActionStatus] = useState([]);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        console.log(data);
        getAllParameters();
        getBranchProducts();
        data?.userActivity === 10 ? getDealsData() : getActivityNotes();
    }, []);
    const getAllParameters = () => {
        axios.get(config.apiUrl + 'employees/getAllParameters')
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data);
                    setInteractions(response.data.interactions);
                    setActivity(response.data.userActivity)
                    setKeyAsk(response.data.keyAsk);
                    setSatisfactions(response.data.satisfactions);
                    setPotentials(response.data.potentials);
                    setOccasions(response.data.occasions);
                    setActionType(response.data.actionTypes);
                    setActionStatus(response.data.actionStatus);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    }
    const getActivityNotes = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            activityId: data.id
        }
        axios.post(config.apiUrl + 'employees/getSalespersonNotesByActivity', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success salesperson notes", response.data);
                    setNotes(response.data.notes);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    }
    const getDealsData = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            activityId: data.id
        }
        axios.post(config.apiUrl + 'employees/getDealDetailsByActivity', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success deals data", response.data);
                    setDealsData(response.data.details);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    }
    const getBranchProducts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            branchId: employee.branchId
        }
        axios.post(config.apiUrl + 'employees/getBranchProducts', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success products", response.data);
                    setProducts(response.data.products);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const productEnquired = (productId) => {
        if (!productId) return
        let result = data?.products?.some(product => product == productId)
        return result;
    }
    const getInteraction = (interactionId) => {
        if (!interactionId) return
        let current_interaction = interactions.filter(item => item.id === interactionId)
        return current_interaction[0]?.interaction;
    }
    const getUserDemand = (userDemandId) => {
        if (!userDemandId) return
        let current_userDemand = keyAsk.filter(item => item.id === userDemandId)
        return current_userDemand[0]?.keyAsk;
    }
    const getActivity = (activityId) => {
        if (!activityId) return
        let current_activity = activity.filter(item => item.id === activityId)
        return current_activity[0]?.activity;
    }
    const getOccasion = (activityId) => {
        if (!activityId) return
        let current_occasion = occasions.filter(item => item.id === activityId)
        return current_occasion[0]?.occasion;
    }
    const getSatisfaction = (activityId) => {
        if (!activityId) return
        let current_satisfaction = satisfactions.filter(item => item.id === activityId)
        return current_satisfaction[0]?.satisfaction;
    }
    const getPotential = (activityId) => {
        if (!activityId) return
        let current_potential = potentials.filter(item => item.id === activityId)
        return current_potential[0]?.potential;
    }
    const getActionType = (activityId) => {
        if (!activityId) return
        let current_type = actionType.filter(item => item.id === activityId)
        return current_type[0]?.action;
    }
    const getActionStatus = (activityId) => {
        if (!activityId) return
        let current_status = actionStatus.filter(item => item.id === activityId)
        return current_status[0]?.status;
    }
    return (
        <div>
            <Modal
                show={true}
                onHide={closePopup}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >   <div className="my-6 flex flex-col justify-center items-center mx-11">
                    <div className="text-center md:text-left">
                        <label className="mr-1 text-lg text-black-600">Customer Activity Details</label>
                    </div>
                    <div className="flex gap-6 w-full items-center mt-3">
                        <div className="flex flex-col items-start">
                            <label className="font-semibold mr-1 mt-2 text-sm text-black-600">Occasion of visit</label>
                            <p className="text-sm w-48 h-9 p-2 border border-solid border-gray-300 rounded">{getOccasion(data.occasion)}</p>
                        </div>
                        <div className="flex flex-col items-start">
                            <label className="font-semibold mr-1 mt-2 text-sm text-black-600">User Activity</label>
                            <p className="text-sm w-48 h-9 p-2 border border-solid border-gray-300 rounded">{getActivity(data.userActivity)}</p>
                        </div>
                    </div>
                    <div className="flex gap-6 w-full items-center mt-3">
                        <div className="flex flex-col items-start">
                            <label className="font-semibold mr-1 mt-2 text-sm text-black-600">Interaction</label>
                            <p className="text-sm w-48 h-9 p-2 border border-solid border-gray-300 rounded">{getInteraction(data.interaction)}</p>
                        </div>
                        <div className="flex flex-col items-start">
                            <label className="font-semibold mr-1 mt-2 text-sm text-black-600">Customer Demand</label>
                            <p className="text-sm w-48 h-9 p-2 border border-solid border-gray-300 rounded">{getUserDemand(data.userDemand)}</p>
                        </div>
                    </div>
                    <div className="flex gap-6 w-full items-center mt-3">
                        <div className="flex flex-col items-start">
                            <label className="font-semibold mr-1 mt-2 text-sm text-black-600">Customer Satisfaction</label>
                            <p className="text-sm w-48 h-9 p-2 border border-solid border-gray-300 rounded">{getSatisfaction(data.customerSatisfaction)}</p>
                        </div>
                        <div className="flex flex-col items-start">
                            <label className="font-semibold mr-1 mt-2 text-sm text-black-600">Customer Potential</label>
                            <p className="text-sm w-48 h-9 p-2 border border-solid border-gray-300 rounded">{getPotential(data.customerPotential)}</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 w-full justify-between items-center mt-2 gap-x-6 gap-y-4">
                        {products && products.map(product => {
                            return (
                                <div className="flex items-center gap-1" key={product.id}>
                                    <span className={`w-3 h-3 border border-gray-600 rounded-[3px] ${productEnquired(product.id) ? "bg-orange-700" : ""}`}>
                                        <img src={tick} alt="" className={`text-white ${productEnquired(product.id) ? "" : "hidden"}`} />
                                    </span>
                                    <span className="text-xs">{product.productFamilyName.length > 25 ? product.productFamilyName.substring(0, 24) + "..." : product.productFamilyName}</span>
                                </div>
                            )
                        })}
                    </div>
                    {data && data.userActivity === 10 ?
                        <div className="flex gap-6 w-full items-center mt-3">
                            <div className="flex flex-col items-start">
                                <label className="font-semibold mr-1 mt-2 text-sm text-black-600">Deal Status</label>
                                <p className="text-sm w-48 h-9 p-2 border border-solid border-gray-300 rounded">{dealsData && dealsData.dealStatus}</p>
                            </div>
                            <div className="flex flex-col items-start">
                                <label className="font-semibold mr-1 mt-2 text-sm text-black-600">Created On</label>
                                <p className="text-sm w-48 h-9 p-2 border border-solid border-gray-300 rounded">{dealsData ? moment(dealsData.createdAt).format("DD-MM-YYYY") : ""}</p>
                            </div>
                        </div>
                        : <>
                            <div className="flex flex-col w-full items-start mt-3">
                                <label className="font-semibold mr-1 text-sm text-black-600">Salesperson Notes</label>
                                <p className="text-sm w-full h-16 p-2 border border-solid border-gray-300 rounded">{notes?.notes}</p>
                            </div>
                            <div className="flex gap-6 w-full items-center mt-3">
                                <div className="flex flex-col items-start">
                                    <label className="font-semibold mr-1 mt-2 text-sm text-black-600">Target Date</label>
                                    <p className="text-sm w-48 h-9 p-2 border border-solid border-gray-300 rounded">{notes?.targetDate ? moment(notes.targetDate).format("DD-MM-YY HH:mm a") : ""}</p>
                                </div>
                                <div className="flex flex-col items-start">
                                    <label className="font-semibold mr-1 mt-2 text-sm text-black-600">Action Status</label>
                                    <p className="text-sm w-48 h-9 p-2 border border-solid border-gray-300 rounded">{getActionStatus(notes?.status)}</p>
                                </div>
                            </div>
                            <div className={`flex gap-6 w-full items-center mt-3 ${notes?.status == 3 || notes?.status == 4 ? "" : "hidden"}`}>
                                <div className="flex flex-col items-start">
                                    <label className="font-semibold mr-1 mt-2 text-sm text-black-600">Completion Date</label>
                                    <p className="text-xs w-48 h-9 p-2 border border-solid border-gray-300 rounded">{notes?.updatedAt ? moment(notes.updatedAt).format("MMMM DD,YY HH:mm a") : ""}</p>
                                </div>
                                <div className="flex flex-col items-start">
                                    <label className="font-semibold mr-1 mt-2 text-sm text-black-600">Action Type</label>
                                    <p className="text-sm w-48 h-9 p-2 border border-solid border-gray-300 rounded">{getActionType(notes?.actionType)}</p>
                                </div>
                            </div>
                        </>}
                </div>
                <div className="flex justify-end text-center my-3 mx-12">
                    <button className="mt-4 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white font-semibold uppercase rounded text-xs focus:outline-none" onClick={closePopup}>Close</button>
                </div>
            </Modal >
        </div >
    )
}

export default ActivityDetails
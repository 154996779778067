import React, { useContext, useState } from 'react'
import Navbar1 from '../../Navbar/Navbar1';
import LeftMenu1 from '../../LeftMenu/LeftMenu1'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { config } from '../../Config/Config'
import SimpleCrypto from 'simple-crypto-js';
import { enqueueSnackbar } from 'notistack';
import EmployeeContext from '../../context/employee/EmpContext';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const CommonTermsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { data } = location.state;
    const { empState: employee } = useContext(EmployeeContext);
    const [isActive, setIsActive] = useState(true);
    console.log(data)
    const handleSelect = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            "serviceId": data.id,
            "isActive": isActive
        }
        console.log(payload)
        axios.post(config.apiUrl + 'companyAdmin/addExternalServiceToCompany', payload, { headers: headers })
            .then(response => {
                console.log("service subscription added successfully", response.data);
                enqueueSnackbar("Service Subscription Added Successfully", { variant: "success" });
                if (isActive) {
                    setTimeout(() => {
                        window.open(data.url, '_blank');
                    }, [2000])
                }
            }).catch(error => {
                console.error('Error Adding Service Subscription: ', error);
                enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
            });
    }
    return (
        <>
            <Navbar1 />
            <LeftMenu1 section="Register" />
            <div><i onClick={() => navigate(`/${employee.companyName}/companyadmin/MarketPlace`)} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i></div>
            <div className='flex flex-col gap-3 ml-[25vw] w-[65vw] mt-20 justify-center items-center'>
                <p className='font-bold text-xl'>
                    Terms and conditions on usage of "{data.serviceName}"
                </p>
                <div className='flex flex-col gap-3 font-semibold text-gray-600 border-2 border-black rounded-xl p-3 w-full min-h-[40vh]'>
                    {data && data["terms"]}
                </div>
                <div className='flex items-center gap-4 mt-4'>
                    <input
                        type='checkbox'
                        onChange={(e) => setIsActive(e.target.checked)}
                        value={isActive}
                        name="register"
                        className='cursor-pointer'
                    />
                    <label className='font-semibold mt-1.5'>By accepting above said information and details,
                        you have understood and participate to provide your company information.</label>
                </div>
                <button onClick={handleSelect}
                    className="max-w-max ml-auto bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs"
                >AGREE</button>
            </div>
        </>
    )
}

export default CommonTermsPage
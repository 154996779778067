import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { Modal } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import { enqueueSnackbar } from 'notistack';
import { config } from "../../Config/Config";
import EmployeeContext from "../../context/employee/EmpContext";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const ReassignDealPopup = ({ show, togglePopup, data, getEmployeeDeals }) => {
    const { empState: employee } = useContext(EmployeeContext)
    const [employees, setEmployees] = useState([]);
    const [empSelected, setEmpSelected] = useState('');
    const [errors, setErrors] = useState({});
    const [empName, setEmpName] = useState("")

    useEffect(() => {
        console.log(data)
        getAllEmployeesByBranch();
    }, []);
    const getAllEmployeesByBranch = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'manager/getAllEmployeesByBranch', { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    console.log("success employees fetched form query", response.data);
                    let curActiveEmps = response.data.employees.filter(emp => emp.status === 1);
                    let curEmps = curActiveEmps && curActiveEmps.filter(emp => emp.role !== 2 && emp.id !== data.salesmanId);
                    setEmployees(curEmps)
                } else {
                    console.log("Error");
                }
            }).catch((error) => {
                console.error('Error loading employees data:', error);
            })
    }
    const updateRM = () => {
        if (!empSelected) {
            enqueueSnackbar("Please fill the required fields", { variant: "error" });
            setErrors({ ...errors, empSelectedError: true });
            return;
        }
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            id: data.id,
            newSalesmanId: empSelected.id,
            name: employee.firstName + " " + employee.lastName,
            empId: employee.employeeId,
            currentStatus: data.dealStatus,
            proposal: false,
            newEmpName: empSelected.firstName + " " + empSelected.lastName
        }
        console.log(payload)
        axios.post(config.apiUrl + 'manager/updateRMForDeal', payload, { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    enqueueSnackbar("RM updated successfully!!", { variant: "success" });
                    getEmployeeDeals();
                    togglePopup();
                } else {
                    console.log("Error");
                }
            }).catch((error) => {
                enqueueSnackbar('Error updating RM', { variant: "error" });
                togglePopup();
            })
    }
    const handleEmpChange = (e) => {
        let currentEmp = JSON.parse(e.target.value);
        console.log(currentEmp);
        setEmpSelected(JSON.parse(e.target.value));
        setErrors({ ...errors, empSelectedError: false })
        setEmpName(currentEmp.firstName + " " + currentEmp.lastName)
    }
    
    return (
        <Modal show={show} onHide={togglePopup} size="sm" centered contentClassName="rounded-2xl">
            <Modal.Body>
                <div className="-mt-4">
                    <div className='-mt-4 mb-8 text-orange-400 text-center'>
                        <p className="text-xl">Reassign RM</p>
                    </div>
                    <div className="my-2">
                        <select name="employee" value={empSelected} className={`text-md w-full px-4 py-2 ${errors.empSelectedError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-1`}
                            onChange={(e) => handleEmpChange(e)}>
                            <option value="" className="text-md">{empName ? empName : "---Select RM---"}</option>
                            {employees && employees.map(employee => {
                                return (
                                    <option className="text-md" key={employee.id} value={JSON.stringify(employee)}>{employee.firstName}&nbsp;{employee.lastName}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="flex justify-center text-center md:text-left">
                        <button className="mt-3 btn-block bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-base" onClick={updateRM}>Update</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ReassignDealPopup
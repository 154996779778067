import React, { useState, useEffect } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import Navbar1 from "../Navbar/Navbar1";
import LeftMenu1 from "../LeftMenu/LeftMenu1";
import { enqueueSnackbar } from 'notistack'

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const CustomerActivity = () => {
    const navigate = useNavigate();
    const locations = useLocation();
    const employee = locations.state?.employee;
    const customer = locations.state?.customer;
    const currentNotes = locations.state?.notes;
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [leadTypes, setLeadTypes] = useState([]);
    const [interaction, setInteraction] = useState([]);
    const [activity, setActivity] = useState([]);
    const [selectedLeadTypes, setSelectedLeadTypes] = useState();
    const [selectedInteraction, setSelectedInteraction] = useState();
    const [selectedActivity, setSelectedActivity] = useState();
    const [customerInterest, setCustomerInterest] = useState('');
    const [keyAsk, setKeyAsk] = useState([]);
    const [selectedKeyAsk, setSelectedKeyAsk] = useState();
    const [satisfactions, setSatisfactions] = useState([]);
    const [selectedSatisfaction, setSelectedSatisfaction] = useState();
    const [potentials, setPotentials] = useState([]);
    const [selectedPotential, setSelectedPotential] = useState();
    const [notes, setNotes] = useState('');
    const [targetDate, setTargetDate] = useState();
    const [occasions, setOccasions] = useState([]);
    const [place, setPlace] = useState([]);
    const [selectedOccasion, setSelectedOccasion] = useState();
    const [selectedPlace, setSelectedPlace] = useState();
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [leadTypePresent, setLeadTypePresent] = useState(false);
    const [placePresent, setPlacePresent] = useState(false);
    const [actionTypes, setActionTypes] = useState([]);
    const [selectedActionType, setSelectedActionType] = useState();
    const [actionStatus, setActionStatus] = useState([]);
    const [selectedActionStatus, setSelectedActionStatus] = useState();
    const [errors, setErrors] = useState({});
    const [genders, setGenders] = useState([]);
    const [selectedGender, setSelectedGender] = useState();
    const [genderPresent, setGenderPresent] = useState(false);
    const [importantDates, setImportantDates] = useState([
        { "occasion": "", "date": null },
        { "occasion": "", "date": null },
        { "occasion": "", "date": null }
    ]);


    useEffect(() => {
        getAllParameters();
        getBranchProducts();
    }, [])
    useEffect(() => {
        console.log("Customer useeffect caleed", customer, currentNotes);
        setFirstName(customer?.firstName);
        setLastName(customer?.lastName)
        if (customer?.email)
            setEmail(simpleCrypto.decrypt(customer?.email))
        if (customer?.mobile)
            setMobile(simpleCrypto.decrypt(customer?.mobile))
        if (customer.leadType) {
            setLeadTypePresent(true)
            setSelectedLeadTypes(customer.leadType)
        }
        if (customer.region) {
            setPlacePresent(true);
            setSelectedPlace(customer?.region);
        }
        if (customer.gender) {
            setGenderPresent(true);
            setSelectedGender(customer.gender);
        }
        if (currentNotes?.actionType) {
            setSelectedActionType(currentNotes?.actionType);
            currentNotes.actionType === 1 ? setSelectedActivity(5) : setSelectedActivity(6)
        }
        if (currentNotes?.notes) {
            setNotes(currentNotes.notes);
        }
        if (customer.importantDates) {
            let imporatant_dates = customer.importantDates.map(item => {
                let obj = {
                    date: item.date ? new Date(item.date) : null,
                    occasion: item.occasion
                }
                return obj;
            })
            setImportantDates([...imporatant_dates])
        }
    }, [customer])
    const addCustomerActivity = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: "error" })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "branchName": employee.branchName,
            "branchId": employee.branchId,
            "companyId": employee.companyId,
            "customerId": customer?.customerId ? customer.customerId : customer.id,
            "firstName": firstName,
            "lastName": lastName,
            "email": email ? simpleCrypto.encrypt(email) : email,
            "mobile": mobile ? simpleCrypto.encrypt(mobile) : mobile,
            "leadType": parseInt(selectedLeadTypes),
            "interaction": selectedInteraction ? parseInt(selectedInteraction) : null,
            "userActivity": parseInt(selectedActivity),
            "salesmanId": employee.id,
            "userDemand": selectedKeyAsk ? parseInt(selectedKeyAsk) : null,
            "customerSatisfaction": selectedSatisfaction ? parseInt(selectedSatisfaction) : null,
            "customerPotential": selectedPotential ? parseInt(selectedPotential) : null,
            "customersInterest": customerInterest,
            "notes": notes,
            "targetDate": targetDate,
            "employeeId": employee.employeeId,
            "products": JSON.stringify(selectedProducts),
            "occasion": selectedOccasion ? parseInt(selectedOccasion) : null,
            "region": selectedPlace ? parseInt(selectedPlace) : null,
            "visitId": customer?.visitId,
            "actionType": selectedActionType ? parseInt(selectedActionType) : null,
            "actionStatus": selectedActionStatus ? parseInt(selectedActionStatus) : null,
            "gender": parseInt(selectedGender),
            "importantDates": parseInt(selectedActivity) === 3 || parseInt(selectedActivity) === 4 ? JSON.stringify(importantDates) : null,
            "dashboardId": customer.id ? customer.id : "",
            "reachOutId": customer?.reachOutId ? customer.reachOutId : ""
        }
        console.log("payload", payload)
        let apiEndpoint = 'employees/createSalesPersonDashboard'
        if (customer?.reachOutId) {
            apiEndpoint = 'employees/createSalesPersonReachOut'
        }
        axios.post(config.apiUrl + 'employees/addCustomerActivity', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                payload["activityId"] = response.data.customerActivity.id;
                axios.post(config.apiUrl + 'employees/addCustomerDetails', payload, { headers: headers })
                    .then((response) => {
                        console.log(response);
                        if (notes) {
                            axios.post(config.apiUrl + apiEndpoint, payload, { headers: headers })
                                .then((response) => {
                                    if (response.data.status == true) {
                                        console.log("success", response.data.message, payload);
                                        navigate(-1);
                                    } else {
                                        console.log("Error", payload);
                                    }
                                })
                                .catch(function (error) {
                                    console.log("error", error, payload);
                                });
                        } else {
                            console.log("success", response.data.message);
                            navigate(-1);
                        }
                    })
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const getAllParameters = () => {
        axios.get(config.apiUrl + 'employees/getAllParameters')
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message);
                    setLeadTypes(response.data.leadTypes);
                    var a = response.data.interactions.filter((data, i) => moment(customer?.createdAt).format("MM/DD/YYYY") < moment(new Date()).format("MM/DD/YYYY") && data.id === 1 ? null : data)
                    setInteraction(a);
                    setActivity(response.data.userActivity)
                    setKeyAsk(response.data.keyAsk);
                    setOccasions(response.data.occasions);
                    setPlace(response.data.region)
                    setSatisfactions(response.data.satisfactions);
                    setPotentials(response.data.potentials);
                    setActionTypes(response.data.actionTypes);
                    setActionStatus(response.data.actionStaus);
                    setGenders(response.data.gender);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    }
    const getBranchProducts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            branchId: employee.branchId
        }
        axios.post(config.apiUrl + 'employees/getBranchProducts', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success products", response.data);
                    setProducts(response.data.products);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const handleChange = (val, arr, func) => {
        func(val);
    }
    const getCurrentLeadType = (typeId) => {
        let leadType = leadTypes.find(item => item.id === typeId);
        return leadType?.leadType;
    }
    const getCurrentPlace = (typeId) => {
        let places = place.find(item => item.id === typeId);
        return places?.place;
    }
    const getCurrentGender = (typeId) => {
        let gender = genders?.find(item => item.id === typeId);
        return gender?.gender;
    }
    const getActionType = (typeId) => {
        let actionType = actionTypes.find(item => item.id === typeId);
        return actionType?.actionType;
    }
    const selectProducts = (e) => {
        let value = (e.target.value);
        if (e.target.checked) {
            setSelectedProducts([...selectedProducts, value])
        }
        else {
            setSelectedProducts(selectedProducts.filter(product => product !== value))
        }
    }
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!firstName) {
            errorObj = {
                ...errorObj,
                firstNameError: true
            }
            error = true;
        }
        if (!lastName) {
            errorObj = {
                ...errorObj,
                lastNameError: true
            };
            error = true;
        }
        if (!selectedGender) {
            errorObj = {
                ...errorObj,
                genderError: true
            };
            error = true;
        }
        if (!email) {
            errorObj = {
                ...errorObj,
                emailError: true
            }
            error = true;
        }
        if (!mobile) {
            errorObj = {
                ...errorObj,
                mobileError: true
            }
            error = true;
        }
        if (!selectedLeadTypes) {
            errorObj = {
                ...errorObj,
                leadTypeError: true
            }
            error = true;
        }
        if (!selectedActivity) {
            errorObj = {
                ...errorObj,
                activityError: true
            }
            error = true;
        }
        if (!selectedPlace) {
            errorObj = {
                ...errorObj,
                placeError: true
            }
            error = true;
        }
        if (!selectedOccasion) {
            errorObj = {
                ...errorObj,
                occasionError: true
            }
            error = true;
        }
        if (!selectedKeyAsk) {
            errorObj = {
                ...errorObj,
                keyAskError: true
            }
            error = true;
        }
        if (!selectedInteraction) {
            errorObj = {
                ...errorObj,
                interactionError: true
            }
            error = true;
        }
        if (!selectedSatisfaction) {
            errorObj = {
                ...errorObj,
                satisfactionError: true
            }
            error = true;
        }
        if (!selectedPotential) {
            errorObj = {
                ...errorObj,
                potentialError: true
            }
            error = true;
        }
        if (notes && !targetDate) {
            errorObj = {
                ...errorObj,
                targetDateError: true
            }
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    const handleOccasionChange = (i, key, value) => {
        let data = [...importantDates]
        data[i] = { ...data[i], [key]: value }
        console.log(data);
        setImportantDates([...data]);
    }
    return (
        <section className="h-screen flex flex-col">
            <Navbar1 employee={employee} />
            <LeftMenu1 section="Add Activity" />
            <div><i onClick={() => navigate(-1)} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i></div>
            <div className="my-16 ml-[18rem] mr-[3rem]">
                <div className="text-center md:text-left mb-4">
                    <label className="mr-1 text-lg text-black-600 md:whitespace-nowrap">Update Customer Activity-({employee.branchName})</label>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                    <div className="flex flex-col gap-1">
                        <p className="text-base font-medium">Details</p>
                        <input className="text-sm w-full p-2 border border-solid border-gray-300 rounded mt-2"
                            type="text"
                            onChange={(e) => setFirstName(e.target.value)}
                            value={firstName}
                            name="firstName"
                            placeholder="First Name" />
                        <input className="text-sm  w-full p-2 border border-solid border-gray-300 rounded mt-2"
                            type="text"
                            onChange={(e) => setLastName(e.target.value)}
                            value={lastName}
                            name="lastName"
                            placeholder="Last Name" />
                        <input className={`text-sm w-full p-2 ${errors.emailError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                            type="text"
                            onChange={(e) => { setEmail(e.target.value); setErrors({ ...errors, emailError: false }) }}
                            value={email}
                            name="email"
                            placeholder="Customer Email" />
                        <input className={`text-sm w-full p-2 ${errors.mobileError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                            type="tel"
                            pattern="[0-9]{10}"
                            maxLength="10"
                            onChange={(e) => { setMobile(e.target.value); setErrors({ ...errors, mobileError: false }) }}
                            value={mobile}
                            name="mobile"
                            placeholder="Customer Mobile" />
                        {leadTypePresent ?
                            <p className="text-sm w-full p-2 border border-solid border-gray-300 rounded mt-2 h-10">{getCurrentLeadType(selectedLeadTypes)}</p>
                            :
                            <select name="leadTypes" value={selectedLeadTypes} className={`text-sm w-full p-2 ${errors.leadTypeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                                onChange={(e) => { handleChange(e.target.value, leadTypes, setSelectedLeadTypes); setErrors({ ...errors, leadTypeError: false }) }} >
                                <option value="">{selectedLeadTypes ? getCurrentLeadType(selectedLeadTypes) : "---Lead Type---"}</option>
                                {leadTypes && leadTypes.map(leadType => {
                                    return (
                                        <option value={leadType.id} key={leadType.id}>{leadType.leadType}</option>
                                    )
                                })}
                            </select>
                        }
                        {placePresent ?
                            <p className="text-sm w-full p-2 border border-solid border-gray-300 rounded mt-2 h-10">{getCurrentPlace(selectedPlace)}</p> :
                            <select name="place" value={selectedPlace} className={`text-sm w-full p-2 ${errors.placeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                                onChange={(e) => { handleChange(e.target.value, place, setSelectedPlace); setErrors({ ...errors, placeError: false }) }} >
                                <option value="">{selectedPlace ? getCurrentPlace(selectedPlace) : "----Place----"}</option>
                                {place && place.map(item => {
                                    return (
                                        <option value={item.id} key={item.id}>{item.place}</option>
                                    )
                                })}
                            </select>
                        }
                        {genderPresent ?
                            <p className="text-sm w-full p-2 border border-solid border-gray-300 rounded mt-2 h-10">{getCurrentGender(selectedGender)}</p> :
                            <select name="genders" value={selectedGender} className={`text-sm w-full p-2 ${errors.genderError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                                onChange={(e) => { handleChange(e.target.value, genders, setSelectedGender); setErrors({ ...errors, genderError: false }) }} >
                                <option value="">{selectedGender ? getCurrentGender(selectedGender) : "---Select Gender---"}</option>
                                {genders && genders.map(gender => {
                                    return (
                                        <option value={gender.id} key={gender.id}>{gender.gender}</option>
                                    )
                                })}
                            </select>
                        }
                    </div>
                    <div className="flex flex-col gap-1">
                        <p className="text-base font-medium">Choices</p>
                        <select name="occasion" value={selectedOccasion} className={`text-sm w-full p-2 ${errors.occasionError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                            onChange={(e) => { handleChange(e.target.value, occasions, setSelectedOccasion); setErrors({ ...errors, occasionError: false }) }} >
                            <option value="">---Reason of visit---</option>
                            {occasions && occasions.map(item => {
                                return (
                                    <option value={item.id} key={item.id}>{item.occasion}</option>
                                )
                            })}
                        </select>
                        <select name="keyAsk" value={selectedKeyAsk} className={`text-sm w-full p-2 ${errors.keyAskError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                            onChange={(e) => { handleChange(e.target.value, keyAsk, setSelectedKeyAsk); setErrors({ ...errors, keyAskError: false }) }} >
                            <option value="">---Cutomer's Ask---</option>
                            {keyAsk && keyAsk.map(item => {
                                return (
                                    <option value={item.id} key={item.id}>{item.keyAsk}</option>
                                )
                            })}
                        </select>
                        <div className="grid grid-cols-2 justify-between ml-2 items-center mt-2 w-full">
                            {products && products.map(product => {
                                return (
                                    <div key={product.id} className="flex">
                                        <label htmlFor={JSON.stringify(product.id)} className="cursor-pointer">
                                            <input
                                                type="checkbox"
                                                className="cursor-pointer"
                                                id={JSON.stringify(product.id)}
                                                name={product.productFamilyName}
                                                value={product.id}
                                                onChange={(e) => selectProducts(e)}
                                            />
                                            &nbsp;{product.productFamilyName}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="flex flex-col gap-1">
                        <p className="text-base font-medium">Actions</p>
                        <select name="activity" value={selectedActivity} className={`text-sm w-full p-2 ${errors.activityError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                            onChange={(e) => { handleChange(e.target.value, activity, setSelectedActivity); setErrors({ ...errors, activityError: false }) }} >
                            <option value="">---User Activity---</option>
                            {activity && activity.map(item => {
                                return (
                                    <option value={item.id} key={item.id}>{item.activity}</option>
                                )
                            })}
                        </select>
                        <select name="interaction" value={selectedInteraction} className={`text-sm w-full p-2 ${errors.interactionError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                            onChange={(e) => { handleChange(e.target.value, interaction, setSelectedInteraction); setErrors({ ...errors, interactionError: false }) }} >
                            <option value="">---Interaction---</option>
                            {interaction && interaction.map(item => {
                                return (
                                    <option value={item.id} key={item.id}>{item.interaction}</option>
                                )
                            })}
                        </select>
                        <div className={`flex flex-col ${parseInt(selectedActivity) === 3 || parseInt(selectedActivity) === 4 ? "" : "hidden"}`}>
                            <div className="grid grid-cols-2 gap-1">
                                <input className={`text-sm w-full p-2 border-[1px] border-gray-300 rounded mt-2`}
                                    type="text"
                                    onChange={(e) => { handleOccasionChange(0, "occasion", e.target.value) }}
                                    value={importantDates && importantDates[0] ? importantDates[0].occasion : ""}
                                    name="date1"
                                    placeholder="Occasion 1" />
                                <DatePicker className="text-sm w-full p-2 border border-gray-500 cursor-pointer mt-2 rounded"
                                    isClearable
                                    dateFormat="MMMM d, yyyy"
                                    placeholderText="Date"
                                    selected={importantDates && importantDates[0] ? importantDates[0].date : null}
                                    onChange={date => {
                                        if (!date) {
                                            handleOccasionChange(0, "date", null)
                                            return;
                                        }
                                        handleOccasionChange(0, "date", date)
                                    }}
                                    showYearDropdown
                                    yearDropdownItemNumber={5}
                                />
                            </div>
                            <div className="grid grid-cols-2 gap-1">
                                <input className={`text-sm w-full p-2 border-[1px] border-gray-300 rounded mt-2`}
                                    type="text"
                                    onChange={(e) => { handleOccasionChange(1, "occasion", e.target.value) }}
                                    value={importantDates && importantDates[1] ? importantDates[1].occasion : ""}
                                    name="date1"
                                    placeholder="Occasion 2" />
                                <DatePicker className="text-sm w-full p-2 border border-gray-500 cursor-pointer mt-2 rounded"
                                    isClearable
                                    dateFormat="MMMM d, yyyy"
                                    placeholderText="Date"
                                    selected={importantDates && importantDates[1] ? importantDates[1].date : null}
                                    onChange={date => {
                                        if (!date) {
                                            handleOccasionChange(1, "date", null)
                                            return;
                                        }
                                        handleOccasionChange(1, "date", date)
                                    }}
                                    showYearDropdown
                                    yearDropdownItemNumber={5}
                                />
                            </div>
                            <div className="grid grid-cols-2 gap-1">
                                <input className={`text-sm w-full p-2 border-[1px] border-gray-300 rounded mt-2`}
                                    type="text"
                                    onChange={(e) => { handleOccasionChange(2, "occasion", e.target.value) }}
                                    value={importantDates && importantDates[2] ? importantDates[2].occasion : ""}
                                    name="date1"
                                    placeholder="Occasion 3" />
                                <DatePicker className="text-sm w-full p-2 border border-gray-500 cursor-pointer mt-2 rounded"
                                    isClearable
                                    dateFormat="MMMM d, yyyy"
                                    placeholderText="Date"
                                    selected={importantDates && importantDates[2] ? importantDates[2].date : null}
                                    onChange={date => {
                                        if (!date) {
                                            handleOccasionChange(2, "date", null)
                                            return;
                                        }
                                        handleOccasionChange(2, "date", date)
                                    }}
                                    showYearDropdown
                                    yearDropdownItemNumber={5}
                                />
                            </div>

                        </div>
                    </div>
                    <div className="flex flex-col gap-1">
                        <p className="text-base font-medium">Evaluations</p>
                        <select name="satisfaction" value={selectedSatisfaction} className={`text-sm w-full p-2 ${errors.satisfactionError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                            onChange={(e) => { handleChange(e.target.value, satisfactions, setSelectedSatisfaction); setErrors({ ...errors, satisfactionError: false }) }} >
                            <option value="">---Cutomer Satisfaction---</option>
                            {satisfactions && satisfactions.map(item => {
                                return (
                                    <option value={item.id} key={item.id}>{item.satisfaction}</option>
                                )
                            })}
                        </select>
                        <select name="potential" value={selectedPotential} className={`text-sm w-full p-2 ${errors.potentialError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                            onChange={(e) => { handleChange(e.target.value, potentials, setSelectedPotential); setErrors({ ...errors, potentialError: false }) }} >
                            <option value="">----Cutomer Potential----</option>
                            {potentials && potentials.map(item => {
                                return (
                                    <option value={item.id} key={item.id}>{item.potential}</option>
                                )
                            })}
                        </select>
                        <textarea className="text-sm w-full p-2 border border-solid border-gray-300 rounded mt-2 resize-none"
                            type="text"
                            rows={2}
                            maxLength={120}
                            onChange={(e) => setCustomerInterest(e.target.value)}
                            value={customerInterest}
                            name="customerInterest"
                            placeholder="Customer's Interest"></textarea>
                        <textarea className="text-sm w-full p-2 border border-solid border-gray-300 rounded mt-2 resize-none"
                            type="text"
                            rows={2}
                            maxLength={120}
                            onChange={(e) => setNotes(e.target.value)}
                            value={notes}
                            name="salesperson notes"
                            placeholder="Relationship Manager Notes"></textarea>
                        <DatePicker className={`text-sm w-full p-2 border-[1px] ${errors.targetDateError ? " border-red-600" : "border-gray-300"} cursor-pointer mt-2 rounded`}
                            isClearable
                            showTimeSelect
                            dateFormat="MMMM d, yyyy h:mmaa"
                            placeholderText="Target Date"
                            filterDate={d => {
                                return new Date() < d;
                            }}
                            selected={targetDate}
                            minTime={new Date().setHours(8, 0, 0)}
                            maxTime={new Date().setHours(19, 0, 0)}
                            onChange={date => {
                                if (!date) {
                                    setTargetDate(null);
                                    return;
                                }
                                setErrors({ ...errors, targetDateError: false })
                                let new_date = new Date(date);
                                let hours = new_date.getHours();
                                if (hours < 8)
                                    new_date = new_date.setHours(8, 0, 0);
                                setTargetDate(new_date)
                            }}
                        />
                        <select name="actiontype" value={selectedActionType} className="text-sm w-full p-2 border border-solid border-gray-300 rounded mt-2"
                            onChange={(e) => handleChange(e.target.value, actionTypes, setSelectedActionType)} >
                            <option value="">{selectedActionType ? getActionType(selectedActionType) : "---Action Type---"}</option>
                            {actionTypes && actionTypes.map(item => {
                                return (
                                    <option value={item.id} key={item.id}>{item.action}</option>
                                )
                            })}
                        </select>
                        {currentNotes && <select name="actionstatus" value={selectedActionStatus} className="text-sm w-full p-2 border border-solid border-gray-300 rounded mt-2"
                            onChange={(e) => handleChange(e.target.value, actionStatus, setSelectedActionStatus)} >
                            <option value="">---Status---</option>
                            {actionStatus && actionStatus.map(action => {
                                return (
                                    <option value={action.id} key={action.id}>{action.status}</option>
                                )
                            })}
                        </select>}
                    </div>
                </div>
                <div className="flex justify-end text-center md:text-left mt-[10rem]">
                    {/* <button className="mt-4 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white uppercase rounded text-xs tracking-wider" onClick={() => navigate(-1)} type="submit">Back</button> */}
                    <button className="mt-4 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs" onClick={addCustomerActivity} type="submit">Add</button>

                </div>
            </div>
        </section >
    )
}

export default CustomerActivity
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment-timezone";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const EditDashboard = ({ data, closePopup, actionStatus, actionTypes, getDashboardActivity, employee }) => {
    const navigate = useNavigate();
    const locations = useLocation();
    const [notes, setNotes] = useState('');
    const [targetDate, setTargetDate] = useState("")
    const [status, setStatus] = useState("")
    const [selectedStatus, setSelectedStatus] = useState()
    const [statusType, setStatusType] = useState()
    const [selectedAction, setSelectedAction] = useState()
    const [actionType, setActionType] = useState()

    useEffect(() => {
        console.log(data, actionTypes);
        setNotes(data.notes)
        setStatus(data.status)
        setTargetDate(new Date(data.targetDate))
        setSelectedStatus(data.statusId)
        getStatus(data.status)
        setSelectedAction(data.actionType)
        getActionType(data.actionType)
    }, []);

    const editDashboardDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "id": data.reachOutId ? data.reachOutId : data.id,
            "notes": notes,
            "actionType": parseInt(selectedAction),
            "targetDate": targetDate,
            "status": parseInt(selectedStatus)
        }
        console.log("payload", payload)
        let apiEndpoint = 'employees/updateStatusForNotesAdded'
        if (data.reachOutId) {
            apiEndpoint = 'employees/updateStatusForReachOuts'
        }
        axios.post(config.apiUrl + apiEndpoint, payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    getDashboardActivity();
                    closePopup()
                    if (payload.status === 2 || payload.status === 3)
                        navigate(`/${employee.companyName}/addcustomeractivity`, { state: { employee, customer: data, notes: payload } })
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const handleChangeStatus = (e) => {
        setSelectedStatus(e.target.value);
    }
    const getActionType = (typeId) => {
        let action = (actionTypes.find(data => data.id === typeId))
        setActionType(action.action)
    }
    const getStatus = (typeId) => {
        let bname = (actionStatus.find(data => data.status === typeId))
        setStatusType(bname.status)
    }
    return (
        <div>
            <Modal
                show={true}
                onHide={closePopup}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="w-3/3 max-w-max m-2 mr-2 mx-auto mb-4">
                    <div className="text-center md:text-left my-3">
                        <label className="mr-1 text-lg text-black-600">{data.employeeName} action for {data.firstName} {data.lastName}</label>
                    </div>
                    <label className="mr-1 text-sm text-black-600">Customer Name</label>
                    <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded"
                        type="text"
                        disabled={true}
                        name="name"
                        value={data.firstName + " " + data.lastName}
                        placeholder="Notes" />
                    <div className="flex gap-4 mt-3">
                        <div className="flex flex-col">
                            <label className="text-sm text-black-600">Status</label>
                            <select name="status" value={selectedStatus} className="text-sm w-40 p-2 border border-solid border-gray-300 rounded"
                                onChange={(e) => handleChangeStatus(e)} >
                                <option value={status ? status : ""}>{statusType ? statusType : "Select Status"}</option>
                                {actionStatus && actionStatus.map(data => {
                                    return (
                                        <option key={data.id} value={data.id}>{data.status}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="flex flex-col">
                            <label className="text-sm text-black-600">Action Type</label>
                            <select name="status" value={selectedAction} className="text-sm w-40 p-2 border border-solid border-gray-300 rounded"
                                onChange={(e) => setSelectedAction(e.target.value)} >
                                <option value={status ? status : ""}>{actionType ? actionType : "Action Type"}</option>
                                {actionTypes && actionTypes.map(data => {
                                    return (
                                        <option key={data.id} value={data.id}>{data.action}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <label className="mr-1 mt-2 text-sm text-black-600">Notes</label>
                    <input className="text-sm w-full p-2 border border-solid border-gray-300 rounded"
                        type="text"
                        onChange={(e) => setNotes(e.target.value)}
                        name="notes"
                        value={notes}
                        placeholder="Notes" />
                    <div className="flex flex-col mt-2 w-max">
                        <label className="mr-1 text-sm text-black-600">Target Date</label>
                        <DatePicker className="text-xs w-44 p-2 border border-gray-500 cursor-pointer rounded"
                            isClearable
                            placeholderText="Select Target Date"
                            filterDate={d => {
                                return new Date() < d;
                            }}
                            showTimeSelect
                            dateFormat="MMMM d, yyyy h:mmaa"
                            selected={targetDate}
                            minTime={new Date().setHours(8, 0, 0)}
                            maxTime={new Date().setHours(19, 0, 0)}
                            onChange={date => {
                                if (!date) {
                                    setTargetDate(null);
                                    return;
                                }
                                let new_date = new Date(date);
                                let hours = new_date.getHours();
                                if (hours < 8)
                                    new_date = new_date.setHours(8, 0, 0);
                                setTargetDate(new_date)
                            }}
                        />
                    </div>

                    <div className="flex justify-between text-center md:text-left">
                        <button className="mt-4 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white font-semibold uppercase rounded text-xs" type="submit" onClick={closePopup}>Back</button>
                        {(notes !== "") ?
                            <button className="mt-4 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2  font-semibold uppercase rounded text-xs" onClick={editDashboardDetails} type="submit">Update</button>
                            :
                            <button className="mt-4 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs" disabled={true} type="submit">Update</button>
                        }
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default EditDashboard
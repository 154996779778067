import logo from '../../images/lms logo.svg';
import { Modal } from 'react-bootstrap';

export default function PreviewPage({ data, togglePopup }) {
    console.log("data", data)
    return (
        <Modal
            show={true}
            onHide={togglePopup}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <div className='flex flex-col gap-2 min-h-[90vh] p-8  text-sm'>
                <div className='text-xl font-semibold flex gap-2'>
                    <img src={logo} alt='' className='w-8 h-8' />
                    <span>Entropi Directory Services</span>
                </div>
                <div className='my-3 flex items-start gap-8'>
                    <div><img src={data && data.logo_url} alt='' className='w-24 h-24 border border-black rounded-lg' /></div>
                    <div className='flex flex-col w-full gap-4'>
                        <div className='flex justify-between w-full gap-4 text-base'>
                            <div>
                                <p className='font-semibold'>Company Name</p>
                                <p className='font-medium'>{data && data.name}</p>
                            </div>
                            <div>
                                <p className='font-semibold text-base'>Web Page</p>
                                <p className='font-medium'>{data && data.website}</p>
                            </div>
                            <div>
                                <p className='font-semibold text-base'>Industry</p>
                                <p className='font-medium'>{data && data.sectorType.sectorType}, {data && data.industryType.industryType}</p>
                            </div>
                        </div>
                        <div className='flex justify-between w-full gap-4'>
                            <div>
                                <p className='font-semibold text-base'>Service Tags</p>
                                <div className='flex'>
                                    {data && data.tags && data.tags.map((tag, i) => <span key={i} className='font-medium'>{tag}{i < data.tags.length - 1 ? "," : ""}</span>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-1'>
                    <p className='text-lg font-semibold'>About</p>
                    <p className='border border-gray-500 rounded-lg p-2 min-h-[40vh]'>{data && data.description}</p>
                </div>
                <div className='flex flex-col gap-1'>
                    <p className='text-lg font-semibold'>Contact Details</p>

                    {!data || (!data.admin_details && !data.sales_head_details) ?
                        <p>Please visit their website for further details</p> :
                        <div className='flex gap-12'>
                            {data.admin_details && data.admin_details.show &&
                                <div className=''>
                                    <p>{data.admin_details.name}</p>
                                    <p>{data.admin_details.email}</p>
                                    <p>{data.admin_details.mobile}</p>
                                </div>
                            }
                            {data.sales_head_details &&
                                <div className=''>
                                    <p>{data.sales_head_details.name}</p>
                                    <p>{data.sales_head_details.email}</p>
                                    <p>{data.sales_head_details.contact}</p>
                                </div>
                            }
                        </div>}
                </div>
            </div>
        </Modal>
    )
}

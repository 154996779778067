import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import moment from 'moment-timezone';
import { Tab, Tabs } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import EmployeeContext from "../context/employee/EmpContext";
import { enqueueSnackbar } from "notistack";
import mail from "../images/mail.svg";
import view from "../images/viewicon.svg"
import InvoicePopup from "../components/common/ViewInvoicePopup";


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const CompanyInvoice = () => {
    const { empState: employee } = useContext(EmployeeContext)
    const navigate = useNavigate();
    const [invoices, setInvoices] = useState([]);
    const [inactiveCompanies, setInactiveCompanies] = useState([]);
    const [key, setKey] = useState("Invoices");
    const [companyTypes, setCompanyTypes] = useState([]);
    const [inactive, setInactive] = useState(false);
    const [show, setShow] = useState(false);
    const [popupData, setPopupData] = useState(null);

    useEffect(() => {
        getAllCompanieInvoices();
        console.log("employee", employee)
    }, []);

    const getAllCompanieInvoices = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getAllInvoices', { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("success", response.data.message, response.data);
                    setInvoices(response.data.invoices);
                    setInactiveCompanies(response.data.inactiveCompanies);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const triggerMail = (id) => {
        if (inactive) {
            return;
        }
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        setInactive(true);
        axios.get(config.apiUrl + 'companyAdmin/sendInvoicePdfToUsersMail', { headers: headers })
            .then(function (response) {
                enqueueSnackbar('Invoice sent to your regisstered mail successfully!', { variant: 'success' })
                setInactive(false);
            })
            .catch(function (error) {
                console.log("error", error);
                enqueueSnackbar('internal sever error', { variant: 'error' });
                setInactive(false);
            });
    };
    const togglePopup = (data = {}) => {
        setShow(!show);
        setPopupData(data);
    }
    return (
        <div className="ml-8 mt-20">
            {show && <InvoicePopup show={show} togglePopup={togglePopup} data={popupData} />}
            <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3 text-left font-semibold text-black"
            >
                <Tab eventKey="Invoices" title="Invoices">
                </Tab>
                <Tab eventKey="term" title="Bills">
                </Tab>
            </Tabs>
            <div className="mt-3 tableFixHead">
                <table className="border-spacing-x-0 border-spacing-y-1" >
                    <thead>
                        <tr className="whitespace-nowrap text-center">
                            <th className="th-heading text-center w-5">S.No</th>
                            <th className="th-heading text-center"> Email</th>
                            <th className="th-heading text-center">Invoice Number</th>
                            <th className="th-heading text-center">Status</th>
                            <th className="th-heading text-center">Generated On</th>
                            <th className="th-heading text-center">Due Date</th>
                            <th className="th-heading text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices && invoices.map((data, i) => (
                            <tr key={i} className="tr whitespace-nowrap text-center" >
                                <td className="td-text">{i + 1}</td>
                                <td className="td-text">{employee.email ? simpleCrypto.decrypt(employee.email) : ""}</td>
                                <td className="td-text">{data.invoice_number}</td>
                                <td className="td-text">{data.status}</td>
                                <td className="td-text">{moment(data.createdAt).format("MM-DD-YYYY")}</td>
                                <td className="td-text">{moment(data.expiryDate).format("MM-DD-YYYY")}</td>
                                <td className="flex gap-4 text-right items-end justify-end">
                                    <div className={`px-2 icon-w text-right`} onClick={() => togglePopup(data)}>
                                        <div className="text-center items-end justify-center z-10">
                                            <img src={view} />
                                        </div>
                                    </div>
                                    <div className={`px-2 icon-w text-right ${inactive ? "bg-gray-500" : ""}`} onClick={() => triggerMail(data.id)}>
                                        <div className="text-center items-end justify-center z-10">
                                            <img src={mail} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default CompanyInvoice
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { config } from '../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import { enqueueSnackbar } from 'notistack';
import { Card, Modal } from 'react-bootstrap';
import moment from 'moment-timezone';
import phone from "../images/phone.svg";
import tick from "../images/tick.svg";
import interaction from "../images/store.svg";
import activity from "../images/user.svg";
import interest from "../images/calendar.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar1 from '../Navbar/Navbar1';
import LeftMenu1 from '../LeftMenu/LeftMenu1';


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const MonthlyView = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { employee } = location.state;
    const [activities, setActivities] = useState([]);
    const [lastMonthDates, setLastMonthDates] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const lastMonth = moment().subtract(1, 'months').format('MM');

    useEffect(() => {
        const startOfLastMonth = moment().subtract(1, 'months').startOf('month').date();
        const endOfLastMonth = moment().subtract(1, 'months').endOf('month').date();
        console.log(startOfLastMonth, endOfLastMonth, employee);
        setLastMonthDates(new Array(endOfLastMonth).fill(false))
        getEmployeeLastMonthActivity();
    }, []);
    useEffect(() => {
        handleDateSelect(1);
    }, [activities])
    const getEmployeeLastMonthActivity = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "id": employee.id,
            "employeeId": employee.employeeId
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'manager/getLastMonthEmployeeActivity', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log(response.data.message, response.data);
                    setActivities(response.data.lastMonthActivities)
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
                enqueueSnackbar("Unable to fetch data currently", { variant: "error" })
            });
    };
    const handleDateSelect = (i) => {
        const endOfLastMonth = moment().subtract(1, 'months').endOf('month').date();
        let newArray = new Array(endOfLastMonth).fill(false)
        newArray[i - 1] = true;
        setLastMonthDates([...newArray])
        const curDate = i < 10 ? "0" + i + "/" + lastMonth : i + "/" + lastMonth;
        let curDateActivities = activities.filter(activity => {
            let updatedAt = moment(activity.updatedAt).format("DD/MM/YY");
            if (updatedAt.substring(0, 5) == curDate)
                return activity;
        })
        console.log("filterd actiity", curDateActivities);
        setFilteredData(curDateActivities);
    }
    const countActivities = (i) => {
        const curDate = i < 10 ? "0" + i + "/" + lastMonth : i + "/" + lastMonth;
        let curDateActivities = [];
        curDateActivities = activities.filter(activity => {
            let updatedAt = moment(activity.updatedAt).format("DD/MM/YY");
            if (updatedAt.substring(0, 5) == curDate)
                return activity;
        })
        return curDateActivities.length;
    }
    return (
        <div className="h-screen flex flex-col">
            <Navbar1 employee={employee} />
            <LeftMenu1 section="Employees Activity" />
            <div>
                <i onClick={() => navigate(`/${employee.companyName}/branchadmin/Employees`)} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i>
            </div>
            <div className='ml-56 flex flex-col gap-8 mt-1'>
                <p className='text-center mt-20'>{"Activities for " + employee.firstName + " " + employee.lastName + " " + moment(new Date()).subtract(1, 'months').format("MMMM YYYY")}</p>
                <div className='grid grid-cols-11 gap-1 gap-y-5 mt-2 border-[1px] mx-10 border-gray-200 rounded-lg w-[80vw] h-[35vh] p-2'>
                    {lastMonthDates && lastMonthDates.map((data, i) => {
                        return (
                            <div key={i} className={`flex flex-col justify-around text-center cursor-pointer rounded-xl w-15 pb-2 ${lastMonthDates[i] ? "bg-[#F7F9CB] border border-yellow-400" : "border-[1px] border-gray-200"}`} onClick={() => handleDateSelect(parseInt(i + 1))}>
                                <p className='text-sm font-medium'>{i < 10 ? "0" + parseInt(i + 1) : parseInt(i + 1)}</p>
                                <p className='text-lg font-semibold'>{countActivities(i + 1)}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='grid grid-cols-2 lg:grid-cols-6 gap-4 mb-16 mx-10  w-[80vw]'>
                    {filteredData && filteredData.map(data => {
                        return (
                            <Card style={{ width: '12rem' }} key={data.updatedAt}>
                                <Card.Body style={{ margin: '0', padding: "0" }}>
                                    <div className={`relative p-2 ${data.activitiesCount == 0 ? "bg-[#FBF9F3]" : "bg-[#E7F9FF]"}`}>
                                        <p className='absolute right-0 top-0 '><img src={data.source !== 1 ? phone : interaction} className='w-5 h-5' /></p>
                                        <p className='timeline-text flex py-0.5'><img src={activity} className='w-7 px-1' />{data.customerName}</p>
                                        <p className='timeline-text flex py-0.5'><img src={interest} className='w-7 px-1' />{data.activity ? data.activity : "No action"}</p>
                                        <p className={`absolute right-0 bottom-0 ${data.activity === "Paid Advance" || data.activity === "Purchased" ? "" : "hidden"}`}><img src={tick} className='w-5 h-5 px-1' /></p>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    })}
                </div>
            </div>
        </div >
    )
}

export default MonthlyView
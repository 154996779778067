import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import React, { useContext, useEffect, useRef, useState } from 'react'
import './Events.css'
import Navbar1 from '../Navbar/Navbar1'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment-timezone'
import AddEvent from './AddEvent'
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import EditEvent from './EditEvent'
import { enqueueSnackbar } from 'notistack'
import EmployeeContext from '../context/employee/EmpContext'
import { useQuery } from 'react-query'

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const Events = () => {
    const navigate = useNavigate();
    const { empState: employee } = useContext(EmployeeContext)
    let calendarRef = useRef(null);
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [data, setData] = useState();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [events, setEvents] = useState([]);
    const [monthEvents, setMonthEvents] = useState([]);
    const [role, setRole] = useState(employee?.role)
    const [toDos, setToDos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [clickCount, setClickCount] = useState(0);
    const timer = useRef();
    const [noToDos, setNoToDos] = useState(new Array(4).fill({}));

    useEffect(() => {
        employee.role === 1 ? getCurrentMonthEvents() : getBranchEvents();
    }, [])
    useEffect(() => {
        if (monthEvents && monthEvents.length > 0) {
            getCuurentDateToDos();
        } else
            setToDos([]);
    }, [currentDate, events])
    const getCurrentMonthEvents = async (date) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let monthAndYear = moment(date).format('MMMM YYYY');
        axios.get(config.apiUrl + 'companyAdmin/getCompanyEvents', { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    setEvents(response.data.events || []);
                    let curEvents = [];
                    response.data.events.forEach(event => {
                        if (event.monthAndYear === monthAndYear)
                            curEvents = [...curEvents, ...event.events];
                    })
                    curEvents.sort((a, b) => {
                        let dateA = moment(a.date, "DD-MM-YYYY")
                        let dateB = moment(b.date, "DD-MM-YYYY")
                        return dateA - dateB;
                    })
                    setMonthEvents(curEvents)
                    setLoading(false)
                    return response;
                } else {
                    console.log("Error");
                    setLoading(false)
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const getBranchEvents = async (date) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let monthAndYear = moment(date).format('MMMM YYYY');
        let payload = {
            branchId: employee.branchId
        }
        axios.post(config.apiUrl + 'manager/getBranchEventsByBranchId', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    setEvents(response.data.events || []);
                    let curEvents;
                    response.data.events.forEach(event => {
                        if (event.monthAndYear === monthAndYear)
                            curEvents = event.events;
                    })
                    setMonthEvents(curEvents)
                    setLoading(false)
                    return response;
                } else {
                    console.log("Error");
                    setLoading(false)
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const handleDateSelect = (selectInfo) => {
        let title = prompt('Please enter a new title for your event')
        let calendarApi = selectInfo.view.calendar
        calendarApi.unselect();
        if (title) {
            calendarApi.addEvent({
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
            })
        }
    }
    const handleEventClick = (clickInfo) => {
        setClickCount(prevCount => {
            clearTimeout(timer.current);
            if (prevCount === 0) {
                timer.current = setTimeout(() => {
                    if (prevCount === 0) {
                        setCurrentDate(clickInfo.date)
                    }
                    setClickCount(0);
                }, 200);
            } else if (prevCount === 1) {
                if (role !== 1) return;
                let date = moment(clickInfo.date).format("MM-DD-YYYY")
                let today = moment().format("MM-DD-YYYY")
                if (moment(date).isBefore(today)) {
                    enqueueSnackbar("Cannot set event for past date!!!", { variant: "error" })
                    setClickCount(0);
                    return;
                }
                setData(clickInfo);
                setShow(true);
                setClickCount(0);
            }
            return prevCount + 1;
        });

    }
    const handleEvents = (events) => {
        // setCurrentEvents(events)
    }
    const handleDatesSet = async (info) => {
        const date = info.start;
        date.setDate(date.getDate() + 10);
        let firstDayOfMonth = moment(date).add(10, 'days').startOf('month');
        if (info.view.type === "dayGridMonth") {
            let monthAndYear = moment(date).add(10, 'days').format('MMMM YYYY');
            let today = new Date();
            let currentMonthAndYear = moment(today).format('MMMM YYYY');
            let curEvents = [];
            events.forEach(event => {
                if (event.monthAndYear === monthAndYear)
                    curEvents = [...curEvents, ...event.events];

            })
            curEvents.sort((a, b) => {
                let dateA = moment(a.date, "DD-MM-YYYY")
                let dateB = moment(b.date, "DD-MM-YYYY")
                return dateA - dateB;
            })
            setMonthEvents(curEvents)
            if (monthAndYear === currentMonthAndYear)
                setCurrentDate(today)
            else
                setCurrentDate(firstDayOfMonth)
        }
    };
    const dayRender = ({ date, el }) => {

        let formattedDate = moment(date).format("DD-MM-YYYY");
        const today = moment(new Date()).format("DD-MM-YYYY");
        let dayNumber = el.querySelector('.fc-daygrid-day-number');
        let dayBox = el.querySelector('.fc-daygrid-day-frame');
        let monthAndYear = moment(date).format('MMMM YYYY');
        let curEvents = [];
        events.forEach(event => {
            if (event.monthAndYear === monthAndYear)
                curEvents = [...curEvents, ...event.events];

        })
        curEvents.sort((a, b) => {
            let dateA = moment(a.date, "DD-MM-YYYY")
            let dateB = moment(b.date, "DD-MM-YYYY")
            return dateA - dateB;
        })
        if (formattedDate === today)
            dayBox.style.backgroundColor = '#ffffe6';
        if (dayNumber && curEvents.length > 0) {
            let isEventPresent = curEvents.filter(event => event.date === formattedDate)
            if (isEventPresent && isEventPresent.length > 0)
                dayNumber.style.color = 'red';
        }
    }
    const toggleShow = () => {
        setShow(!show);
        setData()
    }
    const editEvent = (event) => {
        setData(event);
        setShowEdit(true);
    }
    const toggleShowEdit = () => {
        setShowEdit(!showEdit);
        setData()
    }
    const getCuurentDateToDos = (date) => {
        let curDate = date ? date : currentDate;
        let curEvents = [...monthEvents];
        let formattedDate = moment(curDate).format("DD-MM-YYYY");
        let curToDos = curEvents.filter(event => event.date === formattedDate);
        curToDos = curToDos.map(toDo => { if (toDo.toDos && toDo.toDos.length > 0) return toDo.toDos })
        curToDos = curToDos.filter(todo => { if (todo) return todo })
        if (Array.isArray(curToDos) && curToDos.length > 0 && curToDos[0])
            curToDos = curToDos.map(item => item.map(i => i))
        else
            curToDos = [];
        if (curToDos.length > 4) curToDos.splice(0, 4)
        if (curToDos.length < 4) {
            for (let i = curToDos.length; i < 4; i++)
                curToDos.push([]);
        }
        setToDos(curToDos)

    }
    const handlebackClick = () => {
        if (employee?.role === 1)
            navigate(`/${employee.companyName}/companyadmin/Branch`)
        else if (employee?.role === 2)
            navigate(`/${employee.companyName}/branchadmin/Customers`)
        else
            navigate(`/${employee.companyName}/salesperson/Customers`)
    }
    return (
        <>
            {show && <AddEvent closePopup={toggleShow} data={data} getCurrentMonthEvents={getCurrentMonthEvents} />}
            {showEdit && <EditEvent closePopup={toggleShowEdit} data={data} getCurrentMonthEvents={getCurrentMonthEvents} role={role} />}
            <Navbar1 employee={employee} />
            <div><i onClick={handlebackClick} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-[19rem] top-16 cursor-pointer" aria-hidden="true"></i></div>
            <div className='flex justify-between mt-14 gap-20'>
                <div className='min-h-screen w-[18rem] flex flex-col items-center bg-slate-700 border-t border-white gap-4'>
                    <p className='h-40 font-semibold p-4 w-full text-center text-9xl text-[goldenrod]'>{moment().format('DD')}</p>
                    <p className='font-semibold px-4 py-2 w-full text-center text-2xl text-white'>{moment().format('MMMM')}</p>
                    <span className='border border-white w-56'></span>
                    <p className='font-semibold p-4 w-full text-center text-5xl text-white '>{moment().format('YYYY')}</p>
                    <div className=' border-[1px] border-gray-900 min-h-[16rem] w-[16rem] p-4'>
                        {monthEvents && monthEvents.map((event, i) => {
                            return (
                                <div key={i} className='flex items-center gap-2 hover:underline hover:underline-offset-2 hover:text-white cursor-pointer' onClick={() => editEvent(event)}>
                                    <p className='bg-red-700 rounded-full w-2 h-2'></p>
                                    <p className='text-white whitespace-nowrap text-xs'>{moment(event.date, 'DD-MM-YYYY').format('Do MMM')}&nbsp;{event.event.substring(0, 20)}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {loading ? <></> :
                    <div className='flex flex-col justify-around mr-auto mb-28 overflow-y-auto'>
                        <p className='text-lg font-semibold my-2'>Things To Do</p>
                        <div className='grid grid-cols-4 h-24 w-[70vw] gap-16 mb-2'>
                            {toDos && toDos.length > 0 ? toDos.map((todo, i) => {
                                return (
                                    <div className={`flex flex-col justify-start w-[14vw] gap-1 border-2 ${todo && todo.length > 0 ? "border-gray-700" : "border-gray-300"} rounded-lg`} key={todo?.id ? todo.id : i}>
                                        {todo && todo.length > 0 ?
                                            todo.map(item => <p className='font-semibold text-sm px-2' key={item}>{item}</p>)
                                            : <p className='font-semibold text-sm px-2 text-center'>Regular business day</p>}
                                    </div>
                                )
                            })
                                : noToDos.map((data, i) => {
                                    return (
                                        <div className={`flex flex-col justify-start w-[14vw] gap-1 border-2 border-gray-700 rounded-lg`} key={i}>
                                            <p className='font-semibold text-sm px-2 text-center'>Regular business day</p>
                                        </div>
                                    )
                                })}
                        </div>
                        <div className='w-[70vw] events'>
                            <FullCalendar
                                ref={calendarRef}
                                height={520}
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                headerToolbar={{
                                    start: 'prev',
                                    center: 'title',
                                    end: 'next'
                                }}
                                initialView='dayGridMonth'
                                weekends={true}
                                select={handleDateSelect}
                                eventClick={handleEventClick}
                                eventsSet={handleEvents}
                                dateClick={handleEventClick}
                                datesSet={handleDatesSet}
                                dayCellDidMount={dayRender}
                            />
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default Events
import React, { useState, useEffect, useContext } from "react";
import SimpleCrypto from "simple-crypto-js";
import axios from 'axios';
import { config } from '../Config/Config';
import moment from "moment-timezone";
import { enqueueSnackbar } from 'notistack'
import EmployeeContext from "../context/employee/EmpContext";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import info from "../images/info.svg"

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function AddBranchTarget() {
    const { empState: employee } = useContext(EmployeeContext)
    const [parentTargets, setParentTargets] = useState([])
    const [branchTargets, setBranchTargets] = useState([])
    const [pastYear, setPastYear] = useState("")
    const [curYear, setCurYear] = useState("")
    const [nextYear, setNextYear] = useState("")
    const [loading, setLoading] = useState(false)
    const [curIndex, setCurIndex] = useState(0);


    const addBranchTarget = (status) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            "id": branchTargets[curIndex] ? branchTargets[curIndex].id : null,
            "year": branchTargets[curIndex] ? branchTargets[curIndex].year : null,
            "targets": branchTargets[curIndex] ? JSON.stringify(branchTargets[curIndex].targets) : null,
            "status": status,
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'manager/updateBranchTarget', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log(response.data);
                    branchTargets[0].status = status;
                    setBranchTargets([...branchTargets])
                    enqueueSnackbar(response.data.message, { variant: "success" })
                }
            })
            .catch(function (error) {
                console.log("error", error)
            })
    };
    const addEmployeeTarget = (status) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            "id": branchTargets[curIndex] ? branchTargets[curIndex].id : null,
            "year": branchTargets[curIndex] ? branchTargets[curIndex].year : null,
            "targets": branchTargets[curIndex] ? JSON.stringify(branchTargets[curIndex].targets) : null,
            "status": status,
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'employees/updateEmployeeTargets', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log(response.data);
                    branchTargets[0].status = status;
                    setBranchTargets([...branchTargets])
                    enqueueSnackbar(response.data.message, { variant: "success" })
                }
            })
            .catch(function (error) {
                console.log("error", error)
            })
    };

    const handleTargetChange = (e, i, index) => {
        let newValue = e.target.value ? e.target.value : '';
        let regex = /^\d+(\.\d{1,2})?$/;
        if (newValue && !(regex.test(newValue))) {
            return enqueueSnackbar("Please use numbers only upto 2 decimal places", { variant: 'error' })
        }
        setCurIndex(i);
        let currentTargets = branchTargets[i].targets;
        currentTargets.forEach((target, idx) => {
            if (idx === index) {
                target.target = newValue ? parseFloat(newValue) : "";
                target.forecast = newValue ? parseFloat(newValue) : 0;
            }
        })
        branchTargets[i].targets = currentTargets;
        setBranchTargets([...branchTargets])
    }
    useEffect(() => {
        setLoading(true)
        if (employee.role === 2) {
            getBranchTargets()
        } else {
            getEmployeeTargets()
        }
        setPastYear(parseInt(new Date().getFullYear() - 1) + "-" + new Date().getFullYear());
        setCurYear(new Date().getFullYear() + "-" + parseInt(new Date().getFullYear() + 1));
        setNextYear(parseInt(new Date().getFullYear() + 1) + "-" + parseInt(new Date().getFullYear() + 2))
    }, []);
    const saveTargets = (save) => {
        if (employee.role === 2) {
            addBranchTarget(save)
        } else {
            addEmployeeTarget(save)
        }
    }
    const submitTargets = (submit) => {
        if (employee.role === 2) {
            addBranchTarget(submit)
        } else {
            addEmployeeTarget(submit)
        }
    }
    const getBranchTargets = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "branchId": employee?.branchId
        }
        axios.post(config.apiUrl + 'manager/getBranchTargets', payload, { headers: headers })
            .then(function (response) {

                if (response.data.status == true) {
                    if (response.data.parentTargets?.length === 0) {
                        setParentTargets(response.data.parentTargets)
                        console.log("no data")
                    } else {
                        setParentTargets(response.data.parentTargets);
                        console.log(response);
                        let currentTargets = [];
                        response.data.savedTargets && response.data.savedTargets.length > 0 ? currentTargets.push(response.data.savedTargets[0]) : currentTargets.push(null);
                        response.data.getAllTargets && response.data.getAllTargets.length > 0 ? currentTargets.push(response.data.getAllTargets[0]) : currentTargets.push(null);
                        response.data.pastTargets && response.data.pastTargets.length > 0 ? currentTargets.push(response.data.pastTargets[0]) : currentTargets.push(null);
                        console.log("Current Branch Target", currentTargets);
                        setBranchTargets(currentTargets);
                    }
                }
                setLoading(false);
            })
            .catch(function (error) {
                console.log("error", error);
                setLoading(false);
            })
    };
    const getEmployeeTargets = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "branchId": employee?.branchId,
            "employeeId": employee?.employeeId
        }
        axios.post(config.apiUrl + 'employees/getEmployeeTargets', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    if (response.data.parentTargets?.length === 0) {
                        setParentTargets(response.data.parentTargets)
                        console.log("no data")
                    } else {
                        setParentTargets(response.data.parentTargets);
                        console.log(response);
                        let currentTargets = [];
                        response.data.savedTargets && response.data.savedTargets.length > 0 ? currentTargets.push(response.data.savedTargets[0]) : currentTargets.push(null);
                        response.data.getAllTargets && response.data.getAllTargets.length > 0 ? currentTargets.push(response.data.getAllTargets[0]) : currentTargets.push(null);
                        response.data.pastTargets && response.data.pastTargets.length > 0 ? currentTargets.push(response.data.pastTargets[0]) : currentTargets.push(null);
                        console.log("Current Branch Target", currentTargets);
                        setBranchTargets(currentTargets);
                    }
                }
                setLoading(false);
            })
            .catch(function (error) {
                console.log("error", error);
                setLoading(false);
            })
    };
    if (loading) {
        return (
            <div className='mt-20 flex justify-center items-center'><Spinner animation="border" /></div>
        )
    }
    return (
        <>
            {!parentTargets || parentTargets.length === 0 ? <div className="ml-8 mt-20 font-semibold flex justify-center items-center">{employee.role === 2 ? "No Company Targets Present" : "No Branch Targets Present"}</div>
                :
                <div>
                    <div className="ml-8 mt-20 flex font-semibold text-md text-black">Set Targets &nbsp;</div>
                    <div className="ml-8 grid grid-cols-3 gap-2">
                        {branchTargets && branchTargets.map((target, i) => {
                            if (target && target.parentTarget) {
                                return (
                                    <div key={target.id}>
                                        <div className="flex justify-between">
                                            <p className="font-semibold text-md text-black">{target.year === curYear ? "Current Year" : target.year === pastYear ? "Past Year" : "Next Year"} &nbsp;<span>{target.year}</span></p>
                                            <div className={`flex justify-between items-center gap-3 ${target.status === 4 ? "" : "invisible"}`}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={<Tooltip id="button-tooltip">{target.reasonForRework}</Tooltip>}
                                                ><img src={info} alt="" className="w-4 h-4" />
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                        {!target.targets || target.targets.length === 0 ?
                                            <div className="flex mt-3 border-2 border-gray-800 w-full h-[83.5%] items-center text-center justify-center">
                                                <div className="m-auto font-semibold">No Past Targets</div>
                                            </div> :
                                            <div className="mt-1 w-full">
                                                <div className={"mt-4 grid grid-cols-4 items-center w-full h-14 border-2 bg-gray-200 border-gray-800"}>
                                                    <div className='py-1 px-1 text-center h-full'>Month</div>
                                                    <div className='py-1 px-1 text-center h-full border-2 border-gray-800 border-top-0 border-bottom-0'>{employee.role === 2 ? "Company" : "Branch"} Target</div>
                                                    <div className='py-1 px-1 text-center h-full border-2 border-gray-800 border-left-0 border-top-0 border-bottom-0'>Target</div>
                                                    <div className='py-1 px-1 text-center h-full border-2 border-gray-800 border-top-0 border-left-0 border-right-0 border-bottom-0'>Forecast</div>
                                                </div>
                                                <center>
                                                    {target.targets && target.targets.map((t, index) => (
                                                        <div key={index + t.id} className={"grid grid-cols-4 items-center w-full border-2 border-gray-800"}>
                                                            <div className={"flex py-1 px-1  bg-gray-100 text-center border-2"}>{t.id}</div>
                                                            <div className='border-2 border-top-0 border-r-0 border-bottom-0 border-gray-800'>
                                                                <input
                                                                    className={`flex py-1 w-full bg-gray-100 text-center border-2`}
                                                                    type="number"
                                                                    disabled={true}
                                                                    value={target.parentTarget[index].forecast}
                                                                    readOnly={true}
                                                                />
                                                            </div>
                                                            <div className='border-2 border-top-0 border-bottom-0 border-gray-800'>
                                                                <input
                                                                    className={`flex py-1 w-full bg-gray-100 text-center border-2 ${target.status === 1 ? "bg-gray-100" : (target.status === 1 || target.status === 2 || new Date().getMonth() + 1 >= moment().month(t.id).format("M")) && target.year !== nextYear ? "" : "bg-white cursor-pointer"}`}
                                                                    type="number"
                                                                    value={t.target}
                                                                    disabled={(target.status === 1 || target.status === 2 || new Date().getMonth() + 1 >= moment().month(t.id).format("M")) && target.year !== nextYear ? true : false}
                                                                    onChange={(e) => handleTargetChange(e, i, index)}
                                                                />
                                                            </div>
                                                            <div>
                                                                <input
                                                                    className={"flex py-1 w-full bg-gray-100 text-center border-2"}
                                                                    type="text"
                                                                    disabled={true}
                                                                    value={t.forecast}
                                                                    readOnly={true} />
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <br />
                                                </center >
                                                <div className={`flex justify-between items-center gap-3 ${target.status === 3 || target.status === 4 ? "" : "invisible"}`}>
                                                    <button className="max-w-max bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color p-2 px-3 font-semibold  uppercase rounded text-xs whitespace-nowrap" onClick={() => saveTargets(3)}>Save</button>
                                                    <button className="bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color p-2 px-4 font-semibold uppercase rounded text-xs whitespace-nowrap" onClick={() => submitTargets(2)}>Submit</button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div key={i} className={`${i === 2 && !branchTargets[1] ? "hidden" : ""} ${target && !target.parentTarget ? "hidden" : ""}`}>
                                        <p className="font-semibold text-md text-black">Year&nbsp;{(parseInt(branchTargets[0]?.year?.substring(0, 4)) - i) + "-" + (parseInt(branchTargets[0]?.year?.substring(0, 4)) - i + 1)}</p>
                                        <div className="flex mt-4 border-2 border-gray-800 w-full h-[83%] items-center text-center justify-center">
                                            <div className="m-auto font-semibold">No Past Targets</div>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div >
            }</>
    )
}
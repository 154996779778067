import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Form, OverlayTrigger, Tooltip, FormControl, Card, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import './CustomerTimeline.css';
import moment from 'moment-timezone';
import lead from "../images/leadtype.svg";
import notes from "../images/notes.svg";
import interaction from "../images/interaction.svg";
import activity from "../images/activity.svg";
import demand from "../images/userdemand.svg";
import interest from "../images/customerinterest.svg";
import SimpleCrypto from 'simple-crypto-js';
import ReactSpeedometer from "react-d3-speedometer"
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function CustomerTimeline({ closePopup, getOccasion, currentActivity, getActivity, getPotential, getUserDemand, getLeadType, getInteraction }) {
    const [employees, setEmployees] = useState([])
    const [visits, setVisits] = useState()
    const [purchases, setPurchases] = useState()
    const [percentage, setPercentage] = useState()

    useEffect(() => {
        setVisits(currentActivity.length)
        var purchasesCount = currentActivity?.filter(function (item) {
            return item.userActivity === 3 || item.userActivity === 4;
        }).length;
        setPurchases(purchasesCount)
        let percent = (purchasesCount / currentActivity.length)

        console.log("visits", currentActivity, typeof (currentActivity), currentActivity.length, purchasesCount, percent)
        setPercentage(percent)
        getAllEmployeesByBranch();
    }, [])
    const getAllEmployeesByBranch = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }

        axios.post(config.apiUrl + 'companyAdmin/getAllEmployeesByBranch', {}, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("success employees fetched", response.data.message, response.data);
                    setEmployees(response.data.employees);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const getEmployeeNameAndId = (id) => {
        var a = employees.filter((data, i) => data.id === id)
        if (a?.length === 0) return ""
        return a[0].firstName + " " + a[0].lastName + " " + " (" + a[0].employeeId + ")"
    }
    return (
        <div>
            <Modal
                size="md"
                show={true}
                onHide={closePopup}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                keyboard={false}
            >
                <Modal.Body>
                    <div style={{ background: "white", width: "100%", height: "100%", zIndex: "999", paddingBottom: "0.5rem" }}>
                        <div className='px-3 -py-1 font-semibold text-xl text-left'>{currentActivity && currentActivity.length > 1 && currentActivity[0]?.firstName}&nbsp;{currentActivity && currentActivity.length > 1 && currentActivity[0]?.lastName} Timeline</div>
                        {currentActivity && currentActivity.length === 0 ?
                            <p className='py-10 text-lg font-semibold text-center'>No Activity Found</p>
                            :
                            <div>
                                <div style={{ position: "absolute", right: "16px", top: "40px" }}>
                                    <ReactSpeedometer
                                        width={200}
                                        maxValue={100}
                                        needleColor="black"
                                        // value={(purchases) / (visits) * 100}
                                        // valueFormat={"d"}
                                        // segmentColors={["#e4e4e4", "#ccc", "#8c8c8c", "#545454", "#000"]}
                                        // currentValueText={"${value} %"}
                                        value={(purchases) / (visits) * 100}
                                        valueFormat={"d"}
                                        segmentColors={["#e4e4e4", "#ccc", "#8c8c8c", "#545454", "#3b3a3a"]}
                                        currentValueText={`${purchases}/${visits}`}
                                        textColor={"black"}
                                        needleHeightRatio={0.6}
                                        ringWidth={40}
                                        needleTransitionDuration={3333}
                                        needleTransition="easeElastic"
                                        // currentValueText={`Purchases:${purchases} ,Visits:${visits}`}
                                        // value={purchases/visits}
                                        // needleColor="black"
                                        // startColor="green"
                                        // segments={5}
                                        // segmentColors={["#e4e4e4", "#ccc", "#8c8c8c", "#545454","#000"]}
                                        // maxValue={10}
                                        // valueFormat=".0%"
                                        // width={150}
                                        // height={75}
                                        // currentValueText={"CPU Usage: ${value} %"}
                                        // currentValueText={`Current value: ${purchases/visits * 100}%`}
                                        // textColor={"black"}
                                        customSegmentLabels={[
                                            {
                                                text: 'Poor',
                                                position: 'OUTSIDE',
                                                color: '#555',
                                                fontSize: '12px',
                                            },
                                            {
                                                text: 'Fair',
                                                position: 'OUTSIDE',
                                                color: '#555',
                                                fontSize: '12px',
                                            },
                                            {
                                                text: 'Good',
                                                position: 'OUTSIDE',
                                                color: '#555',
                                                fontSize: '12px',
                                            },
                                            {
                                                text: 'Great',
                                                position: 'OUTSIDE',
                                                color: '#555',
                                                fontSize: '12px',
                                            },
                                            {
                                                text: 'Excellent',
                                                position: 'OUTSIDE',
                                                color: '#555',
                                                fontSize: '12px',
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="t-container position-relative" style={{ marginTop: "3rem" }}>
                                    {currentActivity && currentActivity.map((data, i) => (
                                        <div>
                                            <div className="timeline-block timeline-block-right">
                                                <div className={getActivity(data.userActivity) == "Paid Advance" ? "marker-semi" : getActivity(data.userActivity) == "Purchased" ? "marker" : "marker-empty"}></div>
                                                <div className="timeline-content">
                                                    <div className='flex justify-between font-semibold'><h5>{moment(data.updatedAt).format('MMMM Do YYYY')}</h5><div className='tline-text'>{moment(data.updatedAt).format("hh:mm a")}</div></div>
                                                    <p className='timeline-text flex py-0.5'><img src={activity} className='w-7 px-1' /> {getActivity(data.userActivity) == null ? "No activity" : getActivity(data.userActivity)}</p>
                                                    <p className='timeline-text flex py-0.5'><img src={lead} className='w-7 px-1' /> {getOccasion(data.occasion) == null ? "No Occassion" : getOccasion(data.occasion)}</p>
                                                    <p className='timeline-text flex py-0.5'><img src={interest} className='w-7 px-1' />{getPotential(data.customerPotential) == null ? "No Customer Potential" : getPotential(data.customerPotential)}</p>
                                                    <p className='timeline-text flex py-0.5'><img src={interaction} className='w-7 px-1' />{getEmployeeNameAndId(data.salesmanId)}</p>
                                                    {/* <p className='timeline-text flex py-0.5'><img src={interest} className='w-7 px-1'/> Customer Interest - {data.customersInterest == null ? "No Interest shown" : data.customersInterest}</p>
                                            <p className='timeline-text flex py-0.5'><img src={demand} className='w-7 px-1'/> User Demand - {getUserDemand(data.userDemand) == null ? "No demand" : getUserDemand(data.userDemand)}</p> */}
                                                    {/* <p className='timeline-text flex py-0.5'><img src={notes} className='w-7 px-1'/> {data.notes == null ? "No notes added" : data.notes}</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </div >
    );
}


import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import moment from 'moment-timezone';
import { Tab, Tabs } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import EmployeeContext from "../context/employee/EmpContext";
import { enqueueSnackbar } from "notistack";


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const Dashboard = () => {
    const { empState: employee } = useContext(EmployeeContext)
    const navigate = useNavigate();
    const [companies, setCompanies] = useState([]);
    const [inactiveCompanies, setInactiveCompanies] = useState([]);
    const [key, setKey] = useState("Active Companies");
    const [companyTypes, setCompanyTypes] = useState([]);

    useEffect(() => {
        getAllCompaniesDetails();
    }, []);

    const handleNaviagate = () => {
        navigate('/createcompany', { state: { employee, companyTypes: companyTypes } })
    }

    const getAllCompaniesDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'superAdmin/getAllCompanyData', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, response.data);
                    setCompanies(response.data.countsDataArray);
                    setInactiveCompanies(response.data.inactiveCompanies);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const sendRemainderOtp = (data) => {
        let headers = {
            "Content-Type": 'application/json',
            // "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var email=data?.email?simpleCrypto.decrypt(data.email):""
        var payload = {
            "companyName": data.name,
            "email": email,
            "firstName": data.firstName,
            "lastName": data.lastName,
            "code": data.uniqueCode
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/sendRemainderOtp', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    enqueueSnackbar('Otp sent successfully!', { variant: 'success' })
                    getAllCompaniesDetails()
                } else {
                    console.log("Error", payload);
                    enqueueSnackbar('Something went wrong', { variant: 'error' })
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
                enqueueSnackbar('internal sever error', { variant: 'error' })
            });
    };
    return (
        <div className="ml-8 mt-20">
            <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3 text-left font-semibold text-black"
            >
                <Tab eventKey="Active Companies" title="Active Companies">
                    {/* <div className="flex flex-col justify-between">
                        <div className="text-left font-semibold text-black"><h5>Active Companies</h5></div>
                    </div> */}

                    <div className="mt-3 tableFixHead">
                        <table className="border-spacing-x-0 border-spacing-y-1" >
                            <thead>
                                <tr className="whitespace-nowrap">
                                    <th scope="col" className="th-heading">S.No</th>
                                    <th scope="col" className="th-heading">Company Name</th>
                                    <th scope="col" className="th-heading">Category</th>
                                    <th scope="col" className="th-heading">Branches</th>
                                    <th scope="col" className="th-heading">Employees</th>
                                    <th scope="col" className="th-heading">Customers</th>
                                    <th scope="col" className="th-heading">Status</th>
                                    <th scope="col" className="th-heading">Created On</th>
                                </tr>
                            </thead>
                            <tbody>
                                {companies && companies.map((data, i) => (
                                    <tr key={data.id} className="tr whitespace-nowrap" >
                                        <td className="td-text">{i + 1}</td>
                                        <td className="td-text">{data.name}</td>
                                        <td className="td-text">{data.category}</td>
                                        <td className="td-text pl-12">{data.branchCounts}</td>
                                        <td className="td-text pl-12">{data.employeeCounts}</td>
                                        <td className="td-text pl-12">{data.customerCounts}</td>
                                        <td className="td-text">{data.status === 1 ? "Active" : "Inactive"}</td>
                                        <td className="td-text">{data.createdAt?.split("T")[0].split("-").reverse().join("-")}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Tab>
                   <Tab eventKey="term" title="Inactive Companies">
                    <div className="mt-3 tableFixHead">
                        <table className="border-spacing-x-0 border-spacing-y-1" >
                            <thead>
                                <tr className="whitespace-nowrap">
                                    <th scope="col" className="th-heading">S.No</th>
                                    <th scope="col" className="th-heading">Company Name</th>       
                                    <th scope="col" className="th-heading">Unique Code</th>
                                    <th scope="col" className="th-heading">Admin Email</th>
                                    <th scope="col" className="th-heading">Status</th>
                                    <th scope="col" className="th-heading">Created On</th>
                                    <th scope="col" className="th-heading text-center">Count</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {inactiveCompanies && inactiveCompanies.map((data, i) => (
                                    <tr key={data.id} className="tr whitespace-nowrap" >
                                        <td className="td-text">{i + 1}</td>
                                        <td className="td-text">{data.name}</td>
                                        <td className="td-text">{data.uniqueCode}</td>
                                        <td className="td-text">{data?.email ? simpleCrypto.decrypt(data.email) : "undefined"}</td>
                                        <td className="td-text">{data.status === 1 ? "Active" : "Inactive"}</td>
                                        <td className="td-text">{data.createdAt?.split("T")[0].split("-").reverse().join("-")}</td>
                                        <td className="td-text text-center">{data.remainderCount}</td>
                                        <td className="td-text"> {data.remainderCount >= 5 ? <div></div> :<i onClick={() => sendRemainderOtp(data)} className="fa fa-envelope cursor-pointer" aria-hidden="true"></i>}</td>
                                        
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Tab>
                </Tabs>
        </div>
    )
}

export default Dashboard
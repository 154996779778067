import React, { Component, useEffect, useState } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, NavLink, Router } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment-timezone";
import { useQuery } from "react-query";
import tick from "../images/tick.svg";
import interaction from "../images/target.svg";
import activity from "../images/user.svg";
import interest from "../images/thumbsup.svg";
import customers from "../images/customers.svg";
import deal from "../images/deal.svg";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


export default function BranchDashboard() {
    const [activities, setActivities] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [lastWeekDates, setLastWeekDates] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const today = new Date();
    const endDate = moment().format("DD-MM-YYYY");
    const startDate = moment().subtract(6, 'd').format("DD-MM-YYYY");

    useEffect(() => {
        setLastWeekDates(new Array(7).fill(false))
        getAllLastWeekCountsByBranch();
    }, [])
    useEffect(() => {
        handleDateSelect(1);
    }, [activities])
    const getAllLastWeekCountsByBranch = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'manager/getEmployeesActivitiesByBranch', { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    console.log("success last week activities fetched from query", response.data);
                    setActivities(response.data.empDetails)
                    setIsLoading(false)
                } else {
                    console.log("Error");
                }
            })
            .catch(error => {
                console.error('Error loading activities data:', error);
            })
    };
    const handleDateSelect = (i) => {
        let newArray = new Array(7).fill(false)
        newArray[i - 1] = true;
        setLastWeekDates([...newArray])
        const curDate = moment(today).subtract(7 - i, 'days').format("DD/MM");
        let curDateActivities = activities && activities.filter(activity => {
            let updatedAt = moment(activity.updatedAt).format("DD/MM/YY");
            if (updatedAt.substring(0, 5) == curDate)
                return activity;
        })
        setFilteredData(curDateActivities);
    }
    const countActivities = (i) => {
        const curDate = moment(today).subtract(6 - i, 'days').format("DD/MM");
        let curDateActivities = [];
        curDateActivities = activities && activities.filter(activity => {
            let updatedAt = moment(activity.updatedAt).format("DD/MM/YY");
            if (updatedAt.substring(0, 5) == curDate)
                return activity;
        })
        return curDateActivities[0]?.totalActivities;
    }
    if (isLoading) {
        return <div className="flex justify-center items-center w-full p-20 text-xl text-center font-semibold">Loading...</div>
    }
    return (
        <div className="flex flex-col justify-between ml-8 mt-20">
            <div className="text-left font-semibold text-black">
                <h5> Dashboard ({startDate} to {endDate})</h5>
            </div>
            <div className='flex flex-col gap-8 mt-6'>
                <p className='text-center'>Activities for past 7 days </p>
                <div className='grid grid-cols-7 gap-1 mt-2 border border-black rounded-lg w-[80vw] h-[10vh] p-2'>
                    {lastWeekDates && lastWeekDates.map((data, i) => {
                        return (
                            <div key={i} className={`flex flex-col justify-around text-center cursor-pointer border border-black rounded-xl w-15 pb-2 ${lastWeekDates[i] ? "bg-[#F7F9CB] border border-yellow-400 shadow-md" : "border-[1px] border-gray-200"}`} onClick={() => handleDateSelect(parseInt(i + 1))}>
                                <p className={`text-sm font-medium ${lastWeekDates[i] ? "transform translate-y-1 pr-2" : ""}`}>{moment(today).subtract(6 - i, 'days').format("DD")}</p>
                                <p className={`text-lg font-semibold ${lastWeekDates[i] ? "transform translate-y-1 pr-2" : ""}`}>{countActivities(i)}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-16 min-w-[80vw]'>
                    {filteredData && filteredData.map((data, index) => {
                        return (
                            <Card className="max-w-[15rem]" key={data.updatedAt + index}>
                                <Card.Body style={{ margin: '0', padding: "0" }}>
                                    <div className={`p-1 relative ${data.activitiesCount == 0 ? "bg-[#FBF9F3]" : "bg-[#E7F9FF]"}`}>
                                        <p className={`absolute right-0 top-0 ${data.activity === "Paid Advance" || data.activity === "Purchased" ? "" : "invisible"}`}><img src={tick} className='w-5 h-5 px-1' /></p>
                                        <p className='flex py-0.5 font-semibold text-[13px]'><img src={activity} className='w-7 px-1' />{data.employeeName}</p>
                                        <p className='timeline-text flex justify-between py-0.5 px-1'><img src={customers} className='w-7 px-1' /><span className="w-32 text-start">Customers handled</span><span>{data.visitsCount}</span></p>
                                        <p className='timeline-text flex justify-between py-0.5 px-1'><img src={interaction} className='w-7 px-1' /><span className="w-32 text-start">Sales Completed</span><span>{data.salesCompleted}</span></p>
                                        <p className='timeline-text flex justify-between py-0.5 px-1'><img src={interest} className='w-7 px-1' /><span className="w-32 text-start">Potential Customers</span><span>{data.potentialCustomersCount}</span></p>
                                        <p className='timeline-text flex justify-between py-0.5 px-1'><img src={deal} className='w-7 px-1' /><span className="w-32 text-start">Deals Handled</span><span>{data.dealsCount}</span></p>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    })}
                </div>
            </div >
        </div >
    );
};

import React, { useState, useEffect } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Modal, Form, OverlayTrigger, Tooltip, FormControl, Card, Button } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import { enqueueSnackbar } from 'notistack'
import Navbar1 from "../Navbar/Navbar1";
import LeftMenu1 from "../LeftMenu/LeftMenu1";
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const EditBranch = () => {
    const navigate = useNavigate();
    const locations = useLocation();
    const employee = locations?.state.employee
    const data = locations?.state.data
    const branchTypes = locations?.state.branchTypes
    const branchAreas = locations?.state.branchAreas
    const [branchName, setBranchName] = useState('');
    const [companyId, setCompanyId] = useState("")
    const [adminId, setAdminId] = useState("")
    const [branchCode, setBranchCode] = useState("")
    const [location, setLocation] = useState("")
    const [city, setCity] = useState("")
    const [country, setCountry] = useState("")
    const [status, setStatus] = useState()
    const [branchType, setBranchType] = useState('');
    const [branchNameType, setBranchNameType] = useState('');
    const [selectedBranchType, setSelectedBranchType] = useState();
    const [branchArea, setBranchArea] = useState('');
    const [branchAreaName, setBranchAreaName] = useState('');
    const [selectedBranchArea, setSelectedBranchArea] = useState();
    const [branchId, setBranchId] = useState('')
    const [selectedStatus, setSelectedStatus] = useState();
    const [errors, setErrors] = useState({});
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        console.log("data", data)
        setBranchName(data.branchName)
        setBranchType(data.branchType)
        setBranchArea(data.branchArea)
        setLocation(data.location)
        setCity(data.city)
        setBranchId(data.id)
        setBranchCode(data.branchCode)
        setStatus(data.status)
        setCountry(data.country)
        getBranchType(data.branchType);
        getBranchArea(data.branchArea);
        data.randomRM ? setChecked(data.randomRM) : setChecked(false)
    }, []);


    const editBranch = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: 'error' })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "branchId": branchId,
            "branchName": branchName,
            "branchCode": branchCode,
            "branchType": selectedBranchType == null ? branchType : parseInt(selectedBranchType),
            "branchArea": selectedBranchArea == null ? branchArea : parseInt(selectedBranchArea),
            "adminId": employee?.id,
            "companyId": employee?.companyId,
            "location": location,
            "city": city,
            "country": country,
            "status": selectedStatus == null ? status : parseInt(selectedStatus),
            "randomRM": checked
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/editBranch', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    enqueueSnackbar("Branch Details Updated Successfully !", { variant: 'success' })
                    navigate(-1);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };

    const handleChangeBranchType = (e) => {
        setSelectedBranchType(e.target.value);
    }
    const handleChangeBranchArea = (e) => {
        setSelectedBranchArea(e.target.value);
    }
    const handleChangeStatus = (e) => {
        setSelectedStatus(e.target.value);
    }
    const getBranchType = (typeId) => {
        if (!typeId) return;
        let bname = (branchTypes.find(bType => bType.id === typeId))
        console.log("bname", bname)
        setBranchNameType(bname.branchType)
    }
    const getBranchArea = (typeId) => {
        if (!typeId) return;
        let bname = (branchAreas.find(b => b.id === typeId))
        setBranchAreaName(bname.branchArea)
    }
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!branchName) {
            errorObj = {
                ...errorObj,
                branchNameError: true
            }
            error = true;
        }
        if (!location) {
            errorObj = {
                ...errorObj,
                locationError: true
            };
            error = true;
        }
        if (!city) {
            errorObj = {
                ...errorObj,
                cityError: true
            };
            error = true;
        }
        if (!country) {
            errorObj = {
                ...errorObj,
                countryError: true
            };
            error = true;
        }
        if (!branchCode) {
            errorObj = {
                ...errorObj,
                branchCodeError: true
            }
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    };
    const handleCheck = () => {
        setChecked(!checked)
    };
    return (
        <div>
            {/* <Modal
                show={true}
                backdrop="static"
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >    <Modal.Header closeButton style={{ zIndex: "100" }}>
                </Modal.Header> */}
            <section className="h-screen flex flex-col justify-center items-center mx-5">
                <Navbar1 employee={employee} />
                <LeftMenu1 section="Edit Branch" />
                <div><i onClick={() => navigate(-1)} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i></div>
                <div className="md:w-1/3 max-w-sm mt-10 max-h-[90vh] overflow-y-auto">
                    <div className="text-center md:text-left">
                        <label className="mr-1 text-lg text-black-600">Edit Branch</label>
                    </div>
                    <label className="mr-1 text-sm text-black-600">Branch Code</label>
                    <input className={`text-sm w-full px-4 py-2 -mt-2 ${errors.branchCodeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                        type="text"
                        disabled={true}
                        value={branchCode}
                        onChange={(e) => { setBranchCode(e.target.value); setErrors({ ...errors, branchCodeError: false }) }}
                        name="branchCode"
                        placeholder="Branch Code" />
                    <label className="mr-1 mt-2 text-sm text-black-600">Branch Name</label>
                    <input className={`text-sm w-full px-4 py-2 -mt-2 ${errors.branchNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                        type="text"
                        onChange={(e) => { setBranchName(e.target.value); setErrors({ ...errors, branchNameError: false }) }}
                        name="branchName"
                        value={branchName}
                        placeholder="Branch Name" />
                    <label className="mr-1 mt-2 text-sm text-black-600">Branch Type</label>
                    <select name="branchType" value={selectedBranchType} className={`text-sm w-full px-4 py-2 -mt-2 ${errors.branchTypeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                        onChange={(e) => { handleChangeBranchType(e); setErrors({ ...errors, branchTypeError: false }) }} >
                        <option value={branchType ? branchType : ""}>{branchNameType ? branchNameType : "Select Branch Type"}</option>
                        {branchTypes && branchTypes.map(bType => {
                            return (
                                <option key={bType.id} value={bType.id}>{bType.branchType}</option>
                            )
                        })}
                    </select>
                    <label className="mr-1 mt-2 text-sm text-black-600">Branch Area</label>
                    <select name="branchArea" value={selectedBranchArea} className={`text-sm w-full px-4 py-2 -mt-2 ${errors.branchAreaError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                        onChange={(e) => { handleChangeBranchArea(e); setErrors({ ...errors, branchAreaError: false }) }} >
                        <option value={branchArea ? branchArea : ""}>{branchAreaName ? branchAreaName : "Select Branch Area"}</option>
                        {branchAreas && branchAreas.map(b => {
                            return (
                                <option key={b.id} value={b.id}>{b.branchArea}</option>
                            )
                        })}
                    </select>
                    <label className="mr-1 mt-2 text-sm text-black-600">Status</label>
                    <select name="status" value={selectedStatus} className="text-sm w-full px-4 py-2 -mt-2 border border-solid border-gray-300 rounded"
                        onChange={(e) => handleChangeStatus(e)} >
                        <option value={selectedStatus == null ? status : selectedStatus}>{selectedStatus == null ? status == 1 ? "Active" : "Inactive" : selectedStatus == 1 ? "Active" : "Inactive"}</option>
                        <option key={1} value={1}>Active</option>
                        <option key={2} value={2}>Inactive</option>
                    </select>
                    <label className="mr-1 mt-2 text-sm text-black-600">Location</label>
                    <input className={`text-sm w-full px-4 py-2 -mt-2 ${errors.locationError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                        type="text"
                        onChange={(e) => { setLocation(e.target.value); setErrors({ ...errors, locationError: false }) }}
                        name="location"
                        value={location}
                        placeholder="Location" />
                    <label className="mr-1 mt-2 text-sm text-black-600">City</label>
                    <input className={`text-sm w-full px-4 py-2 -mt-2 ${errors.cityError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                        type="text"
                        onChange={(e) => { setCity(e.target.value); setErrors({ ...errors, cityError: false }) }}
                        name="city"
                        value={city}
                        placeholder="City" />
                    <label className="mr-1 mt-2 text-sm text-black-600">Country</label>
                    <input className={`text-sm w-full px-4 py-2 -mt-2 ${errors.countryError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                        type="text"
                        onChange={(e) => { setCountry(e.target.value); setErrors({ ...errors, countryError: false }) }}
                        name="country"
                        value={country}
                        placeholder="Country" />

                    <div className="flex text-xs items-center gap-2">
                        <input type="checkbox" checked={checked} onChange={handleCheck} />
                        <label className="text-base pt-1.5">Allow Random RM Allocation</label>
                    </div>
                    <div className="flex justify-end text-center md:text-left">
                        <button className="-mt-3 bg-custom-bg-color hover:custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs" onClick={editBranch} type="submit">Update</button>
                    </div>
                </div>
            </section>
            {/* </Modal> */}
        </div>
    )
}

export default EditBranch
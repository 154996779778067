import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import LeftMenu from '../LeftMenu/LeftMenu';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import Customer from '../Customer/Customer';
import EmployeeDashBoard from '../EmployeeDashBoard/EmployeeDashBoard';
import Navbar from '../Navbar/Navbar';
import CustomerDashboard from '../Customer/CustomerDashboard';
import TargetsTable from '../Utils/TargetsTable';
import EmployeeContext from '../context/employee/EmpContext';

import AddBranchTarget from '../BranchAdmin/AddBranchTargets';
import { useQuery } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import ForceLogoutPopup from '../PopUps/ForceLogout';
import AddActuals from './AddActuals';
import Deal from '../components/Deal/Deal';
import RewardsConfig from '../components/rewards/RewardsConfig';
import CustomerSurvey from './CustomerSurvey';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const SalesPerson = () => {
    const navigate = useNavigate();
    const { part } = useParams();
    const { empState: employee } = useContext(EmployeeContext)
    const [customers, setCustomers] = useState([]);
    const [section, setSection] = useState(part || "Customers");
    const [newCustomers, setNewCustomers] = useState([]);
    const [showForceLogOut, setShowForceLogOut] = useState(false);
    const [deals, setDeals] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rewardsFreq, setRewardsFreq] = useState([]);
    const [nonFinacialRewards, setNonFinancialRewards] = useState([]);
    const [rewardTiers, setRewardTiers] = useState([]);

    useEffect(() => {
        if (localStorage.getItem("junk")) {
            navigate(`/${employee.companyName}/salesperson/Customers`)
        }
    }, []);
    useEffect(() => {
        if (section === "Meetings")
            navigate(`/${employee.companyName}/meetings`)
    }, [section]);
    const getAllCustomers = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        return axios.get(config.apiUrl + 'employees/getAllCutomerIdBySalesManId', { headers: headers })
    };
    const { data: customersDetails, refetch: refetchCustomers } = useQuery("customers-details", getAllCustomers, {
        enabled: section === 'Customers',
        onSuccess: (response) => {
            console.log(response.data.message, response.data);
            setCustomers(response.data.customers)
        },
        onError: (error) => {
            console.error('Error loading products data:', error);
            if (error.response.status == 401)
                // enqueueSnackbar("Unauthorized!!Kindly Login again", { variant: "error" })
                setShowForceLogOut(true)
        },
    });
    const getEmployeeDeals = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        setLoading(true)
        return axios.get(config.apiUrl + 'employees/getDealsByEmployee', { headers: headers })
    };
    const { data: dealsData, refetch } = useQuery("deals-details", getEmployeeDeals, {
        enabled: section === 'Deals',
        onSuccess: (response) => {
            if (response.data.status == true) {
                console.log(response.data.message, response.data)
                setDeals(response.data.allDeals);
            } else {
                console.log("Error");
            }
            setLoading(false)
        },
        onError: (error) => {
            console.error('Error loading products data:', error);
            enqueueSnackbar("Something went wrong!!", { variant: "error" });
            setLoading(false)
        },
    });
    const getRewardsConfig = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        return axios.get(config.apiUrl + 'companyAdmin/getRewardsConfigByCompany', { headers: headers })
    };
    const { data: rewards_config } = useQuery("rewads-config", getRewardsConfig, {
        enabled: section === 'Rewards',
        onSuccess: (response) => {
            if (response.data.status == true) {
                console.log(response.data.message, response.data)
                setRewardsFreq(response.data.rewardsFreq);
                setNonFinancialRewards(response.data.nonFinancialRewards);
                setRewardTiers(response.data.rewardTiers)
            } else {
                console.log("Error");
            }
        },
        onError: (error) => {
            console.error('Error loading products data:', error);
            if (error.response.status === 401) setShowForceLogOut(true);
            else enqueueSnackbar("Something went wrong!!", { variant: "error" });
        },
    });
    const getEmployeeRole = (role) => {
        if (role === 1) return "Company Admin"
        else if (role === 4) return "Super Admin"
        else if (role === 2) return "Branch Admin"
        else return "Relationship Manager"
    }

    return (
        <div className='flex'>
            {showForceLogOut && <ForceLogoutPopup employee={employee} />}
            <div className='w-[14vw] min-h-screen bg-black text-white'><LeftMenu section={section} setSection={setSection} /></div>
            <div className='w-5/6'>
                <Navbar employee={employee} role={getEmployeeRole(employee?.role)} />
                {section == "Customers" ?
                    <Customer customers={customers} role={3} newCustomers={newCustomers} refetch={refetchCustomers} />
                    : (section == "Dashboard") ?
                        <EmployeeDashBoard employee={employee} role={"Employee"} />
                        : (section == "EmployeeTargets") ?
                            <TargetsTable role={3} employee={employee} />
                            : (section == "SetEmployeeTargets") ?
                                <AddBranchTarget />
                                : (section === "SetActuals") ?
                                    <AddActuals />
                                    : (section === "Deals") ?
                                        <Deal deals={deals} loading={loading} refetch={refetch} />
                                        : section === "CustomersDashboard" ?
                                            <CustomerDashboard getEmployeeRole={getEmployeeRole} employee={employee} role={getEmployeeRole(employee.role)} />
                                            : (section === "Rewards") ?
                                                <RewardsConfig rewardsFreq={rewardsFreq} nonFinancialRewards={nonFinacialRewards} rewardTiers={rewardTiers} />
                                                : (section === "Survey") ?
                                                    <CustomerSurvey />
                                                    : <></>
                }
            </div>

        </div>
    )
}

export default SalesPerson
import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import Navbar1 from "../Navbar/Navbar1";
import LeftMenu1 from "../LeftMenu/LeftMenu1";
import { Modal } from 'react-bootstrap'
import { enqueueSnackbar } from 'notistack'
import EmployeeContext from "../context/employee/EmpContext";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const RespondCase = ({ closePopup, data }) => {
    const navigate = useNavigate();
    const locations = useLocation();
    const { empState: employee } = useContext(EmployeeContext)
    // const data = locations.state;
    const [caseType, setCaseType] = useState('');
    const [status, setStatus] = useState('');
    const [severity, setSeverity] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [name, setName] = useState('');
    const [caseDetails, setCaseDetails] = useState('');
    const [selectedCaseType, setSelectedCaseType] = useState();
    const [selectedCaseStatus, setSelectedCaseStatus] = useState();
    const [selectedSeverity, setSelectedSeverity] = useState();
    const [leadTypes, setLeadTypes] = useState([]);
    const [selectedLeadTypes, setSelectedLeadTypes] = useState();
    const [place, setPlace] = useState([]);
    const [response, setResponse] = useState();
    const [errors, setErrors] = useState({});


    useEffect(() => {
        console.log("useEffect called", data);
        setCaseType(data.caseTypeData)
        setCaseDetails(data.caseDetails)
        setSeverity(data.severityData)
        setResponse(data.response)
        setStatus(data.status)
        setName(data.name)
        setCreatedAt(data.createdAt)
    }, [])

    const updateCase = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: 'error' })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "caseId": data.id,
            "status": 2,
            "response": response
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/updateCase', payload, { headers: headers })
            .then((response) => {
                console.log(response);
                enqueueSnackbar(response.data.message, { variant: "success" })
                navigate('/superadmin/Cases');
            })
            .catch(function (error) {
                console.log("error", error, payload);
                enqueueSnackbar(error.response.data.message, { variant: "error" })
            });

    };
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!response) {
            errorObj = {
                ...errorObj,
                responseError: true
            }
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    const handleBack = () => {
        if (employee?.role === 1) {
            navigate(`/${employee.companyName}/companyadmin/Cases`)
        } else {
            navigate(`/superadmin/Cases`)
        }
    }
    return (
        // <section className="h-screen flex flex-col">
        //     <Navbar1 />
        //     <LeftMenu1 section={employee?.role === 1 ? "View Case" : (data.status === 1) ? "Respond to Case" : "View Case"} />
        //     <div><i onClick={handleBack} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i></div>
        <Modal show={true} size="md" centered >
            <Modal.Header closeButton style={{ zIndex: "100" }}>
            </Modal.Header>
            <Modal.Body>
                <div className="flex flex-col">
                    <div className="-mt-10 text-xl text-black-600 text-center md:whitespace-nowrap font-semibold">{employee?.role === 1 ? "Response" : (status === 1) ? "Respond to Case" : "Response"}</div>
                    {employee?.role === 1 ? <div className="mt-4">{createdAt?.split("T")[0].split("-").reverse().join("-")}</div> :
                        <div className="mt-3 flex justify-between">
                            <div>{name}
                            </div>
                            <div>{createdAt?.split("T")[0].split("-").reverse().join("-")}</div>
                        </div>
                    }
                    <label className="mr-1 mt-2 text-sm text-black-600">Case Type</label>
                    <input className="text-sm w-full p-2 border border-solid border-gray-300 rounded resize-none"
                        type="text"
                        disabled={true}
                        value={caseType}
                        placeholder="Case Type" />
                    <label className="mr-1 mt-2 text-sm text-black-600">Severity</label>
                    <input className="text-sm w-full p-2 border border-solid border-gray-300 rounded resize-none"
                        type="text"
                        disabled={true}
                        value={severity}
                        placeholder="Case Details" />
                    <label className="mr-1 mt-2 text-sm text-black-600">Case Details</label>
                    <textarea className="text-sm w-full p-2 border border-solid border-gray-300 rounded resize-none"
                        type="text"
                        rows={3}
                        disabled={true}
                        value={caseDetails}
                        placeholder="Case Details" />
                    <label className="mr-1 mt-1 text-sm text-black-600">Response</label>
                    <textarea className={`text-sm w-full p-2 resize-none ${errors.responseError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                        type="text"
                        value={response}
                        disabled={employee?.role === 1 || status === 2 ? true : false}
                        onChange={(e) => { setResponse(e.target.value); setErrors({ ...errors, responseError: false }) }}
                        name="response"
                        placeholder="Response" />
                    {employee?.role === 1 ?
                        <div className="flex justify-end">
                            <button className="mt-2 w-20 text-center bg-orange-600 hover:bg-orange-700 p-2 text-white font-semibold uppercase rounded text-xs" onClick={closePopup} type="submit">Close</button>
                        </div> :
                        <div className="mt-1">{status === 1 ?
                            <div className="flex justify-between">
                                <button className="mt-2 float-right w-20 text-center  bg-orange-600 hover:bg-orange-700 p-2 text-white font-semibold uppercase rounded text-xs" onClick={closePopup} type="submit">Close</button>
                                <button className="mt-2 float-right w-20 text-center bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color p-2 font-semibold uppercase rounded text-xs" onClick={updateCase} type="submit">Submit</button>
                            </div> :
                            <div className="flex-justify-end">
                                <button className="mt-2 float-right w-20 text-center  bg-orange-600 hover:bg-orange-700 p-2 text-white font-semibold uppercase rounded text-xs" onClick={closePopup} type="submit">Close</button>
                            </div>}
                        </div>
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default RespondCase
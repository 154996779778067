import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { Modal } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import { enqueueSnackbar } from 'notistack';
import ErrorPopup from "../PopUps/ErrorPopup";
import EmployeeContext from "../context/employee/EmpContext";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const AssignRm = ({ data, activeEmployees, closePopup }) => {
    const { empState: employee } = useContext(EmployeeContext)
    const [employees, setEmployees] = useState([]);
    const [empSelected, setEmpSelected] = useState('');
    const [errors, setErrors] = useState({});
    const [empName, setEmpName] = useState('')
    const [employeeId, setEmployeeId] = useState(null)

    useEffect(() => {
        console.log("state123", activeEmployees)
        let emp = activeEmployees.filter(employee => employee.role === 3 && employee.status === 1 && employee.employeeId != data.employeeId)
        setEmployees(emp)
        console.log("emp", emp)
    }, [])
    const handleEmployeeChange = (e) => {
        setEmpSelected(e.target.value);
        let emp = activeEmployees && activeEmployees.find(em => em.id === e.target.value);
        let name = emp ? emp.firstName + " " + emp.lastName : "";
        let empId = emp ? emp.employeeId : null;
        setEmployeeId(empId)
        setEmpName(name)
    }
    const updateRelationshipManager = () => {
        if (validateField()) {
            enqueueSnackbar('Please Select Relationship Manager!', { variant: 'error' })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "visitId": data.visitId,
            "activityId": data.activityId,
            "salesmanId": empSelected,
            "employeeName": empName,
            "employeeId": employeeId

        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'manager/updateRelationshipManager', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    console.log("success", response.data.message, payload);
                    enqueueSnackbar(response.data.message, { variant: "success" })
                    closePopup()
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
                enqueueSnackbar("Something went wrong", { variant: "success" })
            });
    };
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!empSelected) {
            errorObj = {
                ...errorObj,
                empSelectedError: true
            }
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    return (
        <Modal show={true} onHide={closePopup} centered contentClassName="w-[20rem] h-[15rem] rounded-2xl ml-24">
            <Modal.Body className="flex flex-col items-center">
                <div className="w-full">
                    <div className="text-center md:text-left">
                        <label className="mt-1 font-semibold mr-1 text-2xl text-custom-bg-color">Reassign RM</label>
                    </div>
                    <div className="my-3 flex items-center justify-center">
                        <select name="employee" value={empSelected} className={`text-base w-3/4 px-4 py-2 ${errors.empSelectedError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-1`}
                            onChange={(e) => { handleEmployeeChange(e); setErrors({ ...errors, empSelectedError: false }) }} >
                            <option value="" className="text-base">Select RM</option>
                            {employees && employees.map(employee => {
                                return (
                                    <option className="text-base" key={employee.id} value={employee.id}>{employee.firstName}&nbsp;{employee.lastName}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="flex justify-center items-center w-full">
                        <button className="mt-3 w-32 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-base" onClick={updateRelationshipManager} type="submit">Submit</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    )
}

export default AssignRm
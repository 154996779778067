import React, { useState, useEffect } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Modal, Form, OverlayTrigger, Tooltip, FormControl, Card, Button } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import ErrorPopup from "../PopUps/ErrorPopup";
import { enqueueSnackbar } from 'notistack'
import Navbar1 from "../Navbar/Navbar1";
import LeftMenu1 from "../LeftMenu/LeftMenu1";
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const EditEmployee = () => {
    const navigate = useNavigate();
    const locations = useLocation();
    const employee = locations?.state.employee
    const branches = locations?.state.branches
    const empDetails = locations?.state.empDetails
    const [activeBranches, setActiveBranches] = useState([])
    const [branchName, setBranchName] = useState(empDetails.branchName)
    const [branchId, setBranchId] = useState(empDetails.branchId)
    const [firstName, setFirstName] = useState(empDetails.firstName)
    const [lastName, setLastName] = useState(empDetails.lastName)
    const [email, setEmail] = useState(simpleCrypto.decrypt(empDetails.email))
    const [mobile, setMobile] = useState(empDetails.mobile)
    const [role, setRole] = useState(empDetails.role)
    const [employeeId, setEmployeeId] = useState(empDetails.employeeId)
    const [show, setShow] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const [popupMsg, setPopupMsg] = useState(false);
    const [status, setStatus] = useState('')
    const [errors, setErrors] = useState({});

    useEffect(() => {
        console.log(branches, empDetails, employee);
        setBranchId(empDetails.branchId)
        setBranchName(empDetails.branchName)
        setStatus(empDetails.status);
        getAllBranches();
    }, []);
    const getAllBranches = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            companyId: employee?.companyId
        }
        axios.post(config.apiUrl + 'companyAdmin/getAllBranches', payload, { headers: headers })
            .then(response => {
                console.log("success branch from query ", response.data);
                let currentBranches = response.data.branches && response.data.branches?.length > 0 ? response.data.branches : [];
                let activeBranches = currentBranches.filter(branch => branch.status === 1 && branch.branchType !== 4)
                setActiveBranches(activeBranches);
            })
            .catch(error => {
                console.error('Error loading branches data:', error);
            })
    };
    const updateEmployee = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: 'error' })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "branchId": branchId,
            "branchName": branchName,
            "role": parseInt(role),
            "status": parseInt(status),
            "employeeId": employeeId,
            "firstName": firstName,
            "lastName": lastName,
            "email": simpleCrypto.encrypt(email),
            "mobile": simpleCrypto.encrypt(mobile),
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/updateEmployeeDetails', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    enqueueSnackbar("Employee Details Updated Successfully !", { variant: "success" })
                    navigate(`/${employee.companyName}/companyadmin/Employee`);
                    // closePopup()
                    // getAllEmployeesByBranch()
                } else {
                    console.log("Error", payload);

                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
                setShow(false);
                setShowPopup(true);
                setPopupMsg(error.response.data.message);
                setTimeout(() => { setShowPopup(false) }, 3000)
            });
    };

    const handleBranchChange = (e) => {
        let bname = (activeBranches.find(branch => branch.id === e.target.value))
        setBranchName(bname.branchName);
        console.log("b", bname)
        setBranchId(e.target.value);
    }
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!branchId) {
            errorObj = {
                ...errorObj,
                branchIdError: true
            }
            error = true;
        }
        if (!firstName) {
            errorObj = {
                ...errorObj,
                firstNameError: true
            };
            error = true;
        }
        if (!lastName) {
            errorObj = {
                ...errorObj,
                lastNameError: true
            };
            error = true;
        }
        if (!email) {
            errorObj = {
                ...errorObj,
                emailError: true
            }
            error = true;
        }
        if (!mobile) {
            errorObj = {
                ...errorObj,
                mobileError: true
            }
            error = true;
        }
        if (!status) {
            errorObj = {
                ...errorObj,
                statusError: true
            }
            error = true;
        }
        if (!employeeId) {
            errorObj = {
                ...errorObj,
                employeeIdError: true
            }
            error = true;
        }
        if (!role) {
            errorObj = {
                ...errorObj,
                roleError: true
            }
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    return (
        <div>
            {showPopup ?
                <ErrorPopup show={showPopup} setShow={setShowPopup} msg={popupMsg} />
                :
                <section className="h-screen flex flex-col justify-center items-center mx-5">
                    <Navbar1 employee={employee} />
                    <LeftMenu1 section="Edit Employee" />
                    <div><i onClick={() => navigate(`/${employee.companyName}/companyadmin/Employee`)} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i></div>
                    <div className="md:w-1/3 max-w-sm mt-10">
                        <div className="text-center md:text-left">
                            <label className="mt-8 mr-1 text-lg text-black-600">Edit Employee</label>
                        </div>
                        <label className="mr-1 mt-2 text-sm text-black-600">Role</label>
                        <select name="role"
                            disabled={role === 1 ? true : false}
                            value={role} className={`text-sm w-full px-4 py-2 ${errors.roleError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                            onChange={(e) => { setRole(e.target.value); setErrors({ ...errors, roleError: false }) }} >
                            <option value="">Select Role</option>
                            <option value={2}>Branch Admin</option>
                            <option value={3}>Relationship Manager</option>
                        </select>
                        <label className="mr-1 mt-2 text-sm text-black-600">Branch</label>
                        {employee.role === 1 ?
                            <select name="branch"
                                value={branchId} className={`text-sm w-full px-4 py-2 ${errors.branchIdError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                                onChange={(e) => { handleBranchChange(e); setErrors({ ...errors, branchIdError: false }) }}>
                                <option value={branchId ? branchId : ""}>{branchName ? branchName : "Select Branch"} </option>
                                {activeBranches && activeBranches.map(branch => {
                                    return (
                                        <option key={branch.id} value={branch.id}>{branch.branchName}</option>
                                    )
                                })}
                            </select>
                            : <p className={`text-sm w-full p-2 min-h-[4vh] border border-gray-500 cursor-pointer rounded ${branchName ? "text-black" : "text-gray-400"}`}>{branchName ? branchName : "Branch Name"}</p>
                        }
                        <div className="flex">
                            <div className="w-1/2 mr-1">
                                <label className="mt-2 text-sm text-black-600">Employee Id</label>
                                <input className={`text-sm w-full px-2 py-2 ${errors.employeeIdError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                                    type="text"
                                    value={employeeId}
                                    disabled={true}
                                    onChange={(e) => { setEmployeeId(e.target.value); setErrors({ ...errors, employeeIdError: false }) }}
                                    name="employeeId"
                                    placeholder="Employee Id" />
                            </div>
                            <div className="w-1/2 ml-1">
                                <label className="mt-2 text-sm text-black-600">Status</label>
                                <select name="status" disabled={role === 1 ? true : false} value={status} className={`text-sm w-full px-2 py-2 ${errors.statusError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                                    onChange={(e) => { setStatus(e.target.value); setErrors({ ...errors, statusError: false }) }}>
                                    <option value="">Status</option>
                                    <option value={1}>Active</option>
                                    <option value={2}>Inactive</option>
                                </select>
                            </div>
                        </div>
                        <label className="mr-1 mt-2 text-sm text-black-600">First Name</label>
                        <input className={`text-sm w-full px-4 py-2 ${errors.firstNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                            type="text"
                            value={firstName}
                            disabled={role === 1 ? true : false}
                            onChange={(e) => { setFirstName(e.target.value); setErrors({ ...errors, firstNameError: false }) }}
                            name="firstName"
                            placeholder="First Name" />
                        <label className="mr-1 mt-2 text-sm text-black-600">Last Name</label>
                        <input className={`text-sm w-full px-4 py-2 ${errors.lastNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                            type="text"
                            onChange={(e) => { setLastName(e.target.value); setErrors({ ...errors, lastNameError: false }) }}
                            name="lastName"
                            disabled={role === 1 ? true : false}
                            value={lastName}
                            placeholder="Last Name" />
                        <label className="mr-1 mt-2 text-sm text-black-600">Mobile</label>
                        <input className={`text-sm w-full px-4 py-2 ${errors.mobileError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                            type="text"
                            value={mobile}
                            onChange={(e) => { setMobile(e.target.value); setErrors({ ...errors, mobileError: false }) }}
                            name="mobile"
                            placeholder="Mobile" />
                        <label className="mr-1 mt-2 text-sm text-black-600">Email</label>
                        <input className={`text-sm w-full px-4 py-2 ${errors.emailError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                            type="email"
                            value={email}
                            onChange={(e) => { setEmail(e.target.value); setErrors({ ...errors, emailError: false }) }}
                            name="email"
                            placeholder="Email" />

                        <div className="flex justify-end text-center md:text-left">
                            {/* <button className="mt-4 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white uppercase rounded text-xs tracking-wider" onClick={removeEmployee} type="submit">Delete</button> */}
                            <button className="mt-4 bg-custom-bg-color hover:custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs" onClick={updateEmployee} type="submit">Update</button>
                        </div>
                    </div>
                    {/* </Modal >} */}
                </section>}
        </div >
    )
}

export default EditEmployee
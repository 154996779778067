import React, { useState, useEffect } from "react";
import { Modal } from 'react-bootstrap'
import error from "../images/errro.svg"
import logout from '../images/logout.svg'
import { useNavigate } from "react-router";
import SimpleCrypto from "simple-crypto-js";
import { config } from "../Config/Config";
import axios from "axios";
import { enqueueSnackbar } from "notistack";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const LogoutSecond = ({ setShowLogOut, employeeId, companyId }) => {
    const navigate = useNavigate();
    useEffect(() => {

    }, []);
    const Logout = () => {
        updateLogOutTime();
    }
    const updateLogOutTime = async () => {
        let payload = {
            employeeId: employeeId,
            companyId: companyId
        }
        axios.post(config.apiUrl + "employees/logoutFromAllDevices", payload)
            .then((response) => {
                if (response.data.status === true) {
                    console.log("Success", response.data.message);
                    enqueueSnackbar("Logged out from other device", { variant: "success" })
                    setShowLogOut(false)
                } else {
                    console.log("Error")
                }
            })
    }
    return (
        <Modal show={true} onHide={() => setShowLogOut(false)} size="md" centered >
            <Modal.Header closeButton style={{ zIndex: "100" }} className="p-0 m-0">
            </Modal.Header>
            <Modal.Body>
                <div className='-mt-8 flex flex-col gap-4 justify-center items-center'>
                    <p className='text-lg text-orange-400'>Currently logged in  another device</p>
                    <p className='text-sm text-orange-400'>Do you want to log out from other device?</p>
                    <div className="flex justify-between text-center md:text-left">
                        <button className="mt-4 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white font-semibold uppercase rounded text-xs mr-5" onClick={() => setShowLogOut(false)} type="submit">No</button>
                        <button className="ml-5 mt-4 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs" onClick={Logout} type="submit">Yes</button>
                    </div>
                </div>
            </Modal.Body>

        </Modal>
    )
}

export default LogoutSecond


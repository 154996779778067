import React, { useEffect, useState } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import { useQuery } from "react-query";
import moment from "moment-timezone";
import { Card } from "react-bootstrap";
import tick from "../images/tick.svg";
import interaction from "../images/store.svg";
import activity from "../images/user.svg";
import interest from "../images/calendar.svg";
import prod from "../images/prod.svg";
import { enqueueSnackbar } from "notistack";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const endDate = moment().format("DD-MM-YYYY");
const startDate = moment().subtract(6, 'd').format("DD-MM-YYYY");
export default function CustomerDashboard({ employee }) {
    const [visitsCount, setVisitsCount] = useState()
    const [activitiesCount, setActivitiesCount] = useState()
    const [potentialCount, setPotentialCount] = useState()
    const [purchasedCount, setPurchasedCount] = useState()
    const [productFamilyName, setProductFamilyName] = useState("")
    const [filteredData, setFilteredData] = useState([]);
    const [activities, setActivities] = useState([]);
    const [lastWeekDates, setLastWeekDates] = useState([]);
    const [mostEnquiredProd, setMostEnquiredProd] = useState("")
    const today = new Date();

    useEffect(() => {
        setLastWeekDates(new Array(7).fill(false))
        getEmployeeLastWeekActivity();
        // getLastWeekCustomerDetails()
    }, [])
    useEffect(() => {
        handleDateSelect(1);
    }, [activities])
    const getEmployeeLastWeekActivity = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "id": employee.id,
            "employeeId": employee.employeeId,
            "branchId": employee.branchId
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'employees/getLastWeekActivity', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log(response.data.message, response.data);
                    setActivities(response.data.lastWeekActivities);
                    setVisitsCount(response.data.visitsCount);
                    setActivitiesCount(response.data.activitiesCount);
                    setPurchasedCount(response.data.salesCount);
                    setPotentialCount(response.data.potentialCount);
                    setProductFamilyName(response.data.keyProduct ? response.data.keyProduct.productFamilyName : "");
                    setMostEnquiredProd(response.data.enquiry ? response.data.enquiry.productFamilyName : "");
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
                enqueueSnackbar("Unable to load data", { variant: "error" })
            });
    };
    const handleDateSelect = (i) => {
        let newArray = new Array(7).fill(false)
        newArray[i - 1] = true;
        setLastWeekDates([...newArray])
        const curDate = moment(today).subtract(7 - i, 'days').format("DD/MM");
        let curDateActivities = activities.filter(activity => {
            let updatedAt = moment(activity.updatedAt).format("DD/MM/YY");
            if (updatedAt.substring(0, 5) == curDate)
                return activity;
        })
        setFilteredData(curDateActivities);
    }
    const countActivities = (i) => {
        const curDate = moment(today).subtract(6 - i, 'days').format("DD/MM");
        let curDateActivities = [];
        curDateActivities = activities.filter(activity => {
            let updatedAt = moment(activity.updatedAt).format("DD/MM/YY");
            if (updatedAt.substring(0, 5) == curDate)
                return activity;
        })
        return curDateActivities.length;
    }
    return (
        <div className="flex flex-col justify-between ml-8 mt-20">
            <div className="text-left font-semibold text-black">
                <h5> Dashboard ({startDate} to {endDate})</h5>
            </div>
            <div className="flex gap-4 items-center text-center" style={{ marginTop: "1.5rem" }}>
                <div className="flex flex-col justify-around items-center py-2 rounded-xl bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color w-24 h-24 font-semibold">
                    <p className="text-xl pt-1">{visitsCount}</p>
                    <p className="text-sm pt-1">Visits</p>
                </div>
                <div className="flex flex-col justify-around items-center py-2 rounded-xl bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color w-24 h-24 font-semibold">
                    <p className="text-xl pt-1">{activitiesCount}</p>
                    <p className="text-sm pt-1">Activities</p>
                </div>
                <div className="flex flex-col justify-around items-center py-2 rounded-xl bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color w-24 h-24 font-semibold">
                    <p className="text-xl pt-1">{purchasedCount}</p>
                    <p className="text-sm pt-1">Purchased</p>
                </div>
                <div className="flex flex-col justify-around items-center py-2 rounded-xl bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color w-24 h-24 font-semibold">
                    <p className="text-xl pt-1">{potentialCount}</p>
                    <p className="text-sm pt-1">Potential</p>
                </div>
                <div className="flex flex-col rounded-xl bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color items-center text-center justify-around py-2 w-24 h-24 font-semibold">
                    <p className="text-sm">{productFamilyName === '' ? "-" : productFamilyName}</p>
                    <p className="text-xs">Key Product</p>
                </div>
                <div className="flex flex-col rounded-xl bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color items-center text-center justify-around py-2 w-24 h-24 font-semibold">
                    <p className="text-sm">{mostEnquiredProd === '' ? "-" : mostEnquiredProd}</p>
                    <p className="text-xs">Most Enquired</p>
                </div>
            </div>
            <div className='flex flex-col gap-8 mt-6'>
                <p className='text-center'>Activities for past 7 days </p>
                <div className='grid grid-cols-7 gap-1 mt-2 border border-black rounded-lg w-[80vw] h-[10vh] p-2'>
                    {lastWeekDates && lastWeekDates.map((data, i) => {
                        return (
                            <div key={i} className={`flex flex-col justify-around text-center cursor-pointer border border-black rounded-xl w-15 pb-2 ${lastWeekDates[i] ? "bg-[#F7F9CB] border border-yellow-400 shadow-md" : "border-[1px] border-gray-200"}`} onClick={() => handleDateSelect(parseInt(i + 1))}>
                                <p className={`text-sm font-medium ${lastWeekDates[i] ? "transform translate-y-1 pr-2" : ""}`}>{moment(today).subtract(6 - i, 'days').format("DD")}</p>
                                <p className={`text-lg font-semibold ${lastWeekDates[i] ? "transform translate-y-1 pr-2" : ""}`}>{countActivities(i)}</p>
                            </div>
                        )
                    })}
                </div>
                {/* {filteredData && filteredData.length > 0 ? <p className='font-semibold text-lg text-center'>Activity details for {moment(filteredData[0].updatedAt).format("DD/MM/YYYY")}</p> : null} */}
                <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-16 min-w-[80vw]'>
                    {filteredData && filteredData.map(data => {
                        return (
                            <Card className="max-w-[15rem]" key={data.updatedAt}>
                                <Card.Body style={{ margin: '0', padding: "0" }} className={`relative min-h-max ${data.activitiesCount == 0 ? "bg-[#FBF9F3]" : "bg-[#E7F9FF]"}`}>
                                    <div>
                                        <p className={`absolute right-0 top-0 ${data.activity === "Paid Advance" || data.activity === "Purchased" ? "" : "invisible"}`}><img src={tick} className='w-5 h-5 px-1' /></p>
                                        <p className='timeline-text flex py-0.5'><img src={activity} className='w-7 px-1' />{data.customerName}</p>
                                        <p className='timeline-text flex py-0.5'><img src={interest} className='w-7 px-1' />{data.activity ? data.activity : "No action"}</p>
                                        <p className='timeline-text flex py-0.5'><img src={interaction} className='w-7 px-1' />{data.source === 4 || data.userActivity === 10 ? data.dealStatus : data.source === 1 ? "Visited the store" : "Reach Out"}</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    })}
                </div>
            </div>
            <div className={`absolute bottom-1 left-[15vw] flex items-center max-w-max mx-auto gap-4 text-sm mt-1`}>
                <div className='flex gap-2 items-center'><img src={tick} className='w-5 h-5 px-1' /><span className="font-medium">Purchased/Paid Advance</span></div>
                <div className='flex gap-2 items-center'><div className='w-3 h-3 bg-[#90CAF9] rounded-xl'></div><span>New Pending</span></div>
                <div className='flex gap-2 items-center'><div className='w-3 h-3 bg-[#FFE082] rounded-xl'></div><span>Existing Customer</span></div>
            </div>
        </div>
    );
};

import React, { Component, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
import { Bar } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const RewardsCount = () => {
    const [rewardsData, setRewardsData] = useState([]);
    const [rewardsAxis, setRewardsAxis] = useState([]);
    const [values, setValues] = useState([]);
    const [current, setCurrent] = useState();

    useEffect(() => {
        getRewardsCountByBranch()
    }, [])
    const getRewardsCountByBranch = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getRewardsDataByBranch', { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log(response.data.message, response.data);
                    setRewardsData(response.data.branchesRewardsArray);
                    setRewardsAxis(response.data.nonFinRewards);
                    response.data?.branchesRewardsArray?.length > 0 ? setCurrent(response.data.branchesRewardsArray[0].id) : setCurrent("");
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    useEffect(() => {
        updateValuesData();
    }, [current])

    const updateValuesData = () => {
        let newValuesArr = [];
        let data = rewardsData && rewardsData.find(deal => deal.id === current);
        console.log("data", data)
        newValuesArr = rewardsAxis && rewardsAxis.map(nf => data[nf.tag]);
        setValues(newValuesArr);
    }
    const rewards = {
        labels: rewardsData && rewardsData.map(d => d.branchName),
        datasets: [
            {
                data: rewardsData && rewardsData.map(d => d.rewardCount),
                backgroundColor: [
                    'black',
                ],
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20
            }
        ]
    }
    const rewardsType = {
        labels: rewardsAxis && rewardsAxis.map(d => d.tag),
        datasets: [
            {
                data: values,
                backgroundColor: [
                    'black',
                ],
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20
            }
        ]
    }
    return (
        <div className="flex gap-2">
            <Card className="flex flex-col justify-between relative" style={{ marginTop: "1rem", height: "250px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                <div className="font-semibold text-lg ml-8">Rewards Count By Branch</div>
                <div style={{ height: "200px" }}>
                    <Bar data={rewards}
                        options={{
                            maintainAspectRatio: true,
                            responsive: true,
                            redraw: false,
                            elements: {
                                line: {
                                    tension: 1
                                }
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        font: {
                                            size: 8,
                                        },
                                    },
                                },
                                y: {
                                    ticks: {
                                        precision: 0
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: false
                                },
                                tooltip: {
                                    enabled: true,
                                },
                            }
                        }
                        } />
                </div>
            </Card>
            <Card className="flex flex-col justify-between relative" style={{ marginTop: "1rem", height: "250px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                <div className="font-semibold text-lg ml-8">Rewards Type By Branch</div>
                <select name="status" value={current} className="absolute -top-1 right-1 text-xs w-32  border border-solid border-gray-300 rounded mt-2"
                    onChange={(e) => setCurrent(e.target.value)} >
                    <option value="">---Choose Branch---</option>
                    {rewardsData && rewardsData.map((item, i) => {
                        return (
                            <option value={item.id} key={item.id}>{item.branchName}</option>
                        )
                    })}
                </select>
                <div style={{ height: "200px" }}>
                    <Bar data={rewardsType}
                        options={{
                            elements: {
                                line: {
                                    tension: 1
                                }
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                    ticks: {
                                        font: {
                                            size: 8, // Adjust the font size as needed
                                        },
                                    },
                                },
                                y: {
                                    ticks: {
                                        precision: 0
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                    text: "Age"
                                },
                                legend: {
                                    display: false
                                },
                            }
                        }
                        } />
                </div>
            </Card>
        </div>
    );
};

export default RewardsCount
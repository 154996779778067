import { enqueueSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import DatePicker from 'react-datepicker';
import { config } from '../../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import axios from 'axios';
import EmployeeContext from '../../context/employee/EmpContext';
import { useNavigate } from 'react-router-dom';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const CustomerEvaluation = (props) => {
    const { empState: employee } = useContext(EmployeeContext);
    const navigate = useNavigate();

    const [errors, setErrors] = useState({});

    const back = (e) => {
        e.preventDefault();
        props.prevStep();
    }

    const saveAndContinue = (e) => {
        e.preventDefault();
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: "error" })
            return
        };
        addCustomerActivity();
    };
    const validateField = () => {
        let error = false;
        let errorObj = {};
        let completedStatus = [3, 4]
        if (!props.inputValues.satisfaction) {
            errorObj = {
                ...errorObj,
                satisfactionError: true
            }
            error = true;
        }
        if (!props.inputValues.potential) {
            errorObj = {
                ...errorObj,
                potentialError: true
            }
            error = true;
        }
        if (props.inputValues.newNotes && !props.inputValues.newTargetDate && !completedStatus.includes(parseInt(props.inputValues.actionStatus))) {
            errorObj = {
                ...errorObj,
                targetDateError: true
            }
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    const addCustomerActivity = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "branchName": employee.branchName,
            "branchId": employee.branchId,
            "companyId": employee.companyId,
            "customerId": props.customer?.customerId ? props.customer.customerId : props.customer?.id,
            "interaction": parseInt(props.inputValues.interaction),
            "userActivity": parseInt(props.inputValues.activity),
            "salesmanId": employee.id,
            "userDemand": parseInt(props.inputValues.keyAsk),
            "customerSatisfaction": parseInt(props.inputValues.satisfaction),
            "customerPotential": parseInt(props.inputValues.potential),
            "customersInterest": props.inputValues.customerInterest,
            "notes": props.inputValues.newNotes ? props.inputValues.newNotes : parseInt(props.inputValues.actionType) === 3 ? "Meet In Person" : parseInt(props.inputValues.actionType) === 2 ? "Send Email" : "Make a call",
            "targetDate": props.inputValues.newTargetDate,
            "employeeId": employee.employeeId,
            "products": JSON.stringify(props.selectedProducts),
            "occasion": parseInt(props.inputValues.occasion),
            "visitId": props.customer?.visitId,
            "actionType": props.inputValues.actionType ? parseInt(props.inputValues.actionType) : null,
            "actionStatus": props.inputValues.actionStatus ? parseInt(props.inputValues.actionStatus) : null,
            "importantDates": JSON.stringify(props.importantDates),
            "dashboardId": props.customer?.id ? props.customer.id : "",
            "reachOutId": props.customer?.reachOutId ? props.customer.reachOutId : "",
            "source": props.customer?.newDealStatus || parseInt(props.inputValues.activity) === 10 ? 4 : props.customer?.reachOutId ? 3 : props.customer?.id ? 2 : 1,
            "activityId": props.activityId,
            "activityStatus": 3,
            "dealActivityId": props.customer?.activityId
        }
        let apiEndpoint = 'employees/createSalesPersonDashboard'
        if (props.customer?.reachOutId) {
            apiEndpoint = 'employees/createSalesPersonReachOut'
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'employees/addCustomerActivity', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                payload["activityId"] = props.activityId ? props.activityId : response.data.customerActivity.id;
                if (props.inputValues.newTargetDate) {
                    console.log("empdashboard api called", response);
                    axios.post(config.apiUrl + apiEndpoint, payload, { headers: headers })
                        .then((response) => {
                            if (response.data.status == true) {
                                console.log("success", response.data.message, payload);
                                enqueueSnackbar("Customer Evaluation Added Successfully!", { variant: "success" })
                                navigate(-1);
                            } else {
                                console.log("Error", payload);
                            }
                        })
                } else {
                    console.log("success", response.data.message);
                    enqueueSnackbar("Customer Evaluation Added Successfully!", { variant: "success" });
                    navigate(-1);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    return (
        <div className='flex justify-center items-center mt-20 w-full ml-12'>
            <div className="flex flex-col gap-1 w-96">
                <p className="text-base font-medium self-center">Customer Evaluations</p>
                <select name="satisfaction" value={props.inputValues.satisfaction} className={`text-sm w-full p-2 ${errors.satisfactionError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                    onChange={(e) => { props.handleChange(e); setErrors({ ...errors, satisfactionError: false }) }} >
                    <option value="">---Cutomer Satisfaction---</option>
                    {props.satisfactions && props.satisfactions.map(item => {
                        return (
                            <option value={item.id} key={item.id}>{item.satisfaction}</option>
                        )
                    })}
                </select>
                <select name="potential" value={props.inputValues.potential} className={`text-sm w-full p-2 ${errors.potentialError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                    onChange={(e) => { props.handleChange(e); setErrors({ ...errors, potentialError: false }) }} >
                    <option value="">----Cutomer Potential----</option>
                    {props.potentials && props.potentials.map(item => {
                        return (
                            <option value={item.id} key={item.id}>{item.potential}</option>
                        )
                    })}
                </select>
                <textarea className="text-sm w-full p-2 border border-solid border-gray-300 rounded mt-2 resize-none"
                    type="text"
                    rows={2}
                    maxLength={120}
                    onChange={(e) => props.handleChange(e)}
                    value={props.inputValues.customerInterest}
                    name="customerInterest"
                    placeholder="Customer's Interest"></textarea>
                <textarea className={`text-sm w-full p-2 border border-solid border-gray-300 rounded mt-2 resize-none`}
                    type="text"
                    rows={2}
                    maxLength={120}
                    onChange={(e) => props.handleChange(e)}
                    value={props.inputValues.newNotes ? props.inputValues.newNotes : props.inputValues.notes}
                    name="newNotes"
                    placeholder="Relationship Manager Notes"></textarea>
                <DatePicker className={`text-sm w-full p-2 border-[1px] ${errors.targetDateError ? " border-red-600" : "border-gray-300"} cursor-pointer mt-2 rounded`}
                    isClearable
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mmaa"
                    placeholderText="Target Date"
                    filterDate={d => {
                        return new Date() < d;
                    }}
                    selected={props.inputValues.newTargetDate ? props.inputValues.newTargetDate : props.inputValues.targetDate}
                    minTime={new Date().setHours(8, 0, 0)}
                    maxTime={new Date().setHours(19, 0, 0)}
                    onChange={date => {
                        if (!date) {
                            props.handleDateChange(null);
                            return;
                        }
                        setErrors({ ...errors, targetDateError: false })
                        let new_date = new Date(date);
                        let hours = new_date.getHours();
                        if (hours < 8)
                            new_date = new_date.setHours(8, 0, 0);
                        props.handleDateChange(new_date)
                    }}
                />
                <select name="actionType" value={props.inputValues.actionType} className="text-sm w-full p-2 border border-solid border-gray-300 rounded mt-2"
                    onChange={(e) => props.handleChange(e)} >
                    <option value="">---Action Type---</option>
                    {props.actionTypes && props.actionTypes.map(item => {
                        return (
                            <option value={item.id} key={item.id}>{item.action}</option>
                        )
                    })}
                </select>
                {props.currentNotes && <select name="actionStatus" value={props.inputValues.actionStatus} className="text-sm w-full p-2 border border-solid border-gray-300 rounded mt-2"
                    onChange={(e) => { props.handleChange(e); setErrors({ ...errors, targetDateError: false }) }} >
                    <option value="">---Status---</option>
                    {props.actionStatus && props.actionStatus.map(action => {
                        return (
                            <option value={action.id} key={action.id}>{action.status}</option>
                        )
                    })}
                </select>}
                <div className='flex justify-between mt-4 w-full'>
                    <button className='bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color rounded-md py-1 px-4 max-w-max uppercase'
                        onClick={back}>Prev</button>
                    <button className='bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color rounded-md py-1 px-4 max-w-max uppercase'
                        onClick={saveAndContinue}>Submit</button>
                </div>
            </div>
        </div>
    );
}

export default CustomerEvaluation;

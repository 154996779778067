import { Modal } from "react-bootstrap";
import qrcode from "qrcode";
import { useContext, useEffect, useState } from "react";
import moment from "moment-timezone";
import axios from "axios";
import { config } from "../../Config/Config";
import SimpleCrypto from "simple-crypto-js";
import EmployeeContext from "../../context/employee/EmpContext";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


function InvoicePopup({ show, togglePopup, data }) {
    const { empState: employee } = useContext(EmployeeContext);
    const [user, setUser] = useState();
    const [curCurrency, setCurCurrency] = useState();
    const [parentCom, setParentCom] = useState();
    const [qr, setQr] = useState();

    useEffect(() => {
        getSubsDetails();
        generateQRCode();
        console.log(data)
    }, [data])

    const getSubsDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            companyId: data.companyId,
            id: data.id,
            invoiceNumber: data.invoice_number
        }
        axios.post(config.apiUrl + 'employees/getSubscriptionDetails', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, response.data);
                    setUser(response.data.empDetails);
                    const cur = response.data.currencyData && response.data.currencyData.find(c => c.currency_name === data.currency);
                    setCurCurrency(cur);
                    setParentCom(response.data.parentCompany);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    }
    const generateQRCode = () => {
        const vpa = config.VPA;
        const amount = data?.total_amount.toFixed(2);
        const upiUrl = `upi://pay?pa=${encodeURIComponent(vpa)}&am=${encodeURIComponent(amount)}&cu=INR`;
        qrcode.toDataURL(upiUrl, (err, dataUrl) => {
            if (err) throw err;
            console.log('Data URI:', dataUrl);
            setQr(dataUrl);
        });
    }
    return (
        <Modal show={show} onHide={togglePopup} centered size="lg" dialogClassName="max-h-[90vh] overflow-y-auto rounded-xl pt-20">
            <Modal.Body className="mt-40">
                <div className="flex items-center justify-center text-base leading-normal">
                    <div>
                        <div className="py-4">
                            <div className="px-0 py-6">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <img src="https://res.cloudinary.com/entropi/image/upload/v1696496685/tywq1bc0o8zublgycgyw.png"
                                            className="h-12" />
                                    </div>

                                    <div className="text-sm">
                                        <div className="flex items-center space-x-4">
                                            <div>
                                                <p className="whitespace-nowrap text-slate-400 text-right">Date</p>
                                                <p
                                                    className="whitespace-nowrap font-bold text-[#5c6ac4] text-right">{moment(data?.createdAt).format('MMMM D, YYYY')}</p>
                                            </div>

                                            <div>
                                                <p className="whitespace-nowrap text-slate-400 text-right">Invoice #</p>
                                                <p className="whitespace-nowrap font-bold text-[#5c6ac4]  text-right">{data?.invoice_number}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-slate-100 px-3 py-6 text-sm">
                                <div className="flex items-center justify-between">
                                    <div className="text-sm text-neutral-600">
                                        <p className="font-bold">Entropi Company INC</p>
                                        <p>Bengaluru, Karnataka</p>
                                        <p>India</p>
                                    </div>

                                    <div className="text-sm text-neutral-600 text-right">
                                        <p className="font-bold">{employee?.firstName + " " + employee.lastName}</p>
                                        <p>{user?.location}{user?.city ? "," : ""}{user?.city}</p>
                                        <p>{user?.country}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="px-0 py-10 text-sm text-neutral-700">
                                <table className="w-full border-collapse border-spacing-0">
                                    <thead>
                                        <tr className="border-b-2 border-[#5c6ac4]">
                                            <th className="pb-3 pl-3 font-bold text-[#5c6ac4]">#</th>
                                            <th className="pb-3 pl-2 font-bold text-[#5c6ac4]">Product details</th>
                                            <th className="pb-3 pl-2 text-right font-bold text-[#5c6ac4]">CURR</th>
                                            <th className="pb-3 pl-2 text-right font-bold text-[#5c6ac4]">Price</th>
                                            <th className="pb-3 pl-2 text-center font-bold text-[#5c6ac4]">Discount</th>
                                            <th className="pb-3 pl-2 text-right font-bold text-[#5c6ac4]">Subtotal</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="border-b">
                                            <td className="py-3 pl-3">1.</td>
                                            <td className="py-3 pl-2">{data.planName}</td>
                                            <td className="py-3 pl-2 text-right">{data?.currency}</td>
                                            <td className="py-3 pl-2 text-right">{curCurrency?.currency_symbol || '₹'} {data?.planPrice ? data.planPrice.toFixed(2) : 0.00}</td>
                                            <td className="py-3 pl-2 text-center">{data?.discount_percentage ? data.discount_percentage.toFixed(2) + "%" : "0.00%"}</td>
                                            <td className="py-3 pl-2 text-right">{curCurrency?.currency_symbol || '₹'}{data?.subtotal.toFixed(2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="w-full my-4 flex items-center justify-end">
                                    <div className="max-w-max flex flex-col items-center">
                                        <div className="w-full flex items-center justify-end">
                                            <div className="border-b p-2">
                                                <div className="whitespace-nowrap text-slate-400">Net total:</div>
                                            </div>
                                            <div className="border-b p-2 text-right min-w-[6rem]">
                                                <div className="whitespace-nowrap font-bold text-[#5c6ac4]">{curCurrency?.currency_symbol || '₹'}{data?.subtotal.toFixed(2)}</div>
                                            </div>
                                        </div>
                                        <div className="w-full flex items-center justify-end">
                                            <div className="border-b p-2">
                                                <div className="whitespace-nowrap text-slate-400">Discount:</div>
                                            </div>
                                            <div className="border-b p-2 text-right min-w-[6rem]">
                                                <div className="whitespace-nowrap font-bold text-[#5c6ac4]">{curCurrency?.currency_symbol || '₹'}{data?.discount.toFixed(2)}</div>
                                            </div>
                                        </div>
                                        <div className="w-full flex items-center justify-end">
                                            <div className="border-b p-2">
                                                <div className="whitespace-nowrap text-slate-400">TAX {data.tax_percentage ? data.tax_percentage + "%" : ""}:</div>
                                            </div>
                                            <div className="border-b  p-2 text-right min-w-[6rem]">
                                                <div className="whitespace-nowrap font-bold text-[#5c6ac4]">{curCurrency?.currency_symbol || '₹'}{data?.tax.toFixed(2)}</div>
                                            </div>
                                        </div>
                                        <div className="w-full flex items-center justify-end">
                                            <div className="bg-[#5c6ac4] px-3 py-2">
                                                <div className="whitespace-nowrap font-bold text-white">Total:</div>
                                            </div>
                                            <div className="bg-[#5c6ac4] px-3 py-2 text-right min-w-[6rem]">
                                                <div className="whitespace-nowrap font-bold text-white">{curCurrency?.currency_symbol || '₹'}{data?.total_amount.toFixed(2)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="flex justify-between items-center">
                                <div className="px-0 text-sm text-neutral-700">
                                    <p className="text-[#5c6ac4] font-bold">PAYMENT METHOD</p>
                                    <p>VISA, MasterCard, UPI, Net banking & Cheques are accepted</p>
                                </div>
                                <img src={qr} alt="" />
                            </div> */}
                            <div className="relative">
                                <div className="px-0 text-sm text-neutral-700">
                                    <p className="text-[#5c6ac4] font-bold">PAYMENT METHOD</p>
                                    <p>VISA, MasterCard, UPI, Net banking & Cheques are accepted</p>
                                </div>
                                <img src={qr} alt="" className="absolute -top-8 right-3 h-32" />
                            </div>

                            <div className="px-0 py-10 text-sm text-neutral-700 mt-20">
                                <p className="text-[#5c6ac4] font-bold">Terms & Coditions</p>
                                <p className="italic">Please complete the payment before the due date i.e. {moment(data.expiryDate).format("MMMM D,YYYY")} otherwise your services will be suspended!</p>
                                <p className="w-full mt-2.5 text-center text-lg">Thank you and Best Wishes</p>
                            </div>

                            <footer className="bottom-0 left-0 bg-slate-100 w-full text-neutral-600 text-center text-xs py-3">
                                {parentCom && parentCom.name}
                                <span className="text-slate-300 px-2">|</span>
                                {parentCom && parentCom.location}
                                <span className="text-slate-300 px-2">|</span>
                                {parentCom && parentCom.country}
                                <span className="text-slate-300 px-2">|</span>
                                {parentCom && `GSTIN: ${parentCom.registrationNo}`}
                            </footer>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    );
}
export default InvoicePopup;
import { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useNavigate } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import editicon from '../images/editicon.svg';
import EmployeeContext from "../context/employee/EmpContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment-timezone";
import ServcieOptOut from "../PopUps/ServiceOptOut";


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const ServicesByCompany = () => {
    const navigate = useNavigate();
    const { empState: employee } = useContext(EmployeeContext)
    const [services, setServices] = useState([])
    const [selectedService, setSelectedService] = useState()
    const [serviceData, setServiceData] = useState();
    const [editServicePopup, setEditServicePopup] = useState(false);
    const [servicePopupData, setServicePopupData] = useState();

    useEffect(() => {
        getServicesByCompany()
    }, []);

    useEffect(() => {
        if (selectedService && selectedService?.data?.length > 0)
            setServiceData(selectedService.data)
        else setServiceData([])
    }, [selectedService])

    const getServicesByCompany = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + `superAdmin/getServicesByCompany`, { headers: headers })
            .then(function (response) {
                console.log(response.data.message, response.data);
                let data_arr = response.data.allServices;
                data_arr.sort((a, b) => {
                    const sizeA = a.data ? a.data.length : 0;
                    const sizeB = b.data ? b.data.length : 0;
                    return sizeB - sizeA
                })
                setServices(data_arr);
                setSelectedService(data_arr[0])
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const handleServices = (val) => {
        // console.log("val", val)
        let currentService = services && services.find(service => service.id == val)
        setSelectedService(currentService)
    }
    const toggleServicePopup = (data = {}) => {
        setServicePopupData({ ...data, section: selectedService.section, service: selectedService.serviceName });
        setEditServicePopup(!editServicePopup);
    }
    return (
        <div className="ml-8 mt-20">
            {editServicePopup && <ServcieOptOut togglePopup={toggleServicePopup} data={servicePopupData} getServicesByCompany={getServicesByCompany} />}
            <select name="services" value={selectedService} className={`ml-1 text-sm flex justify-right w-1/3 px-2 py-2 border-[1px] border-black rounded mt-2`}
                onChange={(e) => handleServices(e.target.value)}>
                <option value={selectedService ? selectedService.id : ""}>{selectedService ? selectedService.serviceName : "Select Service"}</option>
                {services && services.map(item =>
                    <option key={item.id} value={item.id}>{item.serviceName}</option>
                )}
            </select>

            <div className="mt-3 tableFixHead">
                <table className="border-spacing-x-0 border-spacing-y-1" >
                    <thead>
                        <tr className="whitespace-nowrap">
                            <th scope="col" className="th-heading text-center w-5">S.No</th>
                            <th scope="col" className="th-heading text-center"></th>
                            <th scope="col" className="th-heading text-left">Company Name</th>
                            <th scope="col" className="th-heading text-center">Category</th>
                            <th scope="col" className="th-heading text-center">Address</th>
                            <th scope="col" className="th-heading text-center">Subscribed On</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {serviceData && serviceData.map((data, i) => (
                            <tr key={data.id} className="tr whitespace-nowrap text-center" >
                                <td className="td-text">{i + 1}</td>
                                <td className="td-text w-14 pl-2"><img src={data.logo} alt="" className="w-7 h-7" /></td>
                                <td className="td-text text-left">{data.name}</td>
                                <td className="td-text">{data.category}</td>
                                <td className="td-text">{data.city}{data.country ? `, ${data.country}` : ""}</td>
                                <td className="td-text">{data.updatedAt ? moment(data.updatedAt).format("DD/MM/YYYY") : ""}</td>
                                <td className="flex text-right items-end justify-end">
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip id="button-tooltip">Optout Service</Tooltip>}
                                    >
                                        <div className="px-2 icon-w text-right" onClick={() => toggleServicePopup(data)}>
                                            <div className="text-center items-end justify-center"><img src={editicon} /></div>
                                        </div>
                                    </OverlayTrigger>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div >
    )
}

export default ServicesByCompany
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { config } from '../../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import UsersAsk from "../Product Analysis/UsersAskGraph";
import UsersActivity from "../Product Analysis/UserActivityGraph";
import LeadTypesCount from "../CompanyAnalytics/LeadTypesGraph";



var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function CustomerAnalyticsGraphs({ empCount, topEmp }) {
    const [section, setSection] = useState("Customer Data")
    const [usersAsk, setUsersAsk] = useState([]);
    const [usersActivity, setUsersActivity] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        getProductsData()
    }, [])
    const getProductsData = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getProductsAnalysisByCompany', { headers: headers })
            .then(response => {
                console.log("success products data from query ", response.data);
                setUsersAsk(response.data.keyAsk);
                setUsersActivity(response.data.userActivity);
                setProducts(response.data.companyProducts);
                setProductsData(response.data.productsData);
            })
            .catch(error => {
                console.error('Error loading branches data:', error);
            })
    };

    return (
        <div className="mt-1 mb-2">
            <div className="flex gap-4 items-center text-center" style={{ marginTop: "18px" }}>
                {/* <div className="flex flex-col justify-around mt-2 rounded-xl bg-custom-bg-color hover:custom-bg-color text-custom-text-color w-24 h-24 px-1 py-2 font-semibold">
                    <p className="text-2xl">{empCount}</p>
                    <p className="text-md">Employees</p>
                </div>
                <div className="flex flex-col justify-center gap-3 mt-2 rounded-xl bg-custom-bg-color hover:custom-bg-color text-custom-text-color w-24 h-24 p-1 font-semibold">
                    <p className="text-sm">{topEmp?.name || "-"}</p>
                    <p className="whitespace-nowrap text-xs">Top Employee</p>
                </div>
                <div className="mt-2 rounded-xl bg-custom-bg-color hover:custom-bg-color text-custom-text-color w-24 h-24 px-1 py-2 font-semibold">
                    <p className="text-xl">{newCustomerCount}</p>
                    <p>New Customers</p>
                </div>
                <div className="mt-2 flex rounded-xl bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color items-center text-center justify-center w-24 h-24 font-semibold">
                    <p className="text-sm">{returnedProduct ? returnedProduct : "-"}<br />Return</p>
                </div>
                <div className="mt-2 flex rounded-xl bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color items-center text-center justify-center w-24 h-24 font-semibold">
                    <p className="text-sm">{refundedProduct ? refundedProduct : "-"}<br />Refund</p>
                </div> */}
            </div>
            <div className="flex gap-2 max-w-max">
                <UsersAsk usersAsk={usersAsk} productsData={productsData} />
                <UsersActivity productsData={productsData} usersActivity={usersActivity} />
                <LeadTypesCount />
            </div>
        </div>
    );
};

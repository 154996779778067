import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import axios from 'axios';
import { config } from '../Config/Config';
import { Bar } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const AvgDealClosureTime = () => {
    const [values, setValues] = useState();

    useEffect(() => {
        getBranchesData()
    }, []);
    const getBranchesData = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getAvgDealCompletionTimeByBranch', { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log(response.data.message, response.data);
                    setValues(response.data.activeBranches);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const monthlyData = {
        labels: values && values.map(val => val.branchName),
        datasets: [
            {
                label: "Not Successful",
                data: values && values.map(val => val.data["4"]),
                backgroundColor: [
                    'red',
                ],
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20
            },
            {
                label: "Successful",
                data: values && values.map(val => val.data["5"]),
                backgroundColor: [
                    'green',
                ],
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20
            }
        ]
    }
    return (
        <div className="flex gap-2">
            <Card className="flex flex-col justify-between relative" style={{ marginTop: "1rem", height: "250px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                <div className="font-semibold ml-12">
                    <p className="text-sm">Avg Deal Closure Time</p>
                </div>
                <div className="flex justify-center items-center gap-4 w-full">
                    <div className="flex justify-center items-center gap-1"><span className="bg-red-600 w-2 h-2"></span><span className="text-xs">Not Successfull</span></div>
                    <div className="flex justify-center items-center gap-1"><span className="bg-green-600 w-2 h-2"></span><span className="text-xs">Successfull</span></div>
                </div>
                <div style={{ height: "200px" }}>
                    <Bar data={monthlyData}
                        options={{
                            maintainAspectRatio: true,
                            responsive: true,
                            redraw: false,
                            elements: {
                                line: {
                                    tension: 1
                                }
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                },
                                y: {
                                    ticks: {
                                        precision: 0
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: false
                                },
                            }
                        }
                        } />
                </div>
            </Card>
        </div>
    );
};

export default AvgDealClosureTime
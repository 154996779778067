import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { config } from '../Config/Config';
import { enqueueSnackbar } from 'notistack';
import { Modal } from 'react-bootstrap';
import moment from 'moment-timezone';
import EmployeeContext from '../context/employee/EmpContext';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const EditTargetsTable = ({ targets, closePopup, getAllTargets }) => {
    const { empState: employee } = useContext(EmployeeContext)
    const [curTargets, setCurTargets] = useState([]);
    const [year, setYear] = useState("");
    const [parentTarget, setParentTarget] = useState([])

    useEffect(() => {
        console.log("target", targets)
        if (targets && targets.length > 0) {
            setCurTargets(JSON.parse(JSON.stringify(targets[0]?.targets)))
            setParentTarget(targets[0].parentTarget);
        }
        setYear(targets[0]?.year)
    }, [])
    const handleCompanyTargetChange = (e, t, data) => {
        let newValue = e.target.value ? e.target.value : '';
        let regex = /^\d+(\.\d{1,2})?$/;
        if (newValue && !(regex.test(newValue))) {
            return enqueueSnackbar("Please use numbers only upto 2 decimal places", {  variant: "error" })
        }
        curTargets.forEach(target => {
            if (t.id === target.id) {
                target.forecast = parseFloat(newValue);
            }
        })
        console.log("a", curTargets)
        setCurTargets([...curTargets])
    }
    const handleTCompanyTargetChange = (e, t, data) => {
        let newValue = e.target.value ? e.target.value : '';
        let regex = /^\d+(\.\d{1,2})?$/;
        if (newValue && !(regex.test(newValue))) {
            return enqueueSnackbar("Please use numbers only upto 2 decimal places", {  variant: "error" })
        }
        curTargets.forEach(target => {
            if (t.id === target.id) {
                target.target = parseFloat(newValue);
                target.forecast = parseFloat(newValue);
            }
        })
        console.log("a", curTargets)
        setCurTargets([...curTargets])
    }
    const handleTBranchTargetChange = (e, t) => {
        let newValue = e.target.value ? e.target.value : '';
        let regex = /^\d+(\.\d{1,2})?$/;
        if (newValue && !(regex.test(newValue))) {
            return enqueueSnackbar("Please use numbers only upto 2 decimal places", {  variant: "error" })
        }
        curTargets.forEach(target => {
            if (t.id === target.id) {
                target.target = parseFloat(newValue);
                target.forecast = parseFloat(newValue);
            }
        })
        setCurTargets([...curTargets])
    }
    const handleTTargetChange = (e, t, data) => {
        let newValue = e.target.value ? e.target.value : '';
        let regex = /^\d+(\.\d{1,2})?$/;
        if (newValue && !(regex.test(newValue))) {
            return enqueueSnackbar("Please use numbers only upto 2 decimal places", { variant: "error" })
        }
        curTargets.forEach(target => {
            if (t.id === target.id) {
                target.target = parseFloat(newValue);
                target.forecast = parseFloat(newValue);
            }
        })
        console.log("a", curTargets)
        setCurTargets([...curTargets])
    }
    const handleTargetChange = (e, t, data) => {
        let newValue = e.target.value ? e.target.value : '';
        let regex = /^\d+(\.\d{1,2})?$/;
        if (newValue && !(regex.test(newValue))) {
            return enqueueSnackbar("Please use numbers only upto 2 decimal places", {  variant: "error" })
        }
        curTargets.forEach(target => {
            if (t.id === target.id) {
                target.forecast = parseFloat(newValue);
            }
        })
        console.log("a", curTargets)
        setCurTargets([...curTargets])
    }
    const handleBranchTargetChange = (e, t) => {
        let newValue = e.target.value ? e.target.value : '';
        let regex = /^\d+(\.\d{1,2})?$/;
        if (newValue && !(regex.test(newValue))) {
            return enqueueSnackbar("Please use numbers only upto 2 decimal places", { variant: "error"})
        }
        curTargets.forEach(target => {
            if (t.id === target.id) {
                target.forecast = parseFloat(newValue);
            }
        })
        setCurTargets([...curTargets])
    }


    const handleCancel = () => {
        getAllTargets();
        closePopup()
    }
    const updateEmployeesTarget = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let y = new Date().getFullYear() + "-" + parseInt(new Date().getFullYear() + 1)
        let payload = {
            "id": targets[0].id,
            "branchId": targets[0].branchId,
            "employeeId": targets[0].employeeId,
            "year": targets[0].year,
            "status": 1,
            "state": 2,
            "targets": JSON.stringify(curTargets)
        }
        // let apiEndPoint = '';
        // if (targets.id) apiEndPoint = 'manager/updateTargetEmployee';
        // else apiEndPoint = 'manager/addTargetByEmployee'
        axios.post(config.apiUrl + 'employees/updateEmployeeTargets', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                getAllTargets();
                closePopup();
            })
            .catch(function (error) {
                console.log("error", error)
            })
    };
    const updateBranchTarget = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let y = new Date().getFullYear() + "-" + parseInt(new Date().getFullYear() + 1)
        let payload = {
            "id": targets[0].id,
            "branchId": targets[0].branchId,
            "year": targets[0].year,
            "status": 1,
            "state": 2,
            "targets": JSON.stringify(curTargets)
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'manager/updateBranchTarget', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                closePopup();
                getAllTargets();
            })
            .catch(function (error) {
                console.log("error", error)
            })
    };
    const updateCompanyTarget = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let y = new Date().getFullYear() + "-" + parseInt(new Date().getFullYear() + 1)
        let payload = {
            "id": targets[0].id,
            "companyId": targets[0].companyId,
            "year": targets[0].year,
            "status": 1,
            "state": 2,
            "targets": JSON.stringify(curTargets)
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/createCompanyTargets', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                closePopup();
                getAllTargets();
            })
            .catch(function (error) {
                console.log("error", error)
            })
    };
    const updateData = () => {
        if (employee.role === 1) {
            updateCompanyTarget()
        } else if (employee.role === 2) {
            updateBranchTarget()
        } else {
            updateEmployeesTarget()
        }
    }
    return (

        <Modal show={true} size="md" centered >
            <Modal.Header closeButton style={{ zIndex: "100" }}>
            </Modal.Header>
            <Modal.Body>

                {targets.length === 0 ? <div><h4 className='text-center font-semibold -mt-8'>Targets not set. Please set targets !</h4>
                    <center><button className="max-w-max text-center items-center justify-center bg-orange-600 hover:bg-orange-700 mt-3 p-2 px-3 font-semibold text-white uppercase rounded text-xs whitespace-nowrap" onClick={handleCancel} >Cancel</button></center>
                </div> :
                    <div>
                        <div className="-mt-12 text-center font-semibold text-black text-lg"><h5>Edit Target</h5></div>
                        <div className="mt-2 text-center font-semibold text-black text-xs">{employee.role === 1 ? "" : employee.role === 2 ?
                            <h6>{employee?.firstName}&nbsp;{employee?.lastName} &nbsp;({employee?.employeeId})</h6>
                            : <h6>{employee?.firstName}&nbsp;{employee?.lastName} &nbsp;({employee?.employeeId})</h6>}
                        </div>
                        <div className="mt-2 text-left px-1 font-semibold text-black text-base">{year}</div>
                        {employee.role === 1 ?
                            <div className={"mt-2 grid grid-cols-3 items-center w-full border-2 bg-gray-200 border-gray-800"}>
                                <div className='py-1 px-1'>Month</div>
                                <div className='py-1 px-1 border-2 text-center border-gray-800 border-top-0 border-bottom-0'>Target</div>
                                <div className='py-1 px-1 text-center'>Forecast</div>
                            </div> :
                            <div className={"mt-2 grid grid-cols-4 items-center w-full h-16 border-2 bg-gray-200 border-gray-800"}>
                                <div className='py-1 px-1 h-full'>Month</div>
                                <div className='py-1 px-1 h-full border-2 text-center border-gray-800 border-top-0 border-bottom-0'>{employee.role === 2 ? "Company" : "Branch"} Forecast</div>
                                <div className='py-1 px-1 h-full border-2 text-center border-gray-800 border-top-0 border-left-0 border-bottom-0'>Target</div>
                                <div className='py-1 px-1 h-full text-center border-gray-800 border-top-0 border-right-0 border-bottom-0'>Forecast</div>
                            </div>
                        }
                        {employee.role === 1 ?
                            <>
                                {curTargets.map((t, i) => (
                                    <div key={t.id} className={"grid grid-cols-3 items-center w-full h-10 border-2 border-gray-800"}>
                                        <div className={"flex px-1 py-1 text-center border-2 bg-gray-200 "}>{t.id}</div>
                                        <div className='border-2 h-full border-top-0 border-bottom-0 border-gray-800'>
                                            {/* "flex py-1 w-full text-center border-2 bg-gray-200 " */}
                                            {console.log("st", targets[0]?.state)}
                                            <input
                                                className={new Date().getMonth() + 1 >= moment().month(t.id).format("M") || targets[0]?.state != 1 ? "flex py-1 w-full text-center border-2 bg-gray-200 " : "flex py-1 w-full text-center border-2"}
                                                type="number"
                                                disabled={targets[0]?.state === 1 ? false : true}
                                                value={t.target}
                                                onChange={(e) => { employee.role === 1 ? handleTCompanyTargetChange(e, t) : employee.role === 2 ? handleBranchTargetChange(e, t) : handleTargetChange(e, t) }}
                                            />
                                        </div>
                                        <div className='h-full bg-gray-300 '>
                                            <input
                                                className={new Date().getMonth() + 1 >= moment().month(t.id).format("M") ? "flex py-1 w-full text-center border-2 bg-gray-200 " : "flex py-1 w-full text-center border-2"}
                                                type="number"
                                                disabled={new Date().getMonth() + 1 >= moment().month(t.id).format("M") || targets[0]?.state === 1 ? true : false}
                                                value={t.forecast}
                                                onChange={(e) => { employee.role === 1 ? handleCompanyTargetChange(e, t) : employee.role === 2 ? handleBranchTargetChange(e, t) : handleTargetChange(e, t) }} />
                                        </div>
                                    </div>
                                ))
                                }
                            </> :
                            <>
                                {curTargets.map((t, i) => (
                                    <div key={t.id} className={"grid grid-cols-4 items-center w-full h-10 border-2 border-gray-800"}>
                                        <div className={"flex px-1 py-1 text-center border-2 bg-gray-200 "}>{t.id}</div>
                                        <div className='border-2 h-full bg-gray-300 border-top-0 border-bottom-0 border-gray-800'>
                                            <input
                                                className='py-1 w-full text-center'
                                                type="text"
                                                disabled={true}
                                                value={parentTarget && parentTarget[i] ? parentTarget[i].forecast : 0} />
                                        </div>
                                        <div className='border-2 h-full border-top-0 border-left-0 border-bottom-0 border-gray-800'>
                                            <input
                                                className={new Date().getMonth() + 1 >= moment().month(t.id).format("M") || targets[0]?.state != 1 ? "flex py-1 w-full text-center border-2 bg-gray-200 " : "flex py-1 w-full text-center border-2"}
                                                type="number"
                                                disabled={targets[0]?.state === 1 ? false : true}
                                                value={t.target}
                                                onChange={(e) => { employee.role === 2 ? handleTBranchTargetChange(e, t) : handleTTargetChange(e, t) }} />
                                        </div>
                                        <div className='border-2 h-full bg-gray-300 border-left-0 border-right-0 border-top-0 border-bottom-0 border-gray-800'>
                                            <input
                                                className={new Date().getMonth() + 1 >= moment().month(t.id).format("M") ? "flex py-1 w-full text-center border-2 bg-gray-200 " : "flex py-1 w-full text-center border-2"}
                                                type="number"
                                                disabled={new Date().getMonth() + 1 >= moment().month(t.id).format("M") || targets[0]?.state === 1 ? true : false}
                                                value={t.forecast}
                                                onChange={(e) => { employee.role === 1 ? handleCompanyTargetChange(e, t) : employee.role === 2 ? handleBranchTargetChange(e, t) : handleTargetChange(e, t) }} />
                                        </div>
                                    </div>
                                ))
                                }
                            </>}
                        <br />
                        <div className="pr-8 flex justify-between items-center gap-3">
                            <button className="max-w-max bg-orange-600 hover:bg-orange-700 p-2 px-3 font-semibold text-white uppercase rounded text-xs whitespace-nowrap" onClick={handleCancel} >Cancel</button>
                            <button className="bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color p-2 px-4 font-semibold uppercase rounded text-xs whitespace-nowrap -mr-7" onClick={updateData}>Submit</button>

                        </div>

                    </div>}
            </Modal.Body >
        </Modal >
    )
}

export default EditTargetsTable
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { config } from '../../Config/Config';
import SimpleCrypto from 'simple-crypto-js'
import { useNavigate, useParams } from 'react-router-dom';
import LeftMenu1 from '../../LeftMenu/LeftMenu1';
import Navbar1 from '../../Navbar/Navbar1';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)
const ConfigureForm = () => {
    const navigate = useNavigate();
    const { uniquecode: code } = useParams()
    const [questions, setQuestions] = useState([{ "ques": "" }])
    const [originalQues, setOriginalQues] = useState([{ "ques": "" }])

    useEffect(() => {
        getSurveyQuestions()
    }, [])
    const onQuestionChange = (e, i) => {
        let currQuestions = [...questions]
        currQuestions[i]["ques"] = e.target.value
        setQuestions(currQuestions)
    }
    const addQuestion = () => {
        if (questions[questions.length - 1]["ques"] === "") return
        setQuestions([...questions, { "ques": "" }])
    }
    const removeQuestion = (i) => {
        if (i === 0 && questions.length === 1) {
            setQuestions([{ "ques": "" }])
            return
        }
        let currQuestions = questions.filter((q, index) => index !== i)
        setQuestions(currQuestions)
    }
    const configureSurveyForm = async () => {
        let headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "code": code,
            "form": JSON.stringify(questions)
        }
        axios.post(config.apiUrl + "companyAdmin/configuresurveyform", payload, { headers: headers })
            .then((response) => {
                if (response.data.status === true) {
                    console.log("Configuresurveyform Added successfully", response.data.form)
                    setQuestions([{ "ques": "" }])
                    enqueueSnackbar("Questions Added Successfully!", { variant: "success" })
                    navigate(-1)
                } else {
                    console.log("Error")
                }
            }).catch(error => {
                console.error(error)
            })
    }
    const cancel = () => {
        setQuestions([...originalQues])
    }
    const getSurveyQuestions = async () => {
        let headers = {
            "Content-Type": "application/json",
            "applicationType": "web",
            "Accept": "application/json"
        }
        var payload = {
            "code": code
        }
        axios.post(config.apiUrl + "companyAdmin/getsurveyform", payload, { headers: headers })
            .then((response) => {
                if (response.data.status === true) {
                    console.log("Questions Retrieved Successfully", response.data.form)
                    if (response.data.form.surveyForm.length !== 0) {
                        setQuestions(response.data.form.surveyForm);
                        setOriginalQues(response.data.form.surveyForm);
                    }
                } else {
                    console.log("Error")
                }
            }).catch(error => {
                console.error(error)
            })
    }
    return (
        <div>
            <Navbar1 />
            <LeftMenu1 section={"Design Survey"} />
            <div className='flex justify-center items-center ml-[15vw] w-[80vw] mt-20 relative'>
                <div className='absolute left-0 top-0'>
                    <i onClick={() => navigate(-1)}
                        className="fa fa-long-arrow-left fa-2x text-orange-600 cursor-pointer  ml-1"
                        aria-hidden="true"></i>
                </div>
                <div className='flex flex-col justify-center items-center'>
                    <p className='my-3 text-black font-semibold text-xl text-center'>Design Customer Survey Form</p>
                    <div className='mt-3 w-[70vw] min-h-[70vh]'>
                        {questions?.map((ques, i) => (
                            <div className='mb-8 flex gap-2' key={i}>
                                <label className="my-3 font-semibold">Q&nbsp;{1 + i}/5</label>
                                <textarea
                                    rows={2}
                                    style={{ overflowY: "none", resize: "none" }}
                                    value={ques.ques}
                                    className="text-sm w-full px-2 py-2 border border-solid border-gray-300 rounded"
                                    type="text"
                                    placeholder="Enter Your Question"
                                    onChange={(e) => onQuestionChange(e, i)}
                                />
                                <div className={`my-3 mx-auto w-6 h-6 rounded-full border-black border-[1px] text-center cursor-pointer ${i === -1 ? "invisible" : ""}`} >
                                    <div onClick={() => removeQuestion(i)}>-</div>
                                </div>
                            </div>

                        ))}
                        <div className={`my-2 mx-auto w-6 h-6 rounded-full border-black border-[1px] text-center cursor-pointer ${questions.length === 5 ? "hidden" : ""}`} >
                            <div onClick={addQuestion}>+</div>
                        </div>
                    </div>

                    <div className="flex justify-between text-center md:text-left w-[30vw]">
                        <button className="my-3 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white font-semibold uppercase rounded text-xs" onClick={cancel}>CANCEL</button>
                        <button className="my-3 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs" onClick={configureSurveyForm} type="submit">SUBMIT</button>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default ConfigureForm;
import React, { useContext, useEffect, useState } from 'react'
import EmployeeContext from '../../context/employee/EmpContext';
import { useNavigate } from 'react-router-dom';
import Navbar1 from '../../Navbar/Navbar1';
import LeftMenu1 from '../../LeftMenu/LeftMenu1';
import { config } from '../../Config/Config';
import axios from 'axios';
import SimpleCrypto from 'simple-crypto-js';
import moment from 'moment-timezone';
import CertificatePopup from './CertificatePopup';


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const Leaderboard = () => {
    const navigate = useNavigate();
    const { empState: employee } = useContext(EmployeeContext);
    const [rewardsData, setRewardsData] = useState([]);
    const [showCertificate, setShowCertificate] = useState(false);
    const [popupData, setPopupData] = useState(null);

    const getEmployeeRole = () => {
        if (employee.role === 1) return "companyadmin"
        else if (employee.role === 2) return "branchadmin"
        else return "salesperson"
    }
    useEffect(() => {
        getRewardsData();
    }, [])
    const getRewardsData = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }

        let payload = {}
        if (employee.role == 1) payload["companyId"] = employee.companyId;
        else if (employee.role == 2) payload["branchId"] = employee.branchId;
        else if (employee.role == 3) { payload["branchId"] = employee.branchId; payload["employeeId"] = employee.employeeId };
        console.log("payload", payload);
        axios.post(config.apiUrl + 'employees/getRewardsDataByEmployee', payload, { headers: headers })
            .then((response) => {
                console.log(response.data.message, response.data);
                setRewardsData(response.data.rewardsData);
            }).catch(error => {
                console.log("error", error.response.data.message);
            })
    }
    const toggleCertificatePopup = (data = {}) => {
        if (employee.role !== 3) return;
        setPopupData(data);
        setShowCertificate(!showCertificate)
    }
    return (
        <div className="h-screen flex flex-col">
            {showCertificate && <CertificatePopup data={popupData} togglePopup={toggleCertificatePopup} />}
            <Navbar1 />
            <LeftMenu1 section="Rewards Leaderboard" />
            <div className='max-w-min absolute sm:left-36 md:left-44 lg:left-48 xl:left-60 top-16'>
                <i onClick={() => navigate(`/${employee.companyName}/${getEmployeeRole()}/Rewards`)}
                    className="fa fa-long-arrow-left fa-2x text-orange-600 cursor-pointer"
                    aria-hidden="true">
                </i>
            </div>
            <div className="mt-24 sm:ml-36 md:ml-44 lg:ml-48 xl:ml-64 mr-20">
                <div className="flex justify-between">
                    <div className="text-left font-semibold text-black"><h5>Leaderboard</h5></div>
                </div>
                <div className="mt-2 tableFixHead bg-custom-text-color">
                    <table className="border-spacing-x-0 border-spacing-y-2 border-separate" >
                        <thead>
                            <tr className="whitespace-nowrap">
                                <th scope="col" className="th-heading" >S.No</th>
                                <th scope="col" className="th-heading" >Branch</th>
                                <th scope="col" className="th-heading" >Employee</th>
                                <th scope="col" className="th-heading" >Month & Year</th>
                                <th scope="col" className="th-heading" >Reward</th>
                                <th scope="col" className="th-heading" >Date</th>
                            </tr>
                        </thead>
                        <tbody className="">
                            {rewardsData && rewardsData.map((data, i) => (

                                <tr key={i} className="tr whitespace-nowrap" onClick={() => toggleCertificatePopup(data)}>
                                    <td className="td-text pl-4 ">{i + 1}</td>
                                    <td className="td-text">{data.branchName}</td>
                                    <td className="td-text">{data.employeeName}</td>
                                    <td className="td-text">{data.monthAndYear}</td>
                                    <td className="td-text">{data.reward}</td>
                                    <td className="td-text">{moment(data.createdAt).format("DD/MM/YYYY")}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}

export default Leaderboard
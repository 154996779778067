import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import logo from "../images/lms logo.svg";
import nebutech from '../images/t-logo.svg';
import SimpleCrypto from "simple-crypto-js";
import EmployeeContext from "../context/employee/EmpContext";
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const LeftMenu = ({ section, setSection, mainSection, setMainSection }) => {
    const navigate = useNavigate();
    const { empState: employee } = useContext(EmployeeContext)
    const [availableFields, setAvailableFields] = useState([]);
    useEffect(() => {
        if (employee.role !== 4)
            getAvailableMenuFields();
    }, [])
    const getAvailableMenuFields = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'employees/getAvailableMenuFieldsByCompanyId', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success available menu fields", response.data);
                    setAvailableFields(response.data.availableFields);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const isAvailable = (field) => {
        let value = false;
        availableFields.map(item => {
            for (let key in item) {
                if (key === field)
                    value = item[key];
            }
        })
        return value;
    }
    return (
        <div className='w-[14vw] min-h-screen fixed left-0' style={{ color: "#EFF2F5", background: "#142431" }}>
            <div className='text-xl font-bold text-white justify-center items-center text-center'>
                <img src={logo} alt="" className="w-12 h-12 my-1 ml-3" />
                <p className=" border-t-2 border-white "></p>
            </div>
            {employee && employee.role === 1 ?
                <div className='sm:mt-0 lg:mt-3 sm:text-xs lg:text-sm text-xs flex flex-col gap-4'>
                    <div className={`${mainSection === "Company" ? "bg-gray-500" : "bg-inherit"} text-lg px-2 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => { setMainSection("Company"); setSection("Branch") }}>Company</div>
                    {mainSection === "Company" &&
                        <>
                            <div className={`${section === "Branch" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Branch")}>Branch</div>
                            <div className={`${section === "Product" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Product")}>Products</div>
                            <div className={`${section === "Product Branches" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Product Branches")}>Product Branches</div>
                            <div className={`${section === "Employee" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Employee")}>Employee</div>
                            <div className={`${section === "Customer" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Customer")}>Customer</div>
                            {isAvailable("Events") && <div className={`${section === "Events" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => { setSection("Events"); navigate('/companyevents', { state: { employee } }) }}>Events</div>}
                        </>}
                    <div className={`${mainSection === "Analytics" ? "bg-gray-500" : "bg-inherit"} text-lg px-2 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => { setMainSection("Analytics"); setSection("CustomerAnalytics") }}>Analytics</div>
                    {mainSection === "Analytics" &&
                        <>
                            <div className={`${section === "CustomerAnalytics" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("CustomerAnalytics")}>Company Analytics</div>
                            <div className={`${section === "CustomerData" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("CustomerData")}>Customer Analytics</div>
                            <div className={`${section === "BranchData" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("BranchData")}>Branch Analytics</div>
                            <div className={`${section === "Employee Analytics" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Employee Analytics")}>Employee Analytics</div>
                            <div className={`${section === "ProductAnalytics" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("ProductAnalytics")}>Product Analytics</div>
                        </>}
                    {isAvailable("Targets") && <div className={`${mainSection === "Targets" ? "bg-gray-500" : "bg-inherit"} text-lg px-2 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => { setMainSection("Targets"); setSection("Targets") }}>Targets</div>}
                    {mainSection === "Targets" &&
                        <>
                            {isAvailable("Targets") && <div className={`${section === "Targets" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Targets")}>Forecasts</div>}
                            {isAvailable("SetCompanyTargets") && <div className={`${section === "SetCompanyTargets" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("SetCompanyTargets")}>Set Targets</div>}
                            {isAvailable("ApproveTargets") && <div className={`${section === "ApproveTargets" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("ApproveTargets")}>Approve Targets</div>}
                            {isAvailable("Branch Analytics") && <div className={`${section === "Branch Analytics" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Branch Analytics")}>Branch Finances</div>}
                        </>}
                    <div className={`${mainSection === "Admin" ? "bg-gray-500" : "bg-inherit"} text-lg px-2 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => { setMainSection("Admin"); setSection("AuditTrail") }}>Admin</div>
                    {mainSection === "Admin" &&
                        <>
                            <div className={`${section === "AuditTrail" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("AuditTrail")}>Audit Trail</div>
                            {isAvailable("Rewards") && <div className={`${section === "Rewards" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Rewards")}>Rewards Config</div>}
                            <div className={`${section === "Survey Form" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Survey Form")}>Configure Survey Form</div>
                            <div className={`${section === "Invoice" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Invoice")}>Invoice</div>
                            <div className={`${section === "TC" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("TC")}>T&C</div>
                        </>}
                    <div className={`${mainSection === "MarketPlace" ? "bg-gray-500" : "bg-inherit"} text-lg px-2 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => { setMainSection("MarketPlace"); setSection("MarketPlace") }}>Marketplace</div>
                    {mainSection === "MarketPlace" &&
                        <>
                            {/* <div className={`${section === "Directory" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Directory")}>Register for Directory Services</div>
                            <div className={`${section === "ViewLeads" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("ViewLeads")}>View Leads from Directory Service</div>
                            <div className={`${section === "Scheduler" ? "bg-gray-500" : "bg-inherit"} px-6 py-1 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Scheduler")}>Register for Meetings Scheduler</div> */}
                        </>}
                    <div className={`${section === "Cases" ? "bg-gray-500" : "bg-inherit"} text-lg px-2 py-1 hover:bg-gray-500 text-white cursor-pointer absolute bottom-12 w-full`} onClick={() => setSection("Cases")}>Report Case</div>
                </div>
                : (employee && employee.role === 2) ?
                    <div className='sm:mt-0 lg:mt-3 lg:text-base sm:text-xs md:text-sm flex flex-col gap-2'>
                        <div className={`${section === "Customers" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Customers")}>Customers</div>
                        <div className={`${section === "Deals" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Deals")}>Deals</div>
                        <div className={`${section === "Survey" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Survey")}>Customer Survey</div>
                        <div className={`${section === "ToDoList" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("ToDoList")}>To Do List</div>
                        <div className={`${section === "Meetings" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Meetings")}>Meetings</div>
                        <div className={`${section === "Employees" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Employees")}>Employees</div>
                        {isAvailable("Rewards") && <div className={`${section === "Rewards" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Rewards")}>Rewards</div>}
                        <div className={`${section === "Branch Dashboard" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Branch Dashboard")}>Dashboard</div>
                        <div className={`${section === "Product Employees" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Product Employees")}>Product Employees</div>
                        {isAvailable("Events") && <div className={`${section === "Events" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => { setSection("Events"); navigate('/companyevents', { state: { employee } }) }}>Events</div>}
                        {isAvailable("Targets") && <div className={`${section === "Targets" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Targets")}>Forecasts</div>}
                        {isAvailable("SetBranchTargets") && <div className={`${section === "SetBranchTargets" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("SetBranchTargets")}>Set Targets</div>}
                        {isAvailable("ApproveTargets") && <div className={`${section === "ApproveTargets" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("ApproveTargets")}>Approve Targets</div>}
                    </div>
                    : (employee && employee.role === 4) ?
                        <div className='mt-3 sm:text-sm md:text-base text-xs flex flex-col gap-4'>
                            <div className={`${section === "Company" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Company")}>Company</div>
                            <div className={`${section === "CompanyEmployees" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("CompanyEmployees")}>Admins</div>
                            <div className={`${section === "Customizations" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Customizations")}>Field Config</div>
                            <div className={`${section === "MenuConfig" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("MenuConfig")}>Menu Config</div>
                            <div className={`${section === "MarketPlaceConfig" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("MarketPlaceConfig")}>Marketplace Config</div>
                            <div className={`${section === "ServicesByCompany" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("ServicesByCompany")}>Services</div>
                            <div className={`${section === "Dashboard" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Dashboard")}>Dashboard</div>
                            <div className={`${section === "PricingConfig" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("PricingConfig")}>Pricing</div>
                            <div className={`${section === "Invoice" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Invoice")}>Invoice</div>
                            <div className={`${section === "Cases" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Cases")}>Cases</div>
                          
                        </div>
                        : <div className='mt-3 sm:text-sm md:text-base text-xs flex flex-col gap-3'>
                            <div className={`${section === "Customers" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Customers")}>Customers</div>
                            <div className={`${section === "Deals" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Deals")}>Deals</div>
                            <div className={`${section === "Survey" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Survey")}>Customer Survey</div>
                            <div className={`${section === "Dashboard" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Dashboard")}>To Do List</div>
                            <div className={`${section === "Meetings" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Meetings")}>Meetings</div>
                            {isAvailable("Rewards") && <div className={`${section === "Rewards" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("Rewards")}>Rewards</div>}
                            <div className={`${section === "CustomersDashboard" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("CustomersDashboard")}>Dashboard</div>
                            {isAvailable("Events") && <div className={`${section === "Events" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => { setSection("Events"); navigate('/companyevents', { state: { employee } }) }}>Events</div>}
                            {isAvailable("SetEmployeeTargets") && <div className={`${section === "SetEmployeeTargets" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("SetEmployeeTargets")}>Set Targets</div>}
                            {isAvailable("EmployeeTargets") && <div className={`${section === "EmployeeTargets" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("EmployeeTargets")}>Forecasts</div>}
                            {isAvailable("SetActuals") && <div className={`${section === "SetActuals" ? "bg-gray-500" : "bg-inherit"} px-6 py-2 hover:bg-gray-500 text-white cursor-pointer`} onClick={() => setSection("SetActuals")}>Set Actuals</div>}
                        </div>
            }
            <div className='position-absolute bottom-0 left-0 bg-white w-full'><img src={nebutech} className='ml-2 mt-1 mb-2 w-32 h-8' />
            </div>
        </div >
    )
}

export default LeftMenu
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from "./Routes";
import EmpStateContext from './context/employee/EmpState';
import { QueryClientProvider, QueryClient } from 'react-query';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <EmpStateContext>
        <Router>
          <Routes />
        </Router>
      </EmpStateContext>
    </QueryClientProvider>
  );
}

export default App;

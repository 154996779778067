import { Route, Routes } from 'react-router-dom';
import React from 'react';
import Login from './Login/Login.js';
import ResetPassword from './Login/ResetPassword.js';
import SignUp from './SignUp/SignUp.js';
import CreateBranch from './Branch/CreateBranch.js';
import CreateEmployee from './Employee/CreateEmployee.js';
import Home from './Home/Home.js';
import CompanyAdmin from './CompanyAdmin/CompanyAdmin.js';
import BranchAdmin from './BranchAdmin/BranchAdmin.js';
import SalesPerson from './SalesPerson/SalesPerson.js';
import AddCustomer from './BranchAdmin/AddCustomer.js';
import CustomerActivity from './SalesPerson/CustomerActivity.js';
import CreateProduct from './CompanyAdmin/CreateProduct.js';
import AssignSalesman from './BranchAdmin/AssignSalesman.js';
import EditBranch from './CompanyAdmin/EditBranch.js';
import EditProduct from './CompanyAdmin/EditProduct.js';
import EditEmployee from './CompanyAdmin/EditEmployee.js';
import ReachOut from './SalesPerson/ReachOut.js';
import UploadCsv from './CompanyAdmin/UploadCsv.js';
import SuperAdmin from './SuperAdmin/SuperAdmin.js';
import CreateCompany from './SuperAdmin/CreateCompany.js';
import EditCompany from './SuperAdmin/EditCompany.js';
import AddAdmin from './SuperAdmin/AddAdmin.js';
import Events from './Events/Events.js';
import EditEmpDetails from './SuperAdmin/EditEmpDetails.js';
import MonthlyView from './MonthlyView/MonthlyView.js';
import RaiseCase from './Cases/RaiseCase.js';
import RespondCase from './Cases/RespondCase.js';
import MultiStepForm from './components/ActivityForm/MultiStepForm.js';
import Terms from './SignUp/Terms.js';
import UserDetails from './components/ActivityForm/UserDetails.js';
import CustomerEvaluation from './components/ActivityForm/CustomerEvaluation.js';
import DealDetails from './components/Deal/DealDetails.js';
import PageNotFound from './components/invalidRoute/PageNotFound.js';
import Leaderboard from './components/rewards/Leaderboard.js';
import Calendar from './components/Calendar/Calendar.js';
import SurveyForm from './components/SurveyForms/SurveyForm.js';
import ConfigureForm from './components/SurveyForms/ConfigureForm.js';
import Unregister from './components/Directory/Unregister.js';
import AddService from './SuperAdmin/AddService.js';
import EditService from './SuperAdmin/EditService.js';
import AddPlan from './SuperAdmin/AddPlan.js';
import EditPlan from './SuperAdmin/EditPlan.js';
import GenerateInvoice from './SuperAdmin/GenerateInvoice.js';
import CommonTermsPage from './components/Directory/CommonTermsPage.js';
import VerifyOtp from './SignUp/VerifyOtp.js';

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/:companyname/:id/login" element={<Login />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/:companyname/:id/terms" element={<Terms />} />
            <Route path="/:companyname/:id/signup" element={<SignUp />} />
            <Route path="/:companyname/:id/verifyotp" element={<VerifyOtp />} />
            <Route path="/:companyname/createbranch" element={<CreateBranch />} />
            <Route path="/:companyname/bulkupload" element={<UploadCsv />} />
            <Route path="/:companyname/editbranch" element={<EditBranch />} />
            <Route path="/:companyname/createproduct" element={<CreateProduct />} />
            <Route path="/:companyname/editproduct" element={<EditProduct />} />
            <Route path="/:companyname/createemployee" element={<CreateEmployee />} />
            <Route path="/:companyname/editemployee" element={<EditEmployee />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/:companyname/:id/resetpassword" element={<ResetPassword />} />
            <Route path="/home" element={<Home />} />
            <Route path="/:companyname/companyadmin/:part" element={<CompanyAdmin />} />
            <Route path="/:companyname/branchadmin/:part" element={<BranchAdmin />} />
            <Route path="/:companyname/salesperson/:part" element={<SalesPerson />} />
            <Route path="/:companyname/addcustomer" element={<AddCustomer />} />
            <Route path="/:companyname/assignsalesman" element={<AssignSalesman />} />
            <Route path="/:companyname/addcustomeractivity" element={<MultiStepForm />} />
            <Route path="/:companyname/reachout" element={<ReachOut />} />
            <Route path="/upload" element={<UploadCsv />} />
            <Route path="/superadmin/:part" element={<SuperAdmin />} />
            <Route path="/createcompany" element={<CreateCompany />} />
            <Route path="/addadmin" element={<AddAdmin />} />
            <Route path="/editcompany" element={<EditCompany />} />
            <Route path="/companyevents" element={<Events />} />
            <Route path="/editdetails" element={<EditEmpDetails />} />
            <Route path="/addservice" element={<AddService />} />
            <Route path="/editservice" element={<EditService />} />
            <Route path="/addplan" element={<AddPlan />} />
            <Route path="/editplan" element={<EditPlan />} />
            <Route path="/branchadmin/activity/:name" element={<MonthlyView />} />
            <Route path="/:companyname/cases" element={<RaiseCase />} />
            <Route path="/:companyname/addcase" element={<RaiseCase />} />
            <Route path="/:companyname/viewcase" element={<RespondCase />} />
            <Route path="/respondcase" element={<RespondCase />} />
            <Route path="/viewcase" element={<RespondCase />} />
            <Route path="/:companyname/customerDetails" element={<UserDetails />} />
            <Route path="/:companyname/customerEvaluation" element={<MultiStepForm />} />
            <Route path="/:companyname/dealdetails" element={<DealDetails />} />
            <Route path="/:companyname/rewards/leaderboard" element={<Leaderboard />} />
            <Route path="/:companyname/meetings" element={<Calendar />} />
            <Route path="/newmeeting" element={<Calendar />} />
            <Route path="/:companyname/:uniquecode/surveyform" element={<SurveyForm />} />
            <Route path="/:companyname/:uniquecode/configureform" element={<ConfigureForm />} />
            <Route path="/:companyname/:service/register" element={<CommonTermsPage />} />
            <Route path="/:companyname/:service/unregister" element={<Unregister />} />
            <Route path="/superadmin/:companyname/generateinvoice" element={<GenerateInvoice />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}

export default AppRoutes;
import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import SimpleCrypto from "simple-crypto-js";
import { useQuery } from 'react-query';
import { enqueueSnackbar } from 'notistack'
import EmployeeContext from "../context/employee/EmpContext";
import RespondCase from "./RespondCase";
import viewicon from '../images/viewicon.svg';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const Cases = () => {
    const navigate = useNavigate();
    const locations = useLocation();
    const { empState: employee } = useContext(EmployeeContext)
    const [cases, setCases] = useState([]);
    const [section, setSection] = useState('Cases');
    const [severityData, setSeverityData] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const [caseTypeData, setCaseTypeData] = useState([]);
    const [showView, setShowView] = useState(false);
    const [viewData, setViewData] = useState();


    useEffect(() => {
        console.log("useEffect called");
        getAllCasesReferenceTablesData();
        getCompanyCases();
    }, [])
    const handleNavigateCases = () => {
        navigate(`/${employee.companyName}/addcase`, { state: { employee, severityData: severityData, statusData: statusData, caseTypeData: caseTypeData } })
    }
    const handleView = (data) => {
        // navigate(`/${employee.companyName}/viewcase`, { state: data })
        setShowView(!showView)
        setViewData(data)

    }
    const togglePopup = () => {
        setShowView(!showView)
    }
    const getAllCasesReferenceTablesData = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getAllCasesReferenceTablesData', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, response.data);
                    setSeverityData(response.data.severity);
                    setStatusData(response.data.caseStatus);
                    setCaseTypeData(response.data.caseType);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const getCompanyCases = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        return axios.get(config.apiUrl + 'companyAdmin/getCompanyCases', { headers: headers })
    };
    const { data: caseDetails } = useQuery("cases", getCompanyCases, {
        enabled: section === 'Cases',
        onSuccess: (response) => {
            if (response.data.status == true) {
                console.log("success customers fetched from query", response.data.message, response.data);
                setCases(response.data.cases);
            } else {
                console.log("Error");
            }
        },
        onError: (error) => {
            console.error('Error loading products data:', error);
        },
    });

    return (
        <div className="ml-8 mt-20">
            {showView && <RespondCase data={viewData} closePopup={togglePopup} />}
            {/* <div className="flex flex-col justify-between">
                <div className="text-left font-semibold text-black"><h5>Cases</h5></div>
                <div className="-mt-5 px-8 text-right">
                    <div onClick={handleNavigateCases}
                        className="bg-custom-bg-color hover:bg-custom-bg-color cursor-pointer text-custom-text-color px-4 py-2 font-semibold  uppercase rounded text-xs -mr-7"
                    >
                        Register Case
                    </div>
                </div>
            </div> */}
            <div className="flex justify-between">
                <div className="text-left font-semibold text-black"><h5>Cases</h5></div>
                <div className="flex flex-row">
                    <div className="">
                        <button onClick={handleNavigateCases} className="bg-custom-bg-color hover:bg-custom-bg-color px-4 py-2 font-semibold text-custom-text-color uppercase rounded text-xs -mr-1"> Register Case</button>
                    </div>
                </div>
            </div>

            <div className="mt-3 tableFixHead">
                <table className="border-spacing-x-0 border-spacing-y-2 border-separate" >
                    <thead>
                        <tr className="whitespace-nowrap">
                            <th scope="col" className="th-heading" >S.No</th>
                            <th scope="col" className="th-heading" >Client Name</th>
                            <th scope="col" className="th-heading" >Severity</th>
                            <th scope="col" className="th-heading" >Issue Date</th>
                            <th scope="col" className="th-heading" >Status</th>
                            <th scope="col" className="th-heading" >Response Date</th>
                            <th scope="col" className="th-heading text-right" >Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cases.length === 0 ?
                            <tr className="tr whitespace-nowrap">
                                <td className="td-text"></td>
                                <td className="td-text"></td>
                                <td className="td-text"></td>
                                <td className="td-text text-lg font-semibold">No Cases</td>
                                <td className="td-text"></td>
                                <td className="td-text"></td>
                                <td className=""></td>
                            </tr> :
                            <>
                                {cases && cases.map((data, i) => {
                                    return (
                                        <tr key={data.id} className="tr whitespace-nowrap">
                                            <td className="td-text">{i + 1}</td>
                                            <td className="td-text">{data.clientName}</td>
                                            <td className="td-text">{data.severityData}</td>
                                            <td className="td-text">{data.createdAt?.split("T")[0].split("-").reverse().join("-")}</td>
                                            <td className="td-text">{data.caseStatusData}</td>
                                            <td className="td-text">{data.updatedAt?.split("T")[0].split("-").reverse().join("-")}</td>
                                            <td className="flex text-right items-end justify-end">
                                                <div className="px-2 icon-w text-right" onClick={() => handleView(data)} >
                                                    <div className="text-center items-end justify-center z-10"><img src={viewicon} /></div></div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Cases
// onClick={() => navigate(`/${employee.companyName}/editbranch`, { state: { employee, data: data } })}
import React, { useEffect, useState } from 'react'
import Navbar1 from '../Navbar/Navbar1'
import LeftMenu1 from '../LeftMenu/LeftMenu1'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { config } from '../Config/Config'
import { enqueueSnackbar } from 'notistack'
import SimpleCrypto from 'simple-crypto-js'

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const GenerateInvoice = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const data = location?.state?.data;
    const [planName, setPlanName] = useState('');
    const [discountPercentage, setDiscountPercentage] = useState('');
    const [discountAmount, setDiscountAmount] = useState('');
    const [price, setPrice] = useState("");
    const [validity, setValidity] = useState("");
    const [tax, setTax] = useState("");
    const [taxAmount, setTaxAmount] = useState("");
    const [currency, setCurrency] = useState("");
    const [errors, setErrors] = useState({});
    const [totalAmount, setTotalAmount] = useState('');

    useEffect(() => {
        console.log("data", data)
        setPlanName(data.plan_name);
        setTax(data.tax_percentage);
        setValidity(data.validity_years);
        setPrice(data.price);
        setCurrency(data.currency);
    }, [data]);

    useEffect(() => {
        let disAmt = 0, taxAmt = 0;
        if (discountPercentage)
            disAmt = (discountPercentage * price) / 100;
        setDiscountAmount(disAmt);

        if (tax)
            taxAmt = (tax * (price - disAmt)) / 100;
        setTaxAmount(taxAmt);

        let totalAmt = price - disAmt + taxAmt;
        setTotalAmount(totalAmt);
    }, [tax, discountPercentage])
    const handleInputChange = (e, setFunc, errorProp) => {
        const inputValue = e.target.value;
        // Regular expression to match a valid double value
        const doubleRegex = /^-?\d+(\.\d*)?$/;
        if (!doubleRegex.test(inputValue)) {
            setErrors({ ...errors, [errorProp]: true });
            setFunc("");
        }
        else {
            setFunc(inputValue);
            setErrors({ ...errors, [errorProp]: false })
        }
    }
    const generateInvoice = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            subtotal: price ? parseFloat(price) : 0,
            taxPercentage: tax ? parseFloat(tax) : 0,
            tax: taxAmount ? parseFloat(taxAmount) : 0,
            discountPercentage: discountPercentage ? parseFloat(discountPercentage) : 0,
            discount: discountAmount ? parseFloat(discountAmount) : 0,
            totalAmount: totalAmount ? parseFloat(totalAmount) : 0,
            currency: currency,
            id: data.subscriptionId,
            companyId: data.companyId,
            adminId: data.adminId
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'superAdmin/generateinvoice', payload, { headers: headers })
            .then(function (response) {
                console.log("success", response.data.message, payload);
                enqueueSnackbar('Invoice Details Added Successfully!', { variant: "success" });
            })
            .catch(function (error) {
                console.log("error", error);
                enqueueSnackbar(error?.response?.data?.message, { variant: "error" })
            });
    };
    return (
        <div>
            <Navbar1 />
            <LeftMenu1 section="Generate Invoice" />
            <div><i onClick={() => navigate('/superadmin/Company')} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i></div>
            <div className="mt-20 flex flex-col justify-center items-center">
                <div className="md:w-1/3 max-w-sm">
                    <p className='text-2xl font-semibold text-center'>Generate Invoice</p>
                    <label className="mr-1 mt-2 text-sm text-black-600">Plan Name</label>
                    <p className={`text-sm w-full px-4 py-2 bg-white border-[1px] border-gray-300 rounded -mt-2 mb-2`}
                        placeholder="Plan Name">{planName}</p>
                    <div className='flex items-center gap-2'>
                        <div className='w-1/2'>
                            <label className="mr-1 mt-1 text-sm text-black-600">Currency</label>
                            <p name="currency" value={currency} className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded -mt-2 mb-2 bg-white">{currency}</p>
                        </div>
                        <div className='w-1/2'>
                            <label className="mr-1 mt-1 text-sm text-black-600">Validity in Years</label>
                            <p className={`text-sm w-full px-4 py-2 border-[1px] border-gray-300 rounded bg-white -mt-2 mb-2`} placeholder="Plan Validity in years">{validity}</p>
                        </div>
                    </div>
                    <label className="mr-1 mt-1 text-sm text-black-600">Price</label>
                    <p className={`text-sm w-full px-4 py-2 border-[1px] border-gray-300 rounded bg-white -mt-2 mb-2`} placeholder="Plan Price eg. 999.99" >{price}</p>
                    <div className='flex items-center gap-2'>
                        <div className='w-1/2'>
                            <label className="mr-1 mt-1 text-sm text-black-600">Tax Percentage</label>
                            <p className={`text-sm w-full px-4 py-2 border-[1px] border-gray-300 rounded bg-white -mt-1.5 mb-2`} placeholder="Tax Percentage eg. 9.99" >{tax}</p>
                        </div>
                        <div className='w-1/2'>
                            <label className="mr-1 mt-1 text-sm text-black-600">Discount Percentage</label>
                            <input className={`text-sm w-full px-4 py-2 border-[1px] border-gray-300 -mt-2 mb-2 rounded`}
                                type="text"
                                value={discountPercentage}
                                onChange={(e) => { handleInputChange(e, setDiscountPercentage, "taxError") }}
                                name="tax"
                                placeholder="Discount Percentage eg. 9.99" />
                        </div>
                    </div>
                    <div className='flex items-center gap-2'>
                        <div className='w-1/2'>
                            <label className="mr-1 mt-1 text-sm text-black-600">Tax Amount</label>
                            <p className="text-sm w-full px-4 py-2 border-[1px] border-gray-300 rounded -mt-2 mb-2 bg-white min-h-[2rem]">{taxAmount}</p>
                        </div>
                        <div className='w-1/2'>
                            <label className="mr-1 mt-1 text-sm text-black-600">Discount Amount</label>
                            <p className={`text-sm w-full px-4 py-2 border-[1px] border-gray-300 rounded bg-white -mt-2 mb-2 min-h-[2rem]`} placeholder="Plan Validity in years">{discountAmount}</p>
                        </div>
                    </div>
                    <label className="mr-1 mt-1 text-sm text-black-600">Total Amount</label>
                    <p className={`text-sm w-full px-4 py-2 border-[1px] border-gray-300 rounded bg-white -mt-2 mb-2 min-h-[2rem]`} placeholder="Total Amount eg. 999.99" >{totalAmount}</p>
                </div>
                <div className="flex justify-end ">
                    <button className="mt-4 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2  font-semibold uppercase rounded text-xs" onClick={generateInvoice} type="submit">Add</button>
                </div>
            </div >
        </div >
    )
}

export default GenerateInvoice
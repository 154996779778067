import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import 'react-datepicker/dist/react-datepicker.css';
import { config } from "../../Config/Config";
import moment from "moment-timezone";
import { downloadICSFile, generateICSFile } from "./utils";
import download from "../../images/download-icon.svg";
import business from '../../images/business-meet.svg';
import personal from '../../images/personal-meet.svg';
import self from '../../images/self-meet.svg';
import selfGray from '../../images/self-meet-gray.svg';
import inPerson from '../../images/in-person.svg';
import phoneMeet from '../../images/phone-meet.svg';
import virtual from '../../images/virtual-meet.svg';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const EditEvent = ({ data, closePopup, queryEmail, conversationTypes, statusArr, getAllMeetings, empDetails }) => {
    const [selectedStatus, setSelectedStatus] = useState();
    const [meetStatus, setMeetStatus] = useState([]);
    const [rejectFlag, setRejectFlag] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');
    const [errors, setErrors] = useState(false)

    useEffect(() => {
        console.log("data", data, data.meeting_status);
        let startTime = new Date(data.start_time);
        let now = new Date();
        let curMeetingStatus = [...statusArr];
        if (startTime < now) {
            curMeetingStatus = curMeetingStatus.filter(type => type.id === 3 || type.id === 4);
        }
        else if (startTime > now) {
            curMeetingStatus = curMeetingStatus.filter(type => type.id === 1 || type.id === 2 || type.id === 5);
        }
        setMeetStatus(curMeetingStatus);
        setSelectedStatus(data.meeting_status)
    }, []);

    const editMeetingStatus = (event, status) => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "meetingStatus": status || selectedStatus,
            "id": data.id,
            "deletionReason": rejectionReason
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'manager/updateMeetingStatus', payload, { headers: headers })
            .then(function (response) {
                console.log("success", response.data);
                getAllMeetings();
                closePopup();
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const getCurStatus = (id) => {
        if (!id) return "Meeting Status";
        let curStatus = statusArr && statusArr.find(data => data.id == id);
        console.log("idd", id, statusArr, curStatus)
        return curStatus?.status
    };
    const handleDownloadICS = (data) => {
        let startTime = new Date(data.start_time);
        let endTime = new Date(data.end_time);
        const meetingDetails = {
            startYear: startTime.getFullYear(),
            startMonth: startTime.getMonth() + 1,
            startDay: startTime.getDate(),
            startHour: startTime.getHours(),
            startMinute: startTime.getMinutes(),
            endYear: endTime.getFullYear(),
            endMonth: endTime.getMonth() + 1,
            endDay: endTime.getDate(),
            endHour: endTime.getHours(),
            endMinute: endTime.getMinutes(),
            title: data.title,
            description: data.description,
            location: 'Online-Web Meet',
        };
        const icsContent = generateICSFile(meetingDetails);

        if (icsContent) {
            downloadICSFile(icsContent, 'meeting.ics');
        }
    };
    return (
        <div>
            <Modal
                show={true}
                onHide={closePopup}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                contentClassName="border-[2px] border-black rounded-2xl"
            >
                <div className="max-w-lg min-w-[25vw] m-2 mr-2 mx-auto mb-4">
                    <div className="flex items-center w-full justify-between">
                        <label className="mr-1 text-lg text-black-600 md:w-2/3 text-end">Edit Meeting Status</label>
                        <OverlayTrigger placement="right"
                            overlay={<Tooltip>
                                <p>Download ICS file</p>
                            </Tooltip>}>
                            <img src={download} alt="" className="w-6 h-6 cursor-pointer" onClick={() => handleDownloadICS(data)} />
                        </OverlayTrigger>
                    </div>
                    <ul className="flex items-center list-none my-2">
                        <li className="flex-shrink-0 h-3 w-3 bg-black rounded-full text-center relative">
                            <span className="absolute top-4 -right-6 text-xs font-semibold">{moment(data.start_time).format("hh:mma")}</span></li>
                        <li className="flex-grow border-t-2 border-black relative">
                            <span className="absolute top-2 left-[40%] text-xs font-semibold">{moment(data.start_time).format("DD-MM-YYYY")}</span>
                        </li>
                        <li className="flex-shrink-0 h-3 w-3 bg-black rounded-full relative">
                            <span className="absolute top-4 -right-6 text-xs font-semibold">{moment(data.end_time).format("hh:mma")}</span>
                        </li>
                    </ul>
                    <div className="grid grid-cols-3 gap-3 w-full mb-3 mt-10">
                        <div className={`flex flex-col gap-3 rounded-lg items-center justify-center border-[1px] border-gray-400 p-2 ${data.meeting_reason === 1 ? "bg-gray-200" : ""}`}>
                            <img src={business} alt="Official" className="w-10 h-10" />
                            <span className="text-xs font-semibold">Official</span>
                        </div>
                        <div className={`flex flex-col gap-3 rounded-lg items-center justify-center border-[1px] border-gray-400 p-2 ${data.meeting_reason === 2 ? "bg-gray-200" : ""}`}>
                            <img src={personal} alt="Personal" className="w-10 h-10" />
                            <span className="text-xs font-semibold">Personal</span>
                        </div>
                        <div className={`flex flex-col gap-3 rounded-lg items-center justify-center border-[1px] border-gray-400 p-2 ${data.meeting_reason === 3 ? "bg-gray-200" : ""}`}>
                            <img src={queryEmail ? self : selfGray} alt="Self" className="w-10 h-10" />
                            <span className={`text-xs font-semibold ${queryEmail ? "" : "text-gray-300"}`}>Self</span>
                        </div>
                    </div>
                    <div className="grid grid-cols-3 gap-3 w-full mb-2">
                        <div className={`flex flex-col gap-3 rounded-lg items-center justify-center border-[1px] border-gray-400 p-2 ${data.conversation_type === 1 ? "bg-gray-200" : ""}`}>
                            <img src={virtual} alt="Virtual" className="w-10 h-10" />
                            <span className="text-xs font-semibold">Virtual</span>
                        </div>
                        <div className={`flex flex-col gap-3 rounded-lg items-center justify-center border-[1px] border-gray-400 p-2 ${data.conversation_type === 2 ? "bg-gray-200" : ""}`}>
                            <img src={inPerson} alt="In Person" className="w-10 h-10" />
                            <span className="text-xs font-semibold">In Person</span>
                        </div>
                        <div className={`flex flex-col gap-3 rounded-lg items-center justify-center border-[1px] border-gray-400 p-2 ${data.conversation_type === 3 ? "bg-gray-200" : ""}`}>
                            <img src={phoneMeet} alt="Phone" className="w-10 h-10" />
                            <span className={`text-xs font-semibold`}>Phone</span>
                        </div>
                    </div>

                    <label className="mr-1 text-sm text-black-600 -mb-2">Name</label>
                    <p className={`text-sm w-full p-2 h-9 rounded border-[1px] border-gray-300`}
                        placeholder={queryEmail ? "Attendees Name" : "Organisers Name"}>{data.organisers_name === empDetails?.name ? data.attendees_name : data.organisers_name}</p>
                    <label className="mr-1 text-sm text-black-600 mt-2 -mb-2">Email</label>
                    <p className={`text-sm w-full p-2 h-9 rounded border-[1px] border-gray-300`}
                        placeholder={queryEmail ? "Attendees Email" : "Organisers Email"}>{data.organisers_email === empDetails?.email ? data.attendees_email : data.organisers_email}</p>
                    <label className="mr-1 text-sm text-black-600 mt-2 -mb-2">Title</label>
                    <p className={`text-sm w-full p-2 h-9 border-[1px] border-gray-300 rounded resize-none`}
                        placeholder="Enter a title for the meeting">{data.title}</p>
                    <label className="mr-1 text-sm text-black-600 mt-2 -mb-2">Description</label>
                    <p className="text-sm w-full p-2 min-h-[2rem] border border-solid border-gray-300 rounded resize-none"
                        placeholder="Decribe the meeting agenda">{data.description}</p>
                    <div className={`flex gap-4 mt-2 w-full ${rejectFlag ? "hidden" : ""}`}>
                        <div className="flex flex-col w-full">
                            <label className="mr-1 text-sm text-black-600">Staus</label>
                            <select name="status" value={selectedStatus} className="text-sm px-3 py-2 border border-solid border-gray-300 rounded -mt-2"
                                onChange={(e) => { setSelectedStatus(e.target.value); if (e.target.value == 5) { setRejectFlag(true); setErrors(true) } }}
                                disabled={data.meeting_status === 6}>
                                <option value={selectedStatus ? selectedStatus : ""}>{selectedStatus ? getCurStatus(selectedStatus) : "Meeting Status"}</option>
                                {meetStatus && meetStatus.map(data => {
                                    return (
                                        <option key={data.id} value={data.id}>{data.status}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className={`flex flex-col ${rejectFlag ? "" : "hidden"}`}>
                        <label className="mr-1 text-sm text-black-600 mt-2">Reason For Rejection</label>
                        <textarea className={`text-sm w-full p-2 mb-2 -mt-2 ${errors ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded resize-none`}
                            type="text"
                            onChange={(e) => { setRejectionReason(e.target.value); setErrors(false) }}
                            name="title"
                            value={rejectionReason}
                            placeholder="Rejection Reason"
                            rows={1}
                            maxLength={40}
                        />
                    </div>
                    {data?.meeting_status === 6 && <><label className="mr-1 text-sm text-black-600 mt-2 -mb-2">Reason for Deletion</label>
                        <p className="text-sm w-full p-2 min-h-[2rem] border border-solid border-gray-300 rounded resize-none"
                            placeholder="Decribe the meeting agenda">{data.deletion_reason}</p></>}
                    <div className="flex justify-between text-center md:text-left mt-3">
                        <button className={`bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white font-semibold uppercase rounded text-xs ${data.meeting_status === 6 ? "invisible" : ""}`} type="submit" onClick={closePopup}>Back</button>
                        <button className={`bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2  font-semibold uppercase rounded text-xs ${data.meeting_status === 6 ? "hidden" : ""}`} onClick={editMeetingStatus} type="submit">Update</button>
                        <button className={`bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white font-semibold uppercase rounded text-xs ${data.meeting_status === 6 ? "" : "hidden"}`} type="submit" onClick={(e) => editMeetingStatus(e, 5)}>Remove</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default EditEvent
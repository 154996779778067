import { enqueueSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import EmployeeContext from '../context/employee/EmpContext';

const NewSurveyLinkPopuup = ({ togglePopup, newSurvey }) => {
    const { empState: employee } = useContext(EmployeeContext);
    const location = useLocation();
    const [surveyURL, setSurveyURL] = useState('');
    useEffect(() => {
        let url = `${window.location.origin}/${employee.companyName.replace(/\s/g, "")}/${employee.uniqueCode}/surveyform?survey_id=${newSurvey.survey_id}`
        setSurveyURL(url)
    }, [])
    const handleCopyClick = (url) => {
        const input = document.createElement('input');
        input.setAttribute('value', url);
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        enqueueSnackbar("Copied to clipboard!", { variant: 'success' })
    };
    return (
        <Modal show={true} onHide={togglePopup} size="md" centered >
            <Modal.Body>
                <div className='flex flex-col gap-4 justify-center items-center'>
                    <div className=" max-w-sm mx-auto ">
                        <div className="flex items-center w-full justify-between">
                            <p className="mr-1 text-lg text-black-600 text-end font-medium">Survey Link Generated Successfully</p>
                        </div>
                        <p className="text-sm my-2">Click on this link to view the status of the survey</p>
                        <a className='text-blue-600 underline underline-offset-1 -mt-4 break-words' target='_blank'
                            href={surveyURL}>
                            {surveyURL}
                        </a>
                        <button className='absolute top-6 right-4 w-10 float-right bg-custom-bg-color text-custom-text-color p-1 font-semibold uppercase rounded text-[10px]'
                            onClick={() => handleCopyClick(surveyURL)}>COPY</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default NewSurveyLinkPopuup
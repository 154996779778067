import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment-timezone";
import { enqueueSnackbar } from "notistack";
import { config } from "../../Config/Config";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const DealToDoDetails = ({ data, togglePopup }) => {
    const [targetDate, setTargetDate] = useState("")
    const [todoData, setTodoData] = useState();

    useEffect(() => {
        console.log(data);
        getDealToDoDetails();
    }, []);
    const getDealToDoDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            id: data.todoId,
            employeeId: data.employeeId
        }
        axios.post(config.apiUrl + 'employees/getDealToDoDetails', payload, { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    console.log("todoData", response.data.todoDetails)
                    setTodoData(response.data.todoDetails)
                    let tDate = response.data?.todoDetails ? new Date(response.data.todoDetails.targetDate) : null;
                    if (tDate) {
                        let formattedTDate = moment(tDate).format("DD-MM-YYYY hh:mma")
                        setTargetDate(formattedTDate);
                    }
                } else {
                    console.log("Error");
                }
            }).catch((error) => {
                console.error('Error loading products data:', error);
                enqueueSnackbar("Something went wrong!!", { variant: "error" })
            })
    };
    return (
        <div>
            <Modal
                show={true}
                onHide={togglePopup}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="flex flex-col w-full justify-center items-center mx-auto mt-6">
                    <div className="text-center">
                        <label className="mr-1 text-lg text-black-600">ToDo Details</label>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mt-3">
                        <div className="flex flex-col">
                            <label className="text-xs text-gray-600">Customer Name</label>
                            <p className="text-sm text-black w-44 h-9 p-2 border border-solid border-gray-300 rounded">{data.customer}</p>
                        </div>
                        <div className="flex flex-col">
                            <label className="text-xs text-gray-600">Assigned To</label>
                            <p className="text-sm text-black w-44 h-9 p-2 border border-solid border-gray-300 rounded">{todoData && todoData.employeeName}</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mt-3">
                        <div className="flex flex-col">
                            <label className="text-xs text-gray-600">Action Type</label>
                            <p className="text-sm text-black w-44 h-9 p-2 border border-solid border-gray-300 rounded">{todoData && todoData.action}</p>
                        </div>
                        <div className="flex flex-col">
                            <label className=" text-xs text-gray-600">Status</label>
                            <p className="text-sm text-black w-44 h-9 p-2 border border-solid border-gray-300 rounded">{todoData && todoData.status}</p>
                        </div>
                    </div>
                    <div className="flex flex-col mt-3">
                        <label className="text-xs text-gray-600">Notes</label>
                        <p className="text-sm text-black w-[23rem] min-h-[3rem] p-2 border border-solid border-gray-300 rounded">{todoData && todoData.notes}</p>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mt-3">
                        <div className="flex flex-col">
                            <label className="text-xs text-gray-600">Target Date</label>
                            <p className="text-sm w-44 min-h-[1rem] p-2 border border-solid border-gray-300 rounded">{targetDate}</p>
                        </div>
                    </div>
                </div>
                <div className={`flex justify-end text-center my-6 mx-12`}>
                    <button className="mt-4 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white font-semibold uppercase rounded text-xs" type="submit" onClick={togglePopup}>Close</button>
                </div>
            </Modal >
        </div >
    )
}

export default DealToDoDetails
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import moment from 'moment-timezone';
import SimpleCrypto from "simple-crypto-js";
import editicon from "../images/editicon.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const PricingConfig = () => {
    const location = useLocation();
    const employee = location.state;
    const navigate = useNavigate();
    const [pricingPlans, setPricingPlans] = useState([]);

    useEffect(() => {
        getAllPricingPlans();
    }, []);

    const handleNavigate = (data) => {
        navigate("/editplan", { state: { data: data } })
    }

    const handleAddPlan = () => {
        navigate('/addplan', { state: { employee } })
    }
    const getAllPricingPlans = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + `superAdmin/allplans`, { headers: headers })
            .then(function (response) {
                console.log("success", response.data.message, response.data);
                setPricingPlans(response.data.plans);
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };

    return (
        <div>
            <div className="ml-8 mt-20">
                <div className="flex flex-col justify-between">
                    <div className="text-left font-semibold text-black"><h5>Pricing Config</h5></div>
                    <div className="-mt-5 px-8 text-right">
                        <button onClick={handleAddPlan} className="bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs -mr-7">Add Plan</button>
                    </div>
                </div>

                <div className="mt-3 tableFixHead">
                    <table className="border-spacing-x-0 border-spacing-y-2 border-separate" >
                        <thead>
                            <tr className="whitespace-nowrap">
                                <th scope="col" className="th-heading text-left w-5">S.No</th>
                                <th scope="col" className="th-heading text-left">Plan Name</th>
                                <th scope="col" className="th-heading text-center">Validity</th>
                                <th scope="col" className="th-heading text-center">Tax</th>
                                <th scope="col" className="th-heading text-center">Price per Year</th>
                                <th scope="col" className="th-heading text-center">Currency</th>
                                <th scope="col" className="th-heading text-left">Status</th>
                                <th scope="col" className="th-heading text-left">Updated On</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {pricingPlans.map((data, i) => (
                                <tr key={data.id} className="tr whitespace-nowrap text-center" >
                                    <td className="td-text pl-3">{i + 1}</td>
                                    <td className="td-text text-left">{data.plan_name}</td>
                                    <td className="td-text">{data.validity_years} {data.validity_years ? " years" : ""}</td>
                                    <td className="td-text">{data.tax}{data.tax ? "%" : ""}</td>
                                    <td className="td-text">{data.price}</td>
                                    <td className="td-text">{data.currency}</td>
                                    <td className="td-text text-left">{data.is_active ? "Active" : "Inactive"}</td>
                                    <td className="td-text text-left">{moment(data.updatedAt).format("DD/MM/YYYY")}</td>
                                    <td className="flex text-right items-end justify-end">
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip">Edit Plan</Tooltip>}
                                        >
                                            <div className="ml-2 px-2 icon-w text-right" onClick={() => handleNavigate(data)} >
                                                <div className="text-center items-end justify-center z-10"><img src={editicon} /></div></div>
                                        </OverlayTrigger>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default PricingConfig;
import React, { useEffect, useState } from 'react'
import Navbar1 from '../Navbar/Navbar1'
import LeftMenu1 from '../LeftMenu/LeftMenu1'
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import { enqueueSnackbar } from 'notistack';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const AddSubscription = ({ data, setSection }) => {
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({});

    useEffect(() => {
        console.log("data", data)
        getAllPricingPlans();
    }, [data]);

    const getAllPricingPlans = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + `superAdmin/allplans`, { headers: headers })
            .then(function (response) {
                console.log("success", response.data.message, response.data);
                setPlans(response.data.plans);
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const addSub = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            companyId: data.id,
            adminId: data.adminId,
            planName: selectedPlan ? selectedPlan.plan_name : "",
            planDuration: selectedPlan ? selectedPlan.validity_years : "",
            planDescription: selectedPlan ? selectedPlan.plan_description : "",
            currency: selectedPlan ? selectedPlan.currency : "",
            planPrice: selectedPlan ? parseFloat(selectedPlan.price) : 0,
            isActive: true,
            planId: selectedPlan ? selectedPlan.id : ""
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'superAdmin/addnewsubscription', payload, { headers: headers })
            .then(function (response) {
                console.log("success", response.data.message, payload);
                enqueueSnackbar('Subscription Details Added Successfully!', { variant: "success" });
            })
            .catch(function (error) {
                console.log("error", error);
                enqueueSnackbar(error?.response?.data?.message, { variant: "error" })
            });
    };
    return (
        <div>
            <Navbar1 />
            <LeftMenu1 section="New Subscription" />
            <div><i onClick={() => setSection("Company")} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i></div>
            <div className="mt-20 flex flex-col justify-center items-center">
                <div className="md:w-1/3 max-w-sm">
                    <p className='text-2xl font-semibold text-center mb-2'>Add New Subscription</p>
                    <label className="mr-1 mt-2 text-sm text-black-600">Plan Name</label>
                    <select name="selectedPlan" value={selectedPlan} className="text-sm w-full p-2 border border-solid border-gray-300 rounded -mt-2 mb-2"
                        onChange={(e) => e.target.value && setSelectedPlan(JSON.parse(e.target.value))} >
                        <option value={selectedPlan || ""}>{selectedPlan?.plan_name ? selectedPlan.plan_name + " (" + selectedPlan.validity_years + " years)" : "--Select Plan--"}</option>
                        {plans && plans.map(plan => {
                            return (
                                <option key={plan.id} value={JSON.stringify(plan)}>{plan.plan_name}{" (" + plan.validity_years + " years)"}</option>
                            )
                        })}
                    </select>
                    <div className='flex items-center gap-2'>
                        <div className='w-1/2'>
                            <label className="mr-1 mt-1 text-sm text-black-600">Currency</label>
                            <p className="text-sm w-full p-2 border border-solid border-gray-300 rounded -mt-2 mb-2 bg-white min-h-[2.3rem]">{selectedPlan?.currency}</p>
                        </div>
                        <div className='w-1/2'>
                            <label className="mr-1 mt-1 text-sm text-black-600">Validity in Years</label>
                            <p className={`text-sm w-full p-2 border-[1px] border-gray-300 rounded bg-white -mt-2 mb-2 min-h-[2.3rem]`}>{selectedPlan?.validity_years}</p>
                        </div>
                    </div>
                    <label className="mr-1 mt-1 text-sm text-black-600">Price</label>
                    <p className={`text-sm w-full p-2 border-[1px] border-gray-300 rounded -mt-2 mb-2 bg-white min-h-[2.3rem]`}>
                        {selectedPlan?.price}</p>
                    <label className="mr-1 mt-1 text-sm text-black-600">Tax</label>
                    <p className={`text-sm w-full p-2 border-[1px] border-gray-300 rounded -mt-2 mb-2 bg-white min-h-[2.3rem]`}>
                        {selectedPlan?.tax_percentage}</p>
                </div>
                <div className="flex justify-end ">
                    <button className="mt-4 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2  font-semibold uppercase rounded text-xs" onClick={addSub} type="submit">Add</button>
                </div>
            </div>
        </div>
    )
}

export default AddSubscription
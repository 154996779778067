import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import EmployeeCountGraph from "./EmployeesCountGraph";
import LeadTypeGraph from "./LeadTypeGraph";
import CustomersRegionGraph from "./CustomersRegionGraph";
import { useQuery } from 'react-query';
import EmployeeContext from "../context/employee/EmpContext";
import CustomersCount from "./CustomersCount";
import EmpActivity from "./EmployeeAnalytics/EmployeeActivityGraph";
import RewardsCount from "./RewardsCount";


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function EmployeeAnalytics() {
    const { empState: employee } = useContext(EmployeeContext)
    const [section, setSection] = useState("Employee Analytics")
    const [newCustomerCount, setNewCustomerCount] = useState()
    const [topEmployee, setTopEmployee] = useState()
    const [employeeCount, setEmployeeCount] = useState();

    const getTopEmployee = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        return axios.get(config.apiUrl + 'companyAdmin/getLastMonthTopEmployee', { headers: headers })
    };
    const { data } = useQuery("topBranchDetails", getTopEmployee, {
        enabled: section === "Employee Analytics",
        onSuccess: (response) => {
            console.log("success top employee from query ", response.data);
            setTopEmployee(response.data.topEmployee)
            setEmployeeCount(response.data.activeEmployees);
        },
        onError: (error) => {
            console.error('Error loading branches data:', error);
        },
    });


    return (
        <div className="mt-1 mb-2">
            <div className="flex gap-4 items-center text-center" style={{ marginTop: "18px" }}>
                <div className="flex flex-col justify-around mt-2 rounded-xl bg-custom-bg-color hover:custom-bg-color text-custom-text-color w-24 h-24 px-1 py-2 font-semibold">
                    <p className="text-2xl">{employeeCount}</p>
                    <p className="text-md">Employees</p>
                </div>
                <div className="flex flex-col justify-center gap-3 mt-2 rounded-xl bg-custom-bg-color hover:custom-bg-color text-custom-text-color w-24 h-24 p-1 font-semibold">
                    <p className="text-sm">{topEmployee?.name || "-"}</p>
                    <p className="whitespace-nowrap text-xs">Top Employee</p>
                </div>
            </div>
            <div className="flex gap-2 max-w-max">
                <EmpActivity />
                <CustomersRegionGraph />
                <EmployeeCountGraph />
            </div>
            <div className="flex gap-2 max-w-max">
                <RewardsCount />
            </div>
        </div>
    );
};

import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

const UserActivity = (props) => {
    const [errors, setErrors] = useState({});

    const back = (e) => {
        e.preventDefault();
        props.prevStep();
    }

    const saveAndContinue = (e) => {
        e.preventDefault();
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: "error" })
            return
        };
        props.nextStep();
    };
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!props.inputValues.activity) {
            errorObj = {
                ...errorObj,
                activityError: true
            }
            error = true;
        }
        if (!props.inputValues.occasion) {
            errorObj = {
                ...errorObj,
                occasionError: true
            }
            error = true;
        }
        if (!props.inputValues.keyAsk) {
            errorObj = {
                ...errorObj,
                keyAskError: true
            }
            error = true;
        }
        if (!props.inputValues.interaction) {
            errorObj = {
                ...errorObj,
                interactionError: true
            }
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    return (
        <div className='flex justify-center items-center mt-20 w-full ml-12'>
            <div className="flex flex-col gap-1 w-96">
                <p className="text-base font-medium self-center">Activity Details</p>
                <select name="occasion" value={props.inputValues.occasion} className={`text-sm w-full p-2 ${errors.occasionError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                    onChange={(e) => { props.handleChange(e); setErrors({ ...errors, occasionError: false }) }} >
                    <option value="">---Reason of visit---</option>
                    {props.occasions && props.occasions.map(item => {
                        return (
                            <option value={item.id} key={item.id}>{item.occasion}</option>
                        )
                    })}
                </select>
                <select name="keyAsk" value={props.inputValues.keyAsk} className={`text-sm w-full p-2 ${errors.keyAskError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                    onChange={(e) => { props.handleChange(e); setErrors({ ...errors, keyAskError: false }) }} >
                    <option value="">---Cutomer's Ask---</option>
                    {props.keyAsk && props.keyAsk.map(item => {
                        return (
                            <option value={item.id} key={item.id}>{item.keyAsk}</option>
                        )
                    })}
                </select>
                <div className="grid grid-cols-1 justify-between ml-2 items-center mt-2 w-full">
                    {props.products && props.products.map(product => {
                        return (
                            <div key={product.id} className="flex items-center">
                                <label htmlFor={JSON.stringify(product.id)} className="cursor-pointer">
                                    <input
                                        type="checkbox"
                                        className="cursor-pointer"
                                        id={JSON.stringify(product.id)}
                                        name={product.productFamilyName}
                                        value={product.id}
                                        onChange={(e) => props.selectProducts(e)}
                                        defaultChecked={props.selectedProducts.some(p => p == product.id)}
                                    />
                                    &nbsp;{product.productFamilyName}</label>
                            </div>
                        )
                    })}
                </div>
                <select name="activity" value={props.inputValues.activity} className={`text-sm w-full p-2 ${errors.activityError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                    onChange={(e) => { props.handleChange(e); setErrors({ ...errors, activityError: false }) }} >
                    <option value="">---User Activity---</option>
                    {props.activity && props.activity.map(item => {
                        return (
                            <option value={item.id} key={item.id}>{item.activity}</option>
                        )
                    })}
                </select>
                <select name="interaction" value={props.inputValues.interaction} className={`text-sm w-full p-2 ${errors.interactionError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                    onChange={(e) => { props.handleChange(e); setErrors({ ...errors, interactionError: false }) }} >
                    <option value="">---Interaction---</option>
                    {props.interaction && props.interaction.map(item => {
                        return (
                            <option value={item.id} key={item.id}>{item.interaction}</option>
                        )
                    })}
                </select>
                <div className={`flex flex-col ${parseInt(props.inputValues.activity) === 3 || parseInt(props.inputValues.activity) === 4 ? "" : "hidden"}`}>
                    {props.importantDates && props.importantDates.map((d, i) => {
                        return (
                            <div className="grid grid-cols-2 gap-1" key={i}>
                                <input className={`text-sm w-full p-2 border-[1px] border-gray-300 rounded mt-2`}
                                    type="text"
                                    onChange={(e) => { props.handleOccasionChange(i, "occasion", e.target.value) }}
                                    value={d?.occasion ? d.occasion : ""}
                                    name={`date${i}`}
                                    placeholder={`Occasion ${i + 1}`} />
                                <DatePicker className="text-sm w-full p-2 border border-gray-500 cursor-pointer mt-2 rounded"
                                    isClearable
                                    dateFormat="dd/MM"
                                    placeholderText="Date"
                                    selected={d?.date ? d.date : null}
                                    onChange={date => {
                                        if (!date) {
                                            props.handleOccasionChange(i, "date", null)
                                            return;
                                        }
                                        props.handleOccasionChange(i, "date", date)
                                    }}
                                />
                            </div>
                        )
                    })}
                </div>
                <div className='flex justify-end mt-4 w-full'>
                    <button className='bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color rounded-md py-1 px-4 max-w-max uppercase'
                        onClick={saveAndContinue}>Next</button>
                </div>
            </div>
        </div>
    );
}

export default UserActivity;

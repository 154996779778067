import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import EmployeeContext from "../context/employee/EmpContext";
import nebutech from '../images/t-logo.svg';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const LeftMenu1 = ({ section }) => {
    const navigate = useNavigate();
    const { empState: employee } = useContext(EmployeeContext)

    return (
        <div className='w-[14vw] min-h-screen bg-custom-bg-color text-custom-text-color fixed left-0 top-14'>
            <div className='text-xl font-bold text-white justify-center items-center text-center'>
                <p className=" border-t-2 border-white"></p>
            </div>
            <div className='mt-3 sm:text-sm md:text-base text-xs flex flex-col gap-4'>
                <div className={`bg-gray-500 px-6 py-2 text-white cursor-pointer`}>{section}</div>
            </div>
            <div className='position-absolute bottom-14 left-0 bg-white w-full'>
                <img src={nebutech} className='ml-2 mt-1 mb-2 w-32 h-8' />
            </div>
        </div>
    )
}

export default LeftMenu1
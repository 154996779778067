import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react'
import { config } from '../Config/Config';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SimpleCrypto from "simple-crypto-js";
import ErrorPopup from '../PopUps/ErrorPopup';
import logo from "../images/lms logo.svg";
import picture from "../images/lsm picture.svg";
import NebutechLogo from '../NebutechLogo/NebutechLogo';
import EmployeeContext from '../context/employee/EmpContext';
import LogoutSecond from '../PopUps/Logout2';
import RegisterProcess from './RegisterProcess';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const validPasswordRegex =
    /^.*(?=.{10,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/

const Login = () => {
    const emp = useContext(EmployeeContext);
    const { companyname, id: uid } = useParams();
    const navigate = useNavigate();
    const [id, setId] = useState('');
    const [idError, setIdError] = useState("")
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState('');
    const [passwordError, setPasswordError] = useState("")
    const [showLogOut, setShowLogOut] = useState(false);
    const [showError, setShowError] = useState(false);
    const [msg, setMsg] = useState('');
    const [companyDetails, setCompanyDetails] = useState({});
    const [isVisible, setIsVisible] = useState(false);
    const [subsCheck, setSubsCheck] = useState(null);
    const [showRegisterPopup, setShowRegisterPopup] = useState(false)

    useEffect(() => {
        if (localStorage.getItem("random")) {
            let currentEmp = simpleCrypto.decrypt(localStorage.getItem("junk"));
            if (localStorage.getItem("roleId") == 1) {
                navigate(`/${currentEmp?.companyName}/companyadmin/Branch`)
            } else if (localStorage.getItem("roleId") == 2) {
                navigate(`/${currentEmp?.companyName}/branchadmin/Customers`)
            } else if (localStorage.getItem("roleId") == 4) {
                navigate("/superadmin", { state: simpleCrypto.decrypt(localStorage.getItem("junk")) })
            } else {
                navigate(`/${currentEmp?.companyName}/salesperson/Customers`)
            }
        }
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 500);

        return () => clearTimeout(timer);
    }, [])

    useEffect(() => {
        if (uid) {
            getCompanyDetails()
        }
    }, [uid])
    const handleLogin = (e) => {
        e.preventDefault();
        let formFileds = [
            "id",
            "password"
        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = validateField(field) && isValid;
        });
        if (!isValid) {
            console.log("Please enter the valid details")
            setMsg("Please enter the valid details");
            setShowError(true);
            setTimeout(() => { setMsg(''); setShowError(false) }, 2000)
        } else
            login();
    }
    const validateField = (name) => {
        let isValid = false;
        if (name === "id") isValid = validateId();
        else if (name === "password") isValid = validatePassword();
        return isValid;
    }
    const validateId = () => {
        let idError = "";
        const value = id;
        if (value.trim === "") idError = "Please enter valid Employee ID";
        setIdError(idError)
        return idError === "";
    }
    const validatePassword = () => {
        let passwordError = "";
        const value = password;
        if (value.trim === "") passwordError = "Password is required";
        else if (!validPasswordRegex.test(value))
            passwordError = "Min 10 with 1 capital, 1 numeric and 1 character (!@#$%^&*)";
        setPasswordError(passwordError)
        return passwordError === "";
    }
    const login = async () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        let payload = {
            employeeId: id,
            companyId: companyDetails?.id ? companyDetails.id : null,
            password: simpleCrypto.encrypt(password),
            source: 1
        }
        axios.post(config.apiUrl + 'employees/login', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    console.log("employee", response.data.employee);
                    response.data.employee["uniqueCode"] = uid;
                    if (companyDetails?.id) {
                        response.data.employee["companyName"] = companyDetails.name;
                        response.data.employee["businessType"] = companyDetails.businessType;
                        response.data.employee["logo"] = companyDetails.logo;
                        response.data.employee["sectorType"] = companyDetails.sectorType;
                        response.data.employee["industryType"] = companyDetails.industryType;
                        response.data.employee["use_meetings"] = companyDetails.use_meetings;
                        response.data.employee["survey_service"] = companyDetails.surveyServiceStatus;
                    }
                    localStorage.setItem("random", simpleCrypto.encrypt(response.data.accessToken))
                    localStorage.setItem("roleId", response.data.employee.role)
                    localStorage.setItem("junk", simpleCrypto.encrypt(response.data.employee))
                    emp.updateEmpState(response.data.employee)
                    if (response.data.employee.role == 1) {
                        navigate(`/${companyDetails.name}/companyadmin/Branch`, { state: response.data.employee })
                    } else if (response.data.employee.role == 2) {
                        navigate(`/${companyDetails.name}/branchadmin/Customers`, { state: response.data.employee })
                    } else if (localStorage.getItem("roleId") == 4) {
                        navigate("/superadmin/Company", { state: response.data.employee })
                    } else {
                        navigate(`/${companyDetails.name}/salesperson/Customers`, { state: response.data.employee })
                    }
                } else {
                    console.log(response.data.message)
                    setMsg(response.data.message);
                    setShowError(true);
                    setTimeout(() => { setMsg(''); setShowError(false) }, 2000)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
                if (error.response.status === 403) {
                    console.log(error.response.status)
                    setShowLogOut(true);
                }
            });
    }
    const getCompanyDetails = async () => {
        let payload = {
            uniqueCode: uid
        }
        axios.post(config.apiUrl + 'employees/getCompanyDetailsByUniqueCode', payload)
            .then(function (response) {
                if (response.data.status === true) {
                    console.log("company details", response.data);
                    if (response.data?.details?.length > 0) {
                        setCompanyDetails(response.data.details[0]);
                        localStorage.setItem("cName", simpleCrypto.encrypt(response.data.details[0]));
                        setSubsCheck(response.data.subsCheck);
                        sessionStorage.setItem("cName", simpleCrypto.encrypt(response.data.details[0]));
                    }
                } else {
                    console.log(response.data.message)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }
    const toggleRegisterProcess = (e) => {
        e.preventDefault()
        setShowRegisterPopup(!showRegisterPopup);
    }
    const toggleClosePopup = () => {
        setShowRegisterPopup(!showRegisterPopup);
    }
    return (
        <div className="h-screen flex flex-col login-bg">
            <div className="flex w-full h-16 items-center justify-left text-sm" style={{ color: "#EFF2F5", background: "#142431" }}>
                <div className='justify-left items-center ml-3 w-12 cursor-pointer'><img src={logo} /></div>
                <div className="text-2xl font-semibold w-[90vw] text-center items-center">Entropi</div>
            </div>
            <section className="h-screen flex justify-around items-center mr-10">
                <ErrorPopup show={showError} setShow={setShowError} msg={msg} />
                {showRegisterPopup && <RegisterProcess showRegisterPopup={showRegisterPopup} setShowRegisterPopup={setShowRegisterPopup} closePopup={toggleClosePopup} />}
                {showLogOut && <LogoutSecond employeeId={id} companyId={companyDetails?.id} setShowLogOut={setShowLogOut} />}
                <div className="md:w-2/3 max-w-3xl">
                    <img src={picture} />
                </div>
                <form onSubmit={handleLogin} style={{ transitionDuration: '0.3s' }}
                    className={`md:w-1/3 max-w-sm h-[50vh] animate-fade-in ease-in-out ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
                    <div className="flex flex-col text-center md:text-left mb-2">
                        <div className={`flex items-center gap-4 pb-6 justify-center min-h-[4rem]`}>
                            {companyDetails && companyDetails.logo ? <div className='border-2 rounded-xl p-1 border-black'><img src={companyDetails.logo} alt='' className=' w-16 h-16' /></div>: <></>}
                            <p className='text-2xl font-semibold'>{companyDetails?.name}</p>
                        </div>
                        <p className="mr-1 text-lg">Sign in</p>
                    </div>
                    <div>
                        <input
                            className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded"
                            type="text"
                            placeholder="Enter Your Employee Id"
                            onChange={(e) => { setId(e.target.value); setIdError('') }}
                            onBlur={validateId}
                        />
                        <div className='error'>{idError && (
                            <div className="error">{idError}</div>
                        )}</div>
                    </div>
                    <div>
                        <input
                            className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-4"
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter your Password"
                            onChange={(e) => { setPassword(e.target.value); setPasswordError('') }}
                            onBlur={validatePassword}
                        />
                        <span className="sticky float-right -mt-9 p-1 mr-2 cursor-pointer"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <i className="fa fa-eye" aria-hidden="true"></i> : <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                        </span>
                        <div className='error'>{passwordError && (
                            <div className="error">{passwordError}</div>
                        )}</div>
                    </div>
                    <div className="flex justify-between items-center text-center md:text-left">
                        <div></div>
                        <button
                            className="mt-4 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2  font-semibold uppercase rounded text-xs"
                            type="submit"
                            onKeyDown={(e) => console.log(e)}
                        >
                            Login
                        </button>
                    </div>
                    <div className="mt-6 flex justify-between items-center font-semibold text-sm">
                        <div className="font-semibold text-sm text-slate-500 text-center md:text-left">
                            New User?&nbsp;
                            {companyname && uid ?
                                <Link to={`/${companyname}/${uid}/signup`} className="text-red-600 hover:underline hover:underline-offset-4" href={`/${companyname}/${uid}/signup`}>&nbsp;Register</Link>
                                : <Link to={`/signup`} className="text-red-600 hover:underline hover:underline-offset-4" href={`/${companyname}/${uid}/signup`}>&nbsp;Register</Link>}
                        </div>
                        {companyname && uid ?
                            <Link to={`/${companyname}/${uid}/resetpassword`} className="text-blue-600 hover:text-blue-700 hover:underline hover:underline-offset-4">Forgot/Change Password</Link>
                            : <Link to={`/resetpassword`} className="text-blue-600 hover:text-blue-700 hover:underline hover:underline-offset-4">Forgot/Change Password</Link>}
                    </div>
                    {subsCheck && subsCheck == 1 ? <p className='max-w-max mt-10 mx-auto px-2 bg-orange-600'>Your subscrition is going to expire soon</p>
                        : subsCheck && subsCheck == 2 ? <p className='max-w-max mt-10 mx-auto px-2 bg-red-600'>Your subscrition has expired</p> : null}
                    <div className='mt-3 flex items-center justify-center'>Need Help?
                        <button
                            className="ml-1 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-3 py-2 font-semibold uppercase rounded text-xs"
                            onClick={(e) => toggleRegisterProcess(e)}>
                            Click here
                        </button></div>
                </form>
            </section>
            <NebutechLogo />
        </div >

    )
}

export default Login
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Bar } from 'react-chartjs-2';
import moment from "moment-timezone";


const MeetingCountGraph = ({ graphDates, meetings }) => {
    const [values, setValues] = useState();

    useEffect(() => {
        if (!meetings || meetings?.length === 0) return;
        let meetingsCountData = [];
        const dayNames = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        for (let i = 0; i < 7; i++) {
            const day = moment(graphDates?.start, "YYYY-MM-DD").add(i, 'days').format("YYYY-MM-DD");
            let curCount = 0, type1 = 0, type2 = 0, type3 = 0;
            meetings.forEach(data => {
                let curMeet = moment(new Date(data.start)).format("YYYY-MM-DD");
                if (curMeet === day && data.status !== 5) {
                    curCount++;
                    data.reason == 1 ? type1++ : data.reason == 2 ? type2++ : type3++
                }
            });

            meetingsCountData.push({ day: moment(day).format('ddd'), curCount, date: day, type1, type2, type3 });
        };
        setValues(meetingsCountData);
    }, [graphDates, meetings])

    ///Api for fetching meets count by week
    // const getMeetingCounts = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "applicationType": "web"
    //     }
    //     let payload = {
    //         "empId": empDetails.id
    //     }
    //     axios.post(config.apiUrl + 'manager/getMeetingsCountForThisWeek', payload, { headers: headers })
    //         .then(function (response) {
    //             if (response.data.status == true) {
    //                 console.log("meetings count data fetched successfully", response.data);
    //                 setAllMeets(response.data.allMeets);
    //             } else {
    //                 console.log("Error");
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("error", error);
    //         });
    // };

    const chartData2 = {
        labels: values && values.map((data) => data.day),
        datasets: [
            {
                label: 'Official',
                data: values && values.map((data) => data.type1),
                backgroundColor: 'rgb(0, 68, 204)',
                borderWidth: 0,
                borderRadius: 10,
                barPercentage: 0.3,
                categoryPercentage: 1
            },
            {
                label: 'Personal',
                data: values && values.map((data) => data.type2),
                backgroundColor: 'rgb(153, 153, 102)',
                borderWidth: 0,
                borderRadius: 10,
                barPercentage: 0.3,
                categoryPercentage: 1
            },
            {
                label: 'Self',
                data: values && values.map((data) => data.type3),
                backgroundColor: 'rgb(102, 0, 51)',
                borderWidth: 0,
                borderRadius: 10,
                barPercentage: 0.3,
                categoryPercentage: 1
            },
        ],
    };
    return (
        <>
            {/* <div className="flex gap-2">
                <Card className="flex flex-col gap-2 relative" style={{ marginTop: "1rem", height: "300px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                    <div className="font-semibold">
                        <p className="text-sm text-center">Meeting Count</p>
                    </div>
                    <Pie data={chartData} />
                </Card>
            </div> */}
            <div className="flex gap-2 mt-4">
                <Card className="flex flex-col justify-between gap-2 relative" style={{ marginTop: "1rem", height: "320px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                    <div className="font-semibold">
                        <p className="text-sm text-center">Meetings this Week</p>
                    </div>
                    <div className="flex justify-around items-center max-w-[400px] flex-1">
                        <div className="flex justify-center items-center gap-1"><span className="bg-[#0044cc] w-2 h-2"></span><span className="text-xs">Official</span></div>
                        <div className="flex justify-center items-center gap-1"><span className="bg-[#999966] w-2 h-2"></span><span className="text-xs">Personal</span></div>
                        <div className="flex justify-center items-center gap-1"><span className="bg-[#660033] w-2 h-2"></span><span className="text-xs">Self</span></div>
                    </div>
                    <Bar data={chartData2}
                        height={300}
                        options={{
                            maintainAspectRatio: true,
                            responsive: true,
                            redraw: false,
                            elements: {
                                line: {
                                    tension: 1
                                }
                            },
                            scales: {
                                x: {
                                    stacked: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                                y: {
                                    stacked: true,
                                    ticks: {
                                        precision: 0
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: false
                                },
                            },
                        }
                        } />
                </Card>
            </div>
        </>
    );
};

export default MeetingCountGraph
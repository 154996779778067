import React, { useEffect, useState } from 'react'
import EditTargetsTable from './EditTargetsTable';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { config } from '../Config/Config';
import moment from 'moment-timezone';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)
const TargetsBox = ({ data, role }) => {
    const [totals, setTotals] = useState([]);
    const [achieved, setAchieved] = useState([]);
    const [targets, setTargets] = useState([])
    const [ctargets, setCTargets] = useState()

    useEffect(() => {
        setCTargets(data)
    }, [])

    useEffect(() => {
        let targetsArray = targets.map(t => t.targets)
        const totalCounts = targetsArray.reduce((acc, arr) => {
            arr.forEach((obj) => {
                const existingObj = acc.find((item) => item.id === obj.id);
                if (existingObj) {
                    existingObj.totalCount += obj.target;
                } else {
                    acc.push({ id: obj.id, totalCount: obj.target });
                }
            });
            return acc;
        }, []);
        const totalAchieved = targetsArray.reduce((acc, arr) => {
            arr.forEach((obj) => {
                const existingObj = acc.find((item) => item.id === obj.id);
                if (existingObj) {
                    existingObj.totalAchieved += obj.achieved;
                } else {
                    acc.push({ id: obj.id, totalAchieved: obj.achieved });
                }
            });
            return acc;
        }, []);
        setTotals(totalCounts);
        setAchieved(totalAchieved)
    }, [targets])


    return (
        <div className='flex flex-col w-[16rem]'>
            <div className="flex border-2 border-gray-200 justify-between text-center md:text-left bg-gray-300">
                <div className="flex text-sm w-full font-semibold px-1 py-1 text-left justify-between">
                    {role === 1 ?
                        <div className='flex justify-between w-full'>
                            <p>{ctargets?.branchName} &nbsp;({ctargets?.branchCode})</p>
                            <div>
                                {ctargets?.status === 1 ? <div className='w-3 h-3 bg-green-600 rounded-xl'></div>
                                    : ctargets?.status === 2 ? <div className='w-3 h-3 bg-blue-500 rounded-xl'></div>
                                        : ctargets?.status === 3 ? <div className='w-3 h-3 bg-orange-400 rounded-xl'></div>
                                            : <div className='w-3 h-3 bg-red-700 rounded-xl'></div>}
                            </div>
                        </div>
                        : (role === 2) ?
                            <div className='flex justify-between w-full'>
                                <p>{ctargets?.salesperson}&nbsp;({ctargets?.employeeId})</p>
                                <div>{ctargets?.status === 1 ? <div className='w-3 h-3 bg-green-600 rounded-xl'></div>
                                    : ctargets?.status === 2 ? <div className='w-3 h-3 bg-blue-500 rounded-xl'></div>
                                        : ctargets?.status === 3 ? <div className='w-3 h-3 bg-orange-400 rounded-xl'></div>
                                            : <div className='w-3 h-3 bg-red-700 rounded-xl'></div>}</div>
                            </div> : <></>}
                </div>
            </div>
            <div className='p-2 border-2 bg-gray-100'>
                <div>Yearly Targets {data.targets.map((t) => t.target).reduce((a, b) => parseFloat((a + b).toFixed(2)), 0)}</div>
                <div>Yearly Forecasts {data.targets.map((t) => t.forecast).reduce((a, b) => parseFloat((a + b).toFixed(2)), 0)}</div>
            </div>
        </div>
    )
}


export default TargetsBox
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Modal, Form, OverlayTrigger, Tooltip, FormControl, Card, Button } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import ErrorPopup from "../PopUps/ErrorPopup";
import { enqueueSnackbar } from 'notistack'
import Navbar1 from "../Navbar/Navbar1";
import LeftMenu1 from "../LeftMenu/LeftMenu1";
import uploadImage from "../Utils/UploadImage";
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const EditCompany = () => {
    const navigate = useNavigate();
    const locations = useLocation();
    const employee = locations?.state.employee
    const data = locations?.state.data
    const companyTypes = locations?.state.companyTypes
    const [companyName, setCompanyName] = useState('');
    const [category, setCategory] = useState('');
    const [userType, setUserType] = useState();
    const [selectedUserType, setSelectedUserType] = useState();
    const [pan, setPan] = useState('');
    const [registrationNo, setRegistrationNo] = useState('');
    const [address, setAddress] = useState('');
    const [selectedBusinessType, setSelectedBusinessType] = useState()
    const [typeOfBusiness, setTypeOfBusiness] = useState()
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [show, setShow] = useState(true);
    const [compayNameType, setCompanyNameType] = useState('')
    const [showPopup, setShowPopup] = useState(false);
    const [popupMsg, setPopupMsg] = useState(false);
    const [status, setStatus] = useState('')
    const [companyType, setCompanyType] = useState('Head Office')
    const [selectedStatus, setSelectedStatus] = useState();
    const [companyCode, setCompanyCode] = useState('HO-01')
    const [duration, setDuration] = useState()
    const [selectedDuration, setSelectedDuration] = useState()
    const [city, setCity] = useState()
    const [location, setLocation] = useState()
    const [country, setCountry] = useState()
    const [durations, setDurations] = useState([])
    const [errors, setErrors] = useState({});
    const [companySizes, setCompanySizes] = useState([]);
    const [selectedSize, setSelectedSize] = useState();
    const [industryTypes, setIndustryType] = useState([]);
    const [selectedIndustryType, setSelectedIndustryType] = useState();
    const [image, setImage] = useState();
    const [verifiedStatus, setVerifiedStatus] = useState(false);

    useEffect(() => {
        setCompanyName(data.name)
        setCategory(data.category)
        setStatus(data.status)
        setSelectedUserType(data.userType)
        setTypeOfBusiness(data.typeOfBusiness)
        setPan(data.pan)
        setCity(data.city)
        setLocation(data.location)
        setCountry(data.country)
        setSelectedDuration(data.duration)
        setCompanyCode(data.companyCode);
        setSelectedIndustryType(data.industryType)
        setRegistrationNo(data.registrationNo)
        getCompany(data?.typeOfBusiness)
        getAllDurations()
        getAllUserTypes();
        setImage(data.logo)
        setVerifiedStatus(data.verified)
    }, []);
    const handleChangeBusinessType = (e) => {
        setSelectedBusinessType(e.target.value);
    }
    const handleChangeCategory = (e) => {
        setCategory(e.target.value);
    }
    const handleChangeDuration = (e) => {
        setSelectedDuration(e.target.value);
    }
    const getAllDurations = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getAllDurations', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message);
                    setDurations(response.data.duration);
                    setCompanySizes(response.data.companySizes);
                    setIndustryType(response.data.industryTypes)
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const getAllUserTypes = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getAllUserTypes', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message);
                    setUserType(response.data.userTypes)
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const updateCompany = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: "error" })
            return
        };
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload;
        console.log("type", typeOfBusiness, selectedBusinessType)
        if (category === "Paid") {
            payload = {
                "companyId": data.id,
                "name": companyName,
                "category": category,
                "pan": pan,
                "userType": parseInt(selectedUserType),
                "typeOfBusiness": selectedBusinessType === undefined ? typeOfBusiness : parseInt(selectedBusinessType),
                "duration": parseInt(selectedDuration),
                "registrationNo": registrationNo,
                "city": city,
                "location": location,
                "country": country,
                "status": selectedStatus == null ? status : parseInt(selectedStatus),
                "industryType": parseInt(selectedIndustryType),
                "logo": image,
                "verified": verifiedStatus
            }
        } else {
            payload = {
                "companyId": data.id,
                "name": companyName,
                "category": category,
                "userType": parseInt(selectedUserType),
                "pan": pan,
                "typeOfBusiness": selectedBusinessType === undefined ? typeOfBusiness : parseInt(selectedBusinessType),
                "registrationNo": registrationNo,
                "status": selectedStatus == null ? status : parseInt(selectedStatus),
                "city": city,
                "location": location,
                "country": country,
                "industryType": parseInt(selectedIndustryType),
                "logo": image,
                "verified": verifiedStatus
            }
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/editCompany', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    enqueueSnackbar('Company Details Updated Successfully!', { variant: "success" })
                    navigate(-1);
                } else {
                    console.log("Error", payload);

                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
                setShow(false);
                setShowPopup(true);
                setPopupMsg(error.response.data.message);
                setTimeout(() => { setShowPopup(false) }, 3000)
            });
    };
    const getCompany = (typeId) => {
        let cname = (companyTypes?.find(cType => cType.id === typeId))
        console.log("cname", cname)
        setCompanyNameType(cname.companyType)
    }
    const handleChangeStatus = (e) => {
        setSelectedStatus(e.target.value);
    }
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!companyName) {
            errorObj = {
                ...errorObj,
                companyNameError: true
            }
            error = true;
        }
        if (!category) {
            errorObj = {
                ...errorObj,
                categoryError: true
            };
            error = true;
        }
        if (!status) {
            errorObj = {
                ...errorObj,
                statusError: true
            }
            error = true;
        }
        if (!pan) {
            errorObj = {
                ...errorObj,
                panError: true
            }
            error = true;
        }
        if (!registrationNo) {
            errorObj = {
                ...errorObj,
                registrationNoError: true
            }
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    };
    const handleImage = async (e) => {
        if (e.target.files && e.target.files[0]) {
            console.log(e.target.files[0])
            const res = await uploadImage(e.target.files[0]);
            console.log(res)
            if (res) setImage(res)
        }
    }
    return (
        <div>
            {showPopup ?
                <ErrorPopup show={showPopup} setShow={setShowPopup} msg={popupMsg} />
                :
                <section className="h-screen flex flex-col justify-center items-center mx-5">
                    <Navbar1 employee={employee} />
                    <LeftMenu1 section="Edit Company" />
                    <div>
                        <i onClick={() => navigate('/superadmin/Company')} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i>
                        <div className="h-32 text-center object-contain absolute top-40 right-72">
                            <label htmlFor="upload-button">
                                {image ? (
                                    <img src={image} alt="Logo..." className="w-20 h-20 cursor-pointer" />
                                ) : (
                                    <>
                                        <i className="fa fa-upload fa-2x" aria-hidden="true"></i>
                                        <div className="up-load">Company Logo</div>
                                    </>
                                )}
                            </label>
                            <input
                                type="file"
                                style={{ display: "none" }}
                                id="upload-button"
                                name="image"
                                onChange={(e) => handleImage(e)}
                            />
                        </div>
                    </div>
                    <div className="text-center md:text-left">
                        <label className="text-center md:text-left mt-14 mr-1 text-lg text-black-600">Edit Company</label>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-20">
                        <div className="flex flex-col gap-1.5">
                            <label className="mt-2 text-sm text-black-600">Company Code</label>
                            <p className={`text-sm w-full px-4 py-2 border-[1px] border-gray-300 rounded -mt-2`}>{companyCode}</p>
                            <label className="mt-2 text-sm text-black-600">Company Name</label>
                            <input className={`text-sm w-full px-4 py-2 ${errors.companyNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded -mt-2`}
                                type="text"
                                value={companyName}
                                onChange={(e) => { setCompanyName(e.target.value); setErrors({ ...errors, companyNameError: false }) }}
                                name="companyName"
                                placeholder="Company Name" />
                            <label className="mt-2 text-sm text-black-600">Type Of Business</label>
                            <select name="typeOfBusiness" value={selectedBusinessType} className={`text-sm w-full px-4 py-2 border-[1px] border-gray-300 rounded -mt-2`}
                                onChange={(e) => handleChangeBusinessType(e)} >
                                <option value={typeOfBusiness ? typeOfBusiness : ""}>{compayNameType ? compayNameType : "Select Type"}</option>
                                {companyTypes && companyTypes.map(cType => {
                                    return (
                                        <option key={cType.id} value={cType.id}>{cType.companyType}</option>
                                    )
                                })}
                            </select>
                            <label className="mt-2 text-sm text-black-600">Type Of User</label>
                            <select name="type" value={selectedUserType} className={`text-sm w-full px-4 py-2 ${errors.userTypeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded -mt-2`}
                                onChange={(e) => { setSelectedUserType(e.target.value); setErrors({ ...errors, typeError: false }) }}>
                                <option value="">Select Type of User</option>
                                {userType && userType.map(cType => {
                                    return (
                                        <option key={cType.id} value={cType.id}>{cType.userType}</option>
                                    )
                                })}
                            </select>
                            <label className="mt-2 text-sm text-black-600">Location</label>
                            <input className={`text-sm w-full px-4 py-2 ${errors.locationError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded -mt-2`}
                                type="text"
                                onChange={(e) => { setLocation(e.target.value); setErrors({ ...errors, locationError: false }) }}
                                name="location"
                                value={location}
                                placeholder="Location" />
                            <label className="mt-2 text-sm text-black-600">City</label>
                            <input className={`text-sm w-full px-4 py-2 ${errors.countryError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded -mt-2`}
                                type="text"
                                onChange={(e) => { setCity(e.target.value); setErrors({ ...errors, cityError: false }) }}
                                name="city"
                                value={city}
                                placeholder="City" />
                            <label className="mt-2 text-sm text-black-600">User Type</label>
                            <select name="type" value={selectedUserType} className={`text-sm w-full px-4 py-2 ${errors.userTypeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded -mt-2`}
                                onChange={(e) => { setSelectedUserType(e.target.value); setErrors({ ...errors, typeError: false }) }}>
                                <option value="">Select Type of User</option>
                                {userType && userType.map(cType => {
                                    return (
                                        <option key={cType.id} value={cType.id}>{cType.userType}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="flex flex-col gap-1.5 relative justify-between">
                            <label className="mt-2 text-sm text-black-600">Country</label>
                            <input className={`text-sm w-full px-4 py-2 ${errors.countryError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded -mt-2`}
                                type="text"
                                onChange={(e) => { setCountry(e.target.value); setErrors({ ...errors, countryError: false }) }}
                                name="country"
                                value={country}
                                placeholder="Country" />

                            <label className="mt-2 text-sm text-black-600">Pan</label>
                            <input className={`text-sm w-full px-4 py-2 ${errors.panError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded -mt-2`}
                                type="text"
                                onChange={(e) => { setPan(e.target.value); setErrors({ ...errors, panError: false }) }}
                                name="pan"
                                value={pan}
                                placeholder="PAN" />
                            <label className="mt-2 text-sm text-black-600">Registration No</label>
                            <input className={`text-sm w-full px-4 py-2 ${errors.registrationNoError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded -mt-2`}
                                type="text"
                                onChange={(e) => { setRegistrationNo(e.target.value); setErrors({ ...errors, registrationNoError: false }) }}
                                name="registrationNo"
                                value={registrationNo}
                                placeholder="Registration No" />
                            <label className="mr-1 mt-2 text-sm text-black-600">Status</label>
                            <select name="status" value={selectedStatus} className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded -mt-2"
                                onChange={(e) => handleChangeStatus(e)} >
                                <option value={selectedStatus == null ? status : selectedStatus}>{selectedStatus == null ? status == 1 ? "Active" : "Inactive" : selectedStatus == 1 ? "Active" : "Inactive"}</option>
                                <option key={1} value={1}>Active</option>
                                <option key={2} value={2}>Inactive</option>
                            </select>
                            <label className="mt-2 text-sm text-black-600">Industry Type</label>
                            <select name="industryType" value={selectedIndustryType} className={`text-sm w-full px-4 py-2 border-[1px] border-gray-300 rounded -mt-2`}
                                onChange={(e) => setSelectedIndustryType(e.target.value)} >
                                <option value="">Select Industry Type</option>
                                {industryTypes && industryTypes.map(cType => {
                                    return (
                                        <option key={cType.id} value={cType.id}>{cType.industryType}</option>
                                    )
                                })}
                            </select>
                            <label className="mt-2 text-sm text-black-600">Category</label>
                            <select name="category" value={category} className={`text-sm w-full px-4 py-2 ${errors.categoryError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded -mt-2`}
                                onChange={(e) => handleChangeCategory(e)} >
                                <option value={category}>{category == '' ? "Select Category" : category}</option>
                                <option key={1} value={"Free"}>Free</option>
                                <option key={2} value={"Paid"}>Paid</option>
                            </select>
                            {category === "Paid" ?
                                <div>
                                    <label className="mt-2 text-sm text-black-600">Duration</label>
                                    <select name="type" value={selectedDuration} className={`text-sm w-full px-4 py-2 border-[1px] border-gray-300 rounded -mt-2`}
                                        onChange={(e) => handleChangeDuration(e)}>
                                        <option value="">Select Duration</option>
                                        {durations.map(d => {
                                            return (
                                                <option key={d.id} value={d.id}>{d.duration}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                : ""}
                            <label className="text-base font-medium mt-1 text-black-600">
                                <input className={`text-sm mr-2 cursor-pointer`}
                                    type="checkbox"
                                    onChange={(e) => { setVerifiedStatus(e.target.checked) }}
                                    name="registrationNo"
                                    value={registrationNo}
                                    defaultChecked={data?.verified} />
                                Verification Status</label>
                            <div className="flex justify-end text-center md:text-left">
                                <button className=" bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2  font-semibold uppercase rounded text-xs" onClick={updateCompany} type="submit">Update</button>
                            </div>
                        </div>
                    </div>
                </section>}
        </div >
    )
}

export default EditCompany
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import SuccessPopup from "../PopUps/SuccessPopup";
import ErrorPopup from "../PopUps/ErrorPopup";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../images/lms logo.svg";
import picture from "../images/lsm picture.svg";
import NebutechLogo from "../NebutechLogo/NebutechLogo";
import { enqueueSnackbar } from "notistack";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const validPasswordRegex =
    /^.*(?=.{10,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/
const validPhoneNoRegex = /^\+91[6789]\d{9}$/;

const SignUp = () => {
    const { companyname, id: uid } = useParams();
    const navigate = useNavigate();
    const [mobile, setMobile] = useState("")
    const [mobileError, setMobileError] = useState("")
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState("")
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [employeeIdError, setEmployeeIdError] = useState("")
    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);
    const [msg, setMsg] = useState('');
    const [checked, setChecked] = useState(false);
    const [companyDetails, setCompanyDetails] = useState();
    const [isVisible, setIsVisible] = useState(false);
    const [employeeId, setEmployeeId] = useState('');

    useEffect(() => {
        let compDet = sessionStorage.getItem("cName") || localStorage.getItem("cName");
        if (compDet) {
            setCompanyDetails(simpleCrypto.decrypt(compDet))
        }
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 500);

        return () => clearTimeout(timer);
    }, [])

    const handleBlur = (event) => {
        const { name } = event.target;
        validateField(name);
        return;
    }
    const handleCheck = () => {
        setChecked(!checked)
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let formFileds = [
            "mobile",
            "password",
            "confirmPassword",
            "employeeId"
        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = validateField(field) && isValid;
        });
        if (!isValid) {
            console.log("Please enter the details")
            enqueueSnackbar("Please enter valid the details", { variant: "error" });
        } else
            signUp();
    }
    const signUp = () => {
        if (!checked) {
            setMsg("Please accept terms and conditions");
            setShow(true);
            setTimeout(() => {
                setMsg(''); setShow(false);
            }, 2000)
        } else {
            let headers = {
                "Content-Type": 'application/json',
                "applicationType": "web"
            }
            var payload = {
                "mobile": mobile,
                "password": simpleCrypto.encrypt(password),
                "uniqueCode": uid ? uid : null,
                "employeeId": employeeId && employeeId.toUpperCase()
            }
            console.log("payload", payload)
            axios.post(config.apiUrl + 'employees/register', payload)
                .then(function (response) {
                    console.log(response);
                    if (response.data.status == true) {
                        console.log("success", response.data.message, payload);
                        setMsg(response.data.message);
                        setShow(true);
                        setTimeout(() => {
                            setMsg(''); setShow(false);
                            companyname && uid ? navigate(`/${companyname}/${uid}/login`) : navigate("/")
                        }, 2000)
                    } else {
                        console.log("Error", payload);
                        setMsg(response.data.message);
                        setShowError(true);
                        setTimeout(() => { setMsg(''); setShowError(false) }, 2000)
                    }
                })
                .catch(function (error) {
                    console.log("error", error, payload);
                });
        }
    };

    const validateField = (name) => {
        let isValid = false;
        if (name === "mobile") isValid = validateMobile();
        else if (name === "password") isValid = validatePassword();
        else if (name === "confirmPassword") isValid = validateConfirmPassword();
        else if (name === "employeeId") isValid = validateEmployeeId();
        return isValid;
    }
    const validateMobile = () => {
        let mobileError = "";
        const value = mobile;
        if (value.trim === "") mobileError = "Please enter valid phone number";
        else if (!validPhoneNoRegex.test(value))
            mobileError = "Please enter valid phone number";
        setMobileError(mobileError)
        return mobileError === "";
    }
    const validatePassword = () => {
        let passwordError = "";
        const value = password;
        if (value.trim === "") passwordError = "Password is required";
        else if (!validPasswordRegex.test(value))
            passwordError = "Min 10 with 1 capital, 1 numeric and 1 character (!@#$%^&*)";
        setPasswordError(passwordError)
        return passwordError === "";
    }

    const validateConfirmPassword = () => {
        let confirmPasswordError = "";
        if (password !== confirmPassword)
            confirmPasswordError = "Password does not match";
        setConfirmPasswordError(confirmPasswordError)
        return confirmPasswordError === "";
    }
    const validateEmployeeId = () => {
        let empIdError = "";
        const value = employeeId;
        if (value === "") empIdError = "Employee ID is required";
        setEmployeeIdError(empIdError)
        return empIdError === "";
    }
    return (
        <div className="h-screen flex flex-col login-bg">
            <div className="flex w-full h-16 items-center justify-left text-sm bg-custom-bg-color text-custom-text-color">
                <div className='justify-left items-center ml-3 w-12'><img src={logo} /></div>
                <div className="text-2xl font-semibold w-[90vw] text-center items-center">Entropi</div>
            </div>
            <section className="h-screen flex justify-around items-center mr-10">
                <SuccessPopup show={show} setShow={setShow} msg={msg} />
                <ErrorPopup show={showError} setShow={setShowError} msg={msg} />
                <div className="md:w-2/3 max-w-3xl">
                    <img src={picture} />
                </div>
                <div style={{ transitionDuration: '0.3s' }}
                    className={`md:w-1/3 max-w-sm h-[50vh] animate-fade-in ease-in-out ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
                    <div className="text-center md:text-left">
                        <div className={`flex items-center gap-4 pb-6 justify-center`}>
                            {companyDetails && companyDetails.logo ? <div className='border-2 rounded-xl p-1 border-black'><img src={companyDetails.logo} alt='' className='w-16 h-16' /></div>: <></>}
                            <p className='text-2xl font-semibold'>{companyDetails?.name}</p>
                        </div>
                        <p className="mr-1 text-lg">Register</p>
                    </div>
                    <div>
                        <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-2"
                            type="text"
                            placeholder="Employee ID"
                            name="employeeId"
                            onChange={(e) => { setEmployeeId(e.target.value); setEmployeeIdError(''); }}
                            onBlur={handleBlur} />
                        <div className='error'>
                            {employeeIdError && (<div className="error">{employeeIdError}</div>)}
                        </div>
                    </div>
                    <div>
                        <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-3"
                            type="text"
                            placeholder="Mobile Number"
                            name="mobile"
                            onChange={(e) => { setMobile(e.target.value); setMobileError('') }}
                            onBlur={handleBlur} />
                        <div className='error'>{mobileError && (
                            <div className="error">{mobileError}</div>
                        )}</div>
                    </div>
                    <div>
                        <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-3"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            onChange={(e) => { setPassword(e.target.value); setPasswordError('') }}
                            onBlur={handleBlur}
                            placeholder="Password" />
                        <span className="sticky float-right -mt-9 p-1 mr-2 cursor-pointer"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <i className="fa fa-eye" aria-hidden="true"></i> : <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                        </span>
                        <div className='error'>{passwordError && (
                            <div className="error">{passwordError}</div>
                        )}</div>
                    </div>
                    <div>
                        <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-3"
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            onChange={(e) => { setConfirmPassword(e.target.value); setConfirmPasswordError('') }}
                            onBlur={handleBlur}
                            placeholder="Confirm Password" />
                        <span className="sticky float-right -mt-9 p-1 mr-2 cursor-pointer"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                            {showConfirmPassword ? <i className="fa fa-eye" aria-hidden="true"></i> : <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                        </span>
                        <div className='error'>{confirmPasswordError && (
                            <div className="error">{confirmPasswordError}</div>
                        )}</div>
                    </div>
                    <div className="flex text-xs mt-3"><input type="checkbox" checked={checked} onChange={handleCheck} /> <span className="ml-1 text-xs">I agree to the
                        {companyname && uid ?
                            <Link to={`/${companyname}/${uid}/terms`}><span className="text-sky-500 hover:text-sky-500"> Terms & Conditions </span></Link>
                            : <Link to={`/terms`}><span className="text-sky-500 hover:text-sky-500"> Terms & Conditions </span></Link>}
                    </span></div>
                    <div className="flex justify-between items-center text-center md:text-left">
                        <div></div>
                        <button className="mt-1 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs" type="submit" onClick={handleSubmit}>SignUp</button>
                    </div>
                    <div className="mt-3 font-semibold text-sm text-slate-500 text-center md:text-left">
                        Already have an account?
                        {companyname && uid ?
                            <Link className="text-red-600 hover:underline hover:underline-offset-4" to={`/${companyname}/${uid}/login`}>&nbsp;Login</Link>
                            : <Link className="text-red-600 hover:underline hover:underline-offset-4" to={`/`}>&nbsp;Login</Link>}
                    </div>
                </div>
            </section>
            <NebutechLogo />
        </div>
    )
}

export default SignUp

import React, { useEffect, useState } from 'react';
import tick from "../../images/tick.svg";
import axios from 'axios';
import { config } from '../../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import { enqueueSnackbar } from 'notistack';
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const EditFields = ({ activeEntities, productEmployees, products }) => {
    const [originalValues, setOriginalValues] = useState([]);
    const [currentValues, setCurrentValues] = useState([]);
    const [companyTypes, setCompanyTypes] = useState([]);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        getCompanyTypes();
    }, [])
    const getCompanyTypes = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }

        axios.get(config.apiUrl + 'superAdmin/getCompanyTypes', { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    if (response.data.status == true) {
                        console.log("success all products fetched from query", response.data);
                        setCompanyTypes(response.data.companyTypes);
                        setColumns(response.data.columns);
                        setCurrentValues(JSON.parse(JSON.stringify(response.data.currentValues)));
                        setOriginalValues(JSON.parse(JSON.stringify(response.data.currentValues)));
                    } else {
                        console.log("Error");
                    }
                } else {
                    console.log("Error");
                }
            }).catch(error => {
                console.error('Error loading company types data:', error);
            })
    };

    const isAvailable = (fieldStatus, col) => {
        let currentValsArray = fieldStatus && fieldStatus.map(item => item[col]).filter(item => item !== undefined);
        if (!currentValsArray || currentValsArray.length === 0) return false;
        return currentValsArray[0];
    }
    const toggleAvailability = (id, col) => {
        let newStatusArray = [...currentValues]
        newStatusArray = newStatusArray.map(item => {
            if (item.id === id) {
                let currentStatus = item.fieldStatus;
                currentStatus.forEach(element => {
                    if (element[col] !== undefined) {
                        element[col] = !element[col];
                    }
                });
            }
            return item;
        })
        setCurrentValues([...newStatusArray])
    }
    const updateFieldAvailability = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            newFieldStatus: JSON.stringify(currentValues)
        }
        console.log("payload", payload);
        axios.post(config.apiUrl + 'superAdmin/updateFieldAvailability', payload, { headers: headers })
            .then((response) => {
                console.log(response.data.message, response.data);
                enqueueSnackbar("Customer Activity Fields Updated Successfully!", { variant: "success" })
                getCompanyTypes();
                setOriginalValues(currentValues);
            }).catch(error => {
                console.log("error", error.response.data.message);
            })
    };
    const cancel = () => {
        setCurrentValues([...originalValues]);
    }
    function camelCaseToTitleCase(inputString) {
        const titleCaseString = inputString.replace(/([A-Z])/g, ' $1');
        return titleCaseString.charAt(0).toUpperCase() + titleCaseString.slice(1);
    }

    return (
        <div className="ml-8 mt-20">
            <div className="text-left font-semibold text-black"><h5>Customer Activitiy Fields Availability</h5></div>
            <div className="mt-4 tableFixHead" style={{ height: "77vh" }}>
                <table className="border-spacing-x-0 border-spacing-y-2 border-separate">
                    <thead style={{ background: "#eee" }}>
                        <tr>
                            <th scope="col" className="th-heading text-left">Company Types</th>
                            {companyTypes && companyTypes?.length > 0 && companyTypes.map((data, i) => {
                                return (
                                    <th key={i} scope="col" className="th-heading text-center">{data.companyType}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {columns && columns?.length > 0 && columns.map((col, i) => (
                            <tr key={i} className="tr whitespace-nowrap">
                                <td className="td-text text-left">{camelCaseToTitleCase(col.field)}</td>
                                {currentValues && currentValues?.length > 0 && currentValues.map((data, i) => {
                                    return (
                                        <td key={data?.id} className="td-text">
                                            <div className="flex justify-center items-center">
                                                <span className="w-6 h-6 border-2 border-black cursor-pointer" onClick={() => toggleAvailability(data?.id, col.field)}>
                                                    <img src={isAvailable(data?.fieldStatus, col?.field) ? tick : ""} alt="" />
                                                </span>
                                            </div>
                                        </td>
                                    )
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="pr-8 flex justify-between items-center gap-3">
                <button className="max-w-max bg-orange-600 hover:bg-orange-700 p-2 px-3 text-white font-semibold uppercase rounded text-xs whitespace-nowrap" onClick={() => cancel()}>Cancel</button>
                <button className=" bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color p-2 px-4  font-semibold uppercase rounded text-xs whitespace-nowrap" onClick={() => updateFieldAvailability()}>Save</button>
            </div>
        </div>
    )
}

export default EditFields
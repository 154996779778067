import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import EmployeeContext from '../../context/employee/EmpContext';
import Navbar1 from '../../Navbar/Navbar1';
import LeftMenu1 from '../../LeftMenu/LeftMenu1';

const Unregister = () => {
    const { empState: employee } = useContext(EmployeeContext);
    const navigate = useNavigate();
    const location = useLocation();
    const { data } = location.state;
    const goback = () => {
        navigate(`/${employee.companyName}/companyadmin/MarketPlace`)
    }
    return (
        <div>
            <Navbar1 />
            <LeftMenu1 section={`Unregister`} />
            <div><i onClick={() => goback()} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i></div>

            <div className='flex flex-col gap-3 justify-center items-center w-2/3 mt-20 ml-[20vw] px-20'>
                <p className='font-bold text-xl'>
                    Steps to unregister from {data.serviceName}
                </p>
                <div className='flex flex-col gap-3 font-semibold text-gray-600 border-2 border-black rounded-xl p-3'>
                    <p>
                        To discontinue this service at any time by utilising the "Report Case" function under the case type "Marketplace" and specifying case details as "<span className='font-bold text-black'>Opt Out – {data.serviceName}</span>". Upon processing this case, the company's details will no longer be included in search results.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Unregister
import React, { useState, useEffect } from "react";
import { Modal } from 'react-bootstrap'
import error from "../images/errro.svg"
import logout from '../images/logout.svg'
import { useNavigate } from "react-router";
import SimpleCrypto from "simple-crypto-js";
import { config } from "../Config/Config";
import axios from "axios";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const LogoutPopup = ({ closePopup, employee }) => {
    const navigate = useNavigate();
    useEffect(() => {
        console.log(employee)
    }, []);
    const Logout = () => {
        updateLogOutTime();
        localStorage.removeItem("random");
        localStorage.removeItem("roleId");
        localStorage.removeItem("junk");
        localStorage.removeItem("cName");
        if (employee?.uniqueCode && employee?.companyName)
            navigate(`/${employee.companyName}/${employee.uniqueCode}/login`);
        else
            navigate("/");
    }
    const updateLogOutTime = async () => {
        let headers = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let payload = {
            employeeId: employee?.employeeId,
            companyId: employee.companyId,
            token: simpleCrypto.decrypt(localStorage.getItem("random")),
        }
        axios.post(config.apiUrl + "employees/updateLogoutTime", payload, { headers: headers })
            .then((response) => {
                if (response.data.status === true) {
                    console.log("Success", response.data.message)
                } else {
                    console.log("Error")
                }
            })
    }
    return (
        <Modal show={true} onHide={closePopup} size="md" centered >
            <Modal.Header closeButton style={{ zIndex: "100" }}>
            </Modal.Header>
            <Modal.Body>
                <div className='flex flex-col gap-4 justify-center items-center'>
                    <p className='-mt-10 text-lg text-orange-400'>Are you sure you want to logout?</p>
                    <div className="flex justify-between text-center md:text-left">
                        <button className="mt-4 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white font-semibold uppercase rounded text-xs mr-5" onClick={closePopup} type="submit">No</button>
                        <button className="ml-5 mt-4 bg-custom-bg-color hover:custom-bg-color text-custom-text-color px-4 py-2  font-semibold uppercase rounded text-xs" onClick={Logout} type="submit">Yes</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default LogoutPopup


import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const employee = location.state;
    console.log(employee);
    const handleBranchClick = () => {
        navigate("/createbranch", { state: { employee } })
    }
    const handleEmployeeClick = () => {
        navigate("/createemployee", { state: { employee } })
    }
    return (
        <div className=''>
            {employee.role === 1 ?
                <div className='h-screen flex flex-col justify-center items-center'>
                    Hello Admin,what would you like to do
                    <div className='my-4'>
                        <div onClick={handleBranchClick} className='text-black font-semibold hover:underline cursor-pointer'>Create a Branch</div>
                    </div>
                    <div>
                        <div onClick={handleEmployeeClick} className='text-black font-semibold hover:underline cursor-pointer'>Add an Employee</div>
                    </div>
                </div>
                : null}

        </div>
    )
}

export default Home
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import axios from 'axios';
import { config } from '../../Config/Config';
import { Bar } from 'react-chartjs-2';
import SimpleCrypto from "simple-crypto-js";


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const LeadsToSales = () => {
    const [branches, setBranches] = useState([]);
    const [values, setValues] = useState();
    const [monthlyValues, setMonthlyValues] = useState();
    const [current, setCurrent] = useState();

    useEffect(() => {
        getLeadsToSalesCountByBranch()
    }, []);
    useEffect(() => {
        setMonthlyData()
    }, [current])
    const setMonthlyData = () => {
        let currentMonthData = values && values.find(val => val.id === current);
        if (!currentMonthData) return;
        setMonthlyValues(currentMonthData["data"]);
    }
    const getLeadsToSalesCountByBranch = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getLeadsToSalesCountByBranch', { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log(response.data.message, response.data);
                    setBranches(response.data.branches);
                    response.data.dataArray?.length > 0 ? setValues(response.data.dataArray) : setValues([]);
                    response.data.dataArray?.length > 0 ? setCurrent(response.data.dataArray[0]?.id) : setCurrent(null)
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const monthlyData = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
            {
                label: "Leads To Sales Count",
                data: monthlyValues && monthlyValues.map(val => val.count),
                backgroundColor: ['black'],
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20
            }
        ]
    }
    return (
        <div className="flex gap-2">
            <Card className="flex flex-col justify-between relative" style={{ marginTop: "1rem", height: "250px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                <div className="font-semibold ml-12">
                    <span className="text-sm">Leads To Sales Count</span>
                </div>
                <select name="branches" value={current} className="absolute -top-1 right-1 text-xs w-32  border border-solid border-gray-300 rounded mt-2"
                    onChange={(e) => setCurrent(e.target.value)} >
                    <option value="">---Choose Branch---</option>
                    {branches && branches.map((item, i) => {
                        return (
                            <option value={item.id} key={i}>{item.branchName}</option>
                        )
                    })}
                </select>
                <div style={{ height: "200px" }}>
                    <Bar data={monthlyData}
                        options={{
                            maintainAspectRatio: true,
                            responsive: true,
                            redraw: false,
                            elements: {
                                line: {
                                    tension: 1
                                }
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                },
                                y: {
                                    ticks: {
                                        precision: 0
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: false
                                },
                            }
                        }
                        } />
                </div>
            </Card>
        </div>
    );
};

export default LeadsToSales
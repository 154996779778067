import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar1 from '../../Navbar/Navbar1';
import LeftMenu1 from '../../LeftMenu/LeftMenu1';
import EmployeeContext from '../../context/employee/EmpContext';
import UserDetails from './UserDetails';
import UserActivity from './UserActivity';
import axios from 'axios';
import { config } from '../../Config/Config';
import moment from 'moment-timezone';
import SimpleCrypto from 'simple-crypto-js';
import CustomerEvaluation from './CustomerEvaluation';
import { Spinner } from 'react-bootstrap';
import BackClick from './BackClickPopup';



var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const MultiStepForm = (props) => {
    const navigate = useNavigate();
    const { empState: employee } = useContext(EmployeeContext);
    const location = useLocation();
    const customer = location.state?.customer;
    const currentNotes = location.state?.notes;
    const [step, setStep] = useState(1);
    const [interaction, setInteraction] = useState([]);
    const [activity, setActivity] = useState([]);
    const [keyAsk, setKeyAsk] = useState([]);
    const [satisfactions, setSatisfactions] = useState([]);
    const [potentials, setPotentials] = useState([]);
    const [occasions, setOccasions] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [actionTypes, setActionTypes] = useState([]);
    const [actionStatus, setActionStatus] = useState([]);
    const [errors, setErrors] = useState({});
    const [importantDates, setImportantDates] = useState([
        { "occasion": "Birthday", "date": null },
        { "occasion": "Marriage Anniversary", "date": null },
        { "occasion": "Other", "date": null }
    ]);
    const [activityId, setActivityId] = useState('');
    const [inputValues, setInputValues] = useState({
        occasion: '',
        keyAsk: '',
        activity: '',
        interaction: '',
        notes: '',
        targetDate: null,
        actionType: '',
        actionStatus: '',
        satisfaction: '',
        potential: '',
        customerInterest: '',
        newNotes: '',
        newTargetDate: null,
    });
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        getAllParameters();
        getBranchProducts();
    }, [])
    useEffect(() => {
        console.log("Customer useeffect caleed", customer, currentNotes);
        let currentValues = { ...inputValues };
        if (currentNotes && currentNotes?.actionType) {
            currentValues = { ...currentValues, ["actionType"]: currentNotes.actionType };
            ///setting user activity = Not Interested(i.e. 7) when todo status is Not Interested(i.e. 3)
            if (currentNotes && currentNotes?.status && currentNotes.status === 3)
                currentValues = { ...currentValues, ["activity"]: 7 };
            ///setting user activity = Phone(i.e. 5) when todo action type is Phone(i.e. 1)
            else if (currentNotes.actionType === 1)
                currentValues = { ...currentValues, ["activity"]: 5 };
            ///setting user activity = email(i.e. 6) when todo action type is email(i.e. 2)
            else
                currentValues = { ...currentValues, ["activity"]: 6 }
        }
        if (currentNotes && currentNotes?.notes) {
            currentValues = { ...currentValues, ["notes"]: currentNotes.notes };
        }
        if (currentNotes && currentNotes?.status) {
            currentValues = { ...currentValues, ["actionStatus"]: currentNotes.status };
        }
        if (customer && customer?.importantDates) {
            let imporatant_dates = customer.importantDates.map(item => {
                let obj = {
                    date: item.date ? new Date(item.date) : null,
                    occasion: item.occasion
                }
                return obj;
            })
            setImportantDates([...imporatant_dates])
        }
        setInputValues({ ...inputValues, ...currentValues })
        if (customer && customer?.activityId)
            setActivityId(customer.activityId);
    }, [customer]);

    useEffect(() => {
        if (activityId)
            getCustActivities();
    }, [activityId])

    const getCustActivities = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "activityId": customer.activityId
        }
        setLoading(true)
        axios.post(config.apiUrl + 'employees/getCustActivities', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("activity Id and activity", activityId, response.data);
                    let currentValues = { ...inputValues };
                    response.data.customers?.userActivity ? currentValues = { ...currentValues, ["activity"]: response.data.customers.userActivity } : currentValues = { ...currentValues, ["activity"]: 2 }
                    response.data.customers?.interaction ? currentValues = { ...currentValues, ["interaction"]: response.data.customers?.interaction } : currentValues = { ...currentValues, ["interaction"]: 3 }
                    response.data.customers?.customersInterest ? currentValues = { ...currentValues, ["customerInterest"]: response.data.customers?.customersInterest } : currentValues = { ...currentValues, ["customerInterest"]: "" }
                    response.data.customers?.customerSatisfaction ? currentValues = { ...currentValues, ["satisfaction"]: response.data.customers?.customerSatisfaction } : currentValues = { ...currentValues, ["satisfaction"]: 5 }
                    response.data.customers?.customerPotential ? currentValues = { ...currentValues, ["potential"]: response.data.customers.customerPotential } : currentValues = { ...currentValues, ["potential"]: 5 }
                    response.data.customers?.occasion ? currentValues = { ...currentValues, ["occasion"]: response.data.customers.occasion } : currentValues = { ...currentValues, ["occasion"]: 1 }
                    response.data.customers?.userDemand ? currentValues = { ...currentValues, ["keyAsk"]: response.data.customers.userDemand } : currentValues = { ...currentValues, ["keyAsk"]: 1 }
                    response.data.customers?.products ? setSelectedProducts(response.data.customers.products) : setSelectedProducts([])
                    response.data.activityToDos?.targetDate ? currentValues = { ...currentValues, ["targetDate"]: new Date(response.data.activityToDos.targetDate) } : currentValues = { ...currentValues, ["targetDate"]: null }
                    response.data.activityToDos?.notes ? currentValues = { ...currentValues, ["notes"]: response.data.activityToDos.notes } : currentValues = { ...currentValues, ["notes"]: "" }
                    response.data.activityToDos?.actionType ? currentValues = { ...currentValues, ["actionType"]: response.data.activityToDos.actionType } : currentValues = { ...currentValues, ["actionType"]: '' }
                    if (customer && customer.newDealStatus) {
                        ///setting user activity = Not Interested(i.e. 7) when deal status is Not Interested(i.e. 4)
                        if (customer.newDealStatus == 4)
                            currentValues = { ...currentValues, ["activity"]: 7 }
                        ///setting user activity = Purchased(i.e. 4) when deal status is Successful(i.e. 5)
                        if (customer.newDealStatus == 5)
                            currentValues = { ...currentValues, ["activity"]: 4 }
                        setActivityId()
                    }
                    setInputValues({ ...inputValues, ...currentValues })
                } else {
                    console.log("Error");
                }
                setLoading(false)
            })
            .catch(function (error) {
                console.log("error", error);
                setLoading(false);
            });
    };
    const getAllParameters = () => {
        axios.get(config.apiUrl + 'employees/getAllParameters')
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message);
                    var a = response.data.interactions.filter((data, i) => moment(customer?.createdAt).format("MM/DD/YYYY") < moment(new Date()).format("MM/DD/YYYY") && data.id === 1 ? null : data)
                    setInteraction(a);
                    setKeyAsk(response.data.keyAsk);
                    setOccasions(response.data.occasions);
                    setSatisfactions(response.data.satisfactions);
                    setPotentials(response.data.potentials);
                    setActionTypes(response.data.actionTypes);
                    setActionStatus(response.data.actionStatus);
                    let activityArr = response.data.userActivity;
                    if (!currentNotes) {
                        let excludeItemsId = [5, 6, 7]
                        activityArr = activityArr.filter(a => !excludeItemsId.includes(a.id))
                    }
                    setActivity(activityArr);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    }
    const getBranchProducts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            branchId: employee.branchId
        }
        axios.post(config.apiUrl + 'employees/getBranchProducts', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success products", response.data);
                    setProducts(response.data.products);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const nextStep = () => {
        setStep(step => step + 1)
    }

    const prevStep = () => {
        setStep(step => step - 1)
    }

    const handleChange = (event) => {
        setInputValues({ ...inputValues, [event.target.name]: event.target.value });
    }
    const handleDateChange = (date) => {
        setInputValues({ ...inputValues, newTargetDate: date });
    }
    const selectProducts = (e) => {
        let value = (e.target.value);
        if (e.target.checked) {
            setSelectedProducts([...selectedProducts, value])
        }
        else {
            setSelectedProducts(selectedProducts.filter(product => product !== value))
        }
    }
    const handleOccasionChange = (i, key, value) => {
        let data = [...importantDates]
        data[i] = { ...data[i], [key]: value }
        console.log(data);
        setImportantDates([...data]);
    }
    const closePopup = () => { setShow(false) }
    if (loading) {
        return (
            <div className='mt-20 flex justify-center items-center'><Spinner animation="border" /></div>
        )
    }
    return (
        <div className="h-screen flex flex-col">
            {show && <BackClick closePopup={closePopup} />}
            <Navbar1 employee={employee} />
            <LeftMenu1 section="Add Activity" />
            <div><i onClick={() => setShow(true)} className="fa fa-long-arrow-left fa-2x text-orange-600 absolute left-60 top-20 cursor-pointer" aria-hidden="true"></i></div>
            {step === 1 ?
                <UserActivity
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleChange={handleChange}
                    inputValues={inputValues}
                    occasions={occasions}
                    keyAsk={keyAsk}
                    products={products}
                    selectProducts={selectProducts}
                    activity={activity}
                    interaction={interaction}
                    handleOccasionChange={handleOccasionChange}
                    importantDates={importantDates}
                    errors={errors}
                    setErrors={setErrors}
                    selectedProducts={selectedProducts}
                />
                : step === 2 ?
                    <CustomerEvaluation
                        nextStep={nextStep}
                        prevStep={prevStep}
                        inputValues={inputValues}
                        satisfactions={satisfactions}
                        potentials={potentials}
                        errors={errors}
                        setErrors={setErrors}
                        handleDateChange={handleDateChange}
                        currentNotes={currentNotes}
                        actionTypes={actionTypes}
                        actionStatus={actionStatus}
                        handleChange={handleChange}
                        selectedProducts={selectedProducts}
                        customer={customer}
                        importantDates={importantDates}
                        activityId={activityId}
                    />
                    : <></>
            }
        </div>
    )
}

export default MultiStepForm;